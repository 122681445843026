<app-page-header mainNavigation="back" defaultBack="account-settings">
    <div title [translate]="'BLOCKED_PLAYERS'" i18n="@@BLOCKED_PLAYERS">Blocked players</div>
</app-page-header>

<div class="h-full py-3 flex flex-col bg-white-pattern overflow-y-auto text-black" id="main-content">
    <div [appWebContainer] class="flex-1">
        @if (!loaded) {
            <div class="flex">
                <app-icon icon="loading" size="normal" class="mx-auto animate-spin text-orange"></app-icon>
            </div>
        }
        @if (loaded) {
            @if (blockedPlayers.length) {
                <div class="mt-4 px-4">
                    @for (user of blockedPlayers; track user; let i = $index) {
                        <div class="flex justify-between items-center">
                            <app-profile-photo
                                [ultimate]="user.is_ultimate"
                                [badge]="user?.profile?.badge === 'verified'"
                                [url]="user.profile?.profile_photo_url"
                                class="mr-4 h-fit"></app-profile-photo>
                            <div class="font-semibold text-black">{{ user.full_name }}</div>
                            <app-primary-button
                                class="ml-auto"
                                type="submit"
                                size="extrasmall"
                                (click)="tryUnblock(user.id, i)"
                                [translate]="'UNBLOCK'"
                                i18n="@@UNBLOCK">
                                Unblock
                            </app-primary-button>
                        </div>
                    }
                </div>
            }
            @if (!blockedPlayers.length) {
                <div class="mt-4 text-center text-black" [translate]="'NO_BLOCKED_PLAYERS'" i18n="@@NO_BLOCKED_PLAYERS">
                    You don't have any blocked players!
                </div>
            }
        }
    </div>
</div>
