import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { DowntimesCollectionService } from '@dc-core/dc-firestore/collection-helpers/downtimes.collection.service';
import { DCDowntime } from '@dc-core/dc-firestore/globals/firestore.tables';
import { NavController } from '@ionic/angular';
import { from, Observable, of, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DowntimeGuard {
    /**
     * Constructor
     */
    constructor(
        private _downtimesCollectionService: DowntimesCollectionService,
        private _nav: NavController
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this._check();
    }

    /**
     * Can activate child
     *
     * @param childRoute
     * @param state
     */
    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this._check();
    }

    /**
     * Can load
     *
     * @param route
     * @param segments
     */
    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        return this._check();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check if user is ultimate
     *
     * @private
     */
    private _check(): Observable<boolean> {
        return from(
            this._downtimesCollectionService
                .getInitialDowntimes()
                .then((downtimes) => {
                    if (downtimes.empty) {
                        return true;
                    } else {
                        downtimes.forEach((downtimeDoc) => {
                            const downtime = downtimeDoc.data() as DCDowntime;
                            const isDowntime = this._downtimesCollectionService.checkDowntime(downtime);

                            if (isDowntime) {
                                this._nav.navigateRoot('/maintenance');
                                return false;
                            } else {
                                return true;
                            }
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    return true;
                })
        );
    }
}
