<div class="flex h-full flex-col overflow-hidden bg-white-pattern">
    <app-page-header mainNavigation="custom" color="white">
        <div left>
            <div
                class="text-center font-bold text-black text-sm cursor-pointer"
                (click)="close()"
                [translate]="'CLOSE'"
                i18n="@@CLOSE">
                Close
            </div>
        </div>
        <div title [translate]="'UPGRADE_ACCOUNT'" i18n="@@UPGRADE_ACCOUNT">Upgrade account!</div>
    </app-page-header>

    <div class="flex flex-1 flex-col text-black overflow-y-auto pb-safe">
        @if (upgradeService.discountEvent) {
            <img [src]="'assets/images/discount-events/' + upgradeService.discountEventBanner" />
        }

        <div class="bg-gray-pattern rounded-lg relative mt-4 pt-5 mx-4">
            <div class="absolute top-0 left-0 w-full h-2/5 bg-blue rounded-t-lg"></div>
            <swiper-container
                id="ultimate-features-slider"
                init="false"
                class="h-full"
                [style.--swiper-navigation-size]="'20px'"
                [style.--swiper-navigation-color]="themeService.theme() === 'dark' ? '#000000' : '#FFFFFF'">
                @for (feature of ultimateFeatures; track feature) {
                    <swiper-slide class="h-full">
                        <div class="flex flex-col justify-between h-full">
                            <div class="flex flex-col">
                                <div class="flex justify-center mb-2">
                                    <svg
                                        width="32"
                                        height="32"
                                        viewBox="0 0 32 32"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#ultimate_ribbon_ultimate_subscription)">
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M30.5079 15.9178L27.2712 12.2168L27.7222 7.32191L22.9334 6.23416L20.4263 1.98926L15.9161 3.92599L11.4059 1.98926L8.89871 6.22089L4.10993 7.29538L4.56095 12.2035L1.32422 15.9178L4.56095 19.6188L4.10993 24.527L8.89871 25.6148L11.4059 29.8464L15.9161 27.8964L20.4263 29.8331L22.9334 25.6015L27.7222 24.5137L27.2712 19.6188L30.5079 15.9178ZM15.9161 15.9176C17.3813 15.9176 18.5691 14.7298 18.5691 13.2645C18.5691 11.7993 17.3813 10.6114 15.9161 10.6114C14.4508 10.6114 13.263 11.7993 13.263 13.2645C13.263 14.7298 14.4508 15.9176 15.9161 15.9176ZM10.611 19.8977C10.611 18.1334 14.1462 17.2446 15.9171 17.2446C17.688 17.2446 21.2232 18.1334 21.2232 19.8977V20.5609C21.2232 20.9257 20.9247 21.2242 20.5599 21.2242H11.2742C10.9094 21.2242 10.611 20.9257 10.611 20.5609V19.8977Z"
                                                fill="url(#ultimate_ribbon_ultimate_subscription_linear_gradient)" />
                                        </g>
                                        <defs>
                                            <linearGradient
                                                id="ultimate_ribbon_ultimate_subscription_linear_gradient"
                                                x1="15.9161"
                                                y1="1.98926"
                                                x2="15.9161"
                                                y2="29.8464"
                                                gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#ECD676" />
                                                <stop offset="0.24" stop-color="#D39E3A" />
                                                <stop offset="0.655" stop-color="#EDB948" />
                                                <stop offset="1" stop-color="#BB8B32" />
                                            </linearGradient>
                                            <clipPath id="ultimate_ribbon_ultimate_subscription">
                                                <rect width="31.8367" height="31.8367" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                <h5 class="text-lg font-bold text-center !text-white">{{ feature.title }}</h5>
                            </div>
                            <div class="mt-3 px-5 max-w-xs mx-auto">
                                <img src="{{ feature.image }}" />
                            </div>
                        </div>
                    </swiper-slide>
                }
            </swiper-container>
        </div>
        <div class="mt-4 mx-4 p-4 bg-white flex gap-3 text-black font-bebas-neue rounded-lg">
            @if (devicePlatform === 'web') {
                @if (upgradeService.monthlyPlan) {
                    <div
                        class="bg-gray-50 p-4 w-1/2 rounded-md text-lg cursor-pointer border-1"
                        (click)="setRecurring('monthly')"
                        [ngClass]="recurring === 'monthly' ? 'border-blue' : 'border-gray-50'">
                        <div class="text-3xl flex items-center">
                            &euro; {{ upgradeService.monthlyPlan.monthlyPrice }}
                            <div class="ml-1 text-xs font-open-sans" [translate]="'PER_MONTH'" i18n="@@PER_MONTH">
                                per month
                            </div>
                        </div>
                        <div
                            class="text-xs font-open-sans font-bold -mt-1"
                            [translate]="'BILLED_MONTHLY'"
                            i18n="@@BILLED_MONTHLY">
                            Billed monthly
                        </div>
                    </div>
                }
                @if (upgradeService.yearlyPlan) {
                    <div
                        class="bg-gray-50 p-4 w-1/2 rounded-md text-lg relative border-1"
                        (click)="setRecurring('yearly')"
                        [ngClass]="recurring === 'yearly' ? 'border-blue' : 'border-gray-50'">
                        <div class="text-3xl flex items-center">
                            @if (upgradeService.yearlyPlan.active_discount) {
                                &euro; {{ upgradeService.yearlyPlan.final_first_payment_price }}
                            } @else {
                                &euro; {{ upgradeService.yearlyPlan.price / 100 | number: '1.2-2' }}
                            }
                            <div class="ml-1 text-xs font-open-sans" [translate]="'PER_YEAR'" i18n="@@PER_YEAR">
                                per year
                            </div>
                        </div>
                        <div class="text-xs font-open-sans font-bold -mt-1">
                            {{ 'BILLED_ANNUALLY' | translate }}
                        </div>
                        <div
                            class="absolute right-0 -top-2 bg-blue !text-white rounded-md text-xs px-2 py-1"
                            style="right: -1px">
                            @if (upgradeService.yearlyPlan.active_discount) {
                                <span class="line-through">
                                    <span>&euro; {{ upgradeService.yearlyPlan.first_payment_price }}</span>
                                    <span class="ml-1" [translate]="'PER_YEAR'" i18n="@@PER_YEAR">per year</span>
                                </span>
                            } @else {
                                <span [translate]="'SAVE_DISCOUNT'" i18n="@@SAVE_DISCOUNT" class="mr-1 !text-white">
                                    Save
                                </span>
                                <span class="!text-white">{{ upgradeService.savePercentage }}%</span>
                            }
                        </div>
                    </div>
                }
            } @else if (purchases.monthlyProduct && purchases.yearlyProduct) {
                <div
                    class="bg-gray-50 p-4 w-1/2 rounded-md text-lg border-1"
                    (click)="setRecurring('monthly')"
                    [ngClass]="recurring === 'monthly' ? 'border-blue' : 'border-gray-50'">
                    @if (purchases.yearlyProduct?.pricing?.price) {
                        <div class="text-3xl flex items-center">
                            {{ removeSpaces(purchases.monthlyProduct?.pricing?.price) }}
                            <div class="ml-1 text-xs font-open-sans" [translate]="'PER_MONTH'" i18n="@@PER_MONTH">
                                per month
                            </div>
                        </div>
                        <div class="text-xs font-open-sans font-bold -mt-1">{{ 'BILLED_MONTHLY' | translate }}</div>
                    }
                </div>
                <div
                    class="bg-gray-50 p-4 w-1/2 rounded-md text-lg relative border-1"
                    (click)="setRecurring('yearly')"
                    [ngClass]="recurring === 'yearly' ? 'border-blue' : 'border-gray-50'">
                    @if (purchases.yearlyProduct?.pricing?.price) {
                        <div class="text-3xl flex items-center">
                            {{ removeSpaces(purchases.yearlyProduct?.pricing?.price) }}
                            <div class="ml-1 text-xs font-open-sans" [translate]="'PER_YEAR'" i18n="@@PER_YEAR">
                                per year
                            </div>
                        </div>
                        <div
                            class="text-xs font-open-sans font-bold -mt-1"
                            [translate]="'BILLED_ANNUALLY'"
                            i18n="@@BILLED_ANNUALLY">
                            Billed annually
                        </div>
                    }
                    <div
                        class="absolute right-0 -top-2 bg-blue !text-white rounded-md text-xs px-2 py-1"
                        style="right: -1px">
                        <span [translate]="'SAVE_DISCOUNT'" i18n="@@SAVE_DISCOUNT" class="mr-1">Save</span>
                        <span>{{ purchases.savePercentage }}%</span>
                    </div>
                </div>
            }
        </div>
        <div class="flex flex-col rounded-lg bg-neutral-50 p-4 m-4 duration-200 hover:scale-[1.02]">
            <div class="text-black font-bebas-neue text-lg" [translate]="'INCLUDED'" i18n="@@INCLUDED">Included:</div>
            <div class="ml-3 text-sm text-black font-semibold">
                @for (feature of ultimateFeatures; track feature) {
                    <div class="flex flex-col">
                        <div class="flex items-center mb-1">
                            <div class="text-blue mr-3">&#9679;</div>
                            <div>{{ feature.title }}</div>
                        </div>
                    </div>
                }
            </div>
            <div class="flex flex-col rounded-lg bg-neutral-50 gap-4">
                @if (devicePlatform === 'ios' || devicePlatform === 'android') {
                    <div
                        class="text-black font-bebas-neue text-lg mt-6 leading-none"
                        [translate]="'TERMS_AND_CONDITIONS'"
                        i18n="@@TERMS_AND_CONDITIONS">
                        Terms and conditions:
                    </div>
                    <div class="text-xs text-black">
                        @if (devicePlatform === 'ios') {
                            <p>
                                Ultimate Subscription payment will be charged to iTunes Account at confirmation of
                                purchase. Subscription automatically renews unless auto-renew is turned off at least
                                24-hours before the end of the current period. Account will be charged for renewal
                                within 24-hours prior to the end of the current period, and identify the cost of the
                                renewal. Subscriptions may be managed by the user and auto-renewal may be turned off by
                                going to the user's iTunes Account Settings after purchase.
                            </p>
                        } @else if (devicePlatform === 'android') {
                            <p>
                                Ultimate Subscription payment will be charged to your Google Play account at
                                confirmation of purchase.
                                @if (recurring === 'monthly') {
                                    Subscription automatically renews monthly until you cancel. Cancel anytime in your
                                    Google Play account.
                                } @else if (recurring === 'yearly') {
                                    Subscription automatically renews yearly until you cancel. Cancel anytime in your
                                    Google Play account.
                                }
                            </p>
                        }
                    </div>
                }
                <div class="text-xs text-black mt-4">
                    <button (click)="openTermsOfService()" [translate]="'TERMS_OF_SERVICE'" i18n="@@TERMS_OF_SERVICE">
                        Terms of Service
                    </button>
                    <button
                        class="ml-2"
                        (click)="openPrivacyPolicy()"
                        [translate]="'PRIVACY_POLICY'"
                        i18n="@@PRIVACY_POLICY">
                        Privacy Policy
                    </button>
                    @if (devicePlatform !== 'web') {
                        <button
                            class="ml-2"
                            (click)="purchases.restore(true)"
                            [translate]="'RESTORE_PURCHASE'"
                            i18n="@@RESTORE_PURCHASE">
                            Restore purchase
                        </button>
                    }
                </div>
            </div>
        </div>
        <div>
            <app-primary-button
                class="block px-4 pb-4"
                size="medium"
                addedClasses="w-full"
                [translate]="'BECOME_ULTIMATE'"
                i18n="@@BECOME_ULTIMATE"
                (click)="BuyUltimate()">
                Become Ultimate!
            </app-primary-button>
        </div>
    </div>
</div>
