import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { UserNotificationApiService } from '@dc-api/user-notification.api.service';
import { UserNotification } from '@dc-core/dc-backend/dc-classes';
import { NotificationCategory } from '@dc-core/dc-backend/dc-enums';
import { NavController } from '@ionic/angular';

@Injectable()
export class NotificationsService {
    private navController: NavController = inject(NavController);
    private userNotificationApiService: UserNotificationApiService = inject(UserNotificationApiService);

    public showNotifications: WritableSignal<boolean> = signal(false);
    public notifications: WritableSignal<UserNotification[] | null> = signal(null);
    public newNotifications: WritableSignal<number> = signal(0);
    public category: NotificationCategory | null = null;

    public toggleNotifications(category: NotificationCategory | null): void {
        this.category = category;
        if (this.showNotifications()) {
            this.showNotifications.set(false);
            this.notifications.set(null);
            this.newNotifications.set(0);
        } else {
            this.userNotificationApiService.newNotification.set(null);
            this.newNotifications.set(0);
            this.userNotificationApiService.markUserNotificationsAsSeen({ category }).then(() => {
                this.userNotificationApiService.getUserNotifications({ category, limit: 100, page: 1 }).then((res) => {
                    this.notifications.set(res.data.data);
                });
            });
            this.showNotifications.set(true);
        }
    }

    public goToNotification(notification: UserNotification, index: number): void {
        this.toggleNotifications(this.category);
        this.markAsRead(notification.id, index);

        switch (notification.category) {
            case 'profile':
                switch (notification.event) {
                    case 'friend-request.new':
                        this.navController.navigateForward('friends');
                        break;
                }
                break;
            case 'tournament':
                const tournamentId = notification.data['tournament_id'];
                switch (notification.event) {
                    case 'tournament.participant.reported':
                        this.navController.navigateForward(`tournaments/${tournamentId}/activity`, {
                            queryParams: {
                                participant: notification.data['reported_participant_id'],
                            },
                        });
                        break;
                    case 'tournament.moderator.invited':
                        this.navController.navigateForward(`tournaments/${tournamentId}`);
                        break;
                    case 'tournament.announcement.new':
                        this.navController.navigateForward(`tournaments/${tournamentId}/activity`);
                        break;
                    default:
                        if (tournamentId) {
                            this.navController.navigateForward(`tournaments/${tournamentId}`);
                        }
                        break;
                }
                break;
        }
    }

    public markAsRead(notificationId: number, index: number): void {
        this.userNotificationApiService.markUserNotificationsAsRead({ notification_id: notificationId }).then(() => {
            this.notifications.update((notifications) => {
                notifications.splice(index, 1);
                return notifications;
            });
        });
    }

    public markAllAsRead(): void {
        this.userNotificationApiService.markUserNotificationsAsRead({ category: this.category }).then(() => {
            this.toggleNotifications(this.category);
        });
    }
}
