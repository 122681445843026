import { components } from '../dc-typings/dc-backend-definitions';

export enum PageSetting {
    ACCOUNT = 'account',
    GAME_SETTINGS = 'game_settings',
    BLOCKED_PLAYERS = 'blocked_players',
    RESET_STATISTICS = 'reset_statistics',
}
export type KeyboardClickSource = 'part' | 'full' | 'throws' | 'backspace';
export type SubscriptionStatus = components['schemas']['SubscriptionStatus'];
export type TransactionStatus = components['schemas']['TransactionStatus'];
export type SocialProvider = components['schemas']['SocialProvider'];
export type Badge = components['schemas']['Badge'];
export type CTAActionTarget = components['schemas']['CTAActionTarget'];
export type CTAActionType = components['schemas']['CTAActionType'];
export type CTAColor = components['schemas']['CTAColor'];
export type CTATheme = components['schemas']['CTATheme'];
export type CTAUserLevel = components['schemas']['CTAUserLevel'];
export type ConfigKey = components['schemas']['ConfigKey'];
export type DartColor = components['schemas']['DartColor'];
export type DartFlightShape = components['schemas']['DartFlightShape'];
export type DartGripType = components['schemas']['DartGripType'];
export type DartPart = components['schemas']['DartPart'];
export type DartShaftMaterial = components['schemas']['DartShaftMaterial'];
export type DartTipType = components['schemas']['DartTipType'];
export type Device = components['schemas']['Device'];
export type FriendStatus = components['schemas']['FriendStatus'];
export type EntryMode = components['schemas']['EntryMode'];
export type FinishMode = components['schemas']['FinishMode'];
export type WinningType = components['schemas']['WinningType'];
export type RangeMode = components['schemas']['RangeMode'];
export type GameMode = components['schemas']['GameMode'];
export type MissedThrowEvent = components['schemas']['MissedThrowEvent'];
export type NumberSegment = components['schemas']['NumberSegment'];
export type AmountOfHits = components['schemas']['AmountOfHits'];
export type GoalIncrease = components['schemas']['GoalIncrease'];
export type ResultState = components['schemas']['ResultState'];
export type Bobs27Difficulty = components['schemas']['Bobs27Difficulty'];
export type CricketTacticsSettings = components['schemas']['CricketTacticsSettings'];
export type DoublesTrainingSettings = components['schemas']['DoublesTrainingSettings'];
export type SinglesTrainingSettings = components['schemas']['SinglesTrainingSettings'];
export type AroundTheClockSettings = components['schemas']['AroundTheClockSettings'];
export type AroundTheClockThrowout = components['schemas']['AroundTheClockThrowout'];
export type ShanghaiSettings = components['schemas']['ShanghaiSettings'];
export type SafeHouseSettings = components['schemas']['SafeHouseSettings'];
export type Gender = components['schemas']['Gender'];
export type CouponType = components['schemas']['CouponType'];
export type DiscountEvent = components['schemas']['DiscountEvent'];
export type DiscountType = components['schemas']['DiscountType'];
export type Platform = components['schemas']['Platform'];
export type StatisticsType = components['schemas']['StatisticsType'];
export type ActivityEvent = components['schemas']['ActivityEvent'];
export type ActivityDescription = components['schemas']['ActivityDescription'];
export type SubscriptionState = components['schemas']['SubscriptionState'];
export type TrainingMode = components['schemas']['TrainingMode'];
export type GuestLoginAs = components['schemas']['GuestLoginAs'];
export type ShareFormat = components['schemas']['ShareFormat'];
export type GlobalStatisticsCategory = components['schemas']['GlobalStatisticsCategory'];
export type GlobalStatisticsCategoryField = components['schemas']['GlobalStatisticsCategoryField'];
export type GlobalStatisticsFieldData = components['schemas']['GlobalStatisticsFieldData'];
export type SmartDeviceType = components['schemas']['SmartDeviceType'];
export type ShortSmartDeviceType = 'virt' | 'omni';
export type StatisticNumberFormat = components['schemas']['StatisticNumberFormat'];
export type CameraStreamClipEventType = components['schemas']['CameraStreamClipEventType'];
export type ContactCategory = components['schemas']['ContactCategory'];
export type PaymentProvider = components['schemas']['PaymentProvider'];
export type PaymentMethod = components['schemas']['PaymentMethod'];
export type IntervalPeriod = components['schemas']['IntervalPeriod'];
export type GroupByChartFilter = components['schemas']['GroupByChartFilter'];
export type PeriodChartFilter = components['schemas']['PeriodChartFilter'];
export type CricketTacticsGameSetting = components['schemas']['CricketTacticsGameSetting'];
export type FirestoreUserBackendEventType = components['schemas']['FirestoreUserBackendEventType'];
export type SuspensionReason = components['schemas']['SuspensionReason'];
export type SuspensionFeature = components['schemas']['SuspensionFeature'];
export type ProfilePrivacy = components['schemas']['ProfilePrivacy'];
export type TournamentStatus = components['schemas']['TournamentStatus'];
export type TournamentListType = components['schemas']['TournamentListType'];
export type TournamentApprovalFilter = components['schemas']['TournamentApprovalFilter'];
export type TournamentDatesFilter = components['schemas']['TournamentDatesFilter'];
export type TournamentOpenOrPrivateFilter = components['schemas']['TournamentOpenOrPrivateFilter'];
export type TournamentSetupFilter = components['schemas']['TournamentSetupFilter'];
export type BroadcastEvent = components['schemas']['BroadcastEvent'];
export type NotificationCategory = components['schemas']['NotificationCategory'];
export type TournamentFinishGameEvent = components['schemas']['TournamentFinishGameEvent'];
export type CameraPosition = components['schemas']['CameraPosition'];
export type ReportOrigin = components['schemas']['ReportOrigin'];
export enum SoundPath {
    CALLER = 'caller',
    EFFECTS = 'effects',
    OMNI = 'OMNI',
    RINGTONE = 'ringtone',
    GAMESHOT_LEGS = 'gameshot/legs',
    GAMESHOT_SETS = 'gameshot/sets',
    JOHN = 'john',
    NAMES = 'names',
    REQUIRES = 'requires',
    TEXTS = 'texts',
    RECAP = 'recap',
    YOU_REQUIRE = 'yourequire',
}
export type OverallStatistic = 'threeDartAvg' | 'firstNineAvg' | 'checkoutRate' | 'checkouts' | 'legs';
