import { Route } from '@angular/router';

export const tournamentRoutes: Route[] = [
    {
        path: 'tournaments',
        loadComponent: () => import('./pages/tournaments/tournaments.component').then((c) => c.TournamentsComponent),
    },
    {
        path: 'tournaments/history',
        loadComponent: () =>
            import('./pages/tournaments/history/tournament-history.component').then(
                (c) => c.TournamentHistoryComponent
            ),
    },
    {
        path: 'tournaments/:id',
        data: {
            hideTournamentNotification: true,
        },
        loadComponent: () =>
            import('./pages/tournaments/view/tournament-view.component').then((c) => c.TournamentViewComponent),
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'info',
            },
            {
                path: 'info',
                data: {
                    hideTournamentNotification: true,
                },
                loadComponent: () =>
                    import('./pages/tournament-info/tournament-info.component').then((c) => c.TournamentInfoComponent),
            },
            {
                path: 'participants',
                data: {
                    hideTournamentNotification: true,
                },
                loadComponent: () =>
                    import('./pages/tournament-participants/tournament-participants.component').then(
                        (c) => c.TournamentParticipantsComponent
                    ),
            },
            {
                path: 'bracket',
                data: {
                    hideTournamentNotification: true,
                },
                loadComponent: () =>
                    import('./pages/tournament-bracket/tournament-bracket.component').then(
                        (c) => c.TournamentBracketComponent
                    ),
            },
            {
                path: 'activity',
                data: {
                    hideTournamentNotification: true,
                },
                loadComponent: () =>
                    import('./pages/tournament-activity/tournament-activity.component').then(
                        (c) => c.TournamentActivityComponent
                    ),
            },
        ],
    },
];
