import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { PageHeaderComponent } from '../../../../../components/page-header/page-header.component';
import { IconComponent } from '../../../../shared/components/icon/icon.component';

@Component({
    selector: 'app-privacy-policy-dialog',
    templateUrl: 'privacy-policy.dialog.html',
    styleUrls: ['privacy-policy.dialog.scss'],
    standalone: true,
    imports: [CommonModule, TranslateModule, PageHeaderComponent, IconComponent],
})
export class PrivacyPolicyDialogComponent implements OnInit {
    private modalController: ModalController = inject(ModalController);
    private translateService: TranslateService = inject(TranslateService);

    public lang: string;

    public ngOnInit(): void {
        if (this.translateService.currentLang) {
            this.lang = this.translateService.currentLang;
        } else {
            this.lang = 'en';
        }
    }

    public dismiss(): void {
        this.modalController.dismiss();
    }
}
