<div class="onboarding-dialog">
    <app-page-header mainNavigation="custom" color="transparent">
        @if (canSkip) {
            <div
                left
                class="text-center text-sm font-bold text-black"
                (click)="dismiss()"
                [translate]="'CANCEL'"
                i18n="@@CANCEL">
                Cancel
            </div>
        }
        <div title class="text-black" [translate]="'PARTICIPANT_ONBOARDING'" i18n="@@PARTICIPANT_ONBOARDING">
            Participant onboarding
        </div>
    </app-page-header>

    <div class="onboarding-container">
        <div class="onboarding-tabs">
            <div class="tabs-container">
                @for (slide of slides; track $index) {
                    <div
                        class="tab"
                        [class.bg-orange-50]="$index > currentSlide()"
                        [class.!bg-orange]="$index <= currentSlide()"></div>
                }
            </div>
        </div>

        <div class="slide-container">
            @switch (currentSlide()) {
                @case (0) {
                    <div class="onboarding-slide" [@fade]>
                        <div class="icon">
                            <app-icon icon="tournaments" size="extralarge" class="!text-white"></app-icon>
                        </div>
                        <div
                            class="title"
                            [translate]="'WELCOME_TO_DARTCOUNTER_TOURNAMENTS'"
                            i18n="@@WELCOME_TO_DARTCOUNTER_TOURNAMENTS">
                            Welcome to DartCounter tournaments
                        </div>
                        <div
                            class="body"
                            [translate]="'PARTICIPANT_ONBOARDING_SLIDE_1'"
                            i18n="@@PARTICIPANT_ONBOARDING_SLIDE_1">
                            Tournaments are a fun way to compete against others in structured darts events. Once you
                            join a tournament, you'll play against other participants, track your scores, and aim for
                            the top spot. Ready to get started?
                        </div>
                    </div>
                }
                @case (1) {
                    <div class="onboarding-slide" [@fade]>
                        <div class="icon">
                            <app-icon icon="insights" size="extralarge" class="!text-white"></app-icon>
                        </div>
                        <div class="title" [translate]="'MINIMUM_10_ONLINE_GAMES'" i18n="@@MINIMUM_10_ONLINE_GAMES">
                            Minimum 10 online games
                        </div>
                        <div
                            class="body"
                            [translate]="'PARTICIPANT_ONBOARDING_SLIDE_2'"
                            i18n="@@PARTICIPANT_ONBOARDING_SLIDE_2">
                            To ensure fair play, participants must have completed a minimum of 10 online games before
                            joining a tournament. This helps us confirm your skill level and ensures a balanced
                            competition for everyone. In some cases a tournament may require you to have a specific
                            three-dart average as well.
                        </div>
                    </div>
                }
                @case (2) {
                    <div class="onboarding-slide" [@fade]>
                        <div class="icon">
                            <app-icon icon="tune" size="extralarge" class="!text-white"></app-icon>
                        </div>
                        <div class="title" [translate]="'TOURNAMENT_SETTINGS'" i18n="@@TOURNAMENT_SETTINGS">
                            Tournament settings
                        </div>
                        <div
                            class="body"
                            [translate]="'PARTICIPANT_ONBOARDING_SLIDE_3'"
                            i18n="@@PARTICIPANT_ONBOARDING_SLIDE_3">
                            Each tournament has specific settings
                        </div>

                        <div class="bg-neutral-50 dark:bg-black-fulldivide-y divide-neutral-200 text-black !my-6">
                            <div class="px-5 py-3 flex flex-col">
                                <div class="flex justify-between items-center">
                                    <div class="font-bebas-neue text-2xl truncate leading-none mr-4">
                                        {{ demoTournament.name }}
                                    </div>
                                </div>
                            </div>
                            <div class="px-6 py-3">
                                <app-tournament-details
                                    [tournament]="demoTournament"
                                    [withGameplayName]="false"></app-tournament-details>
                            </div>
                        </div>

                        <div class="flex flex-col space-y-3 w-full">
                            <div class="flex space-x-2">
                                <app-icon icon="calendar" size="large"></app-icon>
                                <div
                                    [translate]="'PARTICIPANT_ONBOARDING_CALENDAR_ICON'"
                                    i18n="@@PARTICIPANT_ONBOARDING_CALENDAR_ICON">
                                    tells you when the tournament takes place
                                </div>
                            </div>
                            <div class="flex space-x-2">
                                <app-icon icon="timer" size="large"></app-icon>
                                <div
                                    [translate]="'PARTICIPANT_ONBOARDING_TIMER_ICON'"
                                    i18n="@@PARTICIPANT_ONBOARDING_TIMER_ICON">
                                    mentions when the tournament starts
                                </div>
                            </div>
                            <div class="flex space-x-2">
                                <app-icon icon="supervisor_account" size="large"></app-icon>
                                <div
                                    [translate]="'PARTICIPANT_ONBOARDING_PLAYERS_ICON'"
                                    i18n="@@PARTICIPANT_ONBOARDING_PLAYERS_ICON">
                                    how many players can partake, and how many spots are left
                                </div>
                            </div>
                            <div class="flex space-x-2">
                                <app-icon icon="check_mark" size="large"></app-icon>
                                <div
                                    [translate]="'PARTICIPANT_ONBOARDING_CHECK_MARK_ICON'"
                                    i18n="@@PARTICIPANT_ONBOARDING_CHECK_MARK_ICON">
                                    when the check-in period starts
                                </div>
                            </div>
                            <div class="flex space-x-2">
                                <app-icon icon="open_lock" size="large"></app-icon>
                                <div
                                    [translate]="'PARTICIPANT_ONBOARDING_LOCK_ICON'"
                                    i18n="@@PARTICIPANT_ONBOARDING_LOCK_ICON">
                                    whether the tournament is public or private
                                </div>
                            </div>
                            <div class="flex space-x-2">
                                <app-icon icon="three_dots" size="large"></app-icon>
                                <div
                                    [translate]="'THREE_DART_AVG_REQUIRED_TO_PARTICIPATE'"
                                    i18n="@@THREE_DART_AVG_REQUIRED_TO_PARTICIPATE">
                                    the 3-dart average required to participate
                                </div>
                            </div>
                        </div>
                    </div>
                }
                @case (3) {
                    <div class="onboarding-slide" [@fade]>
                        <div class="icon">
                            <app-icon icon="insert_emoticon" size="extralarge" class="!text-white"></app-icon>
                        </div>
                        <div class="title" [translate]="'ETIQUETTE_RULES'" i18n="@@ETIQUETTE_RULES">
                            Etiquette rules
                        </div>
                        <div
                            class="body"
                            [translate]="'PARTICIPANT_ONBOARDING_SLIDE_4'"
                            i18n="@@PARTICIPANT_ONBOARDING_SLIDE_4">
                            When you join a tournament, we expect full participation! Be sure to play your matches,
                            follow the rules, and maintain good sportsmanship. Everyone's here for a fun and fair
                            competition! Make sure you've read the code of conduct and the tournament-specific rules
                        </div>
                    </div>
                }
                @case (4) {
                    <div class="onboarding-slide" [@fade]>
                        <div class="icon">
                            <app-icon icon="camera_alt" size="extralarge" class="!text-white"></app-icon>
                        </div>
                        <div class="title" [translate]="'SETUP_EXPECTATIONS'" i18n="@@SETUP_EXPECTATIONS">
                            Setup expectations
                        </div>
                        <div
                            class="body"
                            [translate]="'PARTICIPANT_ONBOARDING_SLIDE_5'"
                            i18n="@@PARTICIPANT_ONBOARDING_SLIDE_5">
                            For a smooth tournament experience, you will need to have a camera setup or use the OMNI
                            system to track your scores. Ensure your equipment is ready to go before you join. If you
                            encounter any issues, check the setup guide in the settings for assistance.
                        </div>
                    </div>
                }
                @case (5) {
                    <div class="onboarding-slide" [@fade]>
                        <div class="icon">
                            <app-icon icon="check_mark" size="extralarge" class="!text-white"></app-icon>
                        </div>
                        <div class="title" [translate]="'CHECK_IN_PERIOD'" i18n="@@CHECK_IN_PERIOD">
                            Check-in period
                        </div>
                        <div
                            class="body"
                            [translate]="'PARTICIPANT_ONBOARDING_SLIDE_6'"
                            i18n="@@PARTICIPANT_ONBOARDING_SLIDE_6">
                            Before the tournament starts, you'll need to check in. This ensures you're ready and
                            available to play. Don't forget to check in during the designated period to avoid being
                            marked as absent! The check-in period will begin shortly before the tournament kicks off.
                        </div>
                    </div>
                }
                @case (6) {
                    <div class="onboarding-slide" [@fade]>
                        <div class="icon">
                            <app-icon icon="crown" size="extralarge" class="!text-white"></app-icon>
                        </div>
                        <div class="title" [translate]="'YOU_ARE_ALL_SET'" i18n="@@YOU_ARE_ALL_SET">You're all set</div>
                        <div
                            class="body"
                            [translate]="'PARTICIPANT_ONBOARDING_SLIDE_7'"
                            i18n="@@PARTICIPANT_ONBOARDING_SLIDE_7">
                            You're all set! Now it's time to enjoy the tournament. Play fair, have fun, and aim for the
                            win! Good luck, and may the best player win!
                        </div>
                    </div>
                }
            }
        </div>
    </div>

    <div class="onboarding-buttons">
        @if (currentSlide() > 0) {
            <app-primary-button
                class="w-full"
                size="normal"
                fill="outline"
                addedClasses="w-full"
                (click)="prevSlide()"
                [translate]="'BACK'"
                i18n="@@BACK">
                Back
            </app-primary-button>
        }

        @if (currentSlide() < slides.length - 1) {
            <app-primary-button
                class="w-full"
                size="normal"
                addedClasses="w-full"
                (click)="nextSlide()"
                [translate]="'NEXT'"
                i18n="@@NEXT">
                Next
            </app-primary-button>
        } @else {
            <app-primary-button
                class="w-full"
                size="normal"
                addedClasses="w-full"
                (click)="dismiss(true)"
                [translate]="'CLOSE'"
                i18n="@@CLOSE">
                Close
            </app-primary-button>
        }
    </div>
</div>
