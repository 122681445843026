import { CommonModule } from '@angular/common';
import {
    Component,
    effect,
    inject,
    input,
    InputSignal,
    OnInit,
    signal,
    untracked,
    WritableSignal,
} from '@angular/core';
import { SmartDeviceApiService } from '@dc-api/smart-device.api.service';
import { TournamentApiService } from '@dc-api/tournament.api.service';
import { Tournament, TournamentParticipant } from '@dc-core/dc-backend/dc-classes';
import { TournamentListType, TournamentStatus } from '@dc-core/dc-backend/dc-enums';
import { OnlineGameplay } from '@dc-core/dc-backend/dc-interfaces';
import { OnlineFunctions } from '@dc-core/dc-gamelogic/online-game/online.functions';
import { TournamentExtendedStatus, TournamentHelper } from '@dc-core/dc-helpers/tournament.helper';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { ModalController, NavController } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { DCLoadingService } from '@providers/DCLoadingService';
import { UpgradeService } from '@providers/UpgradeService';
import { AuthService } from '@services/auth.service';
import { GA_EVENTACTIONS, GA_EVENTCATEGORIES } from 'src/app/app.globals';
import { TournamentView } from 'src/app/modules/tournaments/pages/tournaments/view/tournament-view.component';
import { TournamentService } from 'src/app/modules/tournaments/services/tournament.service';
import { environment } from 'src/environments/environment';

import { ProfilePhotoComponent } from '../../../../../components/profile-photo/profile-photo.component';
import { IconComponent } from '../../../../shared/components/icon/icon.component';
import { JoinTournamentDialogComponent } from '../../dialogs/join-tournament-dialog/join-tournament.dialog';
import {
    TournamentNotificationDialogComponent,
    TournamentNotificationDialogPayload,
} from '../../dialogs/tournament-notification-dialog/tournament-notification.dialog';
import { TournamentParticipantOnboardingDialogComponent } from '../../dialogs/tournament-participant-onboarding/tournament-participant-onboarding.dialog';
import { TournamentButtonComponent } from '../tournament-button/tournament-button.component';
import { TournamentDetailsComponent } from '../tournament-details/tournament-details.component';

@Component({
    selector: 'app-tournament-card',
    templateUrl: 'tournament-card.component.html',
    standalone: true,
    imports: [
        CommonModule,
        IconComponent,
        TournamentDetailsComponent,
        TournamentButtonComponent,
        TranslateModule,
        ProfilePhotoComponent,
    ],
})
export class TournamentCardComponent implements OnInit {
    public tournament: InputSignal<Tournament> = input.required();
    public listType: InputSignal<TournamentListType> = input.required();
    public withUpdates: InputSignal<boolean> = input.required();
    public redirectOnClick: InputSignal<boolean> = input(true);
    public showHost: InputSignal<boolean> = input(false);

    public translateService: TranslateService = inject(TranslateService);
    public onlineFunctions: OnlineFunctions = inject(OnlineFunctions);

    private authService: AuthService = inject(AuthService);
    private navController: NavController = inject(NavController);
    private modalController: ModalController = inject(ModalController);
    private tournamentApiService: TournamentApiService = inject(TournamentApiService);
    private tournamentService: TournamentService = inject(TournamentService);
    private alertService: DartCounterAlertService = inject(DartCounterAlertService);
    private smartDeviceApiService: SmartDeviceApiService = inject(SmartDeviceApiService);
    private upgradeService: UpgradeService = inject(UpgradeService);
    private ga: DartCounterAnalyticsService = inject(DartCounterAnalyticsService);
    private loadingService: DCLoadingService = inject(DCLoadingService);

    public participation: WritableSignal<TournamentParticipant> = signal(null);
    public gameplayName: string;
    public isHost: boolean;
    public status: TournamentExtendedStatus;

    public disabledButton = false;

    private createdAt = new Date();

    constructor() {
        effect(
            () => {
                const untrackedTournament = untracked(this.tournament);
                const updatedTournament = this.tournamentService.updatedTournament();
                if (
                    this.withUpdates() &&
                    untrackedTournament &&
                    updatedTournament.tournament &&
                    updatedTournament.tournament.id === untrackedTournament.id &&
                    updatedTournament.updatedAt >= this.createdAt
                ) {
                    this.tournament().status = updatedTournament.tournament.status;
                    this.tournament().amount_of_participants = updatedTournament.tournament.amount_of_participants;
                    this.tournament().current_participants = updatedTournament.tournament.current_participants;
                    this.setParticipaton(updatedTournament.tournament.participation);
                    this.determineStatus();
                }
            },
            { allowSignalWrites: true }
        );
    }

    public ngOnInit(): void {
        this.participation.set(this.tournament().participation);
        this.gameplayName = this.onlineFunctions.onlineGameplayName(
            this.tournament().game_details[0] as OnlineGameplay
        );
        this.isHost = this.tournament().user_id === this.authService.user.id;
        this.determineStatus();
    }

    public onCardClick(): void {
        if (!this.redirectOnClick()) return;
        this.goToTournament();
    }

    public goToTournament(view: TournamentView = 'info'): void {
        if (!localStorage.getItem('firstTimeJoiningTournament')) {
            this.modalController
                .create({
                    component: TournamentParticipantOnboardingDialogComponent,
                    componentProps: {
                        canSkip: false,
                    },
                    backdropDismiss: false,
                    showBackdrop: true,
                    cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
                })
                .then((elem) => {
                    elem.present();
                    elem.onDidDismiss().then((dialogRes) => {
                        if (dialogRes.data) {
                            localStorage.setItem('firstTimeJoiningTournament', '1');
                            this.goToTournament(view);
                        }
                    });
                });
            return;
        }

        this.navController.navigateForward(`tournaments/${this.tournament().slug}/${view}`);
    }

    public async join(): Promise<void> {
        if (!this.authService.user.is_ultimate) {
            const dialogComponent = await this.upgradeService.GetUpgradeDialog(GA_EVENTCATEGORIES.TOURNAMENTS);
            this.modalController
                .create({
                    component: dialogComponent,
                    cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
                })
                .then((elem) => {
                    elem.present();
                });
            return;
        }

        if (this.tournament().amount_of_participants === this.tournament().current_participants) {
            return;
        }

        if (this.tournament().omni_scoring_required && !this.smartDeviceApiService.omniScorings().length) {
            this.alertService.createAlert({
                title: $localize`:@@NEED_TO_HAVE_AN_OMNI_SCORING:You need to have an Omni Scoring`,
                icon: 'error',
                timer: null,
                showCloseButton: true,
                confirmButtonText: $localize`:@@SETUP:Setup`,
                onConfirm: () => {
                    this.navController.navigateForward('smart-devices');
                },
            });
            return;
        }

        if (!localStorage.getItem('firstTimeJoiningTournament')) {
            this.modalController
                .create({
                    component: TournamentParticipantOnboardingDialogComponent,
                    componentProps: {
                        canSkip: false,
                    },
                    backdropDismiss: false,
                    showBackdrop: true,
                    cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
                })
                .then((elem) => {
                    elem.present();
                    elem.onDidDismiss().then((dialogRes) => {
                        if (dialogRes.data) {
                            localStorage.setItem('firstTimeJoiningTournament', '1');
                            this.join();
                        }
                    });
                });
            return;
        }

        await this.loadingService.ShowDefaultLoader();

        this.tournamentApiService
            .checkTournamentRestriction({ restriction: 'join', tournament_id: this.tournament().id })
            .then((res) => {
                this.loadingService.DismissLoader();

                if (!res.data.restricted) {
                    if (this.disabledButton) {
                        return;
                    }

                    this.disabledButton = true;

                    this.modalController
                        .create({
                            component: JoinTournamentDialogComponent,
                            cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                            componentProps: {
                                tournament: this.tournament(),
                            },
                            backdropDismiss: true,
                            showBackdrop: true,
                        })
                        .then((elem) => {
                            elem.present();
                            elem.onDidDismiss().then((dialogRes) => {
                                this.disabledButton = false;

                                if (dialogRes.data) {
                                    const participation = dialogRes.data.participant as TournamentParticipant;

                                    this.modalController
                                        .create({
                                            component: TournamentNotificationDialogComponent,
                                            componentProps: {
                                                tournament: this.tournament(),
                                                type:
                                                    this.tournament().needs_approval && !participation.approved_at
                                                        ? 'waiting_for_approval'
                                                        : 'joined',
                                            } as TournamentNotificationDialogPayload,
                                            cssClass: 'auto-height',
                                            showBackdrop: true,
                                            backdropDismiss: true,
                                        })
                                        .then((elem) => {
                                            elem.present();
                                        });

                                    this.tournament().current_participants =
                                        dialogRes.data.tournament.current_participants;
                                    this.setParticipaton(participation);
                                    this.determineStatus();

                                    this.tournamentService.setNewParticipatingTournament(this.tournament());
                                }
                            });
                        });
                } else {
                    this.alertService.createAlert({
                        title: res.data.message,
                        icon: 'error',
                    });
                }
            });
    }

    public async checkIn(): Promise<void> {
        if (this.disabledButton || this.tournament().participation.checked_in_at) {
            return;
        }

        this.disabledButton = true;

        await this.loadingService.ShowDefaultLoader();

        this.tournamentApiService
            .checkInForTournament({ tournamentId: this.tournament().id })
            .then((res) => {
                this.ga.trackEvent(GA_EVENTCATEGORIES.TOURNAMENTS, GA_EVENTACTIONS.CHECKIN);

                this.modalController
                    .create({
                        component: TournamentNotificationDialogComponent,
                        componentProps: {
                            tournament: this.tournament(),
                            type: 'checked_in',
                        } as TournamentNotificationDialogPayload,
                        cssClass: 'auto-height',
                        showBackdrop: true,
                        backdropDismiss: true,
                    })
                    .then((elem) => {
                        elem.present();
                    });

                this.tournament().current_participants = res.data.tournament.current_participants;
                this.setParticipaton(res.data.participant);
                this.determineStatus();

                this.tournamentService.setNewParticipatingTournament(this.tournament());
            })
            .finally(() => {
                this.disabledButton = false;
                this.loadingService.DismissLoader();
            });
    }

    public changeButton(status: TournamentStatus): void {
        this.tournament().status = status;
        this.determineStatus();
    }

    private setParticipaton(participant: TournamentParticipant): void {
        this.participation.set({ ...participant });
        this.tournament().participation = participant;
    }

    private determineStatus(): void {
        this.status = TournamentHelper.getPlayStatus(this.tournament(), false);
    }
}
