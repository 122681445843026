<!-- Translation containers-->
@if (false) {
    <ng-container i18n="@@SEARCH">Search</ng-container>
}
<!-- End of translation containers-->

<app-page-header mainNavigation="back" defaultBack="dashboard">
    <div title [translate]="'FRIENDS'" i18n="@@FRIENDS">Friends</div>
    <div right class="right-page-header">
        <app-option-button icon="person_add" (click)="OpenFriends()" color="text-white"></app-option-button>
    </div>
</app-page-header>

<div class="flex flex-col h-full bg-white-pattern overflow-y-auto p-4" id="main-content">
    <div
        class="flex flex-col overflow-y-auto scrollbar-hide"
        appSmallWebContainer
        infiniteScroll
        [scrollWindow]="false"
        (scrolled)="getFriends()">
        @if (incomingFriends?.length) {
            <div class="text-black w-full mb-4">
                <div class="flex items-center justify-center space-x-2">
                    <div class="font-semibold" [translate]="'FRIEND_REQUESTS'" i18n="@@FRIEND_REQUESTS">
                        Friend requests
                    </div>
                    <ion-badge mode="md" item-end class="ion-bg-yellow !text-black">
                        {{ incomingFriends.length }}
                    </ion-badge>
                </div>

                @for (friend of incomingFriends; track friend) {
                    <div class="flex items-center justify-between space-x-2 p-2 text-black">
                        <div class="flex items-center space-x-3 cursor-pointer" (click)="OpenProfile(friend)">
                            <app-profile-photo
                                [size]="40"
                                [ultimate]="friend.is_ultimate"
                                [url]="friend.profile?.profile_photo_url"
                                [badge]="friend.profile?.badge"></app-profile-photo>
                            <div class="space-y-1">
                                <div class="font-semibold">{{ friend.full_name }}</div>
                                <div class="text-xs">
                                    {{ friend.profile?.nickname }}
                                </div>
                            </div>
                        </div>
                        <div class="flex justify-end items-center space-x-2">
                            <div
                                class="rounded-sm border border-black text-black bg-transparent w-8 h-8 inline-flex justify-center items-center cursor-pointer"
                                (click)="DenyFriend(friend)">
                                <app-icon icon="close" size="medium"></app-icon>
                            </div>
                            <div
                                class="rounded-sm border border-orange bg-orange !text-white w-8 h-8 inline-flex justify-center items-center cursor-pointer"
                                (click)="AcceptFriend(friend)">
                                <app-icon icon="done" size="medium"></app-icon>
                            </div>
                        </div>
                    </div>
                }
            </div>
        }

        @if (isUserProfilePrivate === true) {
            <div class="mt-8 flex flex-col items-center text-black" [appWebContainer]>
                <div class="inline-flex justify-center items-center rounded-full w-18 h-18 border-black border-2">
                    <app-icon icon="closed_lock" size="extralarge" class="text-black"></app-icon>
                </div>
                <div
                    class="font-bebas-neue text-2xl text-center mt-4"
                    [translate]="'THIS_PROFILE_IS_PRIVATE'"
                    i18n="@@THIS_PROFILE_IS_PRIVATE">
                    This profile is private
                </div>
                <div
                    class="font-bebas-neue text-lg text-center"
                    [translate]="'BECOME_FRIENDS_TO_VIEW_FULL_PROFILE'"
                    i18n="@@BECOME_FRIENDS_TO_VIEW_FULL_PROFILE">
                    Become friends with this player to view the full profile
                </div>
            </div>
        } @else if (isUserProfilePrivate === false) {
            @if (friends === null) {
                <div class="flex mt-8">
                    <app-icon icon="loading" size="normal" class="mx-auto animate-spin text-black"></app-icon>
                </div>
            }

            @if (allTotal > 0) {
                <div class="dialog-input with-icon">
                    <app-icon icon="person_search"></app-icon>
                    <input type="text" [formControl]="searchControl" placeholder="{{ 'SEARCH' | translate }}" />
                </div>
            }

            @if (friends && friends.length) {
                <div class="flex-1 mt-4">
                    @for (friend of friends; track $index) {
                        <div
                            class="flex items-center space-x-3 p-2 text-black cursor-pointer"
                            (click)="OpenProfile(friend)">
                            <app-profile-photo
                                [size]="40"
                                [ultimate]="friend.is_ultimate"
                                [url]="friend.profile?.profile_photo_url"
                                [badge]="friend.profile?.badge"></app-profile-photo>
                            <div class="space-y-1">
                                <div class="font-semibold">{{ friend.full_name }}</div>
                                <div class="text-xs">
                                    {{ friend.profile?.nickname }}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        }
    </div>

    @if (isUserProfilePrivate === false && allTotal === 0 && userId === auth.user.id && incomingFriends?.length === 0) {
        <div class="flex h-full flex-col items-center px-4 py-8 justify-between text-black" [appWebContainer]>
            <div class="space-y-2 px-4 h-full flex flex-col justify-center">
                <div class="text-center text-lg font-bold" [translate]="'ADD_FRIENDS'" i18n="@@ADD_FRIENDS">
                    Add friends
                </div>
                <div class="text-center text-sm font-semibold" [translate]="'NO_FRIENDS_YET'" i18n="@@NO_FRIENDS_YET">
                    No friends added yet. Find your friends here!
                </div>
            </div>
            <div class="w-full">
                <app-primary-button
                    size="normal"
                    addedClasses="w-full"
                    (click)="OpenFriends()"
                    [translate]="'SEARCH_FRIENDS'"
                    i18n="@@SEARCH_FRIENDS">
                    Search friends
                </app-primary-button>
            </div>
        </div>
    }
</div>
