import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { PageHeaderComponent } from '@components/page-header/page-header.component';
import { ModalController, Platform } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { DartCounterPreferenceService } from 'dc-core/dc-services/preference/preference.service';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';

@Component({
    selector: 'app-terms-of-service-dialog',
    templateUrl: 'terms-of-service.dialog.html',
    styleUrls: ['terms-of-service.dialog.scss'],
    standalone: true,
    imports: [CommonModule, TranslateModule, PageHeaderComponent, IconComponent],
})
export class TermsOfServiceDialogComponent implements OnInit {
    public preferenceService: DartCounterPreferenceService = inject(DartCounterPreferenceService);

    private modalController: ModalController = inject(ModalController);
    private platform: Platform = inject(Platform);

    public isIOS: boolean = null;

    public ngOnInit(): void {
        if (this.platform.is('ios')) {
            this.isIOS = true;
        } else {
            this.isIOS = false;
        }
    }

    public getLongName(): string {
        if (this.isIOS === true) {
            return `Apple's App Store`;
        } else if (this.isIOS === false) {
            return `Google's Play Store`;
        }
    }

    public getShortName(): string {
        if (this.isIOS === true) {
            return `iTunes`;
        } else if (this.isIOS === false) {
            return `Play Store`;
        }
    }

    public dismiss(): void {
        this.modalController.dismiss();
    }
}
