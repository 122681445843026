import { CommonModule } from '@angular/common';
import { Component, input, InputSignal } from '@angular/core';
import { User } from '@dc-core/dc-backend/dc-classes';
import { TranslateModule } from '@ngx-translate/core';

import { ProfilePhotoComponent } from '../../../../../components/profile-photo/profile-photo.component';

@Component({
    selector: 'app-games-end-screen-player',
    standalone: true,
    templateUrl: './games-end-screen-player.component.html',
    imports: [TranslateModule, CommonModule, ProfilePhotoComponent],
})
export class GamesEndScreenPlayerComponent {
    public name: InputSignal<string> = input.required<string>();
    public user: InputSignal<User> = input<User>();
    public users: InputSignal<User[]> = input<User[]>();
    public profilePhotoSize: InputSignal<number> = input<number>(54);
}
