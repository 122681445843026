/* eslint-disable max-len */
import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { Tournament, TournamentGame, TournamentParticipant } from '@dc-core/dc-backend/dc-classes';
import { TournamentFinishGameEvent } from '@dc-core/dc-backend/dc-enums';
import { TournamentHelper } from '@dc-core/dc-helpers/tournament.helper';
import { ModalController } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { PrimaryButtonComponent } from '../../../../primary-button/primary-button.component';
import { IconComponent } from '../../../../shared/components/icon/icon.component';
import { TournamentTimerComponent } from '../../components/tournament-timer/tournament-timer.component';

export interface TournamentNotificationDialogPayload {
    tournament: Tournament;
    game: TournamentGame;
    participant: TournamentParticipant;
    round: number;
    type: TournamentNotificationType;
    finishGameEvent: TournamentFinishGameEvent;
    reason: string;
}

export type TournamentNotificationType =
    | 'joined'
    | 'waiting_for_approval'
    | 'approved'
    | 'checked_in'
    | 'check_in_started'
    | 'withdraw'
    | 'tournament_started'
    | 'game_is_starting'
    | 'lost'
    | 'won'
    | 'has_bye'
    | 'game_won_by_event'
    | 'reported'
    | 'disqualified'
    | 'kicked'
    | 'invited_as_moderator'
    | 'removed_as_moderator'
    | 'invite_cancelled_for_moderator';

@Component({
    selector: 'app-tournament-notification-dialog',
    templateUrl: 'tournament-notification.dialog.html',
    standalone: true,
    imports: [CommonModule, PrimaryButtonComponent, IconComponent, TournamentTimerComponent, TranslateModule],
    animations: [
        trigger('fade', [
            transition(':enter', [style({ opacity: 0 }), animate(200, style({ opacity: 1 }))]),
            transition(':leave', [style({ opacity: 1 }), animate(200, style({ opacity: 0 }))]),
        ]),
    ],
})
export class TournamentNotificationDialogComponent implements OnInit {
    @Input() tournament: Tournament;
    @Input() game: TournamentGame;
    @Input() participant: TournamentParticipant;
    @Input() round: number;
    @Input() type: TournamentNotificationType;
    @Input() finishGameEvent: TournamentFinishGameEvent;
    @Input() reason: string;

    private modalController: ModalController = inject(ModalController);
    private translateService: TranslateService = inject(TranslateService);

    public title: string;
    public subtext: string;
    public timeUntilText: string;
    public timeUntil: string;
    public buttonText: string;

    public ngOnInit(): void {
        this.initNotification();
    }

    public dismiss(action = false): void {
        this.modalController.dismiss(action);
    }

    private initNotification(): void {
        switch (this.type) {
            case 'joined':
                this.title = $localize`:@@JOINED_TOURNAMENT:Joined tournament`;
                $localize`:@@YOU_HAVE_JOINED_THE_TOURNAMENT:You have joined tournament '${this.tournament.name}:name:'`;
                this.subtext = this.translateService.instant('YOU_HAVE_JOINED_THE_TOURNAMENT', {
                    name: this.tournament.name,
                });
                if (this.tournament.status === 'joining_period') {
                    this.timeUntilText = $localize`:@@BEFORE_TOURNAMENT_BEGINS_NEED_CHECK_IN:Before the tournament begins, you'll be asked to check-in to confirm you are ready to play`;
                    this.timeUntil = this.tournament.check_in_from;
                } else if (this.tournament.status === 'check_in_period') {
                    this.timeUntilText = $localize`:@@CHECK_IN_WILL_CLOSE_IN:Check-in will close in`;
                    this.timeUntil = this.tournament.starting_at;
                }
                this.buttonText = $localize`:@@OK:OK`;
                break;
            case 'waiting_for_approval':
                this.title = $localize`:@@WAIT_FOR_APPROVAL:Wait for approval`;
                $localize`:@@YOU_HAVE_ASKED_TO_JOIN_THE_TOURNAMENT:You need to wait for the host to approve your join request before you are entered in tournament '${this.tournament.name}:name:'`;
                this.subtext = this.translateService.instant('YOU_HAVE_ASKED_TO_JOIN_THE_TOURNAMENT', {
                    name: this.tournament.name,
                });
                this.buttonText = $localize`:@@OK:OK`;
                break;
            case 'approved':
                this.title = $localize`:@@ONLINE_SETUP_APPROVED:Online setup approved`;
                $localize`:@@YOU_ARE_APPROVED_FOR_THE_TOURNAMENT:You are approved for tournament '${this.tournament.name}:name:'`;
                this.subtext = this.translateService.instant('YOU_ARE_APPROVED_FOR_THE_TOURNAMENT', {
                    name: this.tournament.name,
                });
                this.buttonText = $localize`:@@GO_TO_TOURNAMENT:Go to tournament`;
                break;
            case 'checked_in':
                this.title = $localize`:@@YOU_ARE_CHECKED_IN:You are checked in`;
                $localize`:@@YOU_ARE_CHECKED_IN_FOR_THE_TOURNAMENT:You are checked in for tournament '${this.tournament.name}:name:'`;
                this.subtext = this.translateService.instant('YOU_ARE_CHECKED_IN_FOR_THE_TOURNAMENT', {
                    name: this.tournament.name,
                });
                this.timeUntilText = $localize`:@@TOURNAMENT_WILL_START_WHEN_CHECK_IN_CLOSES:The tournament will start when the check-in closes`;
                this.timeUntil = this.tournament.starting_at;
                this.buttonText = $localize`:@@OK:OK`;
                break;
            case 'check_in_started':
                this.title = $localize`:@@CHECK_IN_REQUIRED:Check-in required`;
                $localize`:@@CHECK_IN_IS_NOW_OPEN_FOR_THE_TOURNAMENT:Check-in is now open for tournament '${this.tournament.name}:name:'`;
                this.subtext = this.translateService.instant('CHECK_IN_IS_NOW_OPEN_FOR_THE_TOURNAMENT', {
                    name: this.tournament.name,
                });
                this.timeUntilText = $localize`:@@CHECK_IN_WILL_CLOSE_IN:Check-in will close in`;
                this.timeUntil = this.tournament.starting_at;
                this.buttonText = $localize`:@@GO_TO_TOURNAMENT:Go to tournament`;
                break;
            case 'withdraw':
                this.title = $localize`:@@CONFIRM_WITHDRAWAL:Confirm withdrawal`;
                $localize`:@@YOU_SURE_YOU_WANT_TO_WITHDRAW_FROM_TOURNAMENT:Are you sure you want to withdraw from tournament '${this.tournament.name}:name:'?`;
                this.subtext = this.translateService.instant('YOU_SURE_YOU_WANT_TO_WITHDRAW_FROM_TOURNAMENT', {
                    name: this.tournament.name,
                });
                this.buttonText = $localize`:@@CONFIRM:Confirm`;
                break;
            case 'tournament_started':
                this.title = $localize`:@@TOURNAMENT_STARTED:Tournament started`;
                $localize`:@@YOUR_NEXT_GAME_IN_TOURNAMENT_WILL_BEGIN_IN:Your next game in tournament '${this.tournament.name}:name:' wll begin in`;
                this.timeUntilText = this.translateService.instant('YOUR_NEXT_GAME_IN_TOURNAMENT_WILL_BEGIN_IN', {
                    name: this.tournament.name,
                });
                this.buttonText = $localize`:@@GO_TO_TOURNAMENT:Go to tournament`;
                break;
            case 'game_is_starting':
                this.title = $localize`:@@GAME_IS_STARTING:Game is starting`;
                $localize`:@@YOUR_NEXT_GAME_IN_TOURNAMENT_WILL_BEGIN_IN:Your next game in tournament '${this.tournament.name}:name:' wll begin in`;
                this.timeUntilText = this.translateService.instant('YOUR_NEXT_GAME_IN_TOURNAMENT_WILL_BEGIN_IN', {
                    name: this.tournament.name,
                });
                this.timeUntil = this.game.starting_at;
                this.buttonText = $localize`:@@GO_TO_PRE_GAME:Go to pre-game`;
                break;
            case 'lost':
                const placement = TournamentHelper.getBracketPlacement(
                    this.tournament.amount_of_rounds,
                    this.participant.played_until_round
                );

                this.title = $localize`:@@YOU_HAVE_LOST:You have lost!`;
                $localize`:@@YOU_HAVE_FINISHED_PLACE_IN_THE_TOURNAMENT:You have finished place ${placement}:placement: in tournament '${this.tournament.name}:name:'`;
                this.subtext = this.translateService.instant('YOU_HAVE_FINISHED_PLACE_IN_THE_TOURNAMENT', {
                    placement: placement,
                    name: this.tournament.name,
                });
                this.buttonText = $localize`:@@GO_TO_TOURNAMENT:Go to tournament`;
                break;
            case 'has_bye':
                this.title = $localize`:@@YOU_HAVE_GOT_A_BYE:You have got a bye!`;
                $localize`:@@AUTO_ADVANCED_TO_NEXT_ROUND_IN_TOURNAMENT:You have automatically advanced to the next round in tournament '${this.tournament.name}:name:'`;
                this.subtext = this.translateService.instant('AUTO_ADVANCED_TO_NEXT_ROUND_IN_TOURNAMENT', {
                    name: this.tournament.name,
                });
                this.buttonText = $localize`:@@GO_TO_TOURNAMENT:Go to tournament`;
                break;
            case 'game_won_by_event':
                switch (this.finishGameEvent) {
                    case 'withdrawed':
                        this.title = $localize`:@@OPPONENT_HAS_WITHDRAWED:Opponent has withdrawed!`;
                        $localize`:@@AUTO_ADVANCED_TO_NEXT_ROUND_IN_TOURNAMENT:You have automatically advanced to the next round in tournament '${this.tournament.name}:name:'`;
                        this.subtext = this.translateService.instant('AUTO_ADVANCED_TO_NEXT_ROUND_IN_TOURNAMENT', {
                            name: this.tournament.name,
                        });
                        break;
                    case 'left':
                        this.title = $localize`:@@OPPONENT_HAS_LEFT:Opponent has left!`;
                        this.subtext = $localize`:@@YOU_HAVE_WON_THIS_ROUND_BY_LEAVING_OPPONENT:You have won this round because your opponent has left`;
                        break;
                    case 'not_responding':
                        this.title = $localize`:@@OPPONENT_HAS_NOT_RESPONDED_IN_TIME:Opponent has not responded in time!`;
                        this.subtext = $localize`:@@YOU_HAVE_WON_THIS_ROUND_BY_NOT_RESPONDING:You have won this round because your opponent did not respond`;
                        break;
                    case 'disqualified':
                        this.title = $localize`:@@OPPONENT_HAS_BEEN_DISQUALIFIED:Opponent has been disqualified!`;
                        this.subtext = $localize`:@@YOU_HAVE_WON_THIS_ROUND_BY_DISQUALIFICATION:You have won this round by a disqualification`;
                        break;
                }
                this.buttonText = $localize`:@@GO_TO_TOURNAMENT:Go to tournament`;
                break;
            case 'reported':
                this.title = $localize`:@@PARTICIPANT_IS_REPORTED:Participant is reported`;
                $localize`:@@PARTICIPANT_HAS_BEEN_REPORTED_IN_TOURNAMENT_ROUND:${this.participant.user.full_name}:participant: has been reported in ${this.round}:round: in tournament '${this.tournament.name}:tournament:'`;
                this.subtext = this.translateService.instant('PARTICIPANT_HAS_BEEN_REPORTED_IN_TOURNAMENT_ROUND', {
                    participant: this.participant.user.full_name,
                    round: this.round,
                    tournament: this.tournament.name,
                });
                this.buttonText = $localize`:@@VIEW_REPORTS:View reports`;
                break;
            case 'disqualified':
                this.title = $localize`:@@YOU_ARE_DISQUALIFIED:You are disqualified!`;
                $localize`:@@YOU_ARE_DISQUALIFIED_FROM_THE_TOURNAMENT:You are disqualified from tournament '${this.tournament.name}:name:'`;
                this.subtext = this.translateService.instant('YOU_ARE_DISQUALIFIED_FROM_THE_TOURNAMENT', {
                    name: this.tournament.name,
                });
                this.buttonText = $localize`:@@GO_TO_TOURNAMENT:Go to tournament`;
                break;
            case 'kicked':
                this.title = $localize`:@@YOU_HAVE_BEEN_KICKED:You have been kicked!`;
                $localize`:@@YOU_HAVE_BEEN_KICKED_FROM_THE_TOURNAMENT:You have been kicked from tournament '${this.tournament.name}:name:'`;
                this.subtext = this.translateService.instant('YOU_HAVE_BEEN_KICKED_FROM_THE_TOURNAMENT', {
                    name: this.tournament.name,
                });
                this.buttonText = $localize`:@@OK:OK`;
                break;
            case 'invited_as_moderator':
                this.title = $localize`:@@YOU_HAVE_BEEN_INVITED_AS_MODERATOR:You have been invited as moderator!`;
                this.subtext = null;
                this.buttonText = $localize`:@@GO_TO_TOURNAMENT:Go to tournament`;
                break;
            case 'removed_as_moderator':
                this.title = $localize`:@@YOU_HAVE_BEEN_REMOVED:You have been removed!`;
                $localize`:@@YOU_HAVE_BEEN_REMOVED_AS_MOD_FROM_THE_TOURNAMENT:You have been removed as moderator from tournament '${this.tournament.name}:name:'`;
                this.subtext = this.translateService.instant('YOU_HAVE_BEEN_REMOVED_AS_MOD_FROM_THE_TOURNAMENT', {
                    name: this.tournament.name,
                });
                this.buttonText = $localize`:@@OK:OK`;
                break;
            case 'invite_cancelled_for_moderator':
                this.title = $localize`:@@INVITE_HAS_BEEN_CANCELLED:Invite has been cancelled!`;
                $localize`:@@MODERATOR_INVITE_CANCELLED_FOR_TOURNAMENT:Your moderator invite has been cancelled for tournament '${this.tournament.name}:name:'`;
                this.subtext = this.translateService.instant('MODERATOR_INVITE_CANCELLED_FOR_TOURNAMENT', {
                    name: this.tournament.name,
                });
                this.buttonText = $localize`:@@OK:OK`;
                break;
        }
    }
}
