import { Injectable } from '@angular/core';
import {
    addDoc,
    collection,
    CollectionReference,
    DocumentReference,
    Firestore,
    getDoc,
    onSnapshot,
    updateDoc,
} from '@angular/fire/firestore';
import { doc, DocumentSnapshot, QueryDocumentSnapshot } from 'firebase/firestore';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { FireStoreCollectionsService } from '../firestore-collections.service';
import { FIRESTORE_COLLECTION } from '../globals/firestore.tables';
import {
    DartCounterSmartDevice,
    OmniCommand,
    OmniStartGameCommand,
    OmniWebscocketCommand,
} from '@dc-core/dc-services/omni/omni-communication.service';

@Injectable()
export class OmniCollectionService extends FireStoreCollectionsService {
    private collection_name: FIRESTORE_COLLECTION = FIRESTORE_COLLECTION.SMART_DEVICES;
    private firestore_collection: CollectionReference<any>;

    public activeOmniDocRef: DocumentReference<any>;

    constructor(firestore: Firestore) {
        super(firestore);
        this.firestore_collection = this.getConvertedData<any>(this.collection_name);
    }

    initOmni(smartDevice: DartCounterSmartDevice, startGameAfterConnection: boolean = false) {
        if (!this.activeOmniDocRef) {
            console.log('Selecting omni', smartDevice);
            this.activeOmniDocRef = this.getDocByID(smartDevice.guid);

            if (startGameAfterConnection) {
                setTimeout(() => {
                    this.sendCommand({
                        command: OmniCommand.StartGame,
                        saveAllImages: false,
                    } as OmniStartGameCommand);
                }, 1000);
            }
        }
    }

    watchDeviceDocRef(): Observable<any> {
        return new Observable<any>((observer) => {
            try {
                const unsubscribe = onSnapshot(this.activeOmniDocRef, async (docSnapshot) => {
                    const newData = docSnapshot.data();
                    if (newData) {
                        observer.next(newData);
                    }
                });

                // Unsubscribe when observer is unsubscribed
                return () => {
                    unsubscribe();
                };
            } catch (err) {
                observer.error(err);
            }
        });
    }

    public sendCommand(omniSocketCommand: OmniWebscocketCommand): void {
        if (this.activeOmniDocRef) {
            const commandsCollection = collection(this.activeOmniDocRef, 'commands');

            addDoc(commandsCollection, omniSocketCommand)
                .then(() => console.log('Command sent successfully.'))
                .catch((error) => console.error('Error sending command:', error));
        }
    }

    updateItem(ref: DocumentReference<any>, updatedValues: any): Promise<void> {
        return updateDoc(ref, { ...updatedValues });
    }

    getDocByID(id: string): DocumentReference<any> {
        return doc(this.firestore_collection, id);
    }
}
