<div
    class="flex cursor-pointer"
    [class.flex-row-reverse]="position === 'right'"
    [class.justify-between]="space === 'between'"
    [class.text-white]="color === 'white' || color === 'gray'"
    [class.text-black]="color === 'black'"
    (click)="changeItem()">
    <div
        class="relative block h-fit rounded-sm border-2"
        [class.mr-3]="position === 'left'"
        [class.ml-3]="position === 'right'"
        [ngClass]="{
            'border-neutral-200': color === 'white' && !model,
            'border-neutral-100': color === 'gray' && !model,
            'border-white': color === 'black' && !model,
            'border-opacity-60 bg-transparent': !model,
            'border-orange bg-orange': model
        }">
        <div
            class="rounded-sm flex flex-none justify-center items-center"
            [ngClass]="{
                'h-[1.25rem] w-[1.25rem] text-[1.25rem] leading-[1.25rem]': size === 'medium',
                'h-[1rem] w-[1rem] text-[1rem] leading-[1rem]': size === 'small'
            }">
            @if (model) {
                <app-icon icon="check_mark" [size]="size" class="!text-white"></app-icon>
            }
        </div>
    </div>
    <div class="block whitespace-normal text-sm leading-1">
        <ng-content></ng-content>
    </div>
</div>
