<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="dismiss()">Close</div>
        </ion-buttons>
        <div class="slide-modal-title" [translate]="'EDIT_EMAIL'" i18n="@@EDIT_EMAIL">Change email</div>
    </div>
    <div class="slide-modal-content">
        @if (step === 1) {
            <div [appWebContainer]>
                <form (ngSubmit)="SubmitNewEmail()" #form="ngForm" [formGroup]="editEmailForm">
                    <div class="mt-2">
                        <div class="dialog-input-label">
                            <span [translate]="'NEW_EMAIL'" i18n="@@NEW_EMAIL">New email</span>
                        </div>
                        <input
                            type="email"
                            formControlName="new_email"
                            name="email"
                            class="dialog-input"
                            placeholder="{{ 'NEW_EMAIL' | translate }}"
                            required />
                        @if (form.submitted && editEmailForm.get('new_email').errors) {
                            <app-validation-label [noAbsolute]="true">
                                <span [translate]="'NEW_EMAIL_ERROR'" i18n="@@NEW_EMAIL_ERROR">
                                    New email address is required
                                </span>
                            </app-validation-label>
                        }
                    </div>
                    <div class="mt-2">
                        <div class="dialog-input-label">
                            <span [translate]="'REPEAT_NEW_EMAIL'" i18n="@@REPEAT_NEW_EMAIL">Repeat new email</span>
                        </div>
                        <input
                            type="email"
                            formControlName="confirm_new_email"
                            name="email"
                            class="dialog-input"
                            placeholder="{{ 'REPEAT_NEW_EMAIL' | translate }}"
                            required />
                        @if (form.submitted && editEmailForm.get('confirm_new_email').errors) {
                            <app-validation-label [noAbsolute]="true">
                                <span [translate]="'NEW_EMAIL_CONFIRM_ERROR'" i18n="@@NEW_EMAIL_CONFIRM_ERROR">
                                    The email addresses do not match
                                </span>
                            </app-validation-label>
                        }
                    </div>
                    <div class="mt-2 text-orange text-right text-sm cursor-pointer" (click)="step = 2">
                        <p [translate]="'ALREADY_HAVE_CODE'" i18n="@@ALREADY_HAVE_CODE">I already have a code</p>
                    </div>
                    <app-primary-button
                        class="block w-full mt-4"
                        size="medium"
                        addedClasses="w-full"
                        [translate]="'CONFIRM'"
                        i18n="@@CONFIRM"
                        color="orange"
                        [disabled]="!form.valid"
                        type="submit">
                        Confirm
                    </app-primary-button>
                </form>
            </div>
        }
        @if (step === 2) {
            <div [appWebContainer]>
                <form (ngSubmit)="AttemptDigits()" #digitForm="ngForm">
                    @if (sentEmail) {
                        <div>
                            <div [translate]="'CODE_SENT_TO'" i18n="@@CODE_SENT_TO">Code was sent to:</div>
                            <div>{{ this.editEmailForm.get('new_email').value }}</div>
                        </div>
                    }
                    <div class="mt-2">
                        <div class="dialog-input-label" [translate]="'FILL_DIGITS_IN'" i18n="@@FILL_DIGITS_IN">
                            Fill digits in
                        </div>
                        <input
                            type="text"
                            [(ngModel)]="Digits"
                            name="digits"
                            class="dialog-input"
                            placeholder="{{ 'FILL_DIGITS_IN' | translate }}"
                            required />
                    </div>
                    <app-primary-button
                        class="block w-full mt-4"
                        size="medium"
                        addedClasses="w-full"
                        [translate]="'SEND'"
                        i18n="@@SEND"
                        color="orange"
                        [disabled]="!digitForm.form.valid"
                        type="submit">
                        Send
                    </app-primary-button>
                </form>
            </div>
        }
    </div>
</div>
