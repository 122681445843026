import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ProfilePhotoComponent } from '@components/profile-photo/profile-photo.component';
import { AuthApiService } from '@dc-api/auth.api.service';
import { User } from '@dc-core/dc-backend/dc-classes';
import { ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { DartCounterAlertService } from 'dc-core/dc-services/alert.service';
import { PrimaryButtonComponent } from 'src/app/primary-button/primary-button.component';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';
import { ValidationLabelComponent } from 'src/app/shared/components/validation-label/validation-label.component';
import { matchPasswordsValidator } from 'src/app/shared/validators/match-passwords.validator';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-force-change-password-dialog',
    templateUrl: 'force-change-password.dialog.html',
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        ProfilePhotoComponent,
        PrimaryButtonComponent,
        ValidationLabelComponent,
        IconComponent,
    ],
})
export class ForceChangePasswordDialogComponent {
    private alertService: DartCounterAlertService = inject(DartCounterAlertService);
    private authService: AuthService = inject(AuthService);
    private authApiService: AuthApiService = inject(AuthApiService);
    private formBuilder: FormBuilder = inject(FormBuilder);
    private modalController: ModalController = inject(ModalController);

    public passwordForm: FormGroup = this.formBuilder.group(
        {
            password: ['', [Validators.required, Validators.minLength(6)]],
            confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
        },
        { validators: matchPasswordsValidator('password', 'confirmPassword') }
    );
    public passwordVisible: boolean = false;
    public confirmPasswordVisible: boolean = false;
    public user: User = this.authService.user;

    constructor() {}

    public togglePasswordVisibility(controlName: 'password' | 'confirmPassword'): void {
        if (controlName === 'password') this.passwordVisible = !this.passwordVisible;
        else if (controlName === 'confirmPassword') this.confirmPasswordVisible = !this.confirmPasswordVisible;
    }

    public changePassword(): void {
        if (this.passwordForm.invalid) {
            return;
        }

        const password = this.passwordForm.get('password').value;

        this.authApiService
            .resetPassword({ new_password: password, confirm_password: password })
            .then((res) => {
                this.authService.saveAuthentication(res.data.user, res.data.access_token);

                this.dismissModal(true);
            })
            .catch((err) => {
                this.alertService.errorFromApi(err);
            });
    }

    public dismissModal(returnValue: any = null): void {
        this.modalController.dismiss(returnValue);
    }
}
