/* eslint-disable max-len */
import { CommonModule } from '@angular/common';
import { Component, inject, input, InputSignal, OnInit, signal, WritableSignal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PaymentApiService } from '@dc-api/payment.api.service';
import { UserApiService } from '@dc-api/user.api.service';
import { Plan, User } from '@dc-core/dc-backend/dc-classes';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { IonicModule, ModalController, NavController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { DCLoadingService } from '@providers/DCLoadingService';
import { UpgradeService } from '@providers/UpgradeService';
import { AuthService } from '@services/auth.service';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';
import { SelectPaymentMethodDialogComponent } from 'src/dialogs/select-payment-method/select-payment-method.dialog';
import { WebContainerDirective } from 'src/directives/web-container.directive';
import { environment } from 'src/environments/environment';

import { PrimaryButtonComponent } from '../../../../primary-button/primary-button.component';
import { OnPageAuthComponent } from '../../../authentication/components/on-page-auth/on-page-auth.component';

export type RedeemCouponType = 'default' | 'virt' | 'omni';

@Component({
    selector: 'app-redeem-coupon-type',
    standalone: true,
    templateUrl: './redeem-coupon-type.component.html',
    imports: [
        OnPageAuthComponent,
        PrimaryButtonComponent,
        CommonModule,
        FormsModule,
        IonicModule,
        WebContainerDirective,
        TranslateModule,
        IconComponent,
    ],
})
export class RedeemCouponTypeComponent implements OnInit {
    public type: InputSignal<RedeemCouponType> = input.required<RedeemCouponType>();

    public user: WritableSignal<User> = signal(null);
    public code: string = environment.local ? '3MONTHMANDATE' : '';
    public couponId: number = null;
    public hasMandate = false;

    public upgradeService: UpgradeService = inject(UpgradeService);

    private loadingService: DCLoadingService = inject(DCLoadingService);
    private modalController: ModalController = inject(ModalController);
    private navController: NavController = inject(NavController);
    private authService: AuthService = inject(AuthService);
    private userApiService: UserApiService = inject(UserApiService);
    private paymentApiService: PaymentApiService = inject(PaymentApiService);
    private alertService: DartCounterAlertService = inject(DartCounterAlertService);

    ngOnInit(): void {
        if (this.authService.accessToken) {
            this.user.set(this.authService.user);
        }
    }

    redeem(): void {
        if (this.user().is_ultimate) {
            return;
        }

        if (this.code === '') {
            this.alertService.createAlert({
                title: $localize`:@@PLEASE_ENTER_A_COUPON:Please enter a coupon`,
                icon: 'error',
            });
            return;
        }

        this.paymentApiService
            .redeemCoupon({ code: this.code })
            .then(async (res) => {
                await this.userApiService.getCurrentUser({}).then((userRes) => {
                    this.authService.setUser(userRes.data);

                    if (res.data.requires_mandate) {
                        let needsRedirect = false;
                        if (this.type() === 'default' && res.data.smart_device_type) {
                            needsRedirect = true;
                        } else if (this.type() === 'virt' && res.data.smart_device_type !== 'virt_cam') {
                            needsRedirect = true;
                        } else if (this.type() === 'omni' && res.data.smart_device_type !== 'omni_scoring') {
                            needsRedirect = true;
                        }

                        if (needsRedirect) {
                            this.alertService.createAlert({
                                title: $localize`:@@COUPON_CODE_INVALID_CURRENT_PAGE:This coupon is invalid on this page`,
                                text: $localize`:@@YOU_WILL_BE_REDIRECTED_TO_CAMPAIGN:You will be redirected to the appropriate campaign.`,
                                icon: 'info',
                                timer: null,
                                confirmButtonText: $localize`:@@CONFIRM:Confirm`,
                                onConfirm: () => {
                                    switch (res.data.smart_device_type) {
                                        case 'virt_cam':
                                            this.navController.navigateForward('/virt');
                                            break;
                                        case 'omni_scoring':
                                            this.navController.navigateForward('/omni');
                                            break;
                                        default:
                                            this.navController.navigateForward('/redeem-coupon');
                                            break;
                                    }
                                },
                            });
                        } else {
                            this.upgradeService.loadPlans(false);

                            this.couponId = res.data.coupon_id;
                            this.hasMandate = true;
                        }
                    } else {
                        this.alertService.createAlert({
                            text: res.data.message,
                            icon: 'success',
                        });

                        this.navController.navigateForward('/dashboard');
                    }
                });
            })
            .catch((err) => this.alertService.errorFromApi(err));
    }

    upgrade(plan: Plan): void {
        this.modalController
            .create({
                component: SelectPaymentMethodDialogComponent,
                componentProps: {
                    info: $localize`:@@USE_ULTIMATE_FREE_FOR_THREE_MONTHS_MANDATE:Use DartCounter Ultimate free instantly for 3 months. Subscription will be billed when the trial period ends. You can cancel at any time.`,
                },
                cssClass: 'auto-height',
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.loadingService.ShowDefaultLoader();

                        this.paymentApiService
                            .applySubscription({
                                plan_id: plan.id,
                                coupon_id: this.couponId,
                                has_mandate_coupon: true,
                                payment_method: dialogRes.data,
                                success_redirect_url: window.location.origin,
                                cancel_redirect_url: window.location.href,
                            })
                            .then((res) => {
                                window.location.href = res.data.redirect_url;
                            })
                            .catch((err) => {
                                this.alertService.errorFromApi(err);
                            })
                            .finally(() => {
                                this.loadingService.DismissLoader();
                            });
                    }
                });
            });
    }
}
