import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Badge } from '@dc-core/dc-backend/dc-enums';

@Component({
    selector: 'app-profile-photo',
    standalone: true,
    imports: [CommonModule],
    templateUrl: 'profile-photo.component.html',
    styleUrls: ['profile-photo.component.scss'],
})
export class ProfilePhotoComponent implements OnInit, OnChanges {
    @Input() url: string;
    @Input() badge?: Badge = null;
    @Input() rating: any;
    @Input() ratingColor: any;
    @Input() ultimate = false;
    @Input() timesRated: any = 0;
    @Input() big: boolean;
    @Input() small: boolean;
    @Input() size = 48;
    @Input() absoluteSize;

    borderSize: number;
    imageUrl: any;
    badgeImg: any;
    showRating = false;

    checkVerification(): string {
        let icon = '';
        switch (this.badge) {
            case 'elite_1':
                icon = 'Verified_blue';
                break;
            case 'target':
                icon = 'Verified_PRO';
                break;
        }

        icon += '.png';

        return icon;
    }

    checkRating(rating: number, rated: number): void {
        if (rated >= 10) {
            this.showRating = true;
        } else {
            this.showRating = false;
        }

        if (!this.ratingColor) {
            this.ratingColor = 'orange';
        }
    }

    ngOnInit(): void {
        if (this.big) {
            this.borderSize = this.size + 12;
        } else if (this.small) {
            this.borderSize = this.size + 4;
        } else {
            this.borderSize = this.size + 6;
        }

        this.SetImageUrl(this.url);

        if (this.badge != null) {
            this.showRating = false;
            this.badgeImg = this.checkVerification();
        } else {
            this.checkRating(this.rating, this.timesRated);
        }
    }

    ngOnChanges(changes: { [propertyName: string]: any }): void {
        if (changes['url']) {
            this.SetImageUrl(this.url);
        }
    }

    SetImageUrl(url): void {
        if (url) {
            this.imageUrl = url;
            // if (environment.local) {
            //     this.imageUrl = url;
            // } else {
            //     const request = new XMLHttpRequest();
            //     request.open('GET', url, true);
            //     request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            //     request.setRequestHeader('Access-Control-Allow-Origin', '*');
            //     request.send();
            //     request.onload = (): void => {
            //         if (request.status === 200) {
            //             this.imageUrl = url;
            //         } else {
            //             this.imageUrl = './assets/images/user-default.jpg';
            //         }
            //     };
            // }
        } else {
            this.imageUrl = './assets/images/user-default.svg';
        }
    }
}
