import { CommonModule } from '@angular/common';
import { Component, input, InputSignal } from '@angular/core';
import { GroupedGameStatistics } from '@dc-core/dc-backend/dc-interfaces';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-games-end-screen-stats-slide',
    standalone: true,
    templateUrl: './games-end-screen-stats-slide.component.html',
    imports: [TranslateModule, CommonModule],
})
export class GamesEndScreenStatsSlideComponent {
    public statistics: InputSignal<GroupedGameStatistics[]> = input.required<GroupedGameStatistics[]>();
}
