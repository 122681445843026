import { CommonModule } from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    DestroyRef,
    ElementRef,
    HostListener,
    inject,
    input,
    InputSignal,
    OnInit,
    output,
    OutputEmitterRef,
    signal,
    WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SmartDeviceApiService } from '@dc-api/smart-device.api.service';
import { InGameKeyboardType } from '@dc-core/dc-gamelogic/game-interfaces';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-keyboard-dropdown',
    standalone: true,
    imports: [CommonModule, IconComponent],
    templateUrl: 'keyboard-dropdown.component.html',
})
export class KeyboardDropdownComponent implements OnInit {
    public keyboard: InputSignal<InGameKeyboardType> = input.required();
    public isExtraLargeDevice: InputSignal<boolean> = input<boolean>(false);

    public keyboardChanged: OutputEmitterRef<InGameKeyboardType> = output<InGameKeyboardType>();

    private elementRef: ElementRef = inject(ElementRef);
    private changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);
    private smartDeviceApiService: SmartDeviceApiService = inject(SmartDeviceApiService);

    public keyboards: { name: InGameKeyboardType; icon_name: string }[] = [
        { name: 'numbers', icon_name: 'keyboard_default' },
        { name: 'single', icon_name: 'keyboard_single' },
        { name: 'hotkeys', icon_name: 'keyboard_shortcuts' },
    ];
    public dropdownOpened: WritableSignal<boolean> = signal(false);

    public isWeb: boolean = environment.isWeb;

    private destroyRef: DestroyRef = inject(DestroyRef);

    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement: HTMLElement): void {
        if (!targetElement) {
            return;
        }

        const clickedInside = this.elementRef.nativeElement.contains(targetElement);
        if (!clickedInside && this.dropdownOpened()) {
            this.dropdownOpened.set(false);
            this.changeDetectorRef.detectChanges();
        }
    }

    public ngOnInit(): void {
        if (environment.isWeb) {
            this.keyboards.unshift({ name: 'none', icon_name: 'keyboard_only' });
        }

        this.smartDeviceApiService.hasOmni$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((hasOmni) => {
            if (hasOmni && !environment.isWeb) {
                this.keyboards.push({ name: 'omni', icon_name: 'dartboard' });
            }
        });
    }

    public toggleDropdown(): void {
        this.dropdownOpened.update((dropdownOpened) => !dropdownOpened);
    }

    public changeKeyboard(keyboard: InGameKeyboardType): void {
        this.toggleDropdown();
        this.keyboardChanged.emit(keyboard);
    }
}
