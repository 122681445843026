<app-page-header mainNavigation="custom" defaultBack="statistics" [popOnBack]="true">
    <app-icon left icon="arrow_back" (click)="back(false)"></app-icon>
    <div title>{{ title | translate }}</div>
    <app-option-button right icon="bin" (click)="TryRemove()" color="text-white"></app-option-button>
</app-page-header>

<ion-content class="ion-text-black bg-white-pattern">
    @if (game) {
        <div class="bg-white flex flex-col text-center text-black py-6">
            <div class="text-lg font-bold">{{ game.name }}</div>
            <div class="text-sm text-neutral-700">
                {{ game.started_at | date: 'dd-MMM-yyyy H:mm' : '' : translateService.currentLang }}
            </div>
            <div class="flex justify-center gap-2 mt-3">
                <div class="rounded-full text-white bg-black uppercase px-2 font-bold text-xxs">
                    {{ game.max_darts }} <span [translate]="'DARTS'" i18n="@@DARTS">Darts</span>
                </div>
                <div class="rounded-full text-white bg-black uppercase px-2 font-bold text-xxs">
                    {{ game.safe_house | safeHouse }}
                </div>
                <div class="rounded-full text-white bg-black uppercase px-2 font-bold text-xxs">
                    <span [translate]="'INCREASE_BY'" i18n="@@INCREASE_BY">Increase by</span>
                    {{ game.increase_on_success }}
                </div>
            </div>
        </div>

        <div class="relative" [appWebContainer]>
            <div class="mt-3 mx-4">
                <div class="rounded-t-md bg-white px-4 py-2.5">
                    <div
                        class="col-span-6 flex items-center text-lg font-bold text-black"
                        [translate]="'PLAYERS'"
                        i18n="@@PLAYERS">
                        Players
                    </div>
                </div>

                @for (user of game.users; track user.id) {
                    <div
                        [ngClass]="{ 'border-b-1': !$last }"
                        class="flex items-center space-x-2 px-8 py-3 border-b-white text-sm"
                        (click)="user.user ? showPlayerInfo(user.user) : null">
                        @if (user.user) {
                            <app-profile-photo
                                [ultimate]="user.user.is_ultimate"
                                [badge]="user.user.profile?.badge"
                                [size]="32"
                                [url]="user.user.profile?.profile_photo_url"
                                style="height: 32px"></app-profile-photo>
                        } @else {
                            <app-profile-photo [size]="32" style="height: 32px"></app-profile-photo>
                        }
                        <div class="mt-1 text-center font-bold truncate capitalize text-black">
                            {{ user.user?.first_name ?? user.ghost_name }}
                        </div>
                    </div>
                }

                <div class="rounded-t-md bg-white px-4 py-2.5 mt-3">
                    <div
                        class="col-span-6 flex items-center text-lg font-bold text-black"
                        [translate]="'STATISTICS'"
                        i18n="@@STATISTICS">
                        Statistics
                    </div>
                </div>

                <div class="mt-3 mx-4">
                    <div class="flex px-8 py-3 border-b-1 border-b-white text-sm">
                        <div class="w-1/2" [translate]="'HIGHEST_NUMBER'" i18n="@@HIGHEST_NUMBER">Highest number</div>
                        <div class="flex w-1/2 text-center justify-center">{{ game.highest }}</div>
                    </div>
                    <div class="flex px-8 py-3 border-b-1 border-b-white text-sm">
                        <div class="w-1/2" [translate]="'STREAK'" i18n="@@STREAK">Streak</div>
                        <div class="flex w-1/2 text-center justify-center">{{ game.streak }}</div>
                    </div>
                    <div class="flex px-8 py-3 text-sm">
                        <div class="w-1/2" [translate]="'SUCCESS_RATE'" i18n="@@SUCCESS_RATE">Success rate</div>
                        <div class="flex w-1/2 text-center justify-center">{{ game.success_rate }}%</div>
                    </div>
                </div>
            </div>

            @for (round of rounds; track round) {
                <app-accordion
                    class="block mt-3 mx-4 mb-2"
                    [isExpanded]="round.expanded"
                    (expandChange)="round.expanded = !round.expanded">
                    <div accordion-header class="flex flex-row items-center justify-between w-full">
                        <div class="flex flex-row items-center justify-center font-bold text-sm whitespace-nowrap">
                            <span>Round #{{ round.round }} </span>
                            <div
                                class="rounded-full text-white bg-black uppercase px-2 mx-2 py-1 leading-none font-bold text-xxs">
                                {{ round.number_to_throw }}
                            </div>
                            @if (round.success) {
                                <app-icon icon="check_mark" size="small" class="text-green"></app-icon>
                            }
                        </div>
                    </div>
                    <div accordion-body class="p-2 bg-gray-50">
                        <div class="flex border-b-white border-b-1 py-2 text-sm">
                            <div [translate]="'TURN'" i18n="@@TURN" class="w-2/4">Turn</div>
                            <div [translate]="'REMAINING'" i18n="@@REMAINING" class="w-1/4">Remaining</div>
                            <div [translate]="'SCORE'" i18n="@@SCORE" class="w-1/4">Score</div>
                        </div>
                        @for (turn of round.turns; track turn.id) {
                            <div class="flex border-b-1 border-b-white py-2 text-sm">
                                <div class="w-2/4 truncate">{{ turn.playerName }}</div>
                                <div class="w-1/4">{{ turn.number_to_throw }}</div>
                                <div class="w-1/4">{{ turn.total_score }}</div>
                            </div>
                            @if ($last) {
                                <div class="flex py-2">
                                    <div class="w-2/4"></div>
                                    <div class="w-1/4">{{ turn.number_to_throw - turn.total_score }}</div>
                                </div>
                            }
                        }
                    </div>
                </app-accordion>
            }
        </div>
    }
</ion-content>
