<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="dismiss()">Close</div>
        </ion-buttons>
        <div class="slide-modal-title" [translate]="'JOIN_TOURNAMENT'" i18n="@@JOIN_TOURNAMENT">Join tournament</div>
    </div>
    <div class="slide-modal-content">
        @if (isLoading) {
            <div class="h-16 flex justify-center items-center">
                <app-icon icon="loading" size="large" class="animate-spin text-orange h-fit"></app-icon>
            </div>
        } @else {
            <div>
                <div class="my-4">
                    @if (latestOnlineGames && notEnoughOnlineGames) {
                        <div
                            class="flex items-center bg-neutral-200 p-4 text-black overflow-hidden relative w-full rounded-md">
                            <app-icon icon="warning" size="large"></app-icon>
                            <div class="flex flex-col w-full ml-4">
                                <div
                                    class="text-xl font-bebas-neue"
                                    [translate]="'NOT_ENOUGH_ONLINE_GAMES_PLAYED'"
                                    i18n="@@NOT_ENOUGH_ONLINE_GAMES_PLAYED">
                                    Not enough online games played
                                </div>
                                <div
                                    class="text-sm font-medium mt-1"
                                    [translate]="'NEED_TEN_ONLINE_GAMES_PLAYED_FOR_THIS_TOURNAMENT'"
                                    i18n="@@NEED_TEN_ONLINE_GAMES_PLAYED_FOR_THIS_TOURNAMENT">
                                    In order to play in this tournament, you need to have at least 10 online games
                                    played
                                </div>
                                <div class="flex space-x-1 text-sm font-medium mt-2">
                                    <div [translate]="'YOU_HAVE_PLAYED_COLON'" i18n="@@YOU_HAVE_PLAYED_COLON">
                                        You have played:
                                    </div>
                                    <div>
                                        {{ latestOnlineGames.count }}
                                    </div>
                                </div>
                                <div class="flex justify-end items-center space-x-1 mt-2">
                                    <app-primary-button
                                        size="small"
                                        addedClasses="w-full"
                                        (click)="goToGlobalLobby()"
                                        [translate]="'GO_TO_GLOBAL_LOBBY'"
                                        i18n="@@GO_TO_GLOBAL_LOBBY">
                                        Go to global lobby
                                    </app-primary-button>
                                </div>
                            </div>
                        </div>
                    } @else {
                        @if (canJoin) {
                            @if (tournament.is_private && !tournament.participation?.is_admin) {
                                <div class="new-game-card tournament mb-2">
                                    <div class="title-container">
                                        <div class="icon">
                                            <app-icon icon="closed_lock" size="small"></app-icon>
                                        </div>
                                        <div class="title" [translate]="'PASSWORD'" i18n="@@PASSWORD">Password</div>
                                    </div>

                                    <div class="external-cam-input-wrapper">
                                        <div class="external-cam-input">
                                            <input
                                                maxlength="1"
                                                type="number"
                                                placeholder="1"
                                                id="codeInput0"
                                                #codeInput0
                                                (keyup)="
                                                    onInputKeyup($event, finalCode, 0, codeInput0, codeInput1, null)
                                                "
                                                (click)="focusField(codeInput0)" />
                                            <div
                                                class="bottom-line"
                                                [class.selected]="codeInput0 === focusedField"
                                                [class.filled]="finalCode[0]"></div>
                                        </div>
                                        <div class="external-cam-input">
                                            <input
                                                maxlength="1"
                                                type="number"
                                                id="codeInput1"
                                                #codeInput1
                                                placeholder="2"
                                                (keyup)="
                                                    onInputKeyup(
                                                        $event,
                                                        finalCode,
                                                        1,
                                                        codeInput1,
                                                        codeInput2,
                                                        codeInput0
                                                    )
                                                "
                                                (click)="focusField(codeInput1)" />
                                            <div
                                                class="bottom-line"
                                                [class.selected]="codeInput1 === focusedField"
                                                [class.filled]="finalCode[1]"></div>
                                        </div>
                                        <div class="external-cam-input">
                                            <input
                                                maxlength="1"
                                                type="number"
                                                id="codeInput2"
                                                #codeInput2
                                                placeholder="3"
                                                (keyup)="
                                                    onInputKeyup(
                                                        $event,
                                                        finalCode,
                                                        2,
                                                        codeInput2,
                                                        codeInput3,
                                                        codeInput1
                                                    )
                                                "
                                                (click)="focusField(codeInput2)" />
                                            <div
                                                class="bottom-line"
                                                [class.selected]="codeInput2 === focusedField"
                                                [class.filled]="finalCode[2]"></div>
                                        </div>
                                        <div class="external-cam-input">
                                            <input
                                                maxlength="1"
                                                type="number"
                                                id="codeInput3"
                                                #codeInput3
                                                placeholder="4"
                                                (keyup)="
                                                    onInputKeyup(
                                                        $event,
                                                        finalCode,
                                                        3,
                                                        codeInput3,
                                                        codeInput4,
                                                        codeInput2
                                                    )
                                                "
                                                (click)="focusField(codeInput3)" />
                                            <div
                                                class="bottom-line"
                                                [class.selected]="codeInput3 === focusedField"
                                                [class.filled]="finalCode[3]"></div>
                                        </div>
                                        <div class="external-cam-input">
                                            <input
                                                maxlength="1"
                                                type="number"
                                                id="codeInput4"
                                                #codeInput4
                                                placeholder="5"
                                                (keyup)="
                                                    onInputKeyup(
                                                        $event,
                                                        finalCode,
                                                        4,
                                                        codeInput4,
                                                        codeInput5,
                                                        codeInput3
                                                    )
                                                "
                                                (click)="focusField(codeInput4)" />
                                            <div
                                                class="bottom-line"
                                                [class.selected]="codeInput4 === focusedField"
                                                [class.filled]="finalCode[4]"></div>
                                        </div>
                                        <div class="external-cam-input">
                                            <input
                                                maxlength="1"
                                                type="number"
                                                id="codeInput5"
                                                #codeInput5
                                                placeholder="6"
                                                (keyup)="
                                                    onInputKeyup($event, finalCode, 5, codeInput5, null, codeInput4)
                                                "
                                                (click)="focusField(codeInput5)" />
                                            <div
                                                class="bottom-line"
                                                [class.selected]="codeInput5 === focusedField"
                                                [class.filled]="finalCode[5]"></div>
                                        </div>
                                    </div>
                                </div>
                            }

                            @if (tournament.min_three_dart_avg || tournament.max_three_dart_avg) {
                                <div class="new-game-card tournament mb-2">
                                    <div class="title-container">
                                        <div class="icon">
                                            <app-icon icon="three_dots" size="small"></app-icon>
                                        </div>
                                        <div
                                            class="title"
                                            [translate]="'THREE_DART_AVERAGE'"
                                            i18n="@@THREE_DART_AVERAGE">
                                            Three dart average
                                        </div>
                                    </div>

                                    <div class="flex justify-between items-center font-bebas-neue text-lg">
                                        @if (!tournament.min_three_dart_avg) {
                                            <div [translate]="'NO_MINIMUM'" i18n="@@NO_MINIMUM">No minimum</div>
                                        } @else {
                                            <div class="flex items-center space-x-1">
                                                <div [translate]="'MINIMUM'" i18n="@@MINIMUM">Minimum</div>
                                                <div>{{ tournament.min_three_dart_avg }}</div>
                                            </div>
                                        }

                                        @if (!tournament.max_three_dart_avg) {
                                            <div [translate]="'NO_MAXIMUM'" i18n="@@NO_MAXIMUM">No maximum</div>
                                        } @else {
                                            <div class="flex items-center space-x-1">
                                                <div [translate]="'MAXIMUM'" i18n="@@MAXIMUM">Maximum</div>
                                                <div>{{ tournament.max_three_dart_avg }}</div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }

                            <app-update-online-setup></app-update-online-setup>
                        } @else {
                            <div
                                class="flex items-center bg-neutral-200 p-4 text-black overflow-hidden relative w-full rounded-md">
                                <app-icon icon="warning" size="large"></app-icon>
                                <div class="flex flex-col w-full ml-4">
                                    <div
                                        class="text-xl font-bebas-neue"
                                        [translate]="'THREE_DART_AVERAGE_DOES_NOT_MATCH'"
                                        i18n="@@THREE_DART_AVERAGE_DOES_NOT_MATCH">
                                        Three dart average does not match
                                    </div>
                                    <div class="text-sm font-medium mt-1">
                                        {{ joinError }}
                                    </div>
                                </div>
                            </div>
                        }
                    }
                </div>

                @if (!(latestOnlineGames && notEnoughOnlineGames) && canJoin) {
                    <div class="button-container">
                        <app-primary-button
                            color="blue"
                            type="submit"
                            size="normal"
                            addedClasses="w-full"
                            [translate]="'JOIN'"
                            i18n="@@JOIN"
                            (click)="join()">
                            Join
                        </app-primary-button>
                    </div>
                }
            </div>
        }
    </div>
</div>
