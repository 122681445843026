import { Injectable, signal, WritableSignal } from '@angular/core';
import { DocumentData } from '@angular/fire/compat/firestore';
import {
    addDoc,
    collection,
    CollectionReference,
    deleteDoc,
    doc,
    DocumentReference,
    DocumentSnapshot,
    Firestore,
    getDoc,
    getDocs,
    limit,
    onSnapshot,
    orderBy,
    query,
    QueryDocumentSnapshot,
    QuerySnapshot,
    setDoc,
    Timestamp,
    updateDoc,
    where,
} from '@angular/fire/firestore';
import { IntervalManager } from '../../dc-logging/interval.manager';
import { INTERVAL_KEY } from '../../dc-logging/subscription_enums';
import _ from 'lodash';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { FireStoreAuthService } from '../firestore-auth.service';
import { FireStoreCollectionsService } from '../firestore-collections.service';
import { DCFireStoreInvite, FIRESTORE_COLLECTION, INVITATIONSTATUS, OutGoingInvite } from '../globals/firestore.tables';
import { UsersCollectionService } from './users.collection.service';
import { OnlineGameplay } from '@dc-core/dc-backend/dc-interfaces';

@Injectable()
export class InviteCollectionService extends FireStoreCollectionsService {
    private defaultClass = new DCFireStoreInvite();

    private outgoing_firestore_collection: CollectionReference<OutGoingInvite>;

    public allowInvites: boolean = true;
    public incomingInvites$: Subject<DCFireStoreInvite> = new Subject();
    public currentOutgoingInvite: WritableSignal<DCFireStoreInvite> = signal(null);
    public currentOutgoingInviteRef: DocumentReference<DocumentData> = null;
    public currentFriendInviteGame: WritableSignal<OnlineGameplay> = signal(null);
    public currentFriendInviteGameRef: DocumentReference<DocumentData> = null;

    private inviteUpdateSubject: Subject<DCFireStoreInvite> = new Subject();
    public inviteUpdate$ = this.inviteUpdateSubject.asObservable();

    private invitationTimerInterval: any;
    public timer: number = 5;

    constructor(
        firestore: Firestore,
        private _dcFireAuth: FireStoreAuthService,
        private _usersCollectionService: UsersCollectionService,
        private _intervalManager: IntervalManager
    ) {
        super(firestore);
        this.outgoing_firestore_collection = this.getConvertedData<OutGoingInvite>(
            FIRESTORE_COLLECTION.OUTGOING_INVITES
        );
    }

    sendFirestoreInvite(invite: DCFireStoreInvite) {
        let receiverDoc = this._usersCollectionService.getDocByID(invite.receiver_uid);

        const receiverInvitesCollection = collection(receiverDoc, FIRESTORE_COLLECTION.INVITES);
        // return addDoc(receiverInvitesCollection, invite);
        addDoc(receiverInvitesCollection, invite).then((inviteDocRef: DocumentReference<DCFireStoreInvite>) => {
            // Add to the outgoing invites collection
            setDoc(doc(this.outgoing_firestore_collection, inviteDocRef.id), <OutGoingInvite>{
                sender_uid: invite.sender_uid,
                receiver_uid: invite.receiver_uid,
                sent_at: Timestamp.now(),
            });
        });
    }

    updateItem(ref: DocumentReference<DocumentData>, updatedValues: DocumentData): Promise<void> {
        return updateDoc(ref, updatedValues);
    }

    watchIncomingInvites(): Observable<QueryDocumentSnapshot<DCFireStoreInvite>> {
        const collectionRef = collection(this._usersCollectionService.activeUserDocRef, FIRESTORE_COLLECTION.INVITES);

        return new Observable<QueryDocumentSnapshot<DCFireStoreInvite>>((observer) => {
            try {
                const whereClause = this.getAttributeString(this.defaultClass, (obj: DCFireStoreInvite) => obj.status);
                const orderByClause = this.getAttributeString(
                    this.defaultClass,
                    (obj: DCFireStoreInvite) => obj.sent_at
                );
                let ownInvites = query(
                    collectionRef,
                    where(whereClause, '==', INVITATIONSTATUS.PENDING),
                    orderBy(orderByClause, 'asc'),
                    limit(1)
                );

                const unsubscribe = onSnapshot(ownInvites, (snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                        const data = change.doc as QueryDocumentSnapshot<DCFireStoreInvite>;
                        if (change.type === 'added') {
                            if (this.allowInvites) {
                                observer.next(data);
                            }
                        }
                    });
                });

                // Unsubscribe when observer is unsubscribed
                return () => {
                    unsubscribe();
                };
            } catch (err) {
                observer.error(err);
            }
        });
    }

    watchTournamentInvites(): Observable<QueryDocumentSnapshot<DCFireStoreInvite>> {
        const collectionRef = collection(
            this._usersCollectionService.activeUserDocRef,
            FIRESTORE_COLLECTION.TOURNAMENT_INVITES
        );

        return new Observable<QueryDocumentSnapshot<DCFireStoreInvite>>((observer) => {
            try {
                const orderByClause = this.getAttributeString(
                    this.defaultClass,
                    (obj: DCFireStoreInvite) => obj.sent_at
                );
                const ownInvites = query(collectionRef, orderBy(orderByClause, 'desc'), limit(1));

                let currentInvite: QueryDocumentSnapshot<DCFireStoreInvite> = null;

                const unsubscribe = onSnapshot(ownInvites, (snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                        const data = change.doc as QueryDocumentSnapshot<DCFireStoreInvite>;
                        if (change.type === 'added') {
                            currentInvite = data;
                            observer.next(currentInvite);
                        } else if (change.type === 'modified') {
                            if (currentInvite?.id === data.id) {
                                currentInvite = data;
                                observer.next(currentInvite);
                            }
                        } else if (change.type === 'removed') {
                            if (currentInvite?.id === data.id) {
                                currentInvite = null;
                                observer.next(currentInvite);
                            }
                        }
                    });
                });

                // Unsubscribe when observer is unsubscribed
                return () => {
                    unsubscribe();
                };
            } catch (err) {
                observer.error(err);
            }
        });
    }

    getCurrentTournamentInvite(): Promise<QuerySnapshot<DCFireStoreInvite>> {
        const collectionRef = collection(
            this._usersCollectionService.activeUserDocRef,
            FIRESTORE_COLLECTION.TOURNAMENT_INVITES
        );

        try {
            let ownInvites = query(collectionRef, limit(1));

            return getDocs(ownInvites) as Promise<QuerySnapshot<DCFireStoreInvite>>;
        } catch (err) {
            console.error(err);
        }
    }

    checkOwnOutgoingInvites(): Observable<QueryDocumentSnapshot<DCFireStoreInvite>> {
        return new Observable<QueryDocumentSnapshot<DCFireStoreInvite>>((observer) => {
            try {
                const outgoingInvites = query(
                    this.outgoing_firestore_collection,
                    where('sender_uid', '==', this._dcFireAuth.getCurrentUID())
                );

                const unsubscribe = onSnapshot(outgoingInvites, (snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                        const data = change.doc as QueryDocumentSnapshot<OutGoingInvite>;
                        if (change.type === 'added') {
                            this.checkOutgoingQueryDocSnapShot(change.doc);
                            observer.next();
                        }
                    });
                });

                // Unsubscribe when observer is unsubscribed
                return () => {
                    unsubscribe();
                };
            } catch (err) {
                observer.error(err);
            }
        });
    }

    checkOutgoingQueryDocSnapShot(outgoingQuery: QueryDocumentSnapshot<OutGoingInvite>) {
        let outgoingData = outgoingQuery.data();

        let receiverDoc = this._usersCollectionService.getDocByID(outgoingData.receiver_uid);
        const receiverInvitesCollection = collection(receiverDoc, FIRESTORE_COLLECTION.INVITES);
        let inviteReference = doc(receiverInvitesCollection, outgoingQuery.id);

        getDoc(inviteReference).then((docSnapshot: DocumentSnapshot<DCFireStoreInvite>) => {
            if (!docSnapshot.exists()) {
                deleteDoc(outgoingQuery.ref);
                this.currentOutgoingInviteRef = null;
            } else {
                this.currentOutgoingInviteRef = inviteReference;

                onSnapshot(inviteReference, (snapshot: DocumentSnapshot<DCFireStoreInvite>) => {
                    // ... other code ...
                    this.inviteUpdateSubject.next(snapshot.data());
                });

                this.setCurrentOutgoing(docSnapshot.data());
            }
        });
    }

    async updateInviteStatus(inviteRef: DocumentReference<DocumentData>, status: INVITATIONSTATUS) {
        const fsInvite = <DCFireStoreInvite>{
            status: status,
        };

        this.updateItem(inviteRef, fsInvite);

        if (status == INVITATIONSTATUS.ACCEPTED) {
            this.removeIncomingInvites();
            this.removeOutgoingInvites();
        }
    }

    async updateTournamentInviteStatus(inviteRef: DocumentReference<DocumentData>, status: INVITATIONSTATUS) {
        const fsInvite = <DCFireStoreInvite>{
            status: status,
        };

        this.updateItem(inviteRef, fsInvite);

        if (status == INVITATIONSTATUS.ACCEPTED) {
            this.removeTournamentInvites();
        }
    }

    updateOutgoingInvite(updatedValues: OnlineGameplay): Promise<void> {
        return updateDoc(this.currentFriendInviteGameRef, { ...updatedValues });
    }

    removeOutgoingInvites() {
        try {
            if (this.currentOutgoingInviteRef) {
                deleteDoc(this.currentOutgoingInviteRef);
            }

            const removeInviteQuery = query(
                this.outgoing_firestore_collection,
                where('sender_uid', '==', this._dcFireAuth.getCurrentUID())
            );

            this.setCurrentOutgoing(null);

            getDocs(removeInviteQuery).then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    deleteDoc(doc.ref);
                });
            });
        } catch (err) {
            console.error(err);
        }
    }

    removeIncomingInvites() {
        try {
            const myInvitesCollection = collection(
                this._usersCollectionService.activeUserDocRef,
                FIRESTORE_COLLECTION.INVITES
            );

            getDocs(myInvitesCollection).then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    deleteDoc(doc.ref);
                });
            });
        } catch (err) {
            console.error(err);
        }
    }

    removeTournamentInvites() {
        try {
            const myInvitesCollection = collection(
                this._usersCollectionService.activeUserDocRef,
                FIRESTORE_COLLECTION.INVITES
            );

            getDocs(myInvitesCollection).then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    deleteDoc(doc.ref);
                });
            });
        } catch (err) {
            console.error(err);
        }
    }

    setCurrentOutgoing(outgoing: DCFireStoreInvite) {
        this.currentOutgoingInvite.set(outgoing);
        this.stopTimer();

        if (outgoing) {
            const timer = 90 - (Timestamp.now().seconds - outgoing.sent_at.seconds);
            if (timer > 0) {
                this.timer = timer;
                this.startTimer();
            } else {
                // Timeout is already over
                this.removeOutgoingInvites();
            }
        }
    }

    startTimer() {
        this.invitationTimerInterval = setInterval(() => {
            if (this.timer >= 1) {
                this.timer--;
            } else {
                //Timer is over
                this.stopTimer();
                this.removeOutgoingInvites();
            }
        }, 1000);

        this._intervalManager.track(
            'invitesCollection',
            INTERVAL_KEY.OUTGOING_INVITE_TIMER,
            this.invitationTimerInterval
        );
    }

    // Converts seconds to mm:ss format
    showTimer(): string {
        return moment('2015-01-01').startOf('day').seconds(this.timer).format('mm:ss');
    }

    stopTimer() {
        this._intervalManager.clear(INTERVAL_KEY.OUTGOING_INVITE_TIMER);
        this.timer = 0;
    }
}
