import { Match, MatchUser, User } from 'dc-core/dc-backend/dc-classes';
import { EntryMode, FinishMode } from 'dc-core/dc-backend/dc-enums';
import { OnlineGameplay } from 'dc-core/dc-backend/dc-interfaces';
import { ONLINE_GAME_SETTINGS_STORAGE_KEYS } from '../../../game-storage';
import {
    BestOfFirstTo,
    BESTOFMODE,
    ENTRYMODE_DOUBLE,
    ENTRYMODE_MASTER,
    ENTRYMODE_STRAIGHT,
    FINISHMODE_DOUBLE,
    FINISHMODE_MASTER,
    FINISHMODE_STRAIGHT,
    FIRSTTOMODE,
    LEGMODE,
    SetOrLeg,
    SETSMODE,
    STARTSCORE_301,
    STARTSCORE_501,
    STARTSCORE_701,
    STARTSCORE_CUSTOM,
    StartWith,
} from '../../settings.globals';
import { DartCounterOnlineGameSettings } from '../settings-classes';

export class DartCounterOnlineMatchSettings extends DartCounterOnlineGameSettings {
    public startWiths: { label: string; tag: string; type: StartWith }[] = [];
    public startWith: StartWith = '501';
    public customStartWith: number = 170;
    public minCustomStartWith = 101;
    public maxCustomStartWith = 9999;
    public inGameTimer: number = 60;
    public minInGameTimer: number = 30;
    public maxInGameTimer: number = 80;
    public fastScoring: boolean = false;
    public bestOfFirstTos: { label: string; tag: string; type: BestOfFirstTo }[] = [];
    public bestOfFirstTo: BestOfFirstTo = 'best_of';
    public goalAmount: number = 5;
    public minGoalAmount = 1;
    public maxGoalAmount = 30;
    public setsOrLegs: { label: string; tag: string; type: SetOrLeg }[] = [];
    public setOrLeg: SetOrLeg = 'legs';
    public entryModes: { label: string; tag: string; type: EntryMode }[] = [];
    public entryMode: EntryMode = 'straight_in';
    public finishModes: { label: string; tag: string; type: FinishMode }[] = [];
    public finishMode: FinishMode = 'double_out';
    public checkoutPercentage: boolean = true;
    public checkoutPercentageDisabled: boolean = true;
    public twoLegsDifference: boolean = false;
    public suddenDeath: boolean = false;

    constructor(storageKey: ONLINE_GAME_SETTINGS_STORAGE_KEYS = ONLINE_GAME_SETTINGS_STORAGE_KEYS.ONLINE_X01_GAMES) {
        //Set the super-class settings
        super();
        this.storageKey = storageKey;

        //Set the specific settings
        this.startWiths = [STARTSCORE_301, STARTSCORE_501, STARTSCORE_701, STARTSCORE_CUSTOM];
        this.bestOfFirstTos = [BESTOFMODE, FIRSTTOMODE];
        this.setsOrLegs = [LEGMODE, SETSMODE];
        this.entryModes = [ENTRYMODE_STRAIGHT, ENTRYMODE_DOUBLE, ENTRYMODE_MASTER];
        this.finishModes = [FINISHMODE_DOUBLE, FINISHMODE_MASTER, FINISHMODE_STRAIGHT];

        const storedSettings = JSON.parse(localStorage.getItem(this.storageKey));
        if (storedSettings) {
            this.setStoredSettings(storedSettings);
        }
        this.checkCheckoutPercentageDisabled();
    }

    public getSettingsClass(): DartCounterOnlineMatchSettings {
        return this;
    }

    public setStoredSettings(storedSettings: DartCounterOnlineMatchSettings) {
        this.startWith = storedSettings.startWith;
        this.customStartWith = storedSettings.customStartWith;
        this.inGameTimer = storedSettings.inGameTimer;
        this.fastScoring = storedSettings.fastScoring;
        this.bestOfFirstTo = storedSettings.bestOfFirstTo;
        this.goalAmount = storedSettings.goalAmount;
        this.setOrLeg = storedSettings.setOrLeg;
        this.entryMode = storedSettings.entryMode;
        this.finishMode = storedSettings.finishMode;
        this.twoLegsDifference = storedSettings.twoLegsDifference;
        this.suddenDeath = storedSettings.suddenDeath;
    }

    public setUser(user: User): void {
        super.setUser(user);
        this.checkoutPercentage = user.profile.has_checkout_rate;
    }

    changeStartWith(startWith: StartWith): void {
        this.startWith = startWith;
    }

    changeBestOfFirstTo(bestOfFirstTo: BestOfFirstTo): void {
        this.bestOfFirstTo = bestOfFirstTo;
    }

    changeSetOrLeg(setOrLeg: SetOrLeg): void {
        this.setOrLeg = setOrLeg;
    }

    changeEntryMode(entryMode: EntryMode): void {
        this.entryMode = entryMode;
    }

    changeFinishMode(finishMode: FinishMode): void {
        this.finishMode = finishMode;
        this.checkCheckoutPercentageDisabled();
    }

    checkCheckoutPercentageDisabled(): void {
        if (this.finishMode === 'master_out' || this.finishMode === 'straight_out') {
            this.checkoutPercentageDisabled = true;
        } else {
            this.checkoutPercentageDisabled = false;
        }
    }

    checkForErrors(): string[] {
        let errors = [];

        if (this.inGameTimer < this.minInGameTimer) {
            this.inGameTimer = this.minInGameTimer;
            errors.push($localize`:@@ERROR_MIN_TIMER:The minimum timer is 30 seconds`);
        } else if (this.inGameTimer > this.maxInGameTimer) {
            this.inGameTimer = this.maxInGameTimer;
            errors.push($localize`:@@ERROR_MAX_TIMER:The maximum timer is 80 seconds`);
        }

        if (this.startWith === 'custom' && this.customStartWith < this.minCustomStartWith) {
            this.customStartWith = this.minCustomStartWith;
            errors.push($localize`:@@ERROR_MIN_START_SCORE:The minimum start score is 101`);
        } else if (this.startWith === 'custom' && this.customStartWith > this.maxCustomStartWith) {
            this.customStartWith = this.maxCustomStartWith;
            errors.push($localize`:@@ERROR_MAX_START_SCORE:The maximum start score is 9999`);
        }

        if (this.goalAmount < this.minGoalAmount) {
            this.goalAmount = this.minGoalAmount;
        } else if (this.goalAmount > this.maxGoalAmount) {
            this.goalAmount = this.maxGoalAmount;
            errors.push($localize`:@@ERROR_MAX_GOAL_AMOUNT:The maximum goal amount is 30 sets or legs`);
        }

        if (!this.checkoutPercentageDisabled) {
            if (this.finishMode === 'straight_out' && this.checkoutPercentage) {
                errors.push(
                    $localize`:@@CHECKOUT_RATE_NOT_WITH_STRAIGHT_OUT:Checkout rate not supported with straight-out mode`
                );
            } else if (this.finishMode === 'master_out' && this.checkoutPercentage) {
                errors.push(
                    $localize`:@@CHECKOUT_RATE_NOT_WITH_MASTER_OUT:Checkout rate not supported with master-out mode`
                );
            }
        }

        return errors;
    }

    saveSettings(): void {
        localStorage.setItem(
            this.storageKey,
            JSON.stringify({
                startWith: this.startWith,
                customStartWith: this.customStartWith,
                inGameTimer: this.inGameTimer,
                fastScoring: this.fastScoring,
                bestOfFirstTo: this.bestOfFirstTo,
                goalAmount: this.goalAmount,
                setOrLeg: this.setOrLeg,
                entryMode: this.entryMode,
                finishMode: this.finishMode,
                twoLegsDifference: this.twoLegsDifference,
                suddenDeath: this.suddenDeath,
            })
        );
    }

    mapToOnlineGameplayObject(): OnlineGameplay {
        const onlineSavedGameplay: OnlineGameplay = {
            type: this.onlineGameMode,
            game: {
                has_teams: false,
                start_score: this.startWith === 'custom' ? this.customStartWith : parseInt(this.startWith),
                is_best_of: this.bestOfFirstTo === 'best_of',
                has_sets: this.setOrLeg === 'sets',
                goal_amount: this.goalAmount,
                vs_cpu: false,
                has_checkout_rate: this.checkoutPercentageDisabled ? false : this.checkoutPercentage,
                two_legs_difference: this.twoLegsDifference,
                enable_sudden_death: this.twoLegsDifference ? this.suddenDeath : false,
                start_with: this.entryMode,
                end_with: this.finishMode,
                sets: [],
                legs: [],
                is_online: true,
                teams: [],
            } as Match,
            owners: [],
            players: [],
            inGameTimer: this.inGameTimer,
            fastScoring: this.fastScoring,
        };
        return onlineSavedGameplay;
    }
}
