<div class="flex h-full w-[2.625rem] flex-col landscape:flex-col-reverse">
    <div (click)="toggleDropdown()" class="p-2.5 cursor-pointer relative">
        @for (keyboardFromList of keyboards; track $index) {
            @if (keyboard() === keyboardFromList.name) {
                @if (keyboardFromList.name === 'hotkeys') {
                    <app-icon icon="keyboard_default" class="!text-black"></app-icon>
                    <app-icon icon="star" size="medium" class="text-orange absolute right-1 bottom-1.5"></app-icon>
                } @else {
                    <app-icon [icon]="keyboardFromList.icon_name" class="!text-black"></app-icon>
                }
            }
        }
    </div>
    <div class="relative z-50 w-full">
        @if (dropdownOpened()) {
            <div
                class="absolute right-0 z-50 space-y-2 rounded-md bg-white"
                [ngClass]="{
                    'top-0': isWeb && isExtraLargeDevice(),
                    'bottom-4': isWeb && !isExtraLargeDevice(),
                    'landscape:bottom-4': !isWeb,
                    'portrait:top-4': !isWeb
                }"
                style="box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px">
                @for (keyboardFromList of keyboards; track $index) {
                    <div
                        class="flex items-center justify-center rounded-md p-2 cursor-pointer flex-none relative"
                        [class.bg-neutral-100]="keyboard() === keyboardFromList.name"
                        (click)="changeKeyboard(keyboardFromList.name)">
                        @if (keyboardFromList.name === 'hotkeys') {
                            <app-icon icon="keyboard_default" class="text-black"></app-icon>
                            <app-icon
                                icon="star"
                                size="medium"
                                class="text-orange absolute right-1 bottom-1.5"></app-icon>
                        } @else {
                            <app-icon [icon]="keyboardFromList.icon_name" class="text-black"></app-icon>
                        }
                    </div>
                }
            </div>
        }
    </div>
</div>
