import { User } from 'dc-core/dc-backend/dc-classes';
import { GameMode } from 'dc-core/dc-backend/dc-enums';
import { ONLINE_GAME_SETTINGS_STORAGE_KEYS } from 'dc-core/dc-gamelogic/game-storage';

export type OnlineGameJoinRequirements = {
    ultimateOnly: boolean;
    cameraOnly: boolean;
    omniScoringOnly: boolean;
    cameraOrOmniScoringOnly: boolean;
};

export class DartCounterOnlineGameSettings {
    public user: User;
    public storageKey: ONLINE_GAME_SETTINGS_STORAGE_KEYS;

    public onlineGameModes: { title: string; type: GameMode }[] = [];
    public onlineGameMode: GameMode;

    constructor() {
        this.onlineGameModes.push({
            title: $localize`:@@MATCH:Match`,
            type: 'match',
        });
        this.onlineGameModes.push({
            title: $localize`:@@CRICKET_TACTICS:Cricket / Tactics`,
            type: 'cricket_tactics',
        });
    }

    public setUser(user: User) {
        this.user = user;
    }

    setOnlineGameMode(onlineGameMode: GameMode): void {
        this.onlineGameMode = onlineGameMode;
    }
}
