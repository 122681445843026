<modal-content scrollY="false" fullscreen class="bg-white-pattern text-black">
    <div class="auto-height-dialog">
        <app-icon icon="close" class="text-black close-button" (click)="dismiss()"></app-icon>
        <div class="title-container">
            <div class="title" i18n="@@ENTER_YOUR_EMAIL" [translate]="'ENTER_YOUR_EMAIL'">Enter your email</div>
        </div>
        <div class="mt-8 space-y-4">
            <div class="w-full space-y-2">
                <div class="dialog-input-label" [translate]="'EMAIL'" i18n="@@EMAIL">Email</div>
                <input type="text" class="dialog-input" [(ngModel)]="email" />
            </div>

            <app-checkbox
                class="block"
                [model]="newsletterOptIn"
                color="white"
                (itemChange)="newsletterOptIn = !newsletterOptIn">
                <div class="flex text-black" [translate]="'NEWSLETTER_OPT_IN_INFO'" i18n="@@NEWSLETTER_OPT_IN_INFO">
                    Yes, I want to hear about exclusive offers, announcements and the newest products from Target
                    DartCounter, as well as those tailored to my account and activity.
                </div>
            </app-checkbox>
            <app-checkbox
                class="block"
                [model]="targetNewsletterOptIn"
                color="white"
                (itemChange)="targetNewsletterOptIn = !targetNewsletterOptIn">
                <div
                    class="flex text-black"
                    [translate]="'TARGET_NEWSLETTER_OPT_IN_INFO'"
                    i18n="@@TARGET_NEWSLETTER_OPT_IN_INFO">
                    Yes, I wish to be subscribe to Target Darts newsletter and hear about the latest Target Darts
                    products, offers and darts information.
                </div>
            </app-checkbox>
        </div>
        <div class="button-container">
            <app-primary-button
                size="normal"
                addedClasses="w-full"
                color="orange"
                (click)="submit()"
                [translate]="'SAVE'"
                i18n="@@SAVE">
                Save
            </app-primary-button>
        </div>
    </div>
</modal-content>
