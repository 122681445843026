import { Injectable, signal, WritableSignal } from '@angular/core';
import {
    addDoc,
    CollectionReference,
    deleteDoc,
    doc,
    DocumentReference,
    DocumentSnapshot,
    Firestore,
    getDoc,
    getDocs,
    onSnapshot,
    query,
    updateDoc,
    where,
} from '@angular/fire/firestore';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { GameMode } from '../../dc-backend/dc-enums';
import { OnlineGameplay, ONLINEGAMESTATUS } from '../../dc-backend/dc-interfaces';
import { dateTimeFormat } from '../../dc-gamelogic/settings/settings.globals';
import { FireStoreAuthService } from '../firestore-auth.service';
import { FireStoreCollectionsService } from '../firestore-collections.service';
import { DCFireStoreUser, FIRESTORE_COLLECTION, FireStoreOnlineVersion } from '../globals/firestore.tables';
import { ActiveGamesCollectionService } from './active_games.collection.service';

@Injectable()
export class LobbySettingsCollectionService extends FireStoreCollectionsService {
    private defaultClass = new OnlineGameplay();

    private collection_name: FIRESTORE_COLLECTION = FIRESTORE_COLLECTION.LOBBY_SETTINGS;
    private firestore_collection: CollectionReference<OnlineGameplay>;

    public currentGameInLobby: WritableSignal<OnlineGameplay> = signal(null);

    constructor(
        firestore: Firestore,
        private _dcFireAuth: FireStoreAuthService,
        private _activeGamesCollectionService: ActiveGamesCollectionService
    ) {
        super(firestore);
        this.firestore_collection = this.getConvertedData<OnlineGameplay>(this.collection_name);
    }

    watchDoc(id: string, observerFn) {
        let docRef = this.getDocByID(id);
        return onSnapshot(docRef, observerFn);
    }

    addItem(newDoc: OnlineGameplay): Promise<DocumentReference<OnlineGameplay>> {
        return addDoc(this.firestore_collection, newDoc);
    }

    getDocByID(id: string): DocumentReference<OnlineGameplay> {
        return doc(this.firestore_collection, id);
    }

    getDocSnapshot(id: string): Promise<DocumentSnapshot<OnlineGameplay>> {
        return getDoc(this.getDocByID(id));
    }

    updateOwnLobbyGame(updatedValues: OnlineGameplay) {
        if (this.currentGameInLobby()) {
            updateDoc(this.currentGameInLobby().doc_ref, { ...updatedValues });
        }
    }

    addOwner(user: DCFireStoreUser) {
        if (this.currentGameInLobby()) {
            const document = this.getDocByID(this.currentGameInLobby().doc_id);
            getDoc(document).then((docSnapShot) => {
                let gameplay = docSnapShot.data();
                gameplay.onlineGameStatus = ONLINEGAMESTATUS.READY_TO_START;
                gameplay.players.push(user);
                gameplay.owners.push(user.uid);
                gameplay.game.started_at = moment().utc().format(dateTimeFormat);

                this._activeGamesCollectionService.addItem(gameplay);
                this.removeLobbyGame();
            });
        }
    }

    addPlayerToStartGame(gameplay_doc_id: string, user: DCFireStoreUser) {
        const document = this.getDocByID(gameplay_doc_id);
        getDoc(document).then((docSnapShot) => {
            let gameplay = docSnapShot.data();
            gameplay.onlineGameStatus = ONLINEGAMESTATUS.READY_TO_START;
            gameplay.players.push(user);
            gameplay.game.started_at = moment().utc().format(dateTimeFormat);

            this._activeGamesCollectionService.addItem(gameplay);
            this.removeLobbyGame(gameplay_doc_id);
        });
    }

    removeLobbyGame(gameplay_doc_id: string = null): void {
        if (gameplay_doc_id) {
            const document = this.getDocByID(gameplay_doc_id);
            deleteDoc(document).then(() => {
                this.currentGameInLobby.set(null);
            });
        } else if (this.currentGameInLobby()) {
            const document = this.getDocByID(this.currentGameInLobby().doc_id);
            deleteDoc(document).then(() => {
                this.currentGameInLobby.set(null);
            });
        }
    }

    removeOwnItems() {
        try {
            const whereClause = this.getAttributeString(this.defaultClass, (obj: OnlineGameplay) => obj.owners);

            const removables = query(
                this.firestore_collection,
                where(whereClause, 'array-contains', this._dcFireAuth.getCurrentUID())
            );

            getDocs(removables).then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    deleteDoc(doc.ref);
                });
            });

            this.currentGameInLobby.set(null);
        } catch (err) {
            console.error(err);
        }
    }

    watchOwnLobbyGames(): Observable<OnlineGameplay[]> {
        return new Observable<OnlineGameplay[]>((observer) => {
            try {
                const whereClause = this.getAttributeString(this.defaultClass, (obj: OnlineGameplay) => obj.owners);
                const statusClause = this.getAttributeString(
                    this.defaultClass,
                    (obj: OnlineGameplay) => obj.onlineGameStatus
                );
                const testQuery = query(
                    this.firestore_collection,
                    where(whereClause, 'array-contains', this._dcFireAuth.getCurrentUID()),
                    where(statusClause, '!=', ONLINEGAMESTATUS.INVITE_ONLY)
                );

                const ownLobbyGames: OnlineGameplay[] = [];

                const unsubscribe = onSnapshot(testQuery, (snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                        const data = change.doc.data() as OnlineGameplay;
                        const doc_id = change.doc.id;
                        const doc_ref = change.doc.ref;
                        const index = ownLobbyGames.findIndex((game) => game.doc_id === doc_id);

                        if (change.type === 'added') {
                            ownLobbyGames.push({ doc_id, doc_ref, ...data });
                        } else if (change.type === 'modified') {
                            if (index !== -1) {
                                ownLobbyGames[index] = { doc_id, doc_ref, ...data };
                            }
                        } else if (change.type === 'removed') {
                            if (index !== -1) {
                                ownLobbyGames.splice(index, 1);
                            }
                        }
                    });

                    if (!ownLobbyGames.length) {
                        this.currentGameInLobby.set(null);
                    }
                    observer.next(ownLobbyGames);
                });

                // Unsubscribe when observer is unsubscribed
                return () => {
                    unsubscribe();
                };
            } catch (err) {
                observer.error(err);
            }
        });
    }

    getLobby(): Observable<OnlineGameplay[]> {
        return new Observable<OnlineGameplay[]>((observer) => {
            try {
                const onlineGameStatus = this.getAttributeString(
                    this.defaultClass,
                    (obj: OnlineGameplay) => obj.onlineGameStatus
                );
                const onlineGameVersion = this.getAttributeString(
                    this.defaultClass,
                    (obj: OnlineGameplay) => obj.onlineGameVersion
                );

                const testQuery = query(
                    this.firestore_collection,
                    where(onlineGameStatus, '!=', ONLINEGAMESTATUS.INVITE_ONLY),
                    where(onlineGameVersion, '==', FireStoreOnlineVersion)
                );

                const onlineGameplays: OnlineGameplay[] = [];

                const unsubscribe = onSnapshot(testQuery, (snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                        const data = change.doc.data() as OnlineGameplay;
                        const doc_id = change.doc.id;
                        const index = onlineGameplays.findIndex((game) => game.doc_id === doc_id);

                        if (change.type === 'added') {
                            onlineGameplays.push({ doc_id, ...data });
                        } else if (change.type === 'modified') {
                            if (index !== -1) {
                                onlineGameplays[index] = { doc_id, ...data };
                            }
                        } else if (change.type === 'removed') {
                            if (index !== -1) {
                                onlineGameplays.splice(index, 1);
                            }
                        }
                    });

                    observer.next(onlineGameplays);
                });

                // Unsubscribe when observer is unsubscribed
                return () => {
                    unsubscribe();
                };
            } catch (err) {
                observer.error(err);
            }
        });
    }

    getLobbyByGameMode(mode?: GameMode): Observable<OnlineGameplay[]> {
        return new Observable<OnlineGameplay[]>((observer) => {
            try {
                const whereClause = this.getAttributeString(this.defaultClass, (obj: OnlineGameplay) => obj.type);
                const statusClause = this.getAttributeString(
                    this.defaultClass,
                    (obj: OnlineGameplay) => obj.onlineGameStatus
                );

                const testQuery = query(
                    this.firestore_collection,
                    where(whereClause, '==', mode),
                    where(statusClause, '!=', ONLINEGAMESTATUS.INVITE_ONLY)
                );

                const onlineGameplays: OnlineGameplay[] = [];

                const unsubscribe = onSnapshot(testQuery, (snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                        const data = change.doc.data() as OnlineGameplay;
                        const doc_id = change.doc.id;
                        const index = onlineGameplays.findIndex((game) => game.doc_id === doc_id);

                        if (change.type === 'added') {
                            onlineGameplays.push({ doc_id, ...data });
                        } else if (change.type === 'modified') {
                            if (index !== -1) {
                                onlineGameplays[index] = { doc_id, ...data };
                            }
                        } else if (change.type === 'removed') {
                            if (index !== -1) {
                                onlineGameplays.splice(index, 1);
                            }
                        }
                    });

                    observer.next(onlineGameplays);
                });

                // Unsubscribe when observer is unsubscribed
                return () => {
                    unsubscribe();
                };
            } catch (err) {
                observer.error(err);
            }
        });
    }
}
