import { CommonModule } from '@angular/common';
import { Component, input, InputSignal } from '@angular/core';
import { DCFireStoreUser } from '@dc-core/dc-firestore/globals/firestore.tables';

import { IconComponent } from '../shared/components/icon/icon.component';

@Component({
    selector: 'app-online-player-stats',
    templateUrl: 'online-player-stats.component.html',
    standalone: true,
    imports: [CommonModule, IconComponent],
})
export class OnlinePlayerStatsComponent {
    public player: InputSignal<DCFireStoreUser> = input.required();
    public reviewCount: InputSignal<number> = input();
    public showRating: InputSignal<boolean> = input(true);
    public mode: InputSignal<'dark' | 'light'> = input('light');
    public size: InputSignal<'xs' | 'sm' | 'base'> = input('xs');
}
