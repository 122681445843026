<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="dismiss()">Close</div>
        </ion-buttons>
        <div class="slide-modal-title" [translate]="'INFORMATION_REQUIRED'" i18n="@@INFORMATION_REQUIRED">
            Information required
        </div>
    </div>
    <div class="slide-modal-content text-black">
        <div class="subtitle" [translate]="'PROFILE_CHECK_FOR_FEATURE_INFO'" i18n="@@PROFILE_CHECK_FOR_FEATURE_INFO">
            To ensure the safety and security of our users, please update the following profile information.
        </div>
        <div class="subtitle my-4">
            <span i18n="@@READ_PRIVACY_POLICY_FOR_MORE_INFO" [translate]="'READ_PRIVACY_POLICY_FOR_MORE_INFO'"
                >For more info read our
            </span>
            <button
                class="text-orange ml-1"
                (click)="openPrivacyPolicy()"
                [translate]="'PRIVACY_POLICY'"
                i18n="@@PRIVACY_POLICY">
                Privacy Policy
            </button>
        </div>
        @if (!showVerify) {
            <form #ngForm="ngForm" [formGroup]="form" (ngSubmit)="form.valid && save()" class="space-y-4" novalidate>
                @if (form.get('birthdate')) {
                    <div class="w-full space-y-2">
                        <div class="dialog-input-label flex items-center gap-1">
                            {{ 'DATE_OF_BIRTH' | translate }}
                        </div>
                        <div class="dialog-input" (click)="changeBirthdate()">
                            @if (form.get('birthdate').value) {
                                <span
                                    >{{
                                        form.get('birthdate').value
                                            | date: 'd MMM yyyy' : '' : translateService.currentLang
                                    }}
                                </span>
                            } @else {
                                <span [translate]="'NO_DATE_OF_BIRTH'" i18n="@@NO_DATE_OF_BIRTH">
                                    No date of birth
                                </span>
                            }
                        </div>
                        @if (ngForm.submitted && form.get('birthdate').errors) {
                            <span
                                class="text-small text-red-500"
                                [translate]="'DATE_OF_BIRTH_REQUIRED'"
                                i18n="@@DATE_OF_BIRTH_REQUIRED">
                                Date of birth is required
                            </span>
                        }
                    </div>
                }

                @if (form.get('email')) {
                    <div class="w-full space-y-2">
                        <div class="dialog-input-label flex justify-between items-center">
                            {{ 'EMAIL' | translate }}
                            @if (form.get('email').valid && !emailVerified && hasInitialEmail) {
                                <span class="flex items-center gap-[0.1rem]">
                                    <span [translate]="'UNVERIFIED'" i18n="@@UNVERIFIED">Unverified</span>
                                    <app-icon icon="close" size="small" class="text-orange"></app-icon>
                                </span>
                            }
                        </div>
                        <input
                            type="text"
                            [formControl]="form.get('email')"
                            class="dialog-input"
                            placeholder="{{ 'EMAIL' | translate }}" />
                        @if (ngForm.submitted && form.get('email').errors) {
                            <span
                                class="text-small text-red-500"
                                [translate]="'EMAIL_REQUIRED'"
                                i18n="@@EMAIL_REQUIRED">
                                Email is required
                            </span>
                        }
                        @if (existingEmail === form.get('email').value) {
                            <span
                                class="text-small text-red-500"
                                [translate]="'EMAIL_ALREADY_EXISTS'"
                                i18n="@@EMAIL_ALREADY_EXISTS">
                                Email already exists
                            </span>
                        }
                    </div>
                }
                <div class="button-container">
                    <app-primary-button
                        type="submit"
                        size="normal"
                        addedClasses="w-full"
                        color="orange"
                        [translate]="'CONFIRM'"
                        i18n="@@CONFIRM">
                        Confirm
                    </app-primary-button>
                </div>
            </form>
        } @else {
            <form
                #verifyForm="ngForm"
                [formGroup]="validationForm"
                (ngSubmit)="validationForm.valid && tryEmailVerification()"
                class="space-y-4"
                novalidate>
                <div class="w-full space-y-2">
                    <div
                        class="dialog-input-label flex justify-between items-center"
                        [translate]="'VERIFICATION_CODE'"
                        i18n="@@VERIFICATION_CODE">
                        Verification code
                    </div>
                    <input
                        type="text"
                        [formControl]="validationForm.get('code')"
                        class="dialog-input"
                        placeholder="{{ 'FILL_DIGITS_IN' | translate }}" />
                    @if (verifyForm.submitted && validationForm.get('code').errors) {
                        <span
                            class="text-small text-red-500"
                            [translate]="'VERIFICATION_CODE_REQUIRED'"
                            i18n="@@VERIFICATION_CODE_REQUIRED">
                            Verification code is required
                        </span>
                    }
                    @if (invalidCode) {
                        <span
                            class="text-small text-red-500"
                            [translate]="'INVALID_VERIFICATION_CODE'"
                            i18n="@@INVALID_VERIFICATION_CODE"
                            >Invalid verificaton code
                        </span>
                    }
                </div>
                <div class="text-black space-y-2" [translate]="'CODE_SENT_TO'" i18n="@@CODE_SENT_TO">
                    Code was sent to:
                </div>
                <span class="text-black font-semibold">
                    {{ form.get('email').value }}
                </span>
                <div
                    class="text-black my-4"
                    [translate]="'CHECK_SPAM_FOLDER_FOR_EMAIL'"
                    i18n="@@CHECK_SPAM_FOLDER_FOR_EMAIL">
                    Make sure to check your spam folder if you haven't received the confirmation email
                </div>
                <div class="button-container flex space-x-2">
                    <app-primary-button
                        class="w-full"
                        size="small"
                        addedClasses="w-full"
                        color="orange"
                        fill="outline"
                        [translate]="'SEND_VERIFICATION_CODE'"
                        i18n="@@SEND_VERIFICATION_CODE"
                        (click)="sendEmailVerification()">
                        Send verification code
                    </app-primary-button>
                    <app-primary-button
                        class="w-full"
                        type="submit"
                        size="small"
                        addedClasses="w-full"
                        color="orange"
                        [translate]="'CONFIRM'"
                        i18n="@@CONFIRM">
                        Confirm
                    </app-primary-button>
                </div>
            </form>
        }
    </div>
</div>
