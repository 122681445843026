/* eslint-disable max-len */
import { Injectable, signal, WritableSignal } from '@angular/core';
import { PaymentApiService } from '@dc-api/payment.api.service';
import { Discount, Plan } from '@dc-core/dc-backend/dc-classes';
import { DiscountEvent, IntervalPeriod } from '@dc-core/dc-backend/dc-enums';
import { UltimateHelper } from '@dc-core/dc-helpers/ultimate.helper';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { round } from 'lodash';
import { GA_EVENTACTIONS } from 'src/app/app.globals';
import { AllFeaturesDialogComponent } from 'src/dialogs/all-features/all-features';
import { UltimateSubscriptionDialogComponent } from 'src/dialogs/ultimate-subscription/ultimate-subscription';

import { AuthService } from '../services/auth.service';

/*
  Generated class for the DartCounter AudioService.
*/
@Injectable()
export class UpgradeService {
    public monthlyPlan: Plan & { title?: string; monthlyPrice?: number; percentage?: number };
    public yearlyPlan: Plan & { title?: string; monthlyPrice?: number; percentage?: number };
    public savePercentage: number = null;
    public plans: Plan[] & { title?: string; monthlyPrice?: number; percentage?: number }[] = [];
    public ultimateFeatures: string[] = UltimateHelper.getFeatures();

    public discountEvent: DiscountEvent = null;
    public discountEventBanner: string = null;
    public discountEventBannerMobile: string = null;
    public discountEventBannerWeb: string = null;
    public discountEventLoginTitle: string = null;

    public currentActiveDiscount: WritableSignal<Discount> = signal(undefined);

    constructor(
        private auth: AuthService,
        private ga: DartCounterAnalyticsService,
        private _paymentApiService: PaymentApiService
    ) {}

    loadPlans(withDiscount: boolean): Promise<void> {
        return new Promise((resolve) => {
            this._paymentApiService
                .getPlans({ is_active: true })
                .then((res) => {
                    this.monthlyPlan = res.data.find((plan) => plan.interval_period === 'month');
                    this.monthlyPlan.title = $localize`:@@ULTIMATE_MONTHLY:Ultimate Monthly`;
                    this.monthlyPlan.monthlyPrice = this.monthlyPlan.price / 100;
                    this.monthlyPlan.first_payment_price = round(this.monthlyPlan.first_payment_price / 100, 2);
                    this.monthlyPlan.final_first_payment_price = round(
                        this.monthlyPlan.final_first_payment_price / 100,
                        2
                    );

                    this.yearlyPlan = res.data.find((plan) => plan.interval_period === 'year');
                    this.yearlyPlan.title = $localize`:@@ULTIMATE_YEARLY:Ultimate Yearly`;

                    this.yearlyPlan.monthlyPrice = round(Math.floor(this.yearlyPlan.price / 12) / 100, 2);
                    this.yearlyPlan.first_payment_price = round(this.yearlyPlan.first_payment_price / 100, 2);
                    this.yearlyPlan.final_first_payment_price = round(
                        this.yearlyPlan.final_first_payment_price / 100,
                        2
                    );
                    this.yearlyPlan.percentage = round(
                        (1 - this.yearlyPlan.monthlyPrice / this.monthlyPlan.monthlyPrice) * 100,
                        0
                    );

                    const yearlyPrice = Math.ceil(this.yearlyPlan.price);
                    const monthlyPrice = Math.ceil(this.monthlyPlan.price * 12);
                    this.savePercentage = Math.ceil((1 - yearlyPrice / monthlyPrice) * 100);

                    if (withDiscount && this.yearlyPlan.active_discount) {
                        this.discountEvent = this.yearlyPlan.active_discount.event;
                        this.setDiscountEventBanners();
                    } else {
                        this.discountEvent = null;
                    }

                    this.plans = [this.yearlyPlan, this.monthlyPlan];
                })
                .finally(() => resolve());
        });
    }

    checkDiscount(intervalPeriod: IntervalPeriod = 'year'): Promise<Discount> {
        return new Promise((resolve) => {
            this._paymentApiService
                .getPlans({ is_active: true })
                .then((res) => {
                    const plan = res.data.find((plan) => plan.interval_period === intervalPeriod);
                    if (plan.active_discount) {
                        this.discountEvent = plan.active_discount.event;
                        this.setDiscountEventBanners();
                        resolve(plan.active_discount);
                    } else {
                        resolve(null);
                    }
                })
                .catch(() => resolve(null));
        });
    }

    setDiscountEventBanners(): void {
        switch (this.discountEvent) {
            case 'black_friday':
                this.discountEventBanner = 'BlackFriday33Off_carousel.png';
                this.discountEventBannerMobile = 'BlackFriday33Off_mobile.jpg';
                this.discountEventBannerWeb = 'BlackFriday33Off_web.jpg';
                this.discountEventLoginTitle = $localize`:@@LOGIN_FOR_BLACK_FRIDAY:Login to use this Black Friday deal!`;
                break;
        }
    }

    async GetUpgradeDialog(triggeredFrom) {
        let dialog: any = UltimateSubscriptionDialogComponent;
        if (this.auth.user.is_ultimate) {
            this.ga.trackEvent(triggeredFrom, GA_EVENTACTIONS.ALLFEATURES);
            dialog = AllFeaturesDialogComponent;
        } else {
            this.ga.trackEvent(triggeredFrom, GA_EVENTACTIONS.OPENULTIMATE);
        }

        // Return the dialog data
        return dialog;
    }
}
