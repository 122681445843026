import { Component, inject, Input, OnInit } from '@angular/core';
import { UserApiService } from '@dc-api/user.api.service';
import { ReportTurn, User } from '@dc-core/dc-backend/dc-classes';
import { ReportOrigin, SuspensionReason } from '@dc-core/dc-backend/dc-enums';
import { ChatsCollectionService } from '@dc-core/dc-firestore/collection-helpers/chats.collection.service';
import { FSCHatMessage } from '@dc-core/dc-firestore/globals/firestore.tables';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { InGameCameraService } from '@dc-core/dc-services/camera/ingame-camera.service';
import { DropdownSelectItem } from '@dc-core/dc-statistics/statistics.globals';
import { ModalController } from '@ionic/angular';
import _ from 'lodash';
import { GamesLeaveBusterService } from 'src/app/modules/games/services/games-leave-buster.service';
import { GamesReportService } from 'src/app/modules/games/services/games-report.service';

@Component({
    selector: 'app-report-user-dialog',
    templateUrl: 'report-user.dialog.html',
})
export class ReportUserDialogComponent implements OnInit {
    @Input() user: User;
    @Input() origin: ReportOrigin = null;
    @Input() turns: ReportTurn[] | null = null;

    public reason = '';
    public block = true;

    private modalController: ModalController = inject(ModalController);
    private alertService: DartCounterAlertService = inject(DartCounterAlertService);
    private userApiService: UserApiService = inject(UserApiService);
    private chatsCollectionService: ChatsCollectionService = inject(ChatsCollectionService);
    private ingameCameraService: InGameCameraService = inject(InGameCameraService);
    private leaveBusterService: GamesLeaveBusterService = inject(GamesLeaveBusterService);
    private reportService: GamesReportService = inject(GamesReportService);

    suspensionReason: SuspensionReason = null;
    suspensionReasons: DropdownSelectItem[] = [
        {
            label: $localize`:@@SELECT_A_REASON:Select a reason`,
            value: null,
            hidden: true,
        },
        {
            label: $localize`:@@VERBAL_ABUSE_INFO:Verbal abuse (Harassment, offensive language)`,
            value: 'verbal_abuse' as SuspensionReason,
        },
        {
            label: $localize`:@@VISUAL_ABUSE_INFO:Visual abuse (Hateful video content)`,
            value: 'visual_abuse' as SuspensionReason,
        },
        {
            label: $localize`:@@HATE_SPEECH_INFO:Hate speech (Bullying, racism, sexism, homophobia, etc.)`,
            value: 'hate_speech' as SuspensionReason,
        },
        {
            label: $localize`:@@SEXUAL_MISCONDUCT_INFO:Sexual misconduct (Inappropriate video / chat content)`,
            value: 'sexual_misconduct' as SuspensionReason,
        },
        { label: $localize`:@@CHEATING:Cheating`, value: 'cheating' as SuspensionReason },
        {
            label: $localize`:@@OFFENSIVE_OR_INAPPROPRIATE_PROFILE:Offensive or Inappropriate profile`,
            value: 'offensive_or_inappropriate_profile' as SuspensionReason,
        },
    ];
    errors: { reason: boolean; suspensionReason: boolean } = { reason: false, suspensionReason: false };

    ADDITIONAL_INFORMATION = $localize`:@@ADDITIONAL_INFORMATION:Additional information`;

    private reportClipFormData: FormData = null;
    private isReporting = false;

    public ngOnInit(): void {
        try {
            this.ingameCameraService.stopRecording(this.user.id, false).finally(async () => {
                this.reportClipFormData = this.ingameCameraService.getReportClipFormData(this.user.id);
            });
        } catch (err) {
            console.error(err);
        }
    }

    public report(): void {
        if (!this.reason || !this.suspensionReason) {
            this.errors.reason = !this.reason;
            this.errors.suspensionReason = !this.suspensionReason;

            this.alertService.createAlert({
                title: $localize`:@@FILL_IN_REQUIRED_FIELDS:Please fill in the required fields`,
                icon: 'error',
            });
            return;
        }

        if (!this.reportService.checkReport(true)) {
            return;
        }

        if (this.isReporting) {
            return;
        }

        this.isReporting = true;

        let chatLog: FSCHatMessage[] = [];
        if (this.chatsCollectionService.chatUserIds.includes(this.user.id)) {
            chatLog = _.cloneDeep(this.chatsCollectionService.chatLog);
            chatLog.forEach((chat) => {
                chat.sender = {
                    user_id: chat.sender.user_id,
                } as any;
            });
        }

        this.userApiService
            .reportUserById({
                userId: this.user.id,
                reason: this.reason,
                suspension_reason: this.suspensionReason,
                chat_log: JSON.stringify(chatLog),
                turns: this.turns,
                block: this.block,
                tournament_game_id: this.leaveBusterService.currentTournamentGame
                    ? this.leaveBusterService.currentTournamentGame.id
                    : null,
                origin: this.origin,
            })
            .then((res) => {
                this.alertService.createAlert({
                    title: $localize`:@@REPORTED_THE_PLAYER:You reported the player`,
                    icon: 'success',
                });

                if (this.block) {
                    this.user.is_blocked = true;
                }

                if (this.reportClipFormData) {
                    this.ingameCameraService.finishReportClip(res.data, this.reportClipFormData);
                }

                this.close(true);
            })
            .catch(() => this.close(false))
            .finally(() => {
                this.isReporting = false;
            });
    }

    public close(reported = false): void {
        this.modalController.dismiss(reported);
    }
}
