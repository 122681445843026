<app-dialog-view [showCloseButton]="true" (onDismiss)="dismiss()">
    <div title>
        <span>{{ title }}</span>
    </div>

    <div>
        <div class="mb-2">
            @if (cameraOnly || cameraOrOmniScoringOnly) {
                <div class="new-game-card tournament mt-2">
                    <div class="title-container w-fit">
                        <div class="title">
                            <div class="flex items-center rounded-sm bg-white text-black p-1.5 space-x-2 w-fit">
                                <app-icon icon="videocam" size="small"></app-icon>
                                <div
                                    class="ml-1 text-xs font-bold uppercase leading-none font-open-sans"
                                    [translate]="'CAM_ONLY'"
                                    i18n="@@CAM_ONLY">
                                    Cam only
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-col items-start pl-2">
                        @if (
                            videoRoomService.ownCamera.roomID &&
                            videoRoomService.ownUserMedia?.videoStreams?.hasStreams &&
                            videoRoomService.ownUserMedia?.videoStreams?.activeStreams === true
                        ) {
                            <div class="flex h-full portrait:flex-col portrait:sm:flex-row landscape:flex-row mb-4">
                                @if (videoRoomService.ownUserMedia?.videoStreams.hasStreams === false) {
                                    <app-icon
                                        icon="loading"
                                        size="extralarge"
                                        class="mx-auto animate-spin text-orange"></app-icon>
                                }

                                @if (videoRoomService.ownUserMedia?.videoStreams.board?.stream) {
                                    <div class="relative mx-auto basis-1/2 overflow-hidden">
                                        <video
                                            *ngIf="videoRoomService.ownUserMedia?.videoStreams.board?.stream"
                                            class="ingame-camera w-full"
                                            [srcObject]="videoRoomService.ownUserMedia?.videoStreams.board.stream"
                                            [style.transform]="videoRoomService.ownUserMedia?.videoStreams.board.scale"
                                            autoplay
                                            playsinline
                                            webkit-playsinline
                                            muted></video>
                                        <div class="absolute left-4 top-4 h-7 w-7 rounded-md bg-orange p-1">
                                            <app-icon icon="videocam" size="medium" class="!text-white"></app-icon>
                                        </div>
                                    </div>
                                }

                                @if (videoRoomService.ownUserMedia?.videoStreams.player) {
                                    <div class="relative mx-auto basis-1/2 overflow-hidden">
                                        <video
                                            *ngIf="videoRoomService.ownUserMedia?.videoStreams.player?.stream"
                                            class="ingame-camera w-full"
                                            [srcObject]="videoRoomService.ownUserMedia?.videoStreams.player.stream"
                                            autoplay
                                            playsinline
                                            webkit-playsinline
                                            muted></video>
                                        <div class="absolute right-4 top-4 h-7 w-7 rounded-md bg-orange p-1">
                                            <app-icon icon="videocam" size="medium" class="!text-white"></app-icon>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div class="flex justify-center w-full">
                                <app-primary-button class="block" size="extrasmall" (click)="unlinkCamera()">
                                    <div class="flex items-center space-x-2">
                                        <div [translate]="'ACTIVATED'" i18n="@@ACTIVATED">Activated</div>
                                        <div class="h-4 w-4 flex items-center justify-center rounded-full bg-green">
                                            <app-icon
                                                icon="check_mark"
                                                size="extrasmall"
                                                class="!text-white"></app-icon>
                                        </div>
                                    </div>
                                </app-primary-button>
                            </div>
                        } @else {
                            <div class="text-sm flex items-center gap-1 text-black mb-4">
                                <span
                                    [translate]="'CAMERA_REQUIRED_TO_PLAY_THIS_GAME'"
                                    i18n="@@CAMERA_REQUIRED_TO_PLAY_THIS_GAME">
                                    Your camera is required to play this game
                                </span>
                            </div>

                            <div class="flex justify-center w-full">
                                <app-primary-button
                                    size="extrasmall"
                                    (click)="linkCamera()"
                                    [translate]="'ACTIVATE'"
                                    i18n="@@ACTIVATE">
                                    Activate
                                </app-primary-button>
                            </div>
                        }
                    </div>
                </div>
            }

            @if (omniScoringOnly || cameraOrOmniScoringOnly) {
                <div class="new-game-card tournament mt-2">
                    <div class="title-container w-fit">
                        <div class="title">
                            <div
                                class="flex items-center rounded-sm bg-neutral-50 dark:bg-black-full text-black p-1.5 space-x-2 w-fit">
                                <img class="w-16" [src]="'assets/icon/ftr/omni_scoring_logo.webp'" />
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-col items-start pl-2">
                        @if (omniStatus() === SmartDeviceStatus.OWNED && !omniIngameService.omniConnected()) {
                            <div class="text-sm flex items-center gap-1 text-black mb-4">
                                <span
                                    [translate]="'OMNI_REQUIRED_TO_PLAY_THIS_GAME'"
                                    i18n="@@OMNI_REQUIRED_TO_PLAY_THIS_GAME">
                                    Your Omni Scoring is required to play this game
                                </span>
                            </div>

                            <div class="flex justify-center w-full">
                                <app-primary-button
                                    size="extrasmall"
                                    (click)="activateOmni()"
                                    [translate]="'ACTIVATE'"
                                    i18n="@@ACTIVATE">
                                    Activate
                                </app-primary-button>
                            </div>
                        } @else if (omniIngameService.omniConnected()) {
                            <div class="flex justify-center w-full">
                                <app-primary-button size="extrasmall">
                                    <div class="flex items-center space-x-2">
                                        <div [translate]="'ACTIVATED'" i18n="@@ACTIVATED">Activated</div>
                                        <div class="h-4 w-4 flex items-center justify-center rounded-full bg-green">
                                            <app-icon
                                                icon="check_mark"
                                                size="extrasmall"
                                                class="!text-white"></app-icon>
                                        </div>
                                    </div>
                                </app-primary-button>
                            </div>
                        } @else {
                            <div class="text-sm flex items-center gap-1 text-black mb-4">
                                <span
                                    [translate]="'OMNI_REQUIRED_TO_PLAY_THIS_GAME'"
                                    i18n="@@OMNI_REQUIRED_TO_PLAY_THIS_GAME">
                                    Your Omni Scoring is required to play this game
                                </span>
                            </div>

                            <div class="flex justify-center w-full">
                                <app-primary-button
                                    size="extrasmall"
                                    (click)="setupSmartDevice()"
                                    [translate]="'SETUP'"
                                    i18n="@@SETUP">
                                    Setup
                                </app-primary-button>
                            </div>
                        }
                    </div>
                </div>
            }
        </div>

        <div class="button-container flex items-center space-x-2">
            <app-primary-button
                color="orange"
                size="normal"
                class="w-full"
                addedClasses="w-full"
                (click)="confirm()"
                [translate]="'CONFIRM'"
                [disabled]="
                    (cameraOnly &&
                        !(
                            this.videoRoomService.ownCamera.roomID &&
                            this.videoRoomService.ownUserMedia?.videoStreams?.hasStreams &&
                            this.videoRoomService.ownUserMedia?.videoStreams?.activeStreams === true
                        )) ||
                    (omniScoringOnly && !omniIngameService.omniConnected()) ||
                    (cameraOrOmniScoringOnly &&
                        !(
                            this.videoRoomService.ownCamera.roomID &&
                            this.videoRoomService.ownUserMedia?.videoStreams?.hasStreams &&
                            this.videoRoomService.ownUserMedia?.videoStreams?.activeStreams === true
                        ) &&
                        !omniIngameService.omniConnected())
                "
                i18n="@@CONFIRM">
                Confirm
            </app-primary-button>
        </div>
    </div>
</app-dialog-view>
