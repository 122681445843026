export enum LOCAL_GAME_SETTINGS_STORAGE_KEYS {
    MATCH = 'MATCH_SETTINGS',
    CRICKET_TACTICS = 'CRICKET_TACTICS_SETTINGS',
    AROUND_THE_CLOCK = 'AROUND_THE_CLOCK_SETTINGS',
    BOBS_27 = 'BOBS_27_SETTINGS',
    SHANGHAI = 'SHANGHAI_SETTINGS',
    CHECKOUT_121 = 'CHECKOUT_121_SETTINGS',
    SINGLES_TRAINING = 'SINGLES_TRAINING_SETTINGS',
    DOUBLES_TRAINING = 'DOUBLES_TRAINING_SETTINGS',
    SCORE_TRAINING = 'SCORE_TRAINING_SETTINGS',
}

export enum LOCAL_GAME_UNFINISHED_KEYS {
    MATCH = 'MATCH_UNFINISHED',
    CRICKET_TACTICS = 'CRICKET_TACTICS_UNFINISHED',
    AROUND_THE_CLOCK = 'AROUND_THE_CLOCK_UNFINISHED',
    BOBS_27 = 'BOBS_27_UNFINISHED',
    SHANGHAI = 'SHANGHAI_UNFINISHED',
    CHECKOUT_121 = 'CHECKOUT_121_UNFINISHED',
    SINGLES_TRAINING = 'SINGLES_TRAINING_UNFINISHED',
    DOUBLES_TRAINING = 'DOUBLES_TRAINING_UNFINISHED',
    SCORE_TRAINING = 'SCORE_TRAINING_UNFINISHED',
}

export enum ONLINE_GAME_SETTINGS_STORAGE_KEYS {
    ONLINE_X01_GAMES = 'ONLINE_X01_GAMES_SETTINGS',
    ONLINE_CRICKET_TACTICS = 'ONLINE_CRICKET_TACTICS_SETTINGS',

    TOURNAMENT_X01_GAMES = 'TOURNAMENT_X01_GAMES_SETTINGS',
}
