import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { TournamentApiService } from '@dc-api/tournament.api.service';
import { Tournament, TournamentGame } from '@dc-core/dc-backend/dc-classes';
import { TournamentFinishGameEvent } from '@dc-core/dc-backend/dc-enums';
import { PlayerInterim } from '@dc-core/dc-backend/dc-interfaces';
import { ActiveGamesCollectionService } from '@dc-core/dc-firestore/collection-helpers/active_games.collection.service';
import { InviteCollectionService } from '@dc-core/dc-firestore/collection-helpers/invite.collection.service';
import { UsersCollectionService } from '@dc-core/dc-firestore/collection-helpers/users.collection.service';
import { DCFireStoreInvite, INVITATIONSTATUS } from '@dc-core/dc-firestore/globals/firestore.tables';
import { ModalController, ModalOptions, NavController } from '@ionic/angular';
import { AuthService } from '@services/auth.service';
import { getDoc } from 'firebase/firestore';
import moment from 'moment';
import {
    TournamentNotificationDialogComponent,
    TournamentNotificationDialogPayload,
    TournamentNotificationType,
} from 'src/app/modules/tournaments/dialogs/tournament-notification-dialog/tournament-notification.dialog';
import { EchoService } from 'src/app/services/echo.service';
import { TournamentWinnerDialogComponent } from 'src/app/shared/components/dialog/tournament-winner-dialog/tournament-winner.dialog';
import { environment } from 'src/environments/environment';

import {
    OnlineGameJoinRequirementsCheckDialogComponent,
    OnlineGameJoinRequirementsCheckDialogPayload,
} from '../../games/dialogs/online-game-join-requirements-check/online-game-join-requirements-check.dialog';
import { GamesLeaveBusterService } from '../../games/services/games-leave-buster.service';
import { TournamentView } from '../pages/tournaments/view/tournament-view.component';

@Injectable()
export class TournamentService {
    public participatingTournament: WritableSignal<Tournament | null> = signal(null);
    public currentGameInvite: WritableSignal<DCFireStoreInvite> = signal(null);
    public updatedTournament: WritableSignal<{ tournament: Tournament; updatedAt: Date }> = signal({
        tournament: null,
        updatedAt: null,
    });

    private authService: AuthService = inject(AuthService);
    private tournamentApiService: TournamentApiService = inject(TournamentApiService);
    private echoService: EchoService = inject(EchoService);
    private modalController: ModalController = inject(ModalController);
    private navController: NavController = inject(NavController);
    private inviteCollectionService: InviteCollectionService = inject(InviteCollectionService);
    private activeGamesCollectionService: ActiveGamesCollectionService = inject(ActiveGamesCollectionService);
    private leaveBusterService: GamesLeaveBusterService = inject(GamesLeaveBusterService);
    private usersCollectionService: UsersCollectionService = inject(UsersCollectionService);

    public tournamentListeners = new Map<number, boolean>();
    public tournamentModals = new Map<number, { type: TournamentNotificationType; elem: HTMLIonModalElement }>();
    public awaitingModal: { tournamentId: number; type: TournamentNotificationType; elem: HTMLIonModalElement } = null;
    public invites: WritableSignal<Tournament[]> = signal([]);

    public startAllListeners(): void {
        this.getCurrentTournaments();

        this.echoService.privateChannel(
            `user.${this.authService.user.id}`,
            'tournament.check-in.started',
            (data: { tournamentId: number }) => {
                if (this.participatingTournament()?.id === data.tournamentId) {
                    const tournament: Tournament = {
                        ...this.participatingTournament(),
                        status: 'check_in_period',
                    };
                    this.updateTournament(tournament);
                    this.setParticipatingTournament(tournament);

                    this.modalController
                        .create({
                            component: TournamentNotificationDialogComponent,
                            componentProps: {
                                tournament,
                                type: 'check_in_started',
                            } as TournamentNotificationDialogPayload,
                            cssClass: 'auto-height',
                            showBackdrop: true,
                            backdropDismiss: true,
                        })
                        .then((elem) => {
                            this.checkNewDialogAndClearOld(tournament.id, 'check_in_started', elem);

                            elem.present();
                            elem.onDidDismiss().then((dialogRes) => {
                                this.tournamentModals.delete(tournament.id);

                                if (dialogRes.data) {
                                    this.goToTournament(tournament.slug);
                                }
                            });
                        });
                }
            }
        );

        this.echoService.privateChannel(`user.${this.authService.user.id}`, 'tournament.pre-game.started', async () => {
            const participatingTournament = await this.getCurrentTournaments();
            if (participatingTournament) {
                this.updateTournament(participatingTournament);
            }
        });

        this.echoService.privateChannel(
            `user.${this.authService.user.id}`,
            'tournament.participant.approved',
            (data: { tournamentId: number }) => {
                if (this.participatingTournament()?.id === data.tournamentId) {
                    const tournament: Tournament = {
                        ...this.participatingTournament(),
                        participation: {
                            ...this.participatingTournament().participation,
                            approved_at: new Date().toISOString(),
                        },
                    };
                    this.updateTournament(tournament);
                    this.setParticipatingTournament(tournament);

                    this.modalController
                        .create({
                            component: TournamentNotificationDialogComponent,
                            componentProps: {
                                tournament,
                                type: 'approved',
                            } as TournamentNotificationDialogPayload,
                            cssClass: 'auto-height',
                            showBackdrop: true,
                            backdropDismiss: true,
                        })
                        .then((elem) => {
                            this.checkNewDialogAndClearOld(tournament.id, 'approved', elem);

                            elem.present();
                            elem.onDidDismiss().then((dialogRes) => {
                                this.tournamentModals.delete(tournament.id);

                                if (dialogRes.data) {
                                    this.goToTournament(tournament.slug);
                                }
                            });
                        });
                }
            }
        );

        this.echoService.privateChannel(
            `user.${this.authService.user.id}`,
            'tournament.participant.kicked',
            (data: { tournamentId: number; currentParticipants: number; reason: string }) => {
                if (this.participatingTournament()?.id === data.tournamentId) {
                    const tournament: Tournament = {
                        ...this.participatingTournament(),
                        participation: null,
                        current_participants: data.currentParticipants,
                    };

                    this.updateTournament(tournament);

                    this.modalController
                        .create({
                            component: TournamentNotificationDialogComponent,
                            componentProps: {
                                tournament,
                                type: 'kicked',
                                reason: data.reason,
                            } as TournamentNotificationDialogPayload,
                            cssClass: 'auto-height',
                            showBackdrop: true,
                            backdropDismiss: true,
                        })
                        .then((elem) => {
                            this.checkNewDialogAndClearOld(tournament.id, 'kicked', elem);

                            elem.present();
                            elem.onDidDismiss().then(() => {
                                this.tournamentModals.delete(tournament.id);
                            });
                        });
                }

                this.clearListeners(data.tournamentId);
                this.clearParticipatingTournament(data.tournamentId);
            }
        );

        this.echoService.privateChannel(
            `user.${this.authService.user.id}`,
            'tournament.participant.withdrawed',
            (data: { tournamentId: number; currentParticipants: number }) => {
                if (data.tournamentId) {
                    if (this.participatingTournament()?.id === data.tournamentId) {
                        const tournament: Tournament = {
                            ...this.participatingTournament(),
                            participation: null,
                            current_participants: data.currentParticipants,
                        };

                        this.updateTournament(tournament);
                    }

                    this.clearListeners(data.tournamentId);
                    this.clearParticipatingTournament(data.tournamentId);
                }
            }
        );

        this.echoService.privateChannel(
            `user.${this.authService.user.id}`,
            'tournament.participant.reported',
            (data: { tournamentId: number; participantId: number; round: number; reason: string }) => {
                if (this.leaveBusterService.inGame() !== 'online') {
                    if (this.participatingTournament()?.id === data.tournamentId) {
                        const tournament: Tournament = {
                            ...this.participatingTournament(),
                        };

                        if (tournament.participants) {
                            this.modalController
                                .create({
                                    component: TournamentNotificationDialogComponent,
                                    componentProps: {
                                        tournament,
                                        participant: tournament.participants.find(
                                            (participant) => participant.id == data.participantId
                                        ),
                                        round: data.round,
                                        type: 'reported',
                                        reason: data.reason,
                                    } as TournamentNotificationDialogPayload,
                                    cssClass: 'auto-height',
                                    showBackdrop: true,
                                    backdropDismiss: true,
                                })
                                .then((elem) => {
                                    this.checkNewDialogAndClearOld(tournament.id, 'reported', elem);

                                    elem.present();
                                    elem.onDidDismiss().then((dialogRes) => {
                                        this.tournamentModals.delete(tournament.id);

                                        if (dialogRes.data) {
                                            this.goToTournament(tournament.slug, true, 'activity');
                                        }
                                    });
                                });
                        }
                    }
                }
            }
        );

        this.echoService.privateChannel(
            `user.${this.authService.user.id}`,
            'tournament.participant.disqualified',
            (data: { tournamentId: number; reason: string }) => {
                this.leaveBusterService.allowedToLeave = true;

                if (this.participatingTournament()?.id === data.tournamentId) {
                    const tournament: Tournament = {
                        ...this.participatingTournament(),
                        participation: {
                            ...this.participatingTournament().participation,
                            disqualified_at: new Date().toISOString(),
                            result: 'lost',
                        },
                    };
                    this.updateTournament(tournament);
                    this.setParticipatingTournament(tournament);

                    this.goToTournament(tournament.slug, false);

                    this.modalController
                        .create({
                            component: TournamentNotificationDialogComponent,
                            componentProps: {
                                tournament,
                                type: 'disqualified',
                                reason: data.reason,
                            } as TournamentNotificationDialogPayload,
                            cssClass: 'auto-height',
                            showBackdrop: true,
                            backdropDismiss: true,
                        })
                        .then((elem) => {
                            this.checkNewDialogAndClearOld(tournament.id, 'disqualified', elem);

                            elem.present();
                            elem.onDidDismiss().then(() => {
                                this.tournamentModals.delete(tournament.id);
                            });
                        });

                    this.clearListeners(tournament.id);
                    this.clearParticipatingTournament(tournament.id);
                }
            }
        );

        this.echoService.privateChannel(
            `user.${this.authService.user.id}`,
            'tournament.moderator.invited',
            (data: { tournamentId: number; tournamentSlug: string }) => {
                this.modalController
                    .create({
                        component: TournamentNotificationDialogComponent,
                        componentProps: {
                            type: 'invited_as_moderator',
                        } as TournamentNotificationDialogPayload,
                        cssClass: 'auto-height',
                        showBackdrop: true,
                        backdropDismiss: true,
                    })
                    .then((elem) => {
                        if (
                            this.checkNewDialogAndClearOld(
                                data.tournamentId,
                                'invited_as_moderator',
                                elem,
                                this.leaveBusterService.inGame() === 'online'
                            )
                        ) {
                            this.getCurrentTournaments();
                            elem.present();
                        }

                        elem.onDidDismiss().then((dialogRes) => {
                            this.tournamentModals.delete(data.tournamentId);

                            if (dialogRes.data) {
                                this.goToTournament(data.tournamentSlug);
                            }
                        });
                    });
            }
        );

        this.echoService.privateChannel(
            `user.${this.authService.user.id}`,
            'tournament.moderator.removed',
            (data: { tournamentId: number; deleted: boolean; isAdmin: boolean }) => {
                this.invites.update((invites) => invites.filter((invite) => invite.id !== data.tournamentId));

                if (this.participatingTournament()?.id === data.tournamentId) {
                    const tournament: Tournament = {
                        ...this.participatingTournament(),
                        participation: data.deleted
                            ? null
                            : {
                                  ...this.participatingTournament().participation,
                                  invited_for_admin_at: null,
                                  is_admin: null,
                              },
                    };
                    this.updateTournament(tournament);
                    this.setParticipatingTournament(data.deleted ? tournament : null);

                    if (this.leaveBusterService.inGame() !== 'online') {
                        this.modalController
                            .create({
                                component: TournamentNotificationDialogComponent,
                                componentProps: {
                                    tournament,
                                    type: data.isAdmin ? 'removed_as_moderator' : 'invite_cancelled_for_moderator',
                                } as TournamentNotificationDialogPayload,
                                cssClass: 'auto-height',
                                showBackdrop: true,
                                backdropDismiss: true,
                            })
                            .then((elem) => {
                                this.checkNewDialogAndClearOld(tournament.id, 'removed_as_moderator', elem);

                                elem.present();
                                elem.onDidDismiss().then(() => {
                                    this.tournamentModals.delete(tournament.id);
                                });
                            });
                    }
                }

                if (data.deleted) {
                    this.clearListeners(data.tournamentId);
                    this.clearParticipatingTournament(data.tournamentId);
                }
            }
        );

        this.echoService.privateChannel(
            `user.${this.authService.user.id}`,
            'tournament.game.has-bye',
            (data: { tournamentId: number }) => {
                if (this.participatingTournament()?.id === data.tournamentId) {
                    this.modalController
                        .create({
                            component: TournamentNotificationDialogComponent,
                            componentProps: {
                                tournament: this.participatingTournament(),
                                type: 'has_bye',
                            } as TournamentNotificationDialogPayload,
                            cssClass: 'auto-height',
                            showBackdrop: true,
                            backdropDismiss: true,
                        })
                        .then((elem) => {
                            this.checkNewDialogAndClearOld(this.participatingTournament().id, 'has_bye', elem);

                            elem.present();
                            elem.onDidDismiss().then(() => {
                                this.tournamentModals.delete(this.participatingTournament().id);
                            });
                        });
                }
            }
        );

        this.echoService.privateChannel(
            `user.${this.authService.user.id}`,
            'tournament.game.won-by-event',
            (data: { tournamentId: number; finishGameEvent: TournamentFinishGameEvent; reason: string }) => {
                this.leaveBusterService.allowedToLeave = true;

                if (this.participatingTournament()?.id === data.tournamentId) {
                    if (data.finishGameEvent === 'disqualified') {
                        this.goToTournament(this.participatingTournament().slug, false);
                    }

                    this.modalController
                        .create({
                            component: TournamentNotificationDialogComponent,
                            componentProps: {
                                tournament: this.participatingTournament(),
                                type: 'game_won_by_event',
                                finishGameEvent: data.finishGameEvent,
                                reason: data.reason,
                            } as TournamentNotificationDialogPayload,
                            cssClass: 'auto-height',
                            showBackdrop: true,
                            backdropDismiss: true,
                        })
                        .then((elem) => {
                            this.checkNewDialogAndClearOld(
                                this.participatingTournament().id,
                                'game_won_by_event',
                                elem
                            );

                            elem.present();
                            elem.onDidDismiss().then((dialogRes) => {
                                this.tournamentModals.delete(this.participatingTournament().id);

                                if (dialogRes.data && data.finishGameEvent !== 'disqualified') {
                                    this.goToTournament(this.participatingTournament().slug);
                                }

                                this.checkAwaitingModal();
                            });
                        });
                }
            }
        );

        this.echoService.privateChannel(
            `user.${this.authService.user.id}`,
            'tournament.lost',
            (data: { tournamentId: number; amountOfRounds: number; playedUntilRound: number }) => {
                if (this.participatingTournament()?.id === data.tournamentId) {
                    const tournament: Tournament = {
                        ...this.participatingTournament(),
                        amount_of_rounds: data.amountOfRounds,
                        participation: {
                            ...this.participatingTournament().participation,
                            played_until_round: data.playedUntilRound,
                        },
                    };

                    this.modalController
                        .create({
                            component: TournamentNotificationDialogComponent,
                            componentProps: {
                                tournament,
                                participant: tournament.participation,
                                type: 'lost',
                            } as TournamentNotificationDialogPayload,
                            cssClass: 'auto-height',
                            showBackdrop: true,
                            backdropDismiss: true,
                        })
                        .then((elem) => {
                            this.checkNewDialogAndClearOld(tournament.id, 'lost', elem);

                            elem.present();
                            elem.onDidDismiss().then((dialogRes) => {
                                this.tournamentModals.delete(tournament.id);

                                if (dialogRes.data) {
                                    this.goToTournament(tournament.slug);
                                }
                            });
                        });

                    this.clearParticipatingTournament(tournament.id);
                }
            }
        );

        this.echoService.privateChannel(
            `user.${this.authService.user.id}`,
            'tournament.won',
            (data: { tournamentId: number }) => {
                if (this.participatingTournament()?.id === data.tournamentId) {
                    const tournament: Tournament = {
                        ...this.participatingTournament(),
                    };

                    this.modalController
                        .create({
                            component: TournamentWinnerDialogComponent,
                            componentProps: {
                                tournament,
                            },
                            cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
                        } as ModalOptions)
                        .then((elem) => {
                            this.checkNewDialogAndClearOld(tournament.id, 'won', elem);

                            elem.present();
                            elem.onDidDismiss().then((dialogRes) => {
                                this.tournamentModals.delete(tournament.id);

                                if (dialogRes.data) {
                                    this.goToTournament(tournament.slug);
                                }
                            });
                        });

                    this.clearParticipatingTournament(tournament.id);
                }
            }
        );
    }

    public clearAllListeners(): void {
        this.tournamentListeners.forEach((_, id) => {
            this.clearListeners(id);
        });
        this.tournamentListeners.clear();

        this.echoService.stopListening(`user.${this.authService.user.id}`, 'tournament.check-in.started');
        this.echoService.stopListening(`user.${this.authService.user.id}`, 'tournament.pre-game.started');
        this.echoService.stopListening(`user.${this.authService.user.id}`, 'tournament.participant.approved');
        this.echoService.stopListening(`user.${this.authService.user.id}`, 'tournament.participant.withdrawed');
        this.echoService.stopListening(`user.${this.authService.user.id}`, 'tournament.participant.reported');
        this.echoService.stopListening(`user.${this.authService.user.id}`, 'tournament.participant.disqualified');
        this.echoService.stopListening(`user.${this.authService.user.id}`, 'tournament.moderator.invited');
        this.echoService.stopListening(`user.${this.authService.user.id}`, 'tournament.moderator.removed');
        this.echoService.stopListening(`user.${this.authService.user.id}`, 'tournament.game.won-by-event');
        this.echoService.stopListening(`user.${this.authService.user.id}`, 'tournament.lost');
        this.echoService.stopListening(`user.${this.authService.user.id}`, 'tournament.won');
    }

    public getCurrentTournaments(searchTournamentId: number = null): Promise<Tournament> {
        return new Promise((resolve) => {
            this.tournamentApiService
                .getParticipatingTournaments({})
                .then((res) => {
                    let participating = res.data.current;

                    const invites: Tournament[] = [];
                    res.data.tournaments.forEach((tournament) => {
                        if (searchTournamentId == tournament.id) {
                            participating = tournament;
                        }

                        if (tournament.participation.invited_for_admin_at && !tournament.participation.is_admin) {
                            invites.push(tournament);
                        }

                        if (tournament.participation.joined_at) {
                            this.startListeners(tournament);
                        }
                    });

                    this.invites.set(invites);
                    this.participatingTournament.set(res.data.current ? { ...res.data.current } : null);
                    resolve(participating);
                })
                .catch(() => resolve(null));
        });
    }

    public startListeners(tournament: Tournament): void {
        if (!this.tournamentListeners.has(tournament.id)) {
            this.tournamentListeners.set(tournament.id, true);

            this.startTournamentPrivateChannels(tournament);
        }
    }

    public clearListeners(tournamentId: number): void {
        if (this.tournamentListeners.has(tournamentId)) {
            this.echoService.leave(`tournament.${tournamentId}`);
            this.tournamentListeners.delete(tournamentId);
        }
    }

    public setNewParticipatingTournament(tournament: Tournament, force = false): void {
        this.startListeners(tournament);
        const participatingTournament = this.participatingTournament();
        if (
            force ||
            !participatingTournament ||
            participatingTournament.id === tournament.id ||
            tournament.starting_at <= participatingTournament.starting_at
        ) {
            this.participatingTournament.set({ ...tournament });
        }
    }

    public updateTournament(tournament: Tournament): void {
        this.updatedTournament.update(() => ({
            tournament,
            updatedAt: new Date(),
        }));
    }

    public showPreGameInvite(invite: DCFireStoreInvite): void {
        this.modalController
            .create({
                component: TournamentNotificationDialogComponent,
                componentProps: {
                    tournament: invite.tournament_game.tournament,
                    game: invite.tournament_game,
                    type: 'game_is_starting',
                } as TournamentNotificationDialogPayload,
                cssClass: 'auto-height',
                showBackdrop: true,
                backdropDismiss: true,
            })
            .then((elem) => {
                if (this.checkNewDialogAndClearOld(invite.tournament_game.tournament_id, 'game_is_starting', elem)) {
                    elem.present();
                }

                elem.onDidDismiss().then((dialogRes) => {
                    this.tournamentModals.delete(invite.tournament_game.tournament_id);

                    if (dialogRes.data) {
                        this.dismissOnlineEndScreenDialog();
                        this.checkRequirementsAndGoToPreGame(invite);
                    }
                });
            });
    }

    public checkRequirementsAndGoToPreGame(invite: DCFireStoreInvite): void {
        if (!invite) {
            invite = this.currentGameInvite();
        }

        this.checkTournamentRequirements(invite.tournament_game.tournament).then((checked) => {
            if (checked) {
                try {
                    const activeGameRef = this.activeGamesCollectionService.getDocByID(
                        invite.tournament_game.active_game_doc_id
                    );
                    getDoc(activeGameRef).then((docSnapshot) => {
                        const gameplay = docSnapshot.data();
                        if (gameplay) {
                            const players = gameplay.players;
                            players.forEach((player, index) => {
                                if (player.user_id === this.authService.user.id) {
                                    players[index] = this.usersCollectionService.getDCFireStoreUser(
                                        this.authService.user
                                    );
                                }
                            });
                            this.activeGamesCollectionService.updateOnlinePlayersByRef(activeGameRef, players);
                        }
                    });
                } catch (err) {
                    console.error(err);
                }

                this.currentGameInvite.set(null);

                this.activeGamesCollectionService.redirectBack = `/tournaments/${invite.tournament_game.tournament_id}`;

                this.inviteCollectionService.updateTournamentInviteStatus(invite.doc_ref, INVITATIONSTATUS.ACCEPTED);
                this.navController.navigateForward('pre-game/' + invite.tournament_game.active_game_doc_id);
            }
        });
    }

    public checkTournamentRequirements(tournament: Tournament): Promise<boolean> {
        return new Promise((resolve) => {
            this.modalController
                .create({
                    component: OnlineGameJoinRequirementsCheckDialogComponent,
                    componentProps: {
                        title: $localize`:@@TOURNAMENT_REQUIREMENTS:Tournament requirements`,
                        cameraOnly: tournament.camera_required,
                        omniScoringOnly: tournament.omni_scoring_required,
                        cameraOrOmniScoringOnly: false,
                        tournament,
                    } as OnlineGameJoinRequirementsCheckDialogPayload,
                    cssClass: 'auto-height',
                    showBackdrop: true,
                    backdropDismiss: false,
                })
                .then((elem) => {
                    elem.present();
                    elem.onDidDismiss().then((dialogRes) => {
                        if (dialogRes.data) {
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    });
                });
        });
    }

    public startGame(game: TournamentGame): void {
        this.tournamentApiService.startTournamentGame({
            tournamentId: game.tournament_id,
            gameId: game.id,
        });
    }

    public updateGame(game: TournamentGame, playerInterims: PlayerInterim[]): void {
        this.tournamentApiService.updateTournamentGame({
            tournamentId: game.tournament_id,
            gameId: game.id,
            player_interims: playerInterims,
        });
    }

    public finishGame(game: TournamentGame): Promise<void> {
        return new Promise<void>((resolve) => {
            this.tournamentApiService
                .finishTournamentGame({
                    tournamentId: game.tournament_id,
                    gameId: game.id,
                })
                .finally(() => resolve());
        });
    }

    public leaveGame(game: TournamentGame): Promise<void> {
        return new Promise<void>((resolve) => {
            this.tournamentApiService
                .leaveTournamentGame({
                    tournamentId: game.tournament_id,
                    gameId: game.id,
                })
                .finally(() => resolve());
        });
    }

    public quitGame(game: TournamentGame): Promise<void> {
        return new Promise<void>((resolve) => {
            this.tournamentApiService
                .quitTournamentGame({
                    tournamentId: game.tournament_id,
                    gameId: game.id,
                })
                .finally(() => resolve());
        });
    }

    public async removeNotRespondingUser(): Promise<void> {
        if (this.leaveBusterService.currentTournamentGame) {
            const tournament = { ...this.leaveBusterService.currentTournamentGame.tournament };
            await this.activeGamesCollectionService.removeNotRespondingUser();
            await this.finishGame(this.leaveBusterService.currentTournamentGame);

            this.modalController
                .create({
                    component: TournamentNotificationDialogComponent,
                    componentProps: {
                        tournament,
                        type: 'game_won_by_event',
                        finishGameEvent: 'not_responding',
                    } as TournamentNotificationDialogPayload,
                    cssClass: 'auto-height',
                    showBackdrop: true,
                    backdropDismiss: true,
                })
                .then((elem) => {
                    this.checkNewDialogAndClearOld(tournament.id, 'game_won_by_event', elem);

                    elem.present();
                    elem.onDidDismiss().then((dialogRes) => {
                        this.tournamentModals.delete(tournament.id);

                        if (dialogRes.data) {
                            this.goToTournament(tournament.slug);
                        }
                    });
                });
        }
    }

    public clearParticipatingTournament(tournamentId: number): void {
        if (this.participatingTournament()?.id === tournamentId) {
            this.participatingTournament.set(null);
        }
    }

    public isParticipatingRestricting(): boolean {
        if (!this.participatingTournament()) {
            return false;
        }

        return moment(this.participatingTournament().check_in_from).subtract(30, 'minutes').isSameOrBefore(moment());
    }

    public setActiveGameRedirectBack(slug: string): void {
        this.activeGamesCollectionService.redirectBack = `/tournaments/${slug}`;
    }

    public checkAwaitingModal(): void {
        if (this.awaitingModal) {
            this.checkNewDialogAndClearOld(
                this.awaitingModal.tournamentId,
                this.awaitingModal.type,
                this.awaitingModal.elem
            );
            this.awaitingModal.elem.present();
            this.awaitingModal = null;
        }
    }

    private goToTournament(slug: string, forward = true, view: TournamentView = null): void {
        this.dismissOnlineEndScreenDialog();

        const url = view ? `tournaments/${slug}/${view}` : `tournaments/${slug}`;
        if (forward) {
            this.navController.navigateForward(url);
        } else {
            this.navController.navigateBack(url);
        }
    }

    private dismissOnlineEndScreenDialog(): void {
        if (this.leaveBusterService.onlineEndScreenDialog) {
            this.leaveBusterService.onlineEndScreenDialog.dismiss();
            this.leaveBusterService.onlineEndScreenDialog = null;
        }
    }

    private setParticipatingTournament(tournament: Tournament, refresh = false): void {
        if (this.participatingTournament()?.id === tournament.id) {
            if (refresh) {
                this.tournamentApiService.showTournamentByIdOrSlug({ tournamentId: tournament.id }).then((res) => {
                    this.participatingTournament.set(res.data);
                });
            } else {
                this.participatingTournament.set(tournament ? { ...tournament } : null);
            }
        }
    }

    private startTournamentPrivateChannels(tournament: Tournament): void {
        this.echoService.privateChannel(
            `tournament.${tournament.id}`,
            'tournament.started',
            (data: { amountOfParticipants: number; amountOfRounds: number; currentParticipants: number }) => {
                if (this.participatingTournament()?.id === tournament.id) {
                    const updatedTournament: Tournament = {
                        ...this.participatingTournament(),
                        amount_of_participants: data.amountOfParticipants,
                        amount_of_rounds: data.amountOfRounds,
                        current_participants: data.currentParticipants,
                        started_at: new Date().toISOString(),
                        status: 'started',
                    };
                    this.setParticipatingTournament(updatedTournament);
                }
            }
        );

        this.echoService.privateChannel(`tournament.${tournament.id}`, 'tournament.finished', () => {
            if (this.participatingTournament()?.id === tournament.id) {
                const updatedTournament: Tournament = {
                    ...this.participatingTournament(),
                    finished_at: new Date().toISOString(),
                    status: 'finished',
                };
                this.setParticipatingTournament(updatedTournament);
            }
        });

        this.echoService.privateChannel(`tournament.${tournament.id}`, 'tournament.deleted', () => {
            this.clearParticipatingTournament(tournament.id);
        });

        this.echoService.privateChannel(
            `tournament.${tournament.id}`,
            'tournament.game.finished',
            (data: { gameId: number; finishedAt: string }) => {
                if (
                    this.participatingTournament()?.id === tournament.id &&
                    this.participatingTournament().participation?.current_game?.id === data.gameId
                ) {
                    this.participatingTournament.update((participatingTournament) => ({
                        ...participatingTournament,
                        participation: {
                            ...participatingTournament.participation,
                            current_game: {
                                ...participatingTournament.participation.current_game,
                                finished_at: data.finishedAt,
                            },
                        },
                    }));
                }
            }
        );
    }

    private checkNewDialogAndClearOld(
        tournamentId: number,
        type: TournamentNotificationType,
        elem: HTMLIonModalElement,
        setAsAwaiting = false
    ): boolean {
        if (setAsAwaiting) {
            this.setAwaitingModal(tournamentId, type, elem);
            return false;
        }

        let openNewModal = true;

        if (this.tournamentModals.has(tournamentId)) {
            if (this.tournamentModals.get(tournamentId).type === 'game_won_by_event') {
                openNewModal = false;
                this.setAwaitingModal(tournamentId, type, elem);
            } else {
                this.tournamentModals.get(tournamentId).elem.dismiss();
            }
        }

        if (openNewModal) {
            this.tournamentModals.set(tournamentId, { type, elem });
        }

        return openNewModal;
    }

    private setAwaitingModal(tournamentId: number, type: TournamentNotificationType, elem: HTMLIonModalElement): void {
        this.awaitingModal = { tournamentId, type, elem };
    }
}
