<!-- Translation containers-->
@if (false) {
    <ng-container i18n="@@NEW_PASSWORD">New password</ng-container>
    <ng-container i18n="@@REPEAT_PASS">Repeat password</ng-container>
}
<!-- End of translation containers-->

<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <div class="slide-modal-title" [translate]="'MUST_CHANGE_PASSWORD'" i18n="@@MUST_CHANGE_PASSWORD">
            Choose a password
        </div>
    </div>
    <div class="slide-modal-content mt-2">
        <app-profile-photo
            class="h-fit"
            [size]="80"
            [ultimate]="user.is_ultimate"
            [badge]="user.profile?.badge"
            [big]="true"
            [url]="user.profile?.profile_photo_url">
        </app-profile-photo>
        <div class="text-center mt-2" [translate]="'MUST_CHANGE_PASSWORD_INFO'" i18n="@@MUST_CHANGE_PASSWORD_INFO">
            Please change your password to continue
        </div>
        <form
            class="accountform flex flex-col space-y-3 py-4"
            #form="ngForm"
            [formGroup]="passwordForm"
            (ngSubmit)="passwordForm.valid && changePassword()">
            <div>
                <div class="dialog-input-label" [translate]="'NEW_PASSWORD'" i18n="@@NEW_PASSWORD">New password</div>
                <div class="relative">
                    <input
                        class="dialog-input"
                        [type]="passwordVisible ? 'text' : 'password'"
                        [ngClass]="{
                            '!border-red': form.submitted && passwordForm.get('confirmPassword').errors
                        }"
                        placeholder="{{ 'NEW_PASSWORD' | translate }}"
                        formControlName="password" />
                    <app-icon
                        class="flex absolute -translate-y-1/2 top-1/2 opacity-60 right right-4 cursor-pointer text-black"
                        [icon]="passwordVisible ? 'visibility_off' : 'visibility'"
                        (click)="togglePasswordVisibility('password')"></app-icon>
                </div>

                @if (form.submitted && passwordForm.get('password').errors) {
                    @if (passwordForm.get('password').errors.required) {
                        <app-validation-label [noAbsolute]="true">
                            <span [translate]="'PASSWORD_REQUIRED'" i18n="@@PASSWORD_REQUIRED">
                                Password is required
                            </span>
                        </app-validation-label>
                    } @else if (passwordForm.get('password').errors.minlength) {
                        <app-validation-label [noAbsolute]="true">
                            <span
                                [translate]="'PASSWORD_MUST_BE_ATLEAST_6_CHARACTERS_LONG'"
                                i18n="@@PASSWORD_MUST_BE_ATLEAST_6_CHARACTERS_LONG">
                                Password must be atleast 6 characters long
                            </span>
                        </app-validation-label>
                    }
                }
            </div>
            <div>
                <div class="dialog-input-label" [translate]="'REPEAT_PASS'" i18n="@@REPEAT_PASS">Repeat password</div>
                <div class="relative">
                    <input
                        class="dialog-input"
                        [type]="confirmPasswordVisible ? 'text' : 'password'"
                        [ngClass]="{
                            '!border-red':
                                form.submitted &&
                                (passwordForm.get('confirmPassword').errors ||
                                    passwordForm.hasError('passwordsDoNotMatch'))
                        }"
                        formControlName="confirmPassword"
                        placeholder="{{ 'REPEAT_PASS' | translate }}" />
                    <app-icon
                        class="flex absolute -translate-y-1/2 top-1/2 opacity-60 right right-4 cursor-pointer text-black"
                        [icon]="confirmPasswordVisible ? 'visibility_off' : 'visibility'"
                        (click)="togglePasswordVisibility('confirmPassword')"></app-icon>
                </div>
                @if (form.submitted) {
                    @if (passwordForm.get('confirmPassword').errors) {
                        @if (passwordForm.get('confirmPassword').errors.required) {
                            <app-validation-label [noAbsolute]="true">
                                <span [translate]="'PASSWORD_REQUIRED'" i18n="@@PASSWORD_REQUIRED">
                                    Password is required
                                </span>
                            </app-validation-label>
                        } @else if (passwordForm.get('confirmPassword').errors.minlength) {
                            <app-validation-label [noAbsolute]="true">
                                <span
                                    [translate]="'PASSWORD_MUST_BE_ATLEAST_6_CHARACTERS_LONG'"
                                    i18n="@@PASSWORD_MUST_BE_ATLEAST_6_CHARACTERS_LONG">
                                    Password must be atleast 6 characters long
                                </span>
                            </app-validation-label>
                        }
                    } @else if (passwordForm.hasError('passwordsDoNotMatch')) {
                        <app-validation-label [noAbsolute]="true">
                            <span [translate]="'REPEATPASS_ERROR'" i18n="@@REPEATPASS_ERROR">
                                The two passwords don't match
                            </span>
                        </app-validation-label>
                    }
                }
            </div>
            <app-primary-button
                type="submit"
                size="normal"
                [addedClasses]="'w-full'"
                [color]="'orange'"
                [translate]="'SAVE'"
                i18n="@@SAVE">
                Save
            </app-primary-button>
        </form>
    </div>
</div>
