import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import {
    addDoc,
    collection,
    CollectionReference,
    deleteDoc,
    doc,
    DocumentData,
    DocumentReference,
    Firestore,
    getDocs,
    onSnapshot,
    orderBy,
    query,
    QuerySnapshot,
    Timestamp,
    where,
} from '@angular/fire/firestore';
import _ from 'lodash';
import { Observable, Subject, take } from 'rxjs';
import { UserService } from 'src/app/modules/user/services/user.service';
import { OnlineGameplay } from '../../dc-backend/dc-interfaces';
import { CHAT_MSG, IN_GAME_COMMS } from '../../dc-gamelogic/in-game/ingame.globals';
import { RXJSSubscriptionManager } from '../../dc-logging/rxjs-subscription.manager';
import { SUBSCRIPTION_KEY } from '../../dc-logging/subscription_enums';
import { DartCounterPreferenceService } from '../../dc-services/preference/preference.service';
import { FireStoreCollectionsService } from '../firestore-collections.service';
import { DCFireStoreUser, FIRESTORE_COLLECTION, FSCHatMessage } from '../globals/firestore.tables';

export interface SendChatPayload {
    sender: DCFireStoreUser;
    gameplay_doc_id: string;
    chat_msg?: CHAT_MSG;
    custom_msg?: string;
    unfiltered_msg?: string;
    emoji_only?: boolean;
}
@Injectable()
export class ChatsCollectionService extends FireStoreCollectionsService {
    private defaultClass = new FSCHatMessage();

    private collection_name: FIRESTORE_COLLECTION = FIRESTORE_COLLECTION.CHATS;
    private firestore_collection: CollectionReference<FSCHatMessage>;

    private _lastTimestamp: Timestamp = Timestamp.now();

    public showingDialog = false;

    public chatLog: FSCHatMessage[] = [];
    public chatUserIds: number[] = [];
    public incomingChatMessages: WritableSignal<FSCHatMessage[]> = signal([]);
    public unreadChatMessages: WritableSignal<number> = signal(0);
    private _activeGameRef: DocumentReference<OnlineGameplay> | null = null;
    private _currentUserUid: string | null = null;
    public chatScrollSubject$: Subject<Timestamp> = new Subject();

    private userService: UserService = inject(UserService);

    constructor(
        private firestore: Firestore,
        private _rxjsSubscriptionManager: RXJSSubscriptionManager,
        private _preferenceService: DartCounterPreferenceService
    ) {
        super(firestore);
        this.firestore_collection = this.getConvertedData<FSCHatMessage>(this.collection_name);
    }

    enableChatFeature(
        gameDocRef: DocumentReference<OnlineGameplay>,
        userUid: string,
        resetChatLog: boolean,
        allUserIds: number[] | null
    ) {
        this.disableChat(resetChatLog);

        this._activeGameRef = gameDocRef;
        this._currentUserUid = userUid;

        this._lastTimestamp = Timestamp.now();

        if (allUserIds) {
            this.chatUserIds = allUserIds;
        }

        // Set the initial chat
        this.userService
            .canUseSocialFeatures(false, false)
            .pipe(take(1))
            .subscribe((valid: boolean) => {
                if (valid) {
                    this.getInitialMessages().then((chatMessagesSnapshot: QuerySnapshot<FSCHatMessage>) => {
                        if (chatMessagesSnapshot.size > 0) {
                            let chatMessages = _.map(chatMessagesSnapshot.docs, (c) => {
                                return c.data();
                            });

                            this.chatLog = this.groupMessages(chatMessages);
                        }

                        // Check new messages
                        const chatSubscription = this.listenForNewMessages().subscribe(
                            (newChatMessage: FSCHatMessage) => {
                                if (newChatMessage) {
                                    this.addMessageToChat(newChatMessage);

                                    if (newChatMessage.sender.uid != this._currentUserUid) {
                                        if (this._preferenceService.showInGameChat) {
                                            this.incomingChatMessages.update((incomingChatMessages) => {
                                                incomingChatMessages.push(newChatMessage);
                                                return incomingChatMessages;
                                            });
                                            setTimeout(() => {
                                                this.removeIncomingChatMessage(newChatMessage);
                                            }, 5000);
                                        }
                                        this.unreadChatMessages.update((unreadChatMessages) => ++unreadChatMessages);
                                    }

                                    setTimeout(() => {
                                        this.chatScrollSubject$.next(Timestamp.now());
                                    }, 50);
                                }
                            }
                        );

                        this._rxjsSubscriptionManager.addSubscription(
                            'core chats-collection',
                            SUBSCRIPTION_KEY.CHAT,
                            chatSubscription
                        );
                    });
                }
            });
    }

    disableChat(resetChatLog: boolean) {
        this.incomingChatMessages.set([]);
        this.unreadChatMessages.set(0);
        this._rxjsSubscriptionManager.cleanSubscription(SUBSCRIPTION_KEY.CHAT);

        if (resetChatLog) {
            this.chatLog = [];
            this.chatUserIds = [];
        }
    }

    removeIncomingChatMessage(chatMessage: FSCHatMessage): void {
        if (chatMessage) {
            this.incomingChatMessages.update((incomingChatMessages) => {
                _.pull(incomingChatMessages, chatMessage);
                return incomingChatMessages;
            });
        }
    }

    getInitialMessages(): Promise<QuerySnapshot<DocumentData>> {
        const chatCollectionRef = collection(this._activeGameRef, this.collection_name);

        const orderByClause = this.getAttributeString(this.defaultClass, (obj: FSCHatMessage) => obj.sent_at);
        const fullChatQuery = query(chatCollectionRef, orderBy(orderByClause, 'asc'));

        return getDocs(fullChatQuery);
    }

    groupMessages(chatMessages: FSCHatMessage[]) {
        return chatMessages.reduce((result, message) => {
            const lastMessage: FSCHatMessage = result[result.length - 1];
            if (!lastMessage || message.sender.uid != lastMessage.sender.uid) {
                message.messages = [
                    {
                        message: this.getDisplayChatMessage(message, true),
                        unfiltered_message: this.getDisplayChatMessage(message, false),
                        emoji_only: message.emoji_only,
                        sent_at: message.sent_at,
                    },
                ];
                result.push(message);
            } else {
                lastMessage.sent_at = message.sent_at;
                lastMessage.messages.push({
                    message: this.getDisplayChatMessage(message, true),
                    unfiltered_message: this.getDisplayChatMessage(message, false),
                    emoji_only: message.emoji_only,
                    sent_at: message.sent_at,
                });
            }
            return result;
        }, []);
    }

    addMessageToChat(newChatMessage: FSCHatMessage) {
        const lastMessage = _.last(this.chatLog);
        newChatMessage.display_msg = this.getDisplayChatMessage(newChatMessage, true);

        if (!lastMessage || newChatMessage.sender.uid != lastMessage.sender.uid) {
            newChatMessage.messages = [
                {
                    message: this.getDisplayChatMessage(newChatMessage, true),
                    unfiltered_message: this.getDisplayChatMessage(newChatMessage, false),
                    emoji_only: newChatMessage.emoji_only,
                    sent_at: newChatMessage.sent_at,
                },
            ];
            this.chatLog.push(newChatMessage);
        } else {
            // Never add the same chat messages double
            if (newChatMessage.sent_at != lastMessage.sent_at) {
                lastMessage.sent_at = newChatMessage.sent_at;
                lastMessage.messages.push({
                    message: this.getDisplayChatMessage(newChatMessage, true),
                    unfiltered_message: this.getDisplayChatMessage(newChatMessage, false),
                    emoji_only: newChatMessage.emoji_only,
                    sent_at: newChatMessage.sent_at,
                });
            }
        }
    }

    listenForNewMessages(): Observable<FSCHatMessage> {
        const collectionRef = collection(this._activeGameRef, this.collection_name);
        const timestampClause = this.getAttributeString(this.defaultClass, (obj: FSCHatMessage) => obj.sent_at);

        return new Observable<FSCHatMessage>((observer) => {
            try {
                const newChatMessagesQuery = query(collectionRef, where(timestampClause, '>', this._lastTimestamp));

                const unsubscribe = onSnapshot(newChatMessagesQuery, (snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                        const data = change.doc.data() as FSCHatMessage;
                        if (change.type === 'added') {
                            observer.next(data);
                        }
                    });
                });

                // Unsubscribe when observer is unsubscribed
                return () => {
                    unsubscribe();
                };
            } catch (err) {
                observer.error(err);
            }
        });
    }

    sendMessage(chatMessage: FSCHatMessage): Promise<DocumentReference> {
        const chatCollectionRef = collection(this._activeGameRef, FIRESTORE_COLLECTION.CHATS);
        return addDoc(chatCollectionRef, chatMessage);
    }

    sendChatMessage(payload: SendChatPayload): Promise<DocumentReference> {
        const chatMessage: FSCHatMessage = {
            sender: payload.sender,
            sent_at: Timestamp.now(),

            chat_msg: payload.chat_msg,
            custom_msg: payload.custom_msg,
            unfiltered_msg: payload.unfiltered_msg,
            emoji_only: payload.emoji_only,
        };

        return this.sendMessage(chatMessage);
    }

    getDisplayChatMessage(chatMessage: FSCHatMessage, filtered: boolean): string | null {
        if (chatMessage.chat_msg) {
            const online_chat_message = _.find(IN_GAME_COMMS, (e) => e.chat_msg == chatMessage.chat_msg);

            if (!online_chat_message) {
                return null;
            }

            return online_chat_message.text;
        } else if (chatMessage.custom_msg) {
            return filtered ? chatMessage.custom_msg : chatMessage.unfiltered_msg;
        }
    }

    getDocByID(id: string): DocumentReference<FSCHatMessage> {
        return doc(this.firestore_collection, id);
    }

    removeItem(docId): void {
        deleteDoc(this.getDocByID(docId));
    }

    resetIncomingMessages(): void {
        this.incomingChatMessages.set([]);
        this.unreadChatMessages.set(0);
    }
}
