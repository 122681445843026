<div class="flex flex-col gap-y-2" [ngClass]="position === 'top_center' ? 'flex-col-reverse' : ''">
    <div class="flex h-3 w-full">
        <div
            [style.width.%]="getBarWidth(headToHeadData.first_user_wins, headToHeadData.total)"
            [ngClass]="animated ? 'bar-section' : ''">
            <div class="bg-[#44B29D] w-full h-3 mx-auto"></div>
        </div>
        <div
            [style.width.%]="getBarWidth(headToHeadData.draws, headToHeadData.total)"
            [ngClass]="animated ? 'bar-section' : ''">
            <div class="bg-[#727272] w-full h-3 mx-auto"></div>
        </div>
        <div
            [style.width.%]="getBarWidth(headToHeadData.second_user_wins, headToHeadData.total)"
            [ngClass]="animated ? 'bar-section' : ''">
            <div class="bg-[#E3403E] w-full h-3 mx-auto"></div>
        </div>
    </div>
    <div class="flex items-center" [ngClass]="versusPlayer ? 'justify-between' : ''">
        @if (versusPlayer) {
            <div class="text-xs mr-2 whitespace-nowrap overflow-hidden text-ellipsis">
                <span [translate]="'YOU'" i18n="@@YOU">You</span> vs. {{ versusPlayer }}
            </div>
        }
        <div
            class="flex items-center justify-start space-x-2 text-white text-xs uppercase font-bold leading-none h-6 animate__animated"
            [ngClass]="position === 'top_center' ? 'justify-center' : 'justify-start'">
            <div class="rounded-full p-2 flex items-center bg-black space-x-0.5 h-full">
                <div class="bg-[#44B29D] rounded-full w-2 h-2 mr-0.5"></div>
                <div>{{ headToHeadData.first_user_wins }}</div>
                <div [translate]="'WINS'" i18n="@@WINS">Wins</div>
            </div>
            <div class="rounded-full p-2 flex items-center bg-black space-x-0.5 h-full">
                <div class="bg-[#727272] rounded-full w-2 h-2 mr-0.5"></div>
                <div>{{ headToHeadData.draws }}</div>
                <div [translate]="'DRAWS'" i18n="@@DRAWS">Draws</div>
            </div>
            <div class="rounded-full p-2 flex items-center bg-black space-x-0.5 h-full">
                <div class="bg-[#E3403E] rounded-full w-2 h-2 mr-0.5"></div>
                <div>{{ headToHeadData.second_user_wins }}</div>
                <div [translate]="'LOSSES'" i18n="@@LOSSES">Losses</div>
            </div>
        </div>
    </div>
</div>
