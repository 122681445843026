<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="close()">Close</div>
        </ion-buttons>
        <div class="slide-modal-title" [translate]="'ACTIVATE_OMNI_SCORING'" i18n="@@ACTIVATE_OMNI_SCORING">
            Activate Omni Scoring
        </div>
    </div>

    <div class="flex-1 px-4 pb-safe-offset-4">
        <div
            class="subtitle text-center"
            [translate]="'FOUND_OMNI_SCORING_TO_ACTIVATE'"
            i18n="@@FOUND_OMNI_SCORING_TO_ACTIVATE">
            You have an Omni Scoring in your account, tap to activate it!
        </div>

        <app-smart-device-item
            class="block mt-2"
            [smartDevice]="omniCommunicationService.smartDevice"
            [allowedTypes]="['omni_scoring']"
            [canSelect]="true"
            [status]="getStatusClass()"
            (smartDeviceChanged)="smartDeviceChanged($event)"></app-smart-device-item>
    </div>
</div>
