export const COUNTRIES = [
    {
        label: 'Afghanistan',
        name: 'Afghanestan',
        flag: 'af.png',
        alpha2: 'AF',
    },
    {
        label: 'Andorra',
        name: 'Andorra',
        flag: 'ad.png',
        alpha2: 'AD',
    },
    {
        label: 'Angola',
        name: 'Angola',
        flag: 'ao.png',
        alpha2: 'AO',
    },
    {
        label: 'Antigua and Barbuda',
        name: 'Antigua and Barbuda',
        flag: 'ag.png',
        alpha2: 'AG',
    },
    {
        label: 'Argentina',
        name: 'Argentina',
        flag: 'ar.png',
        alpha2: 'AR',
    },
    {
        label: 'Australia',
        name: 'Australia',
        flag: 'au.png',
        alpha2: 'AU',
    },
    {
        label: 'Azerbaijan',
        name: 'Azərbaycan',
        flag: 'az.png',
        alpha2: 'AZ',
    },
    {
        label: 'Bahrain',
        name: 'Bahrain',
        flag: 'bh.png',
        alpha2: 'BH',
    },
    {
        label: 'Bangladesh',
        name: 'Bangladesh',
        flag: 'bd.png',
        alpha2: 'BD',
    },
    {
        label: 'Barbados',
        name: 'Barbados',
        flag: 'bb.png',
        alpha2: 'BB',
    },
    {
        label: 'Palau',
        name: 'Belau',
        flag: 'pw.png',
        alpha2: 'PW',
    },
    {
        label: 'Belgium',
        name: 'België',
        flag: 'be.png',
        alpha2: 'BE',
    },
    {
        label: 'Belize',
        name: 'Belize',
        flag: 'bz.png',
        alpha2: 'BZ',
    },
    {
        label: 'Benin',
        name: 'Bénin',
        flag: 'bj.png',
        alpha2: 'BJ',
    },
    {
        label: 'India',
        name: 'Bhārat',
        flag: 'in.png',
        alpha2: 'IN',
    },
    {
        label: 'Belarus',
        name: 'Bielaruś',
        flag: 'by.png',
        alpha2: 'BY',
    },
    {
        label: 'Bolivia',
        name: 'Bolivia',
        flag: 'bo.png',
        alpha2: 'BO',
    },
    {
        label: 'Bosnia and Herzegovina',
        name: 'Bosnia I Hercegovína',
        flag: 'ba.png',
        alpha2: 'BA',
    },
    {
        label: 'Botswana',
        name: 'Botswana',
        flag: 'bw.png',
        alpha2: 'BW',
    },
    {
        label: 'Brazil',
        name: 'Brasil',
        flag: 'br.png',
        alpha2: 'BR',
    },
    {
        label: 'Brunei',
        name: 'Brunei',
        flag: 'bn.png',
        alpha2: 'BN',
    },
    {
        label: 'Bulgaria',
        name: 'Bulgariya',
        flag: 'bg.png',
        alpha2: 'BG',
    },
    {
        label: 'Burkina Faso',
        name: 'Burkina Faso',
        flag: 'bf.png',
        alpha2: 'BF',
    },
    {
        label: 'Burundi',
        name: 'Burundi',
        flag: 'bi.png',
        alpha2: 'BI',
    },
    {
        label: 'Cape Verde',
        name: 'Cabo Verde',
        flag: 'cv.png',
        alpha2: 'CV',
    },
    {
        label: 'Cameroon',
        name: 'Cameroon',
        flag: 'cm.png',
        alpha2: 'CM',
    },
    {
        label: 'Canada',
        name: 'Canada',
        flag: 'ca.png',
        alpha2: 'CA',
    },
    {
        label: 'Czechia',
        name: 'Česko',
        flag: 'cz.png',
        alpha2: 'CZ',
    },
    {
        label: 'Chile',
        name: 'Chile',
        flag: 'cl.png',
        alpha2: 'CL',
    },
    {
        label: 'China',
        name: 'China',
        flag: 'cn.png',
        alpha2: 'CN',
    },
    {
        label: 'Cyprus',
        name: 'Cyprus',
        flag: 'cy.png',
        alpha2: 'CY',
    },
    {
        label: 'North Korea',
        name: 'Chosŏn',
        flag: 'kp.png',
        alpha2: 'KP',
    },
    {
        label: 'Vatican City',
        name: 'Città del Vaticano',
        flag: 'vc.png',
        alpha2: 'VC',
    },
    {
        label: 'Colombia',
        name: 'Colombia',
        flag: 'co.png',
        alpha2: 'CO',
    },
    {
        label: 'Comoros',
        name: 'Comoros',
        flag: 'km.png',
        alpha2: 'KM',
    },
    {
        label: 'Costa Rica',
        name: 'Costa Rica',
        flag: 'cr.png',
        alpha2: 'CR',
    },
    {
        label: 'Côte d‘Ivoire',
        name: 'Côte d‘Ivoire',
        flag: 'ci.png',
        alpha2: 'CI',
    },
    {
        label: 'Montenegro',
        name: 'Crna Gora',
        flag: 'me.png',
        alpha2: 'ME',
    },
    {
        label: 'Cuba',
        name: 'Cuba',
        flag: 'cu.png',
        alpha2: 'CU',
    },
    {
        label: 'Denmark',
        name: 'Danmark',
        flag: 'dk.png',
        alpha2: 'DK',
    },
    {
        label: 'Kuwait',
        name: 'Dawlat ul-Kuwayt',
        flag: 'kw.png',
        alpha2: 'KW',
    },
    {
        label: 'Germany',
        name: 'Deutschland',
        flag: 'de.png',
        alpha2: 'DE',
    },
    {
        label: 'Maldives',
        name: 'Dhivehi Raajje',
        flag: 'mv.png',
        alpha2: 'MV',
    },
    {
        label: 'Djibouti',
        name: 'Djibouti',
        flag: 'dj.png',
        alpha2: 'DJ',
    },
    {
        label: 'Bhutan',
        name: 'Druk Yul',
        flag: 'bt.png',
        alpha2: 'BT',
    },
    {
        label: 'Algeria',
        name: 'Dzayer',
        flag: 'dz.png',
        alpha2: 'DZ',
    },
    {
        label: 'Ecuador',
        name: 'Ecuador',
        flag: 'ec.png',
        alpha2: 'EC',
    },
    {
        label: 'England',
        extra: 'Great Britain Wales Scotland England United Kingdom',
        name: 'England',
        flag: 'en.png',
        alpha2: 'EN',
    },
    {
        label: 'Estonia',
        name: 'Eesti',
        flag: 'ee.png',
        alpha2: 'EE',
    },
    {
        label: 'El Salvador',
        name: 'El Salvador',
        flag: 'sv.png',
        alpha2: 'SV',
    },
    {
        label: 'Spain',
        name: 'España',
        flag: 'es.png',
        alpha2: 'ES',
    },
    {
        label: 'Fiji',
        name: 'Fiji',
        flag: 'fj.png',
        alpha2: 'FJ',
    },
    {
        label: 'France',
        name: 'France',
        flag: 'fr.png',
        alpha2: 'FR',
    },
    {
        label: 'Ghana',
        name: 'Ghana',
        flag: 'gh.png',
        alpha2: 'GH',
    },
    {
        label: 'Gambia',
        name: 'Gambia',
        flag: 'gm.png',
        alpha2: 'GM',
    },
    {
        label: 'Grenada',
        name: 'Grenada',
        flag: 'gd.png',
        alpha2: 'GD',
    },
    {
        label: 'Guatemala',
        name: 'Guatemala',
        flag: 'gt.png',
        alpha2: 'GT',
    },
    {
        label: 'Equatorial Guinea',
        name: 'Guinea Ecuatorial',
        flag: 'gq.png',
        alpha2: 'GQ',
    },
    {
        label: 'Guinea',
        name: 'Guinée',
        flag: 'gn.png',
        alpha2: 'GN',
    },
    {
        label: 'Guyana',
        name: 'Guyana',
        flag: 'gy.png',
        alpha2: 'GY',
    },
    {
        label: 'Haiti',
        name: 'Haïti',
        flag: 'ht.png',
        alpha2: 'HT',
    },
    {
        label: 'South Korea',
        name: 'Hanguk',
        flag: 'kr.png',
        alpha2: 'KR',
    },
    {
        label: 'Armenia',
        name: 'Hayastán',
        flag: 'am.png',
        alpha2: 'AM',
    },
    {
        label: 'Greece',
        name: 'Hellas',
        flag: 'gr.png',
        alpha2: 'GR',
    },
    {
        label: 'Hong Kong',
        name: 'Heung Gong',
        flag: 'hk.png',
        alpha2: 'HK',
    },
    {
        label: 'Honduras',
        name: 'Honduras',
        flag: 'hn.png',
        alpha2: 'HN',
    },
    {
        label: 'Croatia',
        name: 'Hrvatska',
        flag: 'hr.png',
        alpha2: 'HR',
    },
    {
        label: 'Indonesia',
        name: 'Indonesia',
        flag: 'id.png',
        alpha2: 'ID',
    },
    {
        label: 'Iraq',
        name: 'Iraq',
        flag: 'iq.png',
        alpha2: 'IQ',
    },
    {
        label: 'Iran',
        name: 'Īrān',
        flag: 'ir.png',
        alpha2: 'IR',
    },
    {
        label: 'Ireland',
        name: 'Ireland',
        flag: 'ie.png',
        alpha2: 'IE',
    },
    {
        label: 'Eritrea',
        name: 'Iritriya',
        flag: 'er.png',
        alpha2: 'ER',
    },
    {
        label: 'Iceland',
        name: 'Ísland',
        flag: 'is.png',
        alpha2: 'IS',
    },
    {
        label: 'Israel',
        name: 'Israʼiyl',
        flag: 'il.png',
        alpha2: 'IL',
    },
    {
        label: 'Italy',
        name: 'Italia',
        flag: 'it.png',
        alpha2: 'IT',
    },
    {
        label: 'Ethiopia',
        name: 'Ityopia',
        flag: 'et.png',
        alpha2: 'ET',
    },
    {
        label: 'Jamaica',
        name: 'Jamaica',
        flag: 'jm.png',
        alpha2: 'JM',
    },
    {
        label: 'Jordan',
        name: 'Jordan',
        flag: 'jo.png',
        alpha2: 'JO',
    },
    {
        label: 'Cambodia',
        name: 'Kampuchea',
        flag: 'kh.png',
        alpha2: 'KH',
    },
    {
        label: 'Kazakhstan',
        name: 'Kazakhstán',
        flag: 'kz.png',
        alpha2: 'KZ',
    },
    {
        label: 'Kenya',
        name: 'Kenya',
        flag: 'ke.png',
        alpha2: 'KE',
    },
    {
        label: 'Kosovo',
        name: 'Kosova',
        flag: 'ks.png',
        alpha2: 'KS',
    },
    {
        label: 'Kyrgyzstan',
        name: 'Kyrgyzstan',
        flag: 'kg.png',
        alpha2: 'KG',
    },
    {
        label: 'Laos',
        name: 'Lao',
        flag: 'la.png',
        alpha2: 'LA',
    },
    {
        label: 'Latvia',
        name: 'Latvija',
        flag: 'lv.png',
        alpha2: 'LV',
    },
    {
        label: 'Lesotho',
        name: 'Lesotho',
        flag: 'ls.png',
        alpha2: 'LS',
    },
    {
        label: 'Libya',
        name: 'Lībiyā',
        flag: 'ly.png',
        alpha2: 'LY',
    },
    {
        label: 'Liechtenstein',
        name: 'Liechtenstein',
        flag: 'li.png',
        alpha2: 'LI',
    },
    {
        label: 'Lithuania',
        name: 'Lietuva',
        flag: 'lt.png',
        alpha2: 'LT',
    },
    {
        label: 'Lebanon',
        name: 'Lubnān',
        flag: 'lb.png',
        alpha2: 'LB',
    },
    {
        label: 'Luxemburg',
        name: 'Luxemburg',
        flag: 'lu.png',
        alpha2: 'LU',
    },
    {
        label: 'Madagascar',
        name: 'Madagasikara',
        flag: 'mg.png',
        alpha2: 'MG',
    },
    {
        label: 'Hungary',
        name: 'Magyarország',
        flag: 'hu.png',
        alpha2: 'HU',
    },
    {
        label: 'Malaysia',
        name: 'Malaysia',
        flag: 'my.png',
        alpha2: 'MY',
    },
    {
        label: 'Malawi',
        name: 'Malawi',
        flag: 'mw.png',
        alpha2: 'MW',
    },
    {
        label: 'Mali',
        name: 'Mali',
        flag: 'ml.png',
        alpha2: 'ML',
    },
    {
        label: 'Malta',
        name: 'Malta',
        flag: 'mt.png',
        alpha2: 'MT',
    },
    {
        label: 'Marshall Islands',
        name: 'Marshall Islands',
        flag: 'mh.png',
        alpha2: 'MH',
    },
    {
        label: 'Mauritius',
        name: 'Mauritius',
        flag: 'mu.png',
        alpha2: 'MU',
    },
    {
        label: 'Mexico',
        name: 'México',
        flag: 'mx.png',
        alpha2: 'MX',
    },
    {
        label: 'Micronesia',
        name: 'Micronesia',
        flag: 'fm.png',
        alpha2: 'FM',
    },
    {
        label: 'Egypt',
        name: 'Misr',
        flag: 'eg.png',
        alpha2: 'EG',
    },
    {
        label: 'Mozambique',
        name: 'Moçambique',
        flag: 'mz.png',
        alpha2: 'MZ',
    },
    {
        label: 'Moldova',
        name: 'Moldova',
        flag: 'md.png',
        alpha2: 'MD',
    },
    {
        label: 'Monaco',
        name: 'Monaca',
        flag: 'mc.png',
        alpha2: 'MC',
    },
    {
        label: 'Morocco',
        name: 'Morocco',
        flag: 'ma.png',
        alpha2: 'MA',
    },
    {
        label: 'Thailand',
        name: 'Mueang Thai',
        flag: 'th.png',
        alpha2: 'TH',
    },
    {
        label: 'Mauritania',
        name: 'Muritan',
        flag: 'mr.png',
        alpha2: 'MR',
    },
    {
        label: 'Mongolia',
        name: 'Mongolia',
        flag: 'mn.png',
        alpha2: 'MN',
    },
    {
        label: 'Myanmar',
        name: 'Myanma',
        flag: 'mm.png',
        alpha2: 'MM',
    },
    {
        label: 'Namibia',
        name: 'Namibia',
        flag: 'na.png',
        alpha2: 'na',
    },
    {
        label: 'Nauru',
        name: 'Nauru',
        flag: 'nr.png',
        alpha2: 'NR',
    },
    {
        label: 'Netherlands',
        name: 'Nederland',
        flag: 'nl.png',
        alpha2: 'NL',
    },
    {
        label: 'Nepal',
        name: 'Nepāl',
        flag: 'np.png',
        alpha2: 'NP',
    },
    {
        label: 'New Zealand',
        name: 'New Zealand',
        flag: 'nz.png',
        alpha2: 'NZ',
    },
    {
        label: 'Nicaragua',
        name: 'Nicaragua',
        flag: 'ni.png',
        alpha2: 'NI',
    },
    {
        label: 'Niger',
        name: 'Niger',
        flag: 'ne.png',
        alpha2: 'NE',
    },
    {
        label: 'Northern Ireland United Kingdom',
        name: 'Northern Ireland',
        flag: 'nh.png',
        alpha2: 'NH',
    },
    {
        label: 'Japan',
        name: 'Japan',
        flag: 'jp.png',
        alpha2: 'JP',
    },
    {
        label: 'Norway',
        name: 'Norge',
        flag: 'no.png',
        alpha2: 'NO',
    },
    {
        label: 'Uzbekistan',
        name: 'O‘zbekiston',
        flag: 'uz.png',
        alpha2: 'UZ',
    },
    {
        label: 'Austria',
        name: 'Österreich',
        flag: 'at.png',
        alpha2: 'AT',
    },
    {
        label: 'Pakistan',
        name: 'Pākistān',
        flag: 'pk.png',
        alpha2: 'PK',
    },
    {
        label: 'Panama',
        name: 'Panamá',
        flag: 'pa.png',
        alpha2: 'PA',
    },
    {
        label: 'Papua New Guinea',
        name: 'Papua New Guinea',
        flag: 'pg.png',
        alpha2: 'PG',
    },
    {
        label: 'Paraguay',
        name: 'Paraguái',
        flag: 'py.png',
        alpha2: 'PY',
    },
    {
        label: 'Philippines',
        name: 'Pilipinas',
        flag: 'ph.png',
        alpha2: 'PH',
    },
    {
        label: 'Peru',
        name: 'Perú',
        flag: 'pe.png',
        alpha2: 'PE',
    },
    {
        label: 'Poland',
        name: 'Polska',
        flag: 'pl.png',
        alpha2: 'PL',
    },
    {
        label: 'Portugal',
        name: 'Portugal',
        flag: 'pt.png',
        alpha2: 'PT',
    },
    {
        label: 'Qaṭar',
        name: 'Qaṭar',
        flag: 'qa.png',
        alpha2: 'QA',
    },
    {
        label: 'Dominica Republic',
        name: 'República Dominicana',
        flag: 'dm.png',
        alpha2: 'DM',
    },
    {
        label: 'Central African Republic',
        name: 'République Centrafricaine',
        flag: 'cf.png',
        alpha2: 'CF',
    },
    {
        label: 'Republic of Congo',
        name: 'République du Congo',
        flag: 'cd.png',
        alpha2: 'CD',
    },
    {
        label: 'Gabon',
        name: 'République gabonaise',
        flag: 'ga.png',
        alpha2: 'GA',
    },
    {
        label: 'Romania',
        name: 'România',
        flag: 'ro.png',
        alpha2: 'RO',
    },
    {
        label: 'Russia',
        name: 'Rossiâ',
        flag: 'ru.png',
        alpha2: 'RU',
    },
    {
        label: 'Rwanda',
        name: 'Rwanda',
        flag: 'rw.png',
        alpha2: 'RW',
    },
    {
        label: 'Saint Kitts and Nevis',
        name: 'Saint Kitts and Nevis',
        flag: 'kn.png',
        alpha2: 'KN',
    },
    {
        label: 'Saint Lucia',
        name: 'Saint Lucia',
        flag: 'lc.png',
        alpha2: 'LC',
    },
    {
        label: 'Saint Vincent and the Grenadines',
        name: 'Saint Vincent and the Grenadines',
        flag: 'vc.png',
        alpha2: 'VC',
    },
    {
        label: 'Georgia',
        name: "Sak'art'velo",
        flag: 'ge.png',
        alpha2: 'GE',
    },
    {
        label: 'Samoa',
        name: 'Sāmoa',
        flag: 'ws.png',
        alpha2: 'WS',
    },
    {
        label: 'San Marino',
        name: 'San Marino',
        flag: 'sm.png',
        alpha2: 'SM',
    },
    {
        label: 'São Tomé and Principe',
        name: 'São Tomé e Principe',
        flag: 'st.png',
        alpha2: 'ST',
    },
    {
        label: 'Scotland',
        extra: 'Great Britain Wales Scotland England United Kingdom',
        name: 'Scotland',
        flag: 'sco.png',
        alpha2: 'SCO',
    },
    {
        label: 'Senegal',
        name: 'Sénégal',
        flag: 'sn.png',
        alpha2: 'SN',
    },
    {
        label: 'North Macedonia',
        name: 'Severna Makedonija',
        flag: 'mk.png',
        alpha2: 'MK',
    },
    {
        label: 'Seychelles',
        name: 'Seychelles',
        flag: 'sc.png',
        alpha2: 'SC',
    },
    {
        label: 'Albania',
        name: 'Shqipëria',
        flag: 'al.png',
        alpha2: 'AL',
    },
    {
        label: 'Saudi Arabia',
        name: 'Saudi Arabia',
        flag: 'sa.png',
        alpha2: 'SA',
    },
    {
        label: 'Sierra Leone',
        name: 'Sierra Leone',
        flag: 'sl.png',
        alpha2: 'SL',
    },
    {
        label: 'Singapore',
        name: 'Singapore',
        flag: 'sg.png',
        alpha2: 'SG',
    },
    {
        label: 'Slovenia',
        name: 'Slovenija',
        flag: 'si.png',
        alpha2: 'SI',
    },
    {
        label: 'Slovakia',
        name: 'Slovensko',
        flag: 'sk.png',
        alpha2: 'SK',
    },
    {
        label: 'Solomon Islands',
        name: 'Solomon Islands',
        flag: 'sb.png',
        alpha2: 'SB',
    },
    {
        label: 'Somalia',
        name: 'Soomaaliya',
        flag: 'so.png',
        alpha2: 'SO',
    },
    {
        label: 'South Africa',
        name: 'South Africa',
        flag: 'za.png',
        alpha2: 'ZA',
    },
    {
        label: 'Serbia',
        name: 'Srbija',
        flag: 'rs.png',
        alpha2: 'RS',
    },
    {
        label: 'Sri Lanka',
        name: 'Sri Lankā',
        flag: 'lk.png',
        alpha2: 'LK',
    },
    {
        label: 'Switzerland',
        name: 'Suisse',
        flag: 'ch.png',
        alpha2: 'CH',
    },
    {
        label: 'Sudan',
        name: 'Sudan',
        flag: 'sd.png',
        alpha2: 'SD',
    },
    {
        label: 'Finland',
        name: 'Suomi - Finland',
        flag: 'fi.png',
        alpha2: 'FI',
    },
    {
        label: 'Surinam',
        name: 'Suriname',
        flag: 'sr.png',
        alpha2: 'SR',
    },
    {
        label: 'Syria',
        name: 'Suriyah',
        flag: 'sy.png',
        alpha2: 'SY',
    },
    {
        label: 'Sweden',
        name: 'Sverige',
        flag: 'se.png',
        alpha2: 'SE',
    },
    {
        label: 'Swaziland',
        name: 'Swaziland',
        flag: 'sz.png',
        alpha2: 'SZ',
    },
    {
        label: 'Tanzania',
        name: 'Tanzania',
        flag: 'tz.png',
        alpha2: 'TZ',
    },
    {
        label: 'Chad',
        name: 'Tchad',
        flag: 'td.png',
        alpha2: 'TD',
    },
    {
        label: 'The Bahamas',
        name: 'The Bahamas',
        flag: 'bs.png',
        alpha2: 'BS',
    },
    {
        label: 'East Timor',
        name: 'Timor-Lester',
        flag: 'tl.png',
        alpha2: 'TL',
    },
    {
        label: 'Togo',
        name: 'Togo',
        flag: 'tg.png',
        alpha2: 'TG',
    },
    {
        label: 'Tajikistan',
        name: 'Tojikistan',
        flag: 'tj.png',
        alpha2: 'TJ',
    },
    {
        label: 'Tonga',
        name: 'Tonga',
        flag: 'to.png',
        alpha2: 'TO',
    },
    {
        label: 'Trinidad and Tobago',
        name: 'Trinidad and Tobago',
        flag: 'tt.png',
        alpha2: 'TT',
    },
    {
        label: 'Tunisia',
        name: 'Tunes',
        flag: 'tn.png',
        alpha2: 'TN',
    },
    {
        label: 'Turkey',
        name: 'Türkiye',
        flag: 'tk.png',
        alpha2: 'TK',
    },
    {
        label: 'Turkmenistan',
        name: 'Turkmenistan',
        flag: 'tm.png',
        alpha2: 'TM',
    },
    {
        label: 'Tuvalu',
        name: 'Tuvalu',
        flag: 'tv.png',
        alpha2: 'TV',
    },
    {
        label: 'Uganda',
        name: 'Uganda',
        flag: 'ug.png',
        alpha2: 'UG',
    },
    {
        label: 'United Arab Emirates',
        name: 'United Arab Emirates',
        flag: 'ae.png',
        alpha2: 'AE',
    },
    {
        label: 'Ukraine',
        name: 'Ukraїna',
        flag: 'ua.png',
        alpha2: 'UA',
    },
    {
        label: 'Oman',
        name: 'Umān',
        flag: 'om.png',
        alpha2: 'OM',
    },
    {
        label: 'Great Britain',
        extra: 'Great Britain Wales Scotland England United Kingdom',
        name: 'United Kingdom',
        flag: 'gb.png',
        alpha2: 'GB',
    },
    {
        label: 'United States',
        name: 'United States',
        flag: 'us.png',
        alpha2: 'US',
    },
    {
        label: 'Uruguay',
        name: 'Uruguay',
        flag: 'uy.png',
        alpha2: 'UY',
    },
    {
        label: 'Vanuatu',
        name: 'Vanuatu',
        flag: 'vu.png',
        alpha2: 'VU',
    },
    {
        label: 'Venezuela',
        name: 'Venezuela',
        flag: 've.png',
        alpha2: 'VE',
    },
    {
        label: 'Vietnam',
        name: 'Việt Nam',
        flag: 'vn.png',
        alpha2: 'VN',
    },
    {
        label: 'Wales',
        extra: 'Great Britain Wales Scotland England United Kingdom',
        name: 'Wales',
        flag: 'wl.png',
        alpha2: 'WL',
    },
    {
        label: 'Yemen',
        name: 'Yemen',
        flag: 'ye.png',
        alpha2: 'YE',
    },
    {
        label: 'Zambia',
        name: 'Zambia',
        flag: 'zm.png',
        alpha2: 'ZM',
    },
    {
        label: 'Zimbabwe',
        name: 'Zimbabwe',
        flag: 'zw.png',
        alpha2: 'ZW',
    },
];
