/* eslint-disable max-len */
import { CommonModule } from '@angular/common';
import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OmniThrowsComponent } from '@components/omni/omni-throws.component';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import {
    ConnectionStatus,
    OmniCommunicationService,
    OmniWebscocketCommand,
} from '@dc-core/dc-services/omni/omni-communication.service';
import { CartesianCoordinate, OmniIngameService } from '@dc-core/dc-services/omni/omni-ingame.service';
import { ModalController } from '@ionic/angular';
import { ScrollToBottomDirective } from 'src/directives/scroll-to-bottom.directive';
import { environment } from 'src/environments/environment';

import { Device_Images } from '../app.globals';
import { OmniConnectionLostDialogComponent } from '../modules/smart-devices/dialogs/omni-connection-lost.dialog/omni-connection-lost.dialog';
import { SmartDeviceItemComponent, SmartDeviceStatusColor } from '../smart-device-item/smart-device-item.component';

export interface OmniKeyboardCommand {
    command: OmniWebscocketCommand;
    label: string;
}

export type OmniSection = 'board' | 'debug';

// THE SUPPORTED OMNI SECTIONS IN DARTCOUNTER
export const BOARDSECTION: { label: string; tag: string; type: OmniSection } = {
    label: $localize`:@@OMNI_BOARD:Omni board`,
    tag: 'OMNI_BOARD',
    type: 'board',
};

export const DEBUGSECTION: { label: string; tag: string; type: OmniSection } = {
    label: $localize`:@@DEBUG:debug`,
    tag: 'DEBUG',
    type: 'debug',
};

@Component({
    selector: 'app-omni-keyboard',
    standalone: true,
    templateUrl: 'omni-keyboard.component.html',
    styleUrls: ['./omni-keyboard.component.scss'],
    imports: [OmniThrowsComponent, SmartDeviceItemComponent, CommonModule],
})
export class OmniKeyboardComponent implements OnInit {
    @Input() undoScore = false;
    @Output() undoSingleDart = new EventEmitter();

    @ViewChild(ScrollToBottomDirective, { static: false }) scrollToBottomDirective;

    public deviceImages = Device_Images;
    public dartHits: CartesianCoordinate[] = [null, null, null];

    currentStatus: ConnectionStatus = ConnectionStatus.NONE;
    connectionFailureCheck = false;

    public omni: OmniCommunicationService = inject(OmniCommunicationService);
    public omniIngameService: OmniIngameService = inject(OmniIngameService);

    private modalController: ModalController = inject(ModalController);
    private alertService: DartCounterAlertService = inject(DartCounterAlertService);

    private destroyRef: DestroyRef = inject(DestroyRef);

    ngOnInit(): void {
        this.omni.startGameAfterConnection = false;

        this.omni.connectionStatus$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((status) => {
            this.currentStatus = status;

            if (status === ConnectionStatus.DISCONNECTED && !this.connectionFailureCheck) {
                this.connectionFailureCheck = true;

                this.modalController
                    .create({
                        component: OmniConnectionLostDialogComponent,
                        cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                        showBackdrop: true,
                        backdropDismiss: true,
                    })
                    .then((elem) => {
                        elem.present();
                    });
            }
        });
    }

    getStatusText(): string {
        switch (this.currentStatus) {
            case ConnectionStatus.CONNECTED:
                return 'Connected';
            case ConnectionStatus.CONNECTING:
                return 'Connecting';
            case ConnectionStatus.DISCONNECTED:
                return 'Not Connected';
            default:
                return '';
        }
    }

    getStatusClass(): SmartDeviceStatusColor {
        switch (this.currentStatus) {
            case ConnectionStatus.CONNECTED:
                return 'green';
            case ConnectionStatus.CONNECTING:
                return 'yellow';
            case ConnectionStatus.DISCONNECTED:
                return 'red';
            default:
                return 'red';
        }
    }

    smartDeviceChanged(event) {
        this.omni.loadSmartDevice(event);
        this.omni.connect(true);

        this.alertService.createAlert({
            id: 'CONNECTING_TO_OMNI',
            title: 'Activating OMNI Auto Scoring',
            timer: null,
            icon: 'loading',
        });
    }

    sendCommand(cmd: OmniKeyboardCommand) {
        // Send the command (BoardEmpty, Calib or PlayerChange)
        this.omni.sendMessage(cmd.command);

        // Remove the 'bust' or 'remove darts' info
        this.omniIngameService.currentSingleDartEvent = null;
    }

    emitUndoScore(): void {
        if (this.undoScore) {
            this.undoSingleDart.emit();
        }
    }
}
