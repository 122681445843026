<div
    class="flex flex-col items-center !bg-black py-4 px-safe-offset-4 !text-white overflow-hidden relative"
    id="tournament-dashboard-status">
    <div
        class="flex items-center w-full min-h-[2.5rem] cursor-pointer"
        (click)="status === 'check_in_period' && tournament().participation?.joined_at ? null : toggleCollapse()">
        <app-icon icon="tournaments" size="large" class="!text-white ml-2"></app-icon>
        <div class="flex justify-between items-center w-full ml-4 gap-2">
            <div class="text-[1.375rem] font-bebas-neue" [class.w-full]="!subtext">
                {{ title }}
            </div>

            @if (status === 'check_in_period' && tournament().participation?.joined_at) {
                <app-primary-button
                    class="block flex-none"
                    size="extrasmall"
                    color="yellow"
                    [addedSlotClasses]="'!text-white'"
                    [translate]="'CHECK_IN'"
                    i18n="@@CHECK_IN"
                    (click)="checkIn()">
                    Check-in
                </app-primary-button>
            } @else {
                <app-icon
                    [icon]="collapsed() ? 'keyboard_arrow_down' : 'keyboard_arrow_up'"
                    class="!text-neutral-200 ml-4"></app-icon>
            }
        </div>
    </div>

    @if (!collapsed()) {
        <div class="mt-2 flex w-full items-end px-2 space-x-4" [class]="subtext ? 'justify-between' : 'justify-end'">
            @if (subtext) {
                <div class="flex-1 text-sm font-medium mt-4">
                    {{ subtext }}
                </div>
            }

            @if (tournament().participation?.joined_at) {
                @if (status === 'playing') {
                    @if (
                        tournament().participation?.current_game &&
                        tournament().participation.current_game.active_game_doc_id &&
                        !tournament().participation?.current_game.finished_at
                    ) {
                        @if (tournamentService.currentGameInvite()) {
                            <app-primary-button
                                size="extrasmall"
                                (click)="tournamentService.checkRequirementsAndGoToPreGame(null)"
                                [translate]="'GO_TO_PRE_GAME'"
                                i18n="@@GO_TO_PRE_GAME">
                                Go to pre-game
                            </app-primary-button>
                        } @else {
                            <app-primary-button
                                size="extrasmall"
                                (click)="goToGame()"
                                [translate]="'GO_TO_GAME'"
                                i18n="@@GO_TO_GAME">
                                Go to game
                            </app-primary-button>
                        }
                    } @else if (buttonText) {
                        <app-primary-button
                            class="block flex-none"
                            size="extrasmall"
                            color="orange"
                            (click)="goToTournament('bracket')">
                            {{ buttonText }}
                        </app-primary-button>
                    }
                } @else if (timeUntil) {
                    <app-tournament-timer
                        [timerDate]="timeUntil"
                        [hideTimerWhenExpired]="false"
                        (timerExpired)="nextStatus()"
                        (click)="goToTournament()"></app-tournament-timer>
                } @else if (buttonText) {
                    <app-primary-button
                        class="block flex-none"
                        size="extrasmall"
                        color="orange"
                        (click)="goToTournament()">
                        {{ buttonText }}
                    </app-primary-button>
                }
            }
        </div>
    }
</div>
