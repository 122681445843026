import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GameApiService } from '@dc-api/game.api.service';
import { StatisticsApiService } from '@dc-api/statistics.api.service';
import { ModalController, Platform } from '@ionic/angular';
import { Match, MatchUser } from 'dc-core/dc-backend/dc-classes';
import { SoundPath } from 'dc-core/dc-backend/dc-enums';
import { PlayersHeadToHeadResponse } from 'dc-core/dc-backend/dc-responses';
import { DartCounterAudioService } from 'dc-core/dc-services/audio/audio.service';
import {
    _HeadToHeadAudioFiles,
    H2HAnimationConfig,
    H2HAudioConfig,
    H2HSection,
} from 'dc-core/dc-services/head-to-head/head-to-head.models';
import { DartCounterPreferenceService } from 'dc-core/dc-services/preference/preference.service';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-headtohead-dialog',
    templateUrl: 'headtohead1.dialog.html',
    styleUrls: ['headtohead1.dialog.scss'],
})
export class HeadToHeadDialogOneComponent implements OnInit {
    @ViewChild('focus') focusElement: ElementRef;
    users: any[];

    public isOnline = false;
    public favorite: number;
    public profilePicAnimation = null;
    public playerAnimation = null;

    public lastFiveFound = false;
    public effect2 = false;
    public slideIn = [];
    public effect3 = false;
    public fadeout = false;
    public fadeout2 = false;
    public showSkipButton = false;
    public section: H2HSection = H2HSection.GENERAL_STATS;

    public headToHeadInfo: PlayersHeadToHeadResponse;

    constructor(
        private view: ModalController,
        public preferenceService: DartCounterPreferenceService,
        private http: HttpClient,
        private _changeDetectorRef: ChangeDetectorRef,
        private _platform: Platform,
        private _audioService: DartCounterAudioService,
        public auth: AuthService,
        private _statisticsApiService: StatisticsApiService,
        private _gameApiService: GameApiService
    ) {}

    ngOnInit(): void {
        if (this.isOnline) {
            this.users.forEach((user: any) => {
                user.id = user.user_id;
                user.profile = {
                    nickname: user.nickname,
                    sound: user.sound,
                    profile_photo_url: user.profile_photo_url,
                };
            });
        }

        //Get the API info + players
        this.getPlayerStatistics();
        this.getHeadToHeadInfo();

        this.playGameModeAudio();

        this.favorite = 1;

        setTimeout(() => {
            this.effect2 = true;
            setTimeout(() => {
                this.showAnimations(0, 3);
            }, 1500);
        }, 3000);
    }

    playGameModeAudio() {
        const audioConfig = this.isOnline
            ? this.getRandomAudioConfig(_HeadToHeadAudioFiles.Online)
            : this.getRandomAudioConfig(_HeadToHeadAudioFiles.Friendly);

        this._audioService
            .playAudioFromDevice(SoundPath.JOHN, audioConfig.file, true, audioConfig.duration)
            .then(() => {
                this.setupSecondScreen();
            })
            .catch((error) => {
                console.error('Error playing audio:', error);
                // Optionally move to the next screen even if audio fails to play
                this.setupSecondScreen();
            });

        this.audioAnimation(audioConfig.animation);
    }

    getRandomAudioConfig(audioArray: H2HAudioConfig[]): any {
        return audioArray[Math.floor(Math.random() * audioArray.length)];
    }

    goToSecondScreen(section: H2HSection) {
        this.fadeout = true;

        setTimeout(() => {
            this.section = section;
            if (this.section == H2HSection.HEADTOHEAD) {
                this.showScreen(5);
            } else if (this.section == H2HSection.LASTFIVE) {
                this.showScreen(6);
            } else {
                this.endH2H();
            }
        }, 500);
    }

    showScreen(animationCount: number) {
        this.fadeout = false;
        this.slideIn = [];
        this.showAnimations(0, animationCount);
        this._changeDetectorRef.detectChanges();
    }

    showAnimations(index, stop, delay = 500) {
        this.slideIn[index] = true;
        this._changeDetectorRef.detectChanges();

        if (index < stop) {
            setTimeout(() => {
                index++;
                this.showAnimations(index, stop, delay);
            }, delay);
        } else {
            setTimeout(() => {
                this.showSkipButton = true;
            }, delay * 3);
        }
    }

    setupSecondScreen(): void {
        if (this.headToHeadInfo) {
            if (this.headToHeadInfo.total === 0) {
                this.lastFiveFound = false;
                let goodForm = 0;
                this.users.forEach((user, index) => {
                    if (user.last_matches && user.last_matches.length) {
                        this.lastFiveFound = true;

                        let matchesWon = 0;
                        user.last_matches.forEach((match: Match, lastMatchIndex, object) => {
                            match.users.forEach((matchUser: MatchUser) => {
                                if (matchUser != null) {
                                    if (matchUser.user_id === user.id && user.result === 'won') {
                                        matchesWon++;
                                    }
                                } else {
                                    object.splice(lastMatchIndex, 1);
                                }
                            });
                        });

                        if (matchesWon === user.last_matches.length) {
                            this.users[index].good_form = true;
                            goodForm++;
                        }
                    }
                });

                if (this.lastFiveFound) {
                    let audioConfig: H2HAudioConfig = null;
                    if (goodForm === 0) {
                        audioConfig = this.getRandomAudioConfig(_HeadToHeadAudioFiles.LastMatchesBothMeh);
                    } else if (goodForm === 1) {
                        audioConfig = this.getRandomAudioConfig(_HeadToHeadAudioFiles.LastMatchesFav);
                    } else if (goodForm === 2) {
                        audioConfig = this.getRandomAudioConfig(_HeadToHeadAudioFiles.LastMatchesBothGood);
                    }

                    //With Favorite animation
                    if (audioConfig.animation) {
                        this.audioAnimation(audioConfig.animation);
                    }

                    this._audioService
                        .playAudioFromDevice(SoundPath.JOHN, audioConfig.file, true, audioConfig.duration)
                        .then(() => {
                            this.endH2H();
                        })
                        .catch(() => {
                            this.endH2H();
                        });

                    //Show the last 5 matches
                    this.goToSecondScreen(H2HSection.LASTFIVE);
                } else {
                    this.endH2H();
                }
            }
            //There's been a match in the past
            else {
                let clearWin = false;

                const lastMatch = this.headToHeadInfo.last_match;
                const firstUser = lastMatch.users[0];
                const secondUser = lastMatch.users[1];

                const p1Score = lastMatch.has_sets ? firstUser.total_score_sets : firstUser.total_score_legs;
                const p2Score = lastMatch.has_sets ? secondUser.total_score_sets : secondUser.total_score_legs;
                if (p1Score - p2Score >= 2 || p2Score - p1Score >= 2) {
                    clearWin = true;
                }

                let audioConfig: H2HAudioConfig = null;
                if (clearWin) {
                    audioConfig = this.getRandomAudioConfig(_HeadToHeadAudioFiles.LastOneClear);
                } else {
                    audioConfig = this.getRandomAudioConfig(_HeadToHeadAudioFiles.LastOneThrilling);
                }

                //WHEN DONE PLAYING add this.endH2H
                this._audioService
                    .playAudioFromDevice(SoundPath.JOHN, audioConfig.file, true, audioConfig.duration)
                    .then(() => {
                        this.endH2H();
                    })
                    .catch(() => {
                        this.endH2H();
                    });

                //Show the last Head to Head match
                this.goToSecondScreen(H2HSection.HEADTOHEAD);
            }
        } else {
            this.endH2H();
        }
    }

    audioAnimation(animation: H2HAnimationConfig) {
        if (!animation.timeout) {
            animation.timeout = 1;
        }

        return setTimeout(() => {
            if (animation.target === 'profilepic') {
                this.profilePicAnimation = 'animate__animated animate__' + animation.type;
            } else if (animation.target === 'players') {
                this.playerAnimation = 'animate__animated animate__' + animation.type;
            }
            this._changeDetectorRef.detectChanges();
        }, animation.timeout);
    }

    endH2H() {
        setTimeout(() => {
            this.close(true);
        }, 200);
    }

    public skip() {
        this.endH2H();
    }

    getPlayerStatistics() {
        this.users.forEach((user) => {
            this._statisticsApiService.getProfileStatistics({ user_id: user.id }).then((res) => {
                user.statistics = res.data;
            });
        });
    }

    getHeadToHeadInfo() {
        this._gameApiService
            .getPlayersHeadToHead({
                user1: this.users[0].id,
                user2: this.users[1].id,
            })
            .then((res) => {
                this.headToHeadInfo = res.data;

                this.users[0].last_matches = res.data.first_user_last_matches;
                this.users[1].last_matches = res.data.second_user_last_matches;
            });
    }

    public close(returnValue: any = null): void {
        this.view.dismiss(returnValue);
    }

    ionViewWillLeave() {
        this._audioService.stopAudio(true);
    }
}
