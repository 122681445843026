/* eslint-disable max-len */
import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthApiService } from '@dc-api/auth.api.service';
import { FriendApiService } from '@dc-api/friend.api.service';
import { GameApiService } from '@dc-api/game.api.service';
import { PrivateChatApiService } from '@dc-api/private-chat.api.service';
import { ScoresApiService } from '@dc-api/scores.api.service';
import { StatisticsApiService } from '@dc-api/statistics.api.service';
import { UserApiService } from '@dc-api/user.api.service';
import { ReportOrigin } from '@dc-core/dc-backend/dc-enums';
import {
    ActionSheetController,
    IonContent,
    MenuController,
    ModalController,
    NavController,
    Platform,
    PopoverController,
} from '@ionic/angular';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';
import { TranslateService } from '@ngx-translate/core';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { PopoverService } from '@services/popover.service';
import { ProfilePhotoService } from '@services/profile-photo.service';
import { ThemeService } from '@services/theme.service';
import {
    PlayersHeadToHeadResponse,
    ProfileStatisticsResponse,
    TokenUserResponse,
} from 'dc-core/dc-backend/dc-responses';
import { AlertPayload, DartCounterAlertService } from 'dc-core/dc-services/alert.service';
import { components } from 'dc-core/dc-typings/dc-backend-definitions';
import _ from 'lodash';
import * as moment from 'moment';
import OneSignal from 'onesignal-cordova-plugin';
import { take } from 'rxjs';
import { UserService } from 'src/app/modules/user/services/user.service';
import { PromptDialogComponent } from 'src/dialogs/prompt/prompt.dialog';
import { ReportUserDialogComponent } from 'src/dialogs/report-user/report-user.dialog';
import { environment } from 'src/environments/environment';

import { COUNTRIES } from '../../../dc-core/dc-firestore/globals/countries.globals';
import { GA_EVENTACTIONS, GA_EVENTCATEGORIES } from '../../app/app.globals';
import { ProfileAmountsDialogComponent } from '../../dialogs/profile-amounts/profile-amounts.dialog';
import { ProfileStatsDialogComponent } from '../../dialogs/profile-stats/profile-stats.dialog';
import { DCLoadingService } from '../../providers/DCLoadingService';
import { UpgradeService } from '../../providers/UpgradeService';
import { AuthService } from '../../services/auth.service';
import { ProfilePopoverComponent } from './profilePopover.component';

@Component({
    selector: 'app-profile-view',
    templateUrl: 'profile.html',
})
export class ProfileViewComponent implements OnInit {
    @ViewChild(IonContent) ionContent: IonContent;

    public translateService: TranslateService = inject(TranslateService);
    public themeService: ThemeService = inject(ThemeService);

    private popoverService: PopoverService = inject(PopoverService);
    private privateChatApiService: PrivateChatApiService = inject(PrivateChatApiService);

    public loaded = false;
    public loadedStats = false;
    public loadedHeadToHead = false;
    public user: components['schemas']['User'] = null;
    public statistics: ProfileStatisticsResponse = null;
    public incomingFriends: Array<any>;
    public scores: components['responses']['ScoresResponse']['content']['application/json'] = null;
    public maximums = 0; //amount of 180s
    public base64img = '';
    public searchString = '';
    public rating: any;
    blockLoading: boolean;

    public headToHeadInfo: PlayersHeadToHeadResponse;
    public headToHeadWidths: number[] = null;

    public skeletonThemes = {
        info: {
            'border-radius': '5px',
            height: '25px',
            width: '33%',
            'background-color': 'rgb(76 76 76)',
        },
        info2: {
            'border-radius': '5px',
            height: '22px',
            width: '50%',
            'background-color': 'rgb(76 76 76)',
        },
        stats: {
            'border-radius': '5px',
            height: '36px',
            width: '50%',
            'background-color': 'rgb(76 76 76)',
            'margin-bottom': '0',
        },
        profilepic: {
            'border-radius': '50%',
            height: '112px',
            width: '112px',
            margin: '0 auto',
            'background-color': 'rgb(76 76 76)',
        },
    };

    showBottomNav = false;

    constructor(
        public nav: NavController,
        public auth: AuthService,
        public loading: DCLoadingService,
        private popoverController: PopoverController,
        private ga: DartCounterAnalyticsService,
        private upgrade: UpgradeService,
        private _menu: MenuController,
        public sheet: ActionSheetController,
        public modal: ModalController,
        public route: ActivatedRoute,
        public userApiService: UserApiService,
        public userService: UserService,
        public friendApiService: FriendApiService,
        private _alertService: DartCounterAlertService,
        private translate: TranslateService,
        private scoresApiService: ScoresApiService,
        private _statisticsApiService: StatisticsApiService,
        private _authApiService: AuthApiService,
        private _gameApiService: GameApiService,
        private _profilePhotoService: ProfilePhotoService,
        private platform: Platform
    ) {}

    ngOnInit() {
        this.route.params.pipe(take(1)).subscribe(async (params) => {
            if (params.id && params.id != this.auth.user.id) {
                await this.userApiService
                    .getUserById({ userId: params.id })
                    .then(async (res) => {
                        this.user = res.data;

                        this.loaded = true;

                        await this.getStatistics();

                        this.headToHeadWidths = [33.3, 33.3, 33.3];
                        this.getHeadToHeadInfo();
                    })
                    .catch((e) => {
                        if (e instanceof this.userApiService.getUserById.Error) {
                            const error = e.getActualType();
                            this._alertService.createAlert({
                                title: error.data.message,
                                icon: 'error',
                            });
                        }
                    });
            } else {
                this.headToHeadWidths = null;

                await this.userApiService
                    .getCurrentUser({})
                    .then(async (res) => {
                        this.user = res.data;

                        this.loaded = true;

                        await this.getStatistics();

                        this.GetIncomingFriends();
                    })
                    .catch((e) => {
                        if (e instanceof this.userApiService.getUserById.Error) {
                            const error = e.getActualType();
                            this._alertService.createAlert({
                                title: error.data.message,
                                icon: 'error',
                            });
                        }
                    })
                    .finally();
            }

            this.getScores();
        });

        this.route.queryParams.pipe(take(1)).subscribe(async (params) => {
            if (params.showBottomNav) {
                this.showBottomNav = params.showBottomNav;
            }
        });
    }

    ionViewWillEnter() {
        this.ga.trackView('Profile');

        this._menu.enable(true, 'mainmenu');
        this._menu.enable(false, 'ingamemenu');

        if (this.user && this.user.is_self) {
            this.userApiService.getCurrentUser({}).then(async (res) => {
                this.user = res.data;
            });
        }
    }

    showPopover() {
        this.popoverController
            .create({
                event,
                component: ProfilePopoverComponent,
                componentProps: {
                    isBlocked: this.user.is_blocked,
                    friendStatus: this.user.friend_status,
                },
            })
            .then((popover) => {
                popover.present();
                this.popoverService.init(popover);
                popover.onDidDismiss().then((res) => {
                    this.popoverService.destroy();

                    if (res.data === 'block') {
                        this.tryBlock();
                    } else if (res.data === 'unblock') {
                        this.tryUnblock();
                    } else if (res.data === 'add') {
                        this.AddFriend(this.user.id);
                    } else if (res.data === 'remove') {
                        this.RemoveFriend(this.user.id);
                    } else if (res.data === 'accept') {
                        this.AcceptFriend(this.user.id);
                    } else if (res.data === 'decline') {
                        this.DeclineFriend(this.user.id);
                    } else if (res.data === 'report') {
                        this.modal
                            .create({
                                component: ReportUserDialogComponent,
                                cssClass: 'auto-height',
                                componentProps: {
                                    user: this.user,
                                    origin: 'profile' as ReportOrigin,
                                },
                            })
                            .then((elem) => {
                                elem.present();
                            });
                    }
                });
            });
    }

    getHeadToHeadInfo() {
        this.loadedHeadToHead = false;

        this._gameApiService
            .getPlayersHeadToHead({
                user1: this.auth.user.id,
                user2: this.user.id,
                results_only: true,
            })
            .then((res) => {
                this.headToHeadInfo = res.data;
                this.calculateHeadToHeadWidths();

                this.loadedHeadToHead = true;
            });
    }

    calculateHeadToHeadWidths() {
        const total = this.headToHeadInfo.total;

        const widths = [];
        if (total === 0) {
            widths.push(33.3);
            widths.push(33.3);
            widths.push(33.3);
        } else {
            widths.push((79 / total) * this.headToHeadInfo.first_user_wins + 7);
            widths.push((79 / total) * this.headToHeadInfo.draws + 7);
            widths.push((79 / total) * this.headToHeadInfo.second_user_wins + 7);
        }

        this.headToHeadWidths = widths;
    }

    async getStatistics(): Promise<void> {
        await this._statisticsApiService.getProfileStatistics({ user_id: this.user.id }).then((res) => {
            this.statistics = res.data;
        });

        await this.scoresApiService
            .getSpecificScores({
                user_id: this.user.id,
                score_from: 180,
                score_to: 180,
            })
            .then((res) => {
                this.maximums = res.data.scores;
            });

        this.loadedStats = true;
    }

    showStatsDialog() {
        this.modal
            .create({
                component: ProfileStatsDialogComponent,
                componentProps: {
                    statistics: this.statistics,
                },
                cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
            })
            .then((elem) => {
                elem.present();
            });
    }

    getCountry(abbr) {
        const country = _.find(COUNTRIES, (country) => country.alpha2 === abbr);
        if (!country) {
            return null;
        }
        if (this.user.is_self) {
            return country.name;
        }
        return country.label;
    }

    getFlag(abbr) {
        const country = _.find(COUNTRIES, (country) => country.alpha2 === abbr);
        if (!country) {
            return null;
        }
        return country.flag;
    }

    async showAmountsDialog() {
        if (!this.scores) {
            await this.getScores();
        }

        this.modal
            .create({
                component: ProfileAmountsDialogComponent,
                componentProps: {
                    scores: this.scores,
                },
                cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
            })
            .then((elem) => {
                elem.present();
            });
    }

    showFriends() {
        this.nav.navigateForward('friends', {
            queryParams: {
                userId: this.user.id,
            },
        } as NavigationOptions);
    }

    async OpenSubscriptionDialog() {
        const dialogComponent = await this.upgrade.GetUpgradeDialog(GA_EVENTCATEGORIES.PROFILE);
        this.modal
            .create({
                component: dialogComponent,
                cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
            })
            .then((elem) => {
                elem.present();
            });
    }

    refreshProfile(e) {
        return new Promise(() => {
            this.userApiService
                .getCurrentUser({})
                .then((res) => {
                    const user = res.data;
                    user.created_at = moment(user.created_at).toISOString();
                    this.user = user;
                    e.complete();
                })
                .catch((e) => {
                    e.complete();
                })
                .finally();
        });
    }

    OpenAccount() {
        this.ga.trackEvent(GA_EVENTCATEGORIES.PROFILE, GA_EVENTACTIONS.EDITPROFILE);
        this.nav.navigateForward('edit-profile');
    }

    GetProfile() {
        return new Promise((resolve, reject) => {
            this.userApiService
                .getCurrentUser({})
                .then((res) => {
                    const user = res.data;
                    user.created_at = moment(user.created_at).toISOString();
                    this.user = user;
                    resolve(user);
                })
                .catch((e) => {
                    reject(e);
                })
                .finally();
        }).then((profile: any) => {
            this.getScores();
            if (profile.is_self) {
                this.GetIncomingFriends();
            }
        });
    }

    tryBlock() {
        let promptDialog;

        this.modal
            .create({
                component: PromptDialogComponent,
                componentProps: {
                    title: $localize`:@@BLOCK_PLAYER:Block player`,
                    text: $localize`:@@SURE_TO_BLOCK_PLAYER:Are you sure you want to block this player?`,
                    cancelText: $localize`:@@CANCEL:Cancel`,
                    confirmText: $localize`:@@CONFIRM:Confirm`,
                    confirmColor: 'red',
                } as AlertPayload,
                cssClass: 'auto-height',
                showBackdrop: true,
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();

                promptDialog = elem;
                promptDialog.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.block();
                    }
                });
            });
    }

    tryUnblock() {
        let promptDialog;
        this.modal
            .create({
                component: PromptDialogComponent,
                componentProps: {
                    title: $localize`:@@UNBLOCK_PLAYER:Unblock player`,
                    text: $localize`:@@SURE_TO_UNBLOCK_PLAYER:Are you sure you want to unblock this player?`,
                    cancelText: $localize`:@@CANCEL:Cancel`,
                    confirmText: $localize`:@@CONFIRM:Confirm`,
                    confirmColor: 'orange',
                } as AlertPayload,
                cssClass: 'auto-height',
                showBackdrop: true,
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();

                promptDialog = elem;
                promptDialog.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.unblock();
                    }
                });
            });
    }

    block() {
        this.blockLoading = true;
        this.userApiService
            .blockUserById({ userId: this.user.id })
            .then(() => {
                this.blockLoading = false;

                $localize`:@@BLOCKED_THIS_PLAYER:You've blocked this player`;
                this._alertService.createAlert({
                    title: this.translate.instant('BLOCKED_THIS_PLAYER'),
                });

                this.user.is_blocked = true;
                if (this.user.friend_status === 'friends') {
                    this.friendApiService.denyFriendByUserId({ userId: this.user.id }).then(() => {
                        this.user.friend_status = 'no_friends';
                    });
                }
            })
            .catch(() => {
                this.blockLoading = false;
            });
    }

    unblock() {
        this.blockLoading = true;
        this.userApiService
            .unblockUserById({ userId: this.user.id })
            .then(() => {
                this.user.is_blocked = false;
                this.blockLoading = false;
            })
            .catch(() => {
                this.blockLoading = false;
            });
    }

    async getScores() {
        this.scoresApiService
            .getScores({
                user_id: this.user.id,
            })
            .then((res) => {
                this.scores = res.data;
            });
    }

    async GetIncomingFriends() {
        await this.friendApiService
            .getIncomingFriends({})
            .then((res) => {
                this.incomingFriends = res.data;
                this.friendApiService.notifications = res.data.length;
            })
            .catch((e) => {
                this._alertService.errorFromApi(e);
                this.loading.DismissLoader();
            });
    }

    AddFriend(userId) {
        this.loading.ShowDefaultLoader();
        this.friendApiService
            .addFriendByUserId({ userId })
            .then(() => {
                this.loading.DismissLoader();
                this.user.friend_status = 'pending';
            })
            .catch(() => {
                this.loading.DismissLoader();
            });
    }

    ShowPendingOptions(accountID) {
        $localize`:@@CANCEL_REQUEST:Cancel`;
        const actionSheet = this.sheet.create({
            backdropDismiss: true,
            buttons: [
                {
                    text: this.translate.instant('CANCEL_REQUEST'),
                    icon: 'close',
                    handler: () => {
                        this.friendApiService.denyFriendByUserId({ userId: accountID }).then(() => {
                            this.user.friend_status = 'no_friends';
                        });
                    },
                },
            ],
        } as any);

        actionSheet.then((elem) => elem.present());
    }

    updateProfilePhoto(event: any): void {
        if (!this.user.is_self) return;
        this._profilePhotoService.updateProfilePhoto(this.user, event);
    }

    goToChat(): void {
        if (!this.user.is_self && this.user.friend_status === 'friends') {
            this.privateChatApiService
                .createPrivateChat({ direct_message: true, user_ids: [this.user.id] })
                .then((res) => {
                    if (res.data) {
                        this.nav.navigateForward('chats/' + res.data.id);
                    }
                })
                .catch((err) => {
                    this._alertService.errorFromApi(err);
                });
        }
    }

    ShowFriendOptions(accountID) {
        const actionSheet = this.sheet.create({
            backdropDismiss: true,
            buttons: [
                {
                    text: $localize`:@@REMOVE_FRIEND:Remove friend`,
                    icon: 'close',
                    handler: () => {
                        this.RemoveFriend(accountID);
                    },
                },
            ],
        } as any);

        actionSheet.then((elem) => elem.present());
    }

    RemoveFriend(userId) {
        this.loading.ShowDefaultLoader();
        this.friendApiService
            .removeFriendByUserId({ userId })
            .then(() => {
                this.loading.DismissLoader();
                this.user.friend_status = 'no_friends';
                if (this.user.is_self) {
                    this.GetProfile();
                }
            })
            .catch(() => {
                this.loading.DismissLoader();
            });
    }

    AcceptFriend(userId) {
        this.loading.ShowDefaultLoader();
        this.friendApiService
            .acceptFriendByUserId({ userId })
            .then(() => {
                this.loading.DismissLoader();
                if (this.user.is_self) {
                    this.GetProfile();
                }
                this.user.friend_status = 'friends';
            })
            .catch(() => {
                this.loading.DismissLoader();
            });
    }

    DeclineFriend(userId) {
        this.loading.ShowDefaultLoader();
        this.friendApiService
            .denyFriendByUserId({ userId })
            .then(() => {
                this.loading.DismissLoader();
                if (this.user.is_self) {
                    this.GetProfile();
                }
                this.user.friend_status = 'no_friends';
            })
            .catch(() => {
                this.loading.DismissLoader();
            });
    }

    loginAsUser(): void {
        this._authApiService.loginUsingId({ id: this.user.id }).then(async (res) => {
            if (this.platform.is('capacitor') && !this.platform.is('desktop')) {
                try {
                    // When the user logs out from a device, we want to remove this token from the database
                    // We no longer need to send a push notification to this device for this user.
                    OneSignal.getDeviceState((response) => {
                        if (response?.userId && response?.pushToken) {
                            this._authApiService
                                .unlinkPushToken({ one_signal_id: response.userId, token: response.pushToken })
                                .then(() => {
                                    this.signOutAndLoginAsUser(res.data);
                                })
                                .catch((err) => {
                                    this._alertService.errorFromApi(err);
                                    this.signOutAndLoginAsUser(res.data);
                                });
                        } else {
                            this.signOutAndLoginAsUser(res.data);
                        }
                    });
                } catch (e) {
                    this.signOutAndLoginAsUser(res.data);
                }
            } else {
                this.signOutAndLoginAsUser(res.data);
            }
        });
    }

    signOutAndLoginAsUser(data: TokenUserResponse): void {
        this.auth.signOut().finally(() => {
            this.auth.saveAuthentication(data.user, data.access_token, true);

            this.nav.navigateRoot('/dashboard');
        });
    }

    ionViewWillLeave() {
        this.loading.DismissLoader();
    }
}
