export interface DC_Environment {
    version: string;
    build: number;
    isWeb: boolean;
    local: boolean;
    debug: boolean;
    production: boolean;
    stage: SUPPORTED_ENVIRONMENTS;
    apiUrl: string;
    firebase: {
        projectId: string;
        appId: string;
        storageBucket: string;
        apiKey: string;
        authDomain: string;
        messagingSenderId: string;
        measurementId: string;
    };
    echo: {
        key: string;
        wsHost: string;
        wsPort: number;
        wssPort: number;
        scheme: 'http' | 'https';
    };
    chromecast: {
        receiverApplicationId: string;
    };
    recaptcha: string;
}

export enum SUPPORTED_ENVIRONMENTS {
    LOCAL = 'local',
    DEVELOPMENT = 'development',
    STAGING = 'staging',
    PRODUCTION = 'production',
}
