import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FriendApiService } from '@dc-api/friend.api.service';
import { StatisticsApiService } from '@dc-api/statistics.api.service';
import { UserApiService } from '@dc-api/user.api.service';
import { ResultState } from '@dc-core/dc-backend/dc-enums';
import { ProfileStatisticsResponse } from '@dc-core/dc-backend/dc-responses';
import { OnlineUser } from '@dc-core/dc-gamelogic/online-game/online.ingame.globals';
import { LocalStorageKey } from '@dc-core/dc-localstorage';
import { ActionSheetController, IonicModule, ModalController, NavController, Platform } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { JoyrideModule } from '@providers/ngx-joyride/src/public-api';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Subscription } from 'rxjs';
import { GA_EVENTACTIONS, GA_EVENTCATEGORIES } from 'src/app/app.globals';
import { PrimaryButtonComponent } from 'src/app/primary-button/primary-button.component';
import { DCLoadingService } from 'src/providers/DCLoadingService';
import { JoyrideService } from 'src/providers/ngx-joyride/src/lib/services/joyride.service';
import { AuthService } from 'src/services/auth.service';

import { ProfilePhotoComponent } from '../../../../../components/profile-photo/profile-photo.component';
import { OnlinePlayerStatsComponent } from '../../../../online-player-stats/online-player-stats.component';
import { IconComponent } from '../../../../shared/components/icon/icon.component';

@Component({
    selector: 'app-profile-dialog',
    templateUrl: 'profile.dialog.html',
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        ProfilePhotoComponent,
        NgxSkeletonLoaderModule,
        JoyrideModule,
        OnlinePlayerStatsComponent,
        IconComponent,
        IonicModule,
        PrimaryButtonComponent,
    ],
})
export class ProfileDialogComponent implements OnInit, OnDestroy {
    @Input() user: OnlineUser;
    @Input() canReport = false;
    @Input() canDisqualify = false;
    @Input() showMoreButton = true;

    public authService: AuthService = inject(AuthService);

    private platform: Platform = inject(Platform);
    private modalController: ModalController = inject(ModalController);
    private navController: NavController = inject(NavController);
    private loadingService: DCLoadingService = inject(DCLoadingService);
    private sheetController: ActionSheetController = inject(ActionSheetController);
    private joyrideService: JoyrideService = inject(JoyrideService);
    private translateService: TranslateService = inject(TranslateService);
    private friendApiService: FriendApiService = inject(FriendApiService);
    private userApiService: UserApiService = inject(UserApiService);
    private statisticsApiService: StatisticsApiService = inject(StatisticsApiService);
    private ga: DartCounterAnalyticsService = inject(DartCounterAnalyticsService);

    public statistics: ProfileStatisticsResponse = null;

    public skeletonThemes = {
        info: {
            'border-radius': '5px',
            height: '20px',
            width: '25%',
            'background-color': 'rgb(76 76 76)',
        },
        infosmall: {
            'border-radius': '5px',
            height: '14px',
            width: '10%',
            'background-color': 'rgb(76 76 76)',
        },
        infosmall2: {
            'border-radius': '5px',
            height: '14px',
            width: '25%',
            'background-color': 'rgb(76 76 76)',
        },
        info2: {
            'border-radius': '5px',
            height: '16px',
            width: '25%',
            'background-color': '#ECECEC',
        },
        info3: {
            'border-radius': '5px',
            height: '20px',
            width: '25%',
            'background-color': '#ECECEC',
        },
        stats: {
            'border-radius': '5px',
            height: '50px',
            width: '50%',
            'background-color': 'rgb(76 76 76)',
        },
        profilepic: {
            border: '10px solid #2d2d2d',
            'border-radius': '50%',
            height: '135px',
            width: '135px',
            margin: '0 auto',
            'background-color': 'rgb(76 76 76)',
        },
    };

    public lastResults: ResultState[] = [];
    public twoMonthAverage: number = null;
    public isSuspended = false;
    public doneLoading = false;

    private backButtonSubscription: Subscription;

    public ngOnInit(): void {
        this.getPlayerDetails();

        const showTour = JSON.parse(localStorage.getItem(LocalStorageKey.firstTimeProfileDialog)) ?? true;
        if (showTour) {
            this.showTour();
            localStorage.setItem(LocalStorageKey.firstTimeProfileDialog, JSON.stringify(false));
        }

        this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(9999, () => {
            this.dismiss();
        });
    }

    public showTour(index = null): void {
        this.ga.trackEvent(GA_EVENTCATEGORIES.PROFILEDIALOG, GA_EVENTACTIONS.SHOWTOUR);
        let steps = ['firstStep'];
        if (index !== null) {
            steps = [steps[index]];
        }

        setTimeout(() => {
            this.joyrideService.startTour({
                steps: steps,
                showPrevButton: false,
                themeColor: '#444',
            });
        }, 250);
    }

    public dismiss(returnValue: any = null): void {
        this.modalController.dismiss(returnValue);
    }

    public addFriend(userId: number): void {
        this.ga.trackEvent(GA_EVENTCATEGORIES.PROFILEDIALOG, GA_EVENTACTIONS.ADDFRIEND);
        this.loadingService.ShowDefaultLoader();
        this.friendApiService
            .addFriendByUserId({ userId })
            .then(() => {
                this.loadingService.DismissLoader();
                this.user.friend_status = 'pending';
            })
            .catch(() => {
                this.loadingService.DismissLoader();
            });
    }

    public showPendingOptions(userId: number): void {
        $localize`:@@CANCEL_REQUEST:Cancel`;
        const actionSheet = this.sheetController.create({
            backdropDismiss: true,
            buttons: [
                {
                    text: this.translateService.instant('CANCEL_REQUEST'),
                    icon: 'close',
                    handler: () => {
                        this.removeFriend(userId);
                    },
                },
            ],
        } as any);

        actionSheet.then((elem) => elem.present());
    }

    public showFriendOptions(userId: number): void {
        this.ga.trackEvent(GA_EVENTCATEGORIES.PROFILEDIALOG, GA_EVENTACTIONS.SHOWFRIENDOPTIONS);
        const actionSheet = this.sheetController.create({
            backdropDismiss: true,
            buttons: [
                {
                    text: $localize`:@@REMOVE_FRIEND:Remove friend`,
                    icon: 'close',
                    handler: () => {
                        this.removeFriend(userId);
                    },
                },
            ],
        } as any);

        actionSheet.then((elem) => elem.present());
    }

    public acceptFriend(userId: number): void {
        this.ga.trackEvent(GA_EVENTCATEGORIES.PROFILEDIALOG, GA_EVENTACTIONS.ACCEPTFRIEND);
        this.loadingService.ShowDefaultLoader();
        this.friendApiService
            .acceptFriendByUserId({ userId })
            .then(() => {
                this.loadingService.DismissLoader();
                this.user.friend_status = 'friends';
            })
            .finally(() => this.loadingService.DismissLoader());
    }

    public report(): void {
        this.dismiss('report');
    }

    public disqualify(): void {
        this.dismiss('disqualify');
    }

    public openProfile(): void {
        this.ga.trackEvent(GA_EVENTCATEGORIES.PROFILEDIALOG, GA_EVENTACTIONS.OPENPROFILEUSER);
        this.dismiss();

        this.navController.navigateForward('profile/' + this.user.id);
    }

    public ngOnDestroy(): void {
        if (this.backButtonSubscription) {
            this.backButtonSubscription.unsubscribe();
        }
    }

    private async getPlayerDetails(): Promise<void> {
        this.doneLoading = false;

        await this.statisticsApiService.getOnlineStatistics({ user_id: this.user.id }).then((res) => {
            this.lastResults = res.data.last_results;
            this.twoMonthAverage = res.data.two_month_average;
        });

        await this.userApiService.getUserById({ userId: this.user.id }).then((res) => {
            const user = res.data as OnlineUser;
            user.countryFlag = this.user.countryFlag;
            this.user = user;
        });

        await this.statisticsApiService.getProfileStatistics({ user_id: this.user.id }).then((res) => {
            this.statistics = res.data;
        });

        this.doneLoading = true;
    }

    private removeFriend(userId: number): void {
        this.ga.trackEvent(GA_EVENTCATEGORIES.PROFILEDIALOG, GA_EVENTACTIONS.REMOVEFRIEND);
        this.loadingService.ShowDefaultLoader();
        this.friendApiService
            .denyFriendByUserId({ userId })
            .then(() => {
                this.user.friend_status = 'no_friends';
            })
            .finally(() => this.loadingService.DismissLoader());
    }
}
