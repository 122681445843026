<app-page-header mainNavigation="menu">
    <div title [translate]="'PLAY_ONLINE'" i18n="@@PLAY_ONLINE">Play online</div>
    <div right class="right-page-header">
        <app-control-camera-icon [refreshGame]="true"></app-control-camera-icon>
        @if (isWeb) {
            <app-fullscreen-icon></app-fullscreen-icon>
        }
    </div>
</app-page-header>

<div class="relative flex h-full flex-col overflow-hidden" id="main-content">
    @if (unfinishedGamesService.unfinishedGames.length) {
        <app-unfinished-online-games-dropdown class="block"></app-unfinished-online-games-dropdown>
    }

    <div class="flex flex-1 flex-col overflow-auto overscroll-none bg-white-pattern" id="behind-dropdown-modal">
        <div class="bg-black landscape:px-safe">
            <app-online-saved-gameplay class="block w-full"></app-online-saved-gameplay>
        </div>

        <div
            class="flex flex-col overflow-auto px-4 py-2.5 portrait:space-y-2 landscape:flex-row landscape:space-x-2 landscape:px-safe-offset-4"
            [appLargeWebContainer]>
            <!-- Insights -->
            <div class="flex flex-col space-y-2 landscape:w-1/2">
                <app-activate-smart-devices class="block" [collapsible]="true"></app-activate-smart-devices>

                <!-- Other players -->
                <div class="flex justify-between divide-x divide-neutral-50 rounded-lg bg-panel p-4">
                    <div class="w-1/2 space-y-6">
                        <div
                            class="text-center text-xs font-semibold uppercase text-neutral-400"
                            [translate]="'FRIENDS'"
                            i18n="@@FRIENDS">
                            Friends
                        </div>
                        <div class="flex justify-evenly">
                            <div class="flex flex-col items-center justify-start space-y-2">
                                @if (!online.loaded() || online.onlineFriends === null) {
                                    <ngx-skeleton-loader
                                        appearance="circle"
                                        [theme]="{ height: '27px', width: '27px' }"></ngx-skeleton-loader>
                                }
                                @if (online.loaded() && online.onlineFriends !== null) {
                                    <div
                                        class="font-bebas-neue font-normal leading-8 text-orange dark:text-white text-4xl landscape:md:text-5xl portrait:xs:text-5xl"
                                        [ngClass]="themeService.theme() === 'dark' ? 'text-neutral-50' : 'text-orange'">
                                        {{ online.onlineFriends.length || 0 | formatThousand }}
                                    </div>
                                }
                                <div class="text-xs font-bold uppercase text-neutral-600 landscape:md:text-sm">
                                    <div class="pb-1" [translate]="'ONLINE'" i18n="@@ONLINE">Online</div>
                                </div>
                            </div>
                            <div class="flex flex-col items-center justify-start space-y-2">
                                <ngx-skeleton-loader
                                    appearance="circle"
                                    [theme]="{ height: '27px', width: '27px' }"
                                    *ngIf="!online.loaded() || online.inGameFriends === null"></ngx-skeleton-loader>
                                <div
                                    *ngIf="online.loaded() && online.inGameFriends !== null"
                                    class="font-bebas-neue font-normal leading-8 text-orange dark:text-white text-4xl landscape:md:text-5xl portrait:xs:text-5xl"
                                    [ngClass]="themeService.theme() === 'dark' ? 'text-neutral-50' : 'text-orange'">
                                    {{ online.inGameFriends.length || 0 | formatThousand }}
                                </div>
                                <div class="text-xs font-bold uppercase text-neutral-600 landscape:md:text-sm">
                                    <div class="pb-1" [translate]="'IN_GAME'" i18n="@@IN_GAME">In-game</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-1/2 space-y-6">
                        <div
                            class="text-center text-xs font-semibold uppercase text-neutral-400"
                            [translate]="'WORLDWIDE'"
                            i18n="@@WORLDWIDE">
                            Worldwide
                        </div>
                        <div class="flex justify-evenly">
                            <div class="flex flex-col items-center justify-start space-y-2">
                                <ngx-skeleton-loader
                                    appearance="circle"
                                    [theme]="{ height: '27px', width: '27px' }"
                                    *ngIf="!online.loaded() || online.onlineFriends === null"></ngx-skeleton-loader>
                                <div
                                    *ngIf="online.loaded() && online.onlineFriends !== null"
                                    class="font-bebas-neue font-normal leading-8 text-orange dark:text-white text-4xl landscape:md:text-5xl portrait:xs:text-5xl"
                                    [ngClass]="themeService.theme() === 'dark' ? 'text-neutral-50' : 'text-orange'">
                                    {{ online.onlineUsers || 0 | formatThousand }}
                                </div>
                                <div class="text-xs font-bold uppercase text-neutral-600 landscape:md:text-sm">
                                    <div class="pb-1" [translate]="'ONLINE'" i18n="@@ONLINE">Online</div>
                                </div>
                            </div>
                            <div class="flex flex-col items-center justify-start space-y-2">
                                <ngx-skeleton-loader
                                    appearance="circle"
                                    [theme]="{ height: '27px', width: '27px' }"
                                    *ngIf="!online.loaded() || online.liveGamesCount === null"></ngx-skeleton-loader>
                                <div
                                    *ngIf="online.loaded() && online.liveGamesCount !== null"
                                    class="font-bebas-neue font-normal leading-8 text-orange dark:text-white text-4xl landscape:md:text-5xl portrait:xs:text-5xl"
                                    [ngClass]="themeService.theme() === 'dark' ? 'text-neutral-50' : 'text-orange'">
                                    {{ online.liveGamesCount * 2 | formatThousand }}
                                </div>
                                <div class="text-xs font-bold uppercase text-neutral-600 landscape:md:text-sm">
                                    <div class="pb-1" [translate]="'IN_GAME'" i18n="@@IN_GAME">In-game</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex flex-col space-y-2 landscape:w-1/2">
                <!-- Global mode -->
                <div class="flex flex-col space-y-3 rounded-lg bg-panel p-4">
                    <div class="flex items-center justify-between cursor-pointer" (click)="goGlobalMode()">
                        <div class="flex space-x-2">
                            <div class="inline-flex h-7 w-7 items-center justify-center rounded-full bg-neutral-50">
                                <app-icon icon="public" size="medium" class="text-black"></app-icon>
                            </div>
                            <div
                                class="font-bebas-neue text-2xl text-black"
                                [translate]="'GLOBAL_MODE'"
                                i18n="@@GLOBAL_MODE">
                                Global lobby
                            </div>
                        </div>
                        <app-icon icon="keyboard_arrow_right" class="!text-neutral-200"></app-icon>
                    </div>
                    <div class="flex justify-evenly">
                        <div
                            class="flex flex-col items-center justify-start space-y-2 w-1/2"
                            (click)="goGlobalMode('lobby')">
                            <ngx-skeleton-loader
                                appearance="circle"
                                [theme]="{ height: '27px', width: '27px' }"
                                *ngIf="!online.loaded()"></ngx-skeleton-loader>
                            <div
                                *ngIf="online.loaded()"
                                class="font-bebas-neue text-5xl font-normal leading-8 text-black landscape:text-4xl landscape:md:text-5xl">
                                {{ online.lobbyGames().length }}
                            </div>
                            <div class="text-xs font-bold uppercase text-neutral-600 landscape:md:text-sm">
                                <div class="pb-1" [translate]="'GAMES_IN_LOBBY'" i18n="@@GAMES_IN_LOBBY">
                                    Games in lobby
                                </div>
                            </div>
                        </div>
                        <div
                            class="flex flex-col items-center justify-start space-y-2 w-1/2"
                            (click)="goGlobalMode('live')">
                            <ngx-skeleton-loader
                                appearance="circle"
                                [theme]="{ height: '27px', width: '27px' }"
                                *ngIf="!online.loaded() || online.liveGamesCount === null"></ngx-skeleton-loader>
                            <div
                                *ngIf="online.loaded() && online.liveGamesCount !== null"
                                class="font-bebas-neue text-5xl font-normal leading-8 text-black landscape:text-4xl landscape:md:text-5xl">
                                {{ online.liveGamesCount }}
                            </div>
                            <div class="text-xs font-bold uppercase text-neutral-600 landscape:md:text-sm">
                                <div class="pb-1" [translate]="'LIVE_GAMES'" i18n="@@LIVE_GAMES">Live games</div>
                            </div>
                        </div>
                    </div>
                    @if (online.onlineSavedGameplay) {
                        <app-toggle-switch
                            class="block w-full py-1 text-black"
                            [model]="online.showGameInLobby"
                            (itemChange)="toggleOwnGame()">
                            <span [translate]="'ADD_YOUR_GAME_IN_LOBBY'" i18n="@@ADD_YOUR_GAME_IN_LOBBY">
                                Add your game to lobby
                            </span>
                        </app-toggle-switch>
                    }
                    <div class="flex space-x-2">
                        <app-primary-button
                            class="w-1/2"
                            size="normal"
                            addedClasses="w-full whitespace-nowrap"
                            (click)="goGlobalMode('lobby')"
                            [translate]="'GO_TO_LOBBY'"
                            i18n="@@GO_TO_LOBBY">
                            Go to lobby
                        </app-primary-button>
                        <app-primary-button
                            class="w-1/2"
                            color="black"
                            addedClasses="w-full whitespace-nowrap"
                            size="normal"
                            (click)="goGlobalMode('live')"
                            [translate]="'LIVE_GAMES'"
                            i18n="@@LIVE_GAMES">
                            Live games
                        </app-primary-button>
                    </div>
                </div>

                <!-- Friend mode -->
                <div class="flex flex-col space-y-2 rounded-lg bg-panel p-4" (click)="challengeFriend()">
                    <div class="flex items-center justify-between cursor-pointer">
                        <div class="flex space-x-2">
                            <div class="inline-flex h-7 w-7 items-center justify-center rounded-full bg-neutral-50">
                                <app-icon icon="people_alt" size="medium" class="text-black"></app-icon>
                            </div>
                            <div
                                class="font-bebas-neue text-2xl text-black"
                                [translate]="'FRIENDS_MODE'"
                                i18n="@@FRIENDS_MODE">
                                Friends lobby
                            </div>
                        </div>
                        <app-icon icon="keyboard_arrow_right" class="!text-neutral-200"></app-icon>
                    </div>
                    <app-primary-button
                        size="normal"
                        addedClasses="w-full"
                        [translate]="'CHALLENGE_A_FRIEND'"
                        i18n="@@CHALLENGE_A_FRIEND">
                        Challenge a friend!
                    </app-primary-button>
                </div>
            </div>
        </div>
    </div>

    <app-bottom-navigation class="w-full" active="online"></app-bottom-navigation>
</div>
