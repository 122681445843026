<div class="flex items-center space-x-3">
    <div class="inline-flex w-18 items-center justify-center" *ngIf="smartDevice">
        <img src="assets/icon/ftr/{{ smartDevice.type }}_logo.webp" />
    </div>
    <div [class.w-full]="!smartDevice" [class.cursor-pointer]="smartDevice">
        <div class="flex flex-1 items-center space-x-1.5" (click)="selectSmartDevice()">
            <div class="flex last:text-base font-semibold text-black" *ngIf="smartDevice">
                {{ smartDevice.name }}
            </div>
            <div class="flex flex-col w-full space-y-2 py-2" *ngIf="!smartDevice && canSelect">
                <div
                    class="font-bebas-neue text-2xl text-white"
                    [translate]="'NO_SMART_DEVICES'"
                    i18n="@@NO_SMART_DEVICES"
                    *ngIf="!networks">
                    No smart devices to display
                </div>
                <div
                    class="global-lobby-shadow space-y-2 rounded-md bg-neutral-100 p-4"
                    *ngFor="let network of networks; let networkIndex = index">
                    <div class="flex items-center justify-between" [class.pb-2]="network.devices.length">
                        <div class="text-xs text-black">
                            <span [translate]="'NETWORK'" i18n="@@NETWORK">Network</span>: {{ network.name }}
                        </div>
                    </div>
                    <div
                        *ngFor="let device of network.devices; let deviceIndex = index"
                        (click)="selectSmartDevice(device, network.name)">
                        <div class="flex items-center justify-between p-3 bg-neutral-50">
                            <app-smart-device-item [smartDevice]="device"></app-smart-device-item>
                        </div>
                    </div>
                </div>
            </div>
            <svg
                *ngIf="smartDevice && status"
                [class.text-green]="status === 'green'"
                [class.text-yellow]="status === 'yellow'"
                [class.text-red]="status === 'red'"
                width="0.438rem"
                height="0.438rem"
                viewBox="0 0 8 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <circle cx="4" cy="4" r="4" fill="currentColor" />
            </svg>
            @if (smartDevice?.version) {
                <div
                    class="flex justify-center items-center rounded-md font-semibold text-xs px-2 bg-neutral-200 text-neutral-700">
                    <div>v{{ smartDevice.version }}</div>
                </div>
            }
        </div>
    </div>
</div>
