/* eslint-disable max-len */
import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StatisticsApiService } from '@dc-api/statistics.api.service';
import { TournamentApiService } from '@dc-api/tournament.api.service';
import { Tournament } from '@dc-core/dc-backend/dc-classes';
import { LatestOnlineGamesResponse } from '@dc-core/dc-backend/dc-responses';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { IonicModule, ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { TranslateModule } from '@ngx-translate/core';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { DCLoadingService } from '@providers/DCLoadingService';
import { AuthService } from '@services/auth.service';
import { GA_EVENTACTIONS, GA_EVENTCATEGORIES } from 'src/app/app.globals';
import { PrimaryButtonComponent } from 'src/app/primary-button/primary-button.component';
import { environment } from 'src/environments/environment';

import { IconComponent } from '../../../../shared/components/icon/icon.component';
import { UpdateOnlineSetupComponent } from '../../../profile/components/update-online-setup/edit-online-setup.component';

@Component({
    selector: 'app-join-tournament-dialog',
    templateUrl: 'join-tournament.dialog.html',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        PrimaryButtonComponent,
        IconComponent,
        TranslateModule,
        UpdateOnlineSetupComponent,
    ],
})
export class JoinTournamentDialogComponent implements OnInit {
    @Input() tournament: Tournament;

    public authService: AuthService = inject(AuthService);

    private modalController: ModalController = inject(ModalController);
    private translateService: TranslateService = inject(TranslateService);
    private tournamentApiService: TournamentApiService = inject(TournamentApiService);
    private alertService: DartCounterAlertService = inject(DartCounterAlertService);
    private statisticsApiService: StatisticsApiService = inject(StatisticsApiService);
    private navController: NavController = inject(NavController);
    private ga: DartCounterAnalyticsService = inject(DartCounterAnalyticsService);
    private loadingService: DCLoadingService = inject(DCLoadingService);

    public isLoading: boolean;
    public finalCode: number[] = [null, null, null, null, null, null];
    public focusedField: HTMLInputElement | null = null;
    public notEnoughOnlineGames: boolean;
    public latestOnlineGames: LatestOnlineGamesResponse;
    public canJoin: boolean = true;
    public joinError: string;

    public isProduction = environment.production;

    public async ngOnInit(): Promise<void> {
        this.isLoading = true;

        await this.statisticsApiService.getLatestOnlineGamesStatistics({}).then((res) => {
            this.notEnoughOnlineGames = environment.production ? res.data.count < 10 : false;
            this.latestOnlineGames = res.data;

            if (
                this.tournament.min_three_dart_avg &&
                this.latestOnlineGames.three_dart_average < this.tournament.min_three_dart_avg
            ) {
                $localize`:@@THREE_DART_AVG_IS_TOO_LOWER_THAN_MINIMUM:Your three dart average of ${this.latestOnlineGames.three_dart_average}:average: is lower than the minimum of ${this.tournament.min_three_dart_avg}:minimum:`;
                this.joinError = this.translateService.instant('THREE_DART_AVG_IS_TOO_LOWER_THAN_MINIMUM', {
                    average: this.latestOnlineGames.three_dart_average,
                    minimum: this.tournament.min_three_dart_avg,
                });
                this.canJoin = false;
            } else if (
                this.tournament.max_three_dart_avg &&
                this.latestOnlineGames.three_dart_average > this.tournament.max_three_dart_avg
            ) {
                $localize`:@@THREE_DART_AVG_IS_TOO_HIGHER_THAN_MAXMIMUM:Your three dart average of ${this.latestOnlineGames.three_dart_average}:average: is higher than the maximum of ${this.tournament.max_three_dart_avg}:maximum:`;
                this.joinError = this.translateService.instant('THREE_DART_AVG_IS_TOO_HIGHER_THAN_MAXMIMUM', {
                    average: this.latestOnlineGames.three_dart_average,
                    maximum: this.tournament.max_three_dart_avg,
                });
                this.canJoin = false;
            }
        });

        this.isLoading = false;
    }

    public async join(): Promise<void> {
        if (
            (this.tournament.camera_required || this.tournament.needs_approval) &&
            !this.authService.user.profile.online_setup_url
        ) {
            this.alertService.createAlert({
                title: $localize`:@@YOU_NEED_TO_UPLOAD_AN_ONLINE_SETUP_TO_JOIN:You need to upload an online setup to join`,
                icon: 'error',
            });
            return;
        }

        if (
            this.tournament.is_private &&
            this.finalCode.join('').length !== 6 &&
            !this.tournament.participation?.is_admin
        ) {
            this.alertService.createAlert({
                title: $localize`:@@PLEASE_FILL_IN_A_VALID_PASSWORD:Please fill in a valid password`,
                icon: 'error',
            });
            return;
        }

        await this.loadingService.ShowDefaultLoader();

        this.tournamentApiService
            .joinTournament({
                tournamentId: this.tournament.id,
                password: this.tournament.is_private ? this.finalCode.join('') : null,
            })
            .then((res) => {
                this.ga.trackEvent(GA_EVENTCATEGORIES.TOURNAMENTS, GA_EVENTACTIONS.JOIN);

                this.dismiss(res.data);
            })
            .catch((err) => this.alertService.errorFromApi(err))
            .finally(() => this.loadingService.DismissLoader());
    }

    public goToGlobalLobby(): void {
        this.dismiss();
        this.navController.navigateBack('online-games/global-lobby');
    }

    public dismiss(returnValue: any = null): void {
        this.modalController.dismiss(returnValue);
    }

    public onInputKeyup(
        event: KeyboardEvent,
        model: any[],
        key: number, // key or index to identify the model property
        currentInput: HTMLInputElement,
        nextInput: HTMLInputElement | null,
        previousInput: HTMLInputElement | null
    ): void {
        // Handle backspace separately
        if (event.key.toLowerCase() === 'backspace') {
            currentInput.value = null; // Clear the current input
            model[key] = null;
            if (previousInput) {
                model[key - 1] = null;
                this.focusedField = previousInput;
                previousInput.value = null;
                previousInput.focus();
            }
        } else if (currentInput.value.length) {
            // If it's a character key, replace the input's value with the new key
            currentInput.value = currentInput.value.charAt(currentInput.value.length - 1);
            model[key] = currentInput.value;
            if (nextInput) {
                this.focusedField = nextInput;
                nextInput.focus();
            } else {
                currentInput.blur();
                this.focusedField = null;
            }
        }

        // Stop the default behavior to prevent the input from being added twice
        event.preventDefault();
    }

    public focusField(input: HTMLInputElement): void {
        this.focusedField = input;
    }
}
