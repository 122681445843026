<div class="new-game-card tournament mb-2">
    @if (showTitle()) {
        <div class="title-container">
            <div class="icon">
                <app-icon icon="camera_alt" size="small"></app-icon>
            </div>
            <div class="title" [translate]="'ONLINE_SETUP'" i18n="@@ONLINE_SETUP">Online setup</div>
        </div>
    }

    <div
        class="relative overflow-hidden w-full max-w-xs mx-auto rounded-md"
        [ngClass]="{
            'bg-gray-50 h-40 w-40': !authService.user.profile?.online_setup_url
        }">
        @if (authService.user.profile?.online_setup_url) {
            <img class="w-full h-full rounded-md" [src]="authService.user.profile?.online_setup_url" />
        } @else {
            <img
                class="absolute bottom-0 left-0 right-0 top-0 m-auto w-28 grayscale"
                src="assets/icon/dctarget_stacked_white.png" />
        }
    </div>

    <app-primary-button
        class="block mt-2"
        size="small"
        fill="outline"
        addedClasses="w-full"
        color="orange"
        (click)="uploadOnlineSetup()">
        @if (authService.user.profile?.online_setup_url) {
            <div [translate]="'EDIT_ONLINE_SETUP'" i18n="@@EDIT_ONLINE_SETUP">Edit online setup</div>
        } @else {
            <div [translate]="'CREATE_ONLINE_SETUP'" i18n="@@CREATE_ONLINE_SETUP">Create online setup</div>
        }
    </app-primary-button>
</div>
