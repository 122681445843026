import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnDestroy, OnInit, signal, WritableSignal } from '@angular/core';
import { Tournament } from '@dc-core/dc-backend/dc-classes';
import { ModalController, Platform } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';

import { PageHeaderComponent } from '../../../../../components/page-header/page-header.component';
import { PrimaryButtonComponent } from '../../../../primary-button/primary-button.component';
import { TournamentDetailsComponent } from '../../components/tournament-details/tournament-details.component';

@Component({
    selector: 'app-tournament-participant-onboarding-dialog',
    templateUrl: 'tournament-participant-onboarding.dialog.html',
    standalone: true,
    imports: [
        CommonModule,
        PrimaryButtonComponent,
        TranslateModule,
        PageHeaderComponent,
        IconComponent,
        TournamentDetailsComponent,
    ],
    animations: [
        trigger('fade', [
            transition(':enter', [style({ opacity: 0 }), animate(200, style({ opacity: 1 }))]),
            transition(':leave', [style({ opacity: 1 }), animate(200, style({ opacity: 0 }))]),
        ]),
    ],
})
export class TournamentParticipantOnboardingDialogComponent implements OnInit, OnDestroy {
    @Input() canSkip = true;

    private modalController: ModalController = inject(ModalController);
    private platform: Platform = inject(Platform);

    public slides: number[] = [1, 2, 3, 4, 5, 6, 7];
    public currentSlide: WritableSignal<number> = signal(0);

    public demoTournament: Tournament = {
        name: 'DartCounter Tournament',
        amount_of_participants: 32,
        amount_of_rounds: 4,
        camera_required: true,
        omni_scoring_required: true,
        min_three_dart_avg: 30,
        max_three_dart_avg: 80,
        check_in_from: moment().toISOString(),
        starting_at: moment().toISOString(),
        needs_approval: false,
        is_private: false,
        status: 'joining_period',
        current_participants: 27,
    };

    private backButtonSubscription: Subscription;

    public ngOnInit(): void {
        this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(9999, () => {
            this.dismiss();
        });
    }

    public ngOnDestroy(): void {
        if (this.backButtonSubscription) {
            this.backButtonSubscription.unsubscribe();
        }
    }

    public prevSlide(): void {
        if (this.currentSlide() > 0) {
            this.currentSlide.update((currentSlide) => --currentSlide);
        }
    }

    public nextSlide(): void {
        if (this.currentSlide() < this.slides.length - 1) {
            this.currentSlide.update((currentSlide) => ++currentSlide);
        }
    }

    public dismiss(returnValue = null): void {
        this.modalController.dismiss(returnValue);
    }
}
