/* eslint-disable max-len */
import { CommonModule } from '@angular/common';
import { Component, inject, input, InputSignal } from '@angular/core';
import { JanusVideoRoomService } from '@dc-core/dc-services/dc-janus/janus-video-room.service';
import { LocalCameraService } from '@services/local-camera.service';

import { IconComponent } from '../shared/components/icon/icon.component';

@Component({
    selector: 'app-control-camera-icon',
    templateUrl: 'control-camera-icon.component.html',
    standalone: true,
    imports: [CommonModule, IconComponent],
})
export class ControlCameraIconComponent {
    public refreshGame: InputSignal<boolean> = input.required();

    public videoRoomService: JanusVideoRoomService = inject(JanusVideoRoomService);

    private localCameraService: LocalCameraService = inject(LocalCameraService);

    public linkCamera(): void {
        this.localCameraService.linkCamera(this.refreshGame());
    }

    public unlinkCamera(): void {
        this.localCameraService.unlinkCamera(this.refreshGame());
    }
}
