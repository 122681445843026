//END of DartCounter Core SubTree
import { GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';
import { TextFieldModule } from '@angular/cdk/text-field';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideAuth } from '@angular/fire/auth';
import { initializeFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { loadTranslations } from '@angular/localize';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { CameraPreview } from '@awesome-cordova-plugins/camera-preview/ngx';
//Module Imports
import { File } from '@awesome-cordova-plugins/file/ngx';
//Import Providers
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Instagram } from '@awesome-cordova-plugins/instagram/ngx';
import { Media } from '@awesome-cordova-plugins/media/ngx';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
// import { App } from '@capacitor/app';
//END of DartCounter Core SubTree
// Import Bugsnag and the Angular integration
import { Capacitor } from '@capacitor/core';
import { AccordionComponent } from '@components/accordion/app-accordion.component';
import { AppAlertsComponent } from '@components/app-alerts/app-alerts.component';
import { AppHeadToHeadComponent } from '@components/head-to-head/app-head-to-head.component';
import { HotkeyKeyboardComponent } from '@components/hotkey-keyboard/hotkey-keyboard.component';
import { KeyboardDropdownComponent } from '@components/keyboard-dropdown/keyboard-dropdown.component';
import { AppLoaderComponent } from '@components/loader/app-loader.component';
import { EditOmniScoreDialogComponent } from '@components/omni/edit-omni-score/edit-omni-score.dialog';
import { OmniDartBoardComponent } from '@components/omni/omni-dartboard.component';
import { OmniHeatmapComponent } from '@components/omni/omni-heatmap.component';
import { OmniThrowsComponent } from '@components/omni/omni-throws.component';
import { ZoomedPointComponent } from '@components/omni/zoomed-point.component';
import { OnlineSavedGameplayComponent } from '@components/online-saved-gameplay/online-saved-gameplay.component';
import { PageHeaderComponent } from '@components/page-header/page-header.component';
import { DecimalRoundPipe } from '@components/pipes/decimal-round.pipe';
import { EntryModePipe } from '@components/pipes/entry-mode.pipe';
import { EuroPricePipe } from '@components/pipes/euro-price.pipe';
import { FinishModePipe } from '@components/pipes/finish-mode.pipe';
import { FormatThousandPipe } from '@components/pipes/format-thousand.pipe';
import { LiveGamesFilterPipe } from '@components/pipes/live-games-filter.pipe';
import { SafeHousePipe } from '@components/pipes/safehouse.pipe';
import { SubscriptionStatusPipe } from '@components/pipes/subscription-status.pipe';
import { TransactionStatusPipe } from '@components/pipes/transaction-status.pipe';
import { UpperCaseFirstPipe } from '@components/pipes/upper-case-first.pipe';
import { ProfilePhotoComponent } from '@components/profile-photo/profile-photo.component';
import { RatePlayerDialogComponent } from '@components/rate-player/rate-player.dialog';
import { RematchInviteDialogComponent } from '@components/rematch-invite/rematch-invite.dialog';
import { AppSelectComponent } from '@components/select/app-select.component';
import { SpeechBarsComponent } from '@components/speech-bars/speech-bars';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
//DartCounter Core SubTree modules
import { ApiServicesModule } from '@dc-api/api-services.module';
import { DartCounterFireStoreModule } from '@dc-core/dc-firestore/dc-firestore.module';
import { DartCounterCoreLoggingModule } from '@dc-core/dc-logging/dc-core-logging.module';
import { CookieConsentService } from '@dc-core/dc-services/cookie-consent.service';
import { DartCounterCoreServiceModule } from '@dc-core/dc-services/dc-core-service.module';
import { DartCounterLocalizeService } from '@dc-core/dc-services/localize.service';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TooltipModule } from '@make-lynxwork/ng2-tooltip-directive';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { ConverterProvider } from '@providers/converter.provider';
import { AfterGameService } from '@services/after-game.service';
import { ConfettiService } from '@services/confetti.service';
import { EventOverlayService } from '@services/event-overlay.service';
import { IngameAnimationService } from '@services/ingame-animation.service';
import { LocalCameraService } from '@services/local-camera.service';
import { LoggingService } from '@services/logging.service';
import { MediaWatcherService } from '@services/media-watcher.service';
import { NativeAudioService } from '@services/native-audio.service';
import { NotificationsService } from '@services/notifications.service';
import { PopoverService } from '@services/popover.service';
import { ProfilePhotoService } from '@services/profile-photo.service';
import { RouterEventsService } from '@services/router-events.service';
import { SmartDeviceService } from '@services/smart-device.service';
import { UDPService } from '@services/udp.service';
import { VirtServerService } from '@services/virt-server.service';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { GameLogicModule } from 'dc-core/dc-gamelogic/game-logic.module';
import { DartCounterAlertService } from 'dc-core/dc-services/alert.service';
import { DartCounterAudioService } from 'dc-core/dc-services/audio/audio.service';
import { DartCounterPreferenceService } from 'dc-core/dc-services/preference/preference.service';
import { getAuth, indexedDBLocalPersistence, initializeAuth } from 'firebase/auth';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { DragulaModule } from 'ng2-dragula';
import { ImageCropperModule } from 'ngx-image-cropper';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { from, Observable } from 'rxjs';
import { catchError, map, switchMap, timeout } from 'rxjs/operators';
import { BirthdateDialogComponent } from 'src/app/modules/account-settings/components/birthdate/birthdate.dialog';
import { ChangeLanguageDialogComponent } from 'src/app/modules/account-settings/components/change-language/change-language.dialog';
//Pipes & Components
import { EditEmailComponent } from 'src/app/modules/account-settings/components/edit-email/edit-email';
import { EditPasswordComponent } from 'src/app/modules/account-settings/components/edit-password/edit-password';
import { EditUsernameDialogComponent } from 'src/app/modules/account-settings/components/edit-username/edit-username-dialog';
import { ResetAccountDialogComponent } from 'src/app/modules/account-settings/components/reset-account/reset-account.dialog';
import { BlockedPlayersComponent } from 'src/app/modules/account-settings/pages/blockedplayers/blockedplayers';
import { SubscriptionsComponent } from 'src/app/modules/account-settings/pages/subscriptions/subscriptions';
import { ForceChangePasswordDialogComponent } from 'src/app/modules/authentication/dialogs/force-change-password/force-change-password.dialog';
import { GuestTransitionDialogComponent } from 'src/app/modules/authentication/dialogs/guest-transition/guest-transition.dialog';
import { TournamentService } from 'src/app/modules/tournaments/services/tournament.service';
import { IngameMenuComponent } from 'src/components/ingame-menu/ingame-menu';
import { CountryFilterPipe, MatchPlayersPipe, PrivateChatFilter, UserFilterPipe } from 'src/components/pipes/Filters';
import { AddSmartDeviceDialogComponent } from 'src/dialogs/add-smart-device/add-smart-device.dialog';
import { AllFeaturesDialogComponent } from 'src/dialogs/all-features/all-features';
import { AmountOfTurnsDialogComponent } from 'src/dialogs/amountofturns/amountofturns.dialog';
import { AssignGameLabelsDialogComponent } from 'src/dialogs/assign-game-labels/assign-game-labels.dialog';
import { CameraPreviewDialogComponent } from 'src/dialogs/camera-preview/camera-preview.dialog';
import { CancelSubscriptionDialogComponent } from 'src/dialogs/cancel-subscription/cancel-subscription';
import { CompleteAccountDialogComponent } from 'src/dialogs/complete-account/complete-account.dialog';
import { CountriesDialogComponent } from 'src/dialogs/countries/countries.dialog';
import { CricketTacticsTurnDialogComponent } from 'src/dialogs/cricket-tactics-turn/cricket-tactics-turn.dialog';
import { CropProfilePhotoDialogComponent } from 'src/dialogs/crop-profile-photo/crop-profile-photo.dialog';
import { CTAModuleDialogComponent } from 'src/dialogs/ctamodule/ctamodule';
import { CustomScoreDialogComponent } from 'src/dialogs/customscore/customscore.dialog';
import { DartboardDialogComponent } from 'src/dialogs/dartboard/dartboard';
import { DatePickerDialogComponent } from 'src/dialogs/date-picker/date-picker.dialog';
import { EnterEmailDialogComponent } from 'src/dialogs/enter-email/enter-email';
import { FeatureSlidesDialogComponent } from 'src/dialogs/feature-slides/feature-slides';
import { GameLabelsDialogComponent } from 'src/dialogs/game-labels/game-labels.dialog';
import { GoalAmountDialogComponent } from 'src/dialogs/goalamount/goalamount.dialog';
import { HeatmapDialogComponent } from 'src/dialogs/heatmap/heatmap.dialog';
import { HighlightsDialogComponent } from 'src/dialogs/highlights/highlights.dialog';
import { HotkeysDialogComponent } from 'src/dialogs/hotkeys/hotkeys.dialog';
import { InGameCommsDialogComponent } from 'src/dialogs/in-game-comms/in-game-comms.dialog';
import { LinkSocialsDialogComponent } from 'src/dialogs/link-socials/link-socials-dialog';
import { LinkSoundDialogComponent } from 'src/dialogs/link-sound/link-sound';
import { LiveCameraPreviewDialogComponent } from 'src/dialogs/live-camera-preview/live-camera-preview.dialog';
import { LogViewerDialogComponent } from 'src/dialogs/log-viewer/log-viewer.dialog';
import { LoginOrCreateDialogComponent } from 'src/dialogs/login-or-create/login-or-create';
import { LoginRequiredDialogComponent } from 'src/dialogs/login-required/login-required';
import { ManageGameLabelDialogComponent } from 'src/dialogs/manage-game-label/manage-game-label.dialog';
import { ManageGameLabelCategoryDialogComponent } from 'src/dialogs/manage-game-label-category/manage-game-label-category.dialog';
import { NewOnlineFeatureDialogComponent } from 'src/dialogs/new-online-feature/new-online-feature';
import { OmniActivationDialogComponent } from 'src/dialogs/omni-activation-dialog/omni-activation.dialog';
import { OmniSettingsDialogComponent } from 'src/dialogs/omni-settings/omni-settings.dialog';
import { OwnAccountWarningDialogComponent } from 'src/dialogs/own-account-warning/own-account-warning';
import { PickGameLabelsDialogComponent } from 'src/dialogs/pick-game-labels/pick-game-labels.dialog';
import { PlayerOptionsModalComponent } from 'src/dialogs/playerOptions.modal/playerOptions.modal';
import { ProfileAmountsDialogComponent } from 'src/dialogs/profile-amounts/profile-amounts.dialog';
import { ProfileStatsDialogComponent } from 'src/dialogs/profile-stats/profile-stats.dialog';
import { PromptDialogComponent } from 'src/dialogs/prompt/prompt.dialog';
import { ProvidePasswordDialogComponent } from 'src/dialogs/provide-password/provide-password.dialog';
import { QuitDialogComponent } from 'src/dialogs/quit/quit';
import { QuitOnlyDialogComponent } from 'src/dialogs/quit-only/quit-only';
import { RenameDialogComponent } from 'src/dialogs/rename/rename.dialog';
import { ReportUserDialogComponent } from 'src/dialogs/report-user/report-user.dialog';
import { RequireTripleDialogComponent } from 'src/dialogs/require-triple/require-triple.dialog';
import { SaveOnlineGameDialogComponent } from 'src/dialogs/save-online-game/save-online-game';
import { SearchFriendDialogComponent } from 'src/dialogs/search-friend/search-friend.component';
import { SearchPlayerDialogComponent } from 'src/dialogs/search-player/search-player.component';
import { SendUpgradeReasonsDialogComponent } from 'src/dialogs/send-upgrade-reasons/send-upgrade-reasons';
import { SocialShareDialogComponent } from 'src/dialogs/socialshare/socialshare';
import { SpectatorsDialogComponent } from 'src/dialogs/spectators/spectators.dialog';
import { SpeechToScoreInfoDialogComponent } from 'src/dialogs/speech-to-score-info/speech-to-score-info.dialog';
import { SuddenDeathDialogComponent } from 'src/dialogs/suddendeath/suddendeath.dialog';
import { SwitchSalesFunnelDialogComponent } from 'src/dialogs/switch-sales-funnel/switch-sales-funnel.dialog';
import { TermsAndNewsletterDialogComponent } from 'src/dialogs/terms-and-newsletter/terms-and-newsletter.dialog';
import { ThrowForBullDialogComponent } from 'src/dialogs/throw-for-bull/throw-for-bull';
import { ThrowoutDialogComponent } from 'src/dialogs/throwout/throwout';
import { TutorialDialogComponent } from 'src/dialogs/tutorial/tutorial';
import { UltimateSubscriptionDialogComponent } from 'src/dialogs/ultimate-subscription/ultimate-subscription';
import { UnfinishedLocalDialogComponent } from 'src/dialogs/unfinishedLocal/unfinishedLocal.dialog';
import { UpgradeSalesFunnelDialogComponent } from 'src/dialogs/upgrade-sales-funnel/upgrade-sales-funnel.dialog';
import { VoiceCallDialogComponent } from 'src/dialogs/voice-call/voice-call.dialog';
import { VSDartbotDialogComponent } from 'src/dialogs/vsdartbot/vsdartbot.dialog';
import { YourVirtCamViewDialogComponent } from 'src/dialogs/your-virt-cam-view/your-virt-cam-view.dialog';
import { ButtonOverflowVisibleDirective } from 'src/directives/button-overflow-visible.directive';
import { DisableKeyboardResizeDirective } from 'src/directives/disable-keyboard-scroll.directive';
import { DropdownModalHeightDirective } from 'src/directives/dropdown-modal-height.directive';
import { LargeWebContainerDirective } from 'src/directives/large-web-container.directive';
import { MaintainHeightDirective } from 'src/directives/maintain-height.directive';
import { PxHeightDirective } from 'src/directives/px-height.directive';
import { ScrollToBottomDirective } from 'src/directives/scroll-to-bottom.directive';
import { SetViewportHeightDirective } from 'src/directives/set-viewport-height.directive';
import { SmallWebContainerDirective } from 'src/directives/small-web-container.directive';
import { SwipeDirective } from 'src/directives/swipe.directive';
import { ToolbarOverflowVisibleDirective } from 'src/directives/toolbar-overflow-visible.directive';
import { WebContainerDirective } from 'src/directives/web-container.directive';
import { WebKeyboardContainerDirective } from 'src/directives/web-keyboard-container.directive';
import { environment, socials } from 'src/environments/environment';
import { AuthGuard } from 'src/guards/auth.guard';
import { CanDeactivateGuard } from 'src/guards/CanDeactivateGuard';
import { DevelopmentGuard } from 'src/guards/development.guard';
import { DowntimeGuard } from 'src/guards/downtime.guard';
import { NoAuthGuard } from 'src/guards/no-auth.guard';
import { NoDowntimeGuard } from 'src/guards/no-downtime.guard';
import { NoRedirectAuthGuard } from 'src/guards/no-redirect-auth.guard';
import { NotBannedGuard } from 'src/guards/not-banned.guard';
import { OnlineRestrictionGuard } from 'src/guards/online-restriction.guard';
import { ProfileEditingRestrictionGuard } from 'src/guards/profile-editing-restriction.guard';
import { ActionReplaysComponent } from 'src/pages/action-replays/action-replays.component';
import { ClipPopoverComponent } from 'src/pages/action-replays/popovers/clip-popover/clip-popover.component';
import { ActionReplayViewComponent } from 'src/pages/action-replays/view/action-replay-view.component';
import { BannedComponent } from 'src/pages/banned/banned.component';
import { ConnectComponent } from 'src/pages/connect/connect.component';
import { ContactComponent } from 'src/pages/contact/contact.component';
import { DashboardComponent } from 'src/pages/dashboard/dashboard';
import { DevelopmentComponent } from 'src/pages/dev/dev.component';
import { AroundTheClockNewGameComponent } from 'src/pages/local-games/around-the-clock/around-the-clock.new-game.component';
import { Bobs27NewGameComponent } from 'src/pages/local-games/bobs-27/bobs-27.new-game.component';
import { CricketTacticsNewGameComponent } from 'src/pages/local-games/cricket-tactics/cricket-tactics.new-game.component';
import { DoublesTrainingNewGameComponent } from 'src/pages/local-games/doubles-training/doubles-training.new-game.component';
import { LocalGamesComponent } from 'src/pages/local-games/local-games.component';
import { MatchNewGameComponent } from 'src/pages/local-games/match/match.new-game.component';
import { ScoreTrainingNewGameComponent } from 'src/pages/local-games/score-training/score-training.new-game.component';
import { ShanghaiNewGameComponent } from 'src/pages/local-games/shanghai/shanghai.new-game.component';
import { SinglesTrainingNewGameComponent } from 'src/pages/local-games/singles-training/singles-training.new-game.component';
import { MaintenanceComponent } from 'src/pages/maintenance/maintenance.component';
import { MyFriendsComponent } from 'src/pages/my-friends/my-friends';
import { AcceptDenyGameInviteRequestDialogComponent } from 'src/pages/online/accept-deny-game-invite-request/accept-deny-game-invite-request';
import { AcceptDenyJoinGameRequestDialogComponent } from 'src/pages/online/accept-deny-join-game-request/accept-deny-join-game-request';
import { ActiveGameDialogComponent } from 'src/pages/online/active-game/active-game';
import { AddBreakDialogComponent } from 'src/pages/online/addBreakDialog/addBreakDialog';
import { CameraOptionsPopoverComponent } from 'src/pages/online/cam-options-popover/camera-options-popover.component';
import { EditScoresDialogComponent } from 'src/pages/online/edit-scores-dialog/edit-scores.dialog';
import { FriendLobbyComponent } from 'src/pages/online/friendLobby/friendLobby';
import { GlobalMatchInfoComponent } from 'src/pages/online/globalMatchInfo/globalMatchInfo';
import { HeadToHeadDialogOneComponent } from 'src/pages/online/headtohead1.dialog/headtohead1.dialog';
import { InviteGameRequestDialogComponent } from 'src/pages/online/invite-game-request/invite-game-request';
import { LeaveAfterReportDialogComponent } from 'src/pages/online/leave-after-report/leave-after-report.dialog';
import { GlobalLobbyComponent } from 'src/pages/online/online-games/global-lobby/global-lobby.component';
import { OnlineGamesComponent } from 'src/pages/online/online-games/online-games.component';
import { OpponentNotRespondingDialogComponent } from 'src/pages/online/opponent-not-responding/opponent-not-responding.dialog';
import { OpponentQuitDialogComponent } from 'src/pages/online/opponent-quit/opponent-quit.dialog';
import { ReinviteDialogComponent } from 'src/pages/online/reinviteDialog/reinviteDialog';
import { UnfinishedGamesComponent } from 'src/pages/online/unfinished-games/unfinished-games';
import { CricketTacticsOnlineSetupComponent } from 'src/pages/online-setup/cricket-tactics/cricket-tactics.online-setup.component';
import { MatchOnlineSetupComponent } from 'src/pages/online-setup/match/match.online-setup.component';
import { OnlineSetupComponent } from 'src/pages/online-setup/online-setup.component';
import { PrivateChatConversationComponent } from 'src/pages/private-chats/conversation/private-chat-conversation.component';
import { PrivateChatsComponent } from 'src/pages/private-chats/private-chats.component';
import { ProfileViewComponent } from 'src/pages/profile/profile';
import { ProfilePopoverComponent } from 'src/pages/profile/profilePopover.component';
import { SearchUsersComponent } from 'src/pages/search-users/search-users.component';
import { ShutdownPopoverComponent } from 'src/pages/smart-devices/OMNI/omni-shutdown-popover/omni-shutdown-popover.component';
import { SmartDeviceInvitePopoverComponent } from 'src/pages/smart-devices/popovers/invite-popover/invite-popover.component';
import { SmartDeviceNetworkPopoverComponent } from 'src/pages/smart-devices/popovers/network-popover/network-popover.component';
import { AroundTheClockCardComponent } from 'src/pages/statistics/cards/around-the-clock/around-the-clock-card.component';
import { Bobs27CardComponent } from 'src/pages/statistics/cards/bobs-27/bobs-27-card.component';
import { Checkout121CardComponent } from 'src/pages/statistics/cards/checkout-121/checkout-121-card.component';
import { CricketTacticsCardComponent } from 'src/pages/statistics/cards/cricket-tactics/cricket-tactics-card.component';
import { DoublesTrainingCardComponent } from 'src/pages/statistics/cards/doubles-training/doubles-training-card.component';
import { MatchCardComponent } from 'src/pages/statistics/cards/match/match-card.component';
import { ScoreTrainingCardComponent } from 'src/pages/statistics/cards/score-training/score-training-card.component';
import { ShanghaiCardComponent } from 'src/pages/statistics/cards/shanghai/shanghai-card.component';
import { SinglesTrainingCardComponent } from 'src/pages/statistics/cards/singles-training/singles-training-card.component';
import { AroundTheClockDetailsComponent } from 'src/pages/statistics/details/around-the-clock/around-the-clock-details.component';
import { Bobs27DetailsComponent } from 'src/pages/statistics/details/bobs-27/bobs-27-details.component';
import { Checkout121DetailsComponent } from 'src/pages/statistics/details/checkout-121/checkout-121-details.component';
import { CricketTacticsDetailsComponent } from 'src/pages/statistics/details/cricket-tactics/cricket-tactics-details.component';
import { DoublesTrainingDetailsComponent } from 'src/pages/statistics/details/doubles-training/doubles-training-details.component';
import { MatchDetailsComponent } from 'src/pages/statistics/details/match/match-details.component';
import { ScoreTrainingDetailsComponent } from 'src/pages/statistics/details/score-training/score-training-details.component';
import { ShanghaiDetailsComponent } from 'src/pages/statistics/details/shanghai/shanghai-details.component';
import { SinglesTrainingDetailsComponent } from 'src/pages/statistics/details/singles-training/singles-training-details.component';
import { AroundTheClockListComponent } from 'src/pages/statistics/game-list/around-the-clock/around-the-clock-list';
import { Bobs27ListComponent } from 'src/pages/statistics/game-list/bobs-27/bobs-27-list';
import { Checkout121ListComponent } from 'src/pages/statistics/game-list/checkout-121/checkout-121-list';
import { CricketTacticsListComponent } from 'src/pages/statistics/game-list/cricket-tactics/cricket-tactics-list';
import { DoublesTrainingListComponent } from 'src/pages/statistics/game-list/doubles-training/doubles-training-list';
import { MatchListComponent } from 'src/pages/statistics/game-list/match/match-list';
import { ScoreTrainingListComponent } from 'src/pages/statistics/game-list/score-training/score-training-list';
import { ShanghaiListComponent } from 'src/pages/statistics/game-list/shanghai/shanghai-list';
import { SinglesTrainingListComponent } from 'src/pages/statistics/game-list/singles-training/singles-training-list';
import { AroundTheClockTableComponent } from 'src/pages/statistics/game-table/around-the-clock/around-the-clock-table.component';
import { Bobs27TableComponent } from 'src/pages/statistics/game-table/bobs-27/bobs-27-table.component';
import { Checkout121TableComponent } from 'src/pages/statistics/game-table/checkout-121/checkout-121-table.component';
import { CricketTacticsTableComponent } from 'src/pages/statistics/game-table/cricket-tactics/cricket-tactics-table.component';
import { doublesTrainingTableComponent } from 'src/pages/statistics/game-table/doubles-training/doubles-training-table.component';
import { MatchTableComponent } from 'src/pages/statistics/game-table/match/match-table.component';
import { ScoreTrainingTableComponent } from 'src/pages/statistics/game-table/score-training/score-training-table.component';
import { ShanghaiTableComponent } from 'src/pages/statistics/game-table/shanghai/shanghai-table.component';
import { SinglesTrainingTableComponent } from 'src/pages/statistics/game-table/singles-training/singles-training-table.component';
import { TournamentMatchTableComponent } from 'src/pages/statistics/game-table/tournaments/match/tournament-match-table.component';
import { StatDetailsListComponent } from 'src/pages/statistics/stat-details-list/stat-details-list.component';
import { StatisticsComponent } from 'src/pages/statistics/statistics';
import { BackButtonService } from 'src/providers/BackButtonService';
import { DCLoadingService } from 'src/providers/DCLoadingService';
import { Events } from 'src/providers/EventsService';
import { FireStoreService } from 'src/providers/FirebaseService';
import { FunctionService } from 'src/providers/functions.service';
import { JoyrideModule } from 'src/providers/ngx-joyride/src/lib/joyride.module';
import { PurchaseService } from 'src/providers/PurchaseService';
import { SocialMediaService } from 'src/providers/SocialMediaService';
import { UpgradeService as DCUpgradeService } from 'src/providers/UpgradeService';
import { AuthService } from 'src/services/auth.service';
import xliff from 'xliff';

import { ChatDatePipe } from '../components/pipes/chat-date.pipe';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ControlCameraIconComponent } from './control-camera-icon/control-camera-icon.component';
import { AppFeaturesService } from './core/app-features/services/app-features.service';
import { BetaFlagsService } from './core/beta-flags/services/beta-flags.service';
import { BottomNavigationComponent } from './core/navigation/bottom-navigation/bottom-navigation.component';
import { EventOverlayComponent } from './event-overlay/event-overlay.component';
import { FullscreenIconComponent } from './fullscreen-icon/fullscreen-icon.component';
import { IngameAnimationComponent } from './ingame-animation/ingame-animation.component';
import { KeyboardDartComponent } from './keyboard-dart/keyboard-dart.component';
import { ListSwitchComponent } from './list-switch/list-switch.component';
import { MatchBigSwiperStatsComponent } from './match-big-swiper-stats/match-big-swiper-stats.component';
import { MatchInterimScoreComponent } from './match-interim-score/match-interim-score.component';
import { MatchTeamSwiperStatsComponent } from './match-team-swiper-stats/match-team-swiper-stats.component';
import { BetaFlagComponent } from './modules/account-settings/components/beta-flag/beta-flag.component';
import { AppSettingsViewComponent } from './modules/account-settings/pages/app-settings/app-settings';
import { SettingsMenuViewComponent } from './modules/account-settings/pages/settings-menu/settings-menu';
import { OnPageAuthComponent } from './modules/authentication/components/on-page-auth/on-page-auth.component';
import { RedeemCouponTypeComponent } from './modules/coupon/components/redeem-coupon-type/redeem-coupon-type.component';
import { GamesLocalAddPlayerComponent } from './modules/games/components/games-local-add-player/games-local-add-player.component';
import { GamesOnlineEditJoinRequirementsComponent } from './modules/games/components/games-online-edit-join-requirements/games-online-edit-join-requirements.component';
import { GamesOnlinePageLayoutComponent } from './modules/games/components/games-online-page-layout/games-online-page-layout.component';
import { GamesTrialBannerComponent } from './modules/games/components/games-trial-banner/games-trial-banner.component';
import { ActivateSmartDevicesComponent } from './modules/smart-devices/components/activate-smart-devices/activate-smart-devices.component';
import { TournamentCardComponent } from './modules/tournaments/components/tournament-card/tournament-card.component';
import { TournamentParticipatingStatusComponent } from './modules/tournaments/components/tournament-participating-status/tournament-participating-status.component';
import { NewGameAddTeamComponent } from './new-game-add-team/new-game-add-team.component';
import { NewHotkeysKeyboardComponent } from './new-hotkeys-keyboard/new-hotkeys-keyboard.component';
import { NewNumbersKeyboardComponent } from './new-numbers-keyboard/new-numbers-keyboard.component';
import { NewSingleDartKeyboardComponent } from './new-single-dart-keyboard/new-single-dart-keyboard.component';
import { NgxTranslateDataComponent } from './ngx-translate-data/ngx-translate-data.component';
import { NotificationsIconComponent } from './notifications-icon/notifications-icon.component';
import { OmniKeyboardComponent } from './omni-keyboard/omni-keyboard.component';
import { OmniNotSupportedComponent } from './omni-not-supported/omni-not-supported.component';
import { OnlinePlayerStatsComponent } from './online-player-stats/online-player-stats.component';
import { OptionButtonComponent } from './option-button/option-button.component';
import { PrimaryButtonComponent } from './primary-button/primary-button.component';
import { EchoService } from './services/echo.service';
import { CheckboxNewComponent } from './shared/components/checkbox-new/checkbox-new.component';
import { DialogViewComponent } from './shared/components/dialog/dialog-view/dialog-view.component';
import { IconComponent } from './shared/components/icon/icon.component';
import { ValidationLabelComponent } from './shared/components/validation-label/validation-label.component';
import { SmartDeviceItemComponent } from './smart-device-item/smart-device-item.component';
import { ToggleSwitchComponent } from './toggle-switch/toggle-switch.component';
import { UnfinishedLocalGamesDropdownComponent } from './unfinished-local-games-dropdown/unfinished-local-games-dropdown.component';
import { UnfinishedOnlineGamesDropdownComponent } from './unfinished-online-games-dropdown/unfinished-online-games-dropdown.component';
import { VoiceBarsComponent } from './voice-bars/voice-bars.component';

export class TranslateFallbackHttpLoader implements TranslateLoader {
    private static readonly WAIT_FOR_SERVER = 1500;

    constructor(
        private http: HttpClient,
        public prefix: string,
        public fallbackPrefix: string = '/assets/i18n/',
        public fallbackSuffix: string = '.xlf'
    ) {}

    public getTranslation(lang: string): Observable<Record<string, unknown>> {
        const headers = {
            'Cache-Control': 'no-cache, no-store, must-revalidate, post- check=0, pre-check=0',
        };

        return this.http
            .get(`${this.prefix}${lang}`, { responseType: 'text', headers })
            .pipe(
                switchMap((translation) => from(xliff.xliff12ToJs(translation))),
                map((json) => toTranslationFormat(json))
            )
            .pipe(
                timeout(TranslateFallbackHttpLoader.WAIT_FOR_SERVER),
                catchError((err) => {
                    console.log(`Translation error: ${this.prefix}${lang}`, err);

                    return this.http
                        .get(`${this.fallbackPrefix}${lang}${this.fallbackSuffix}`, {
                            responseType: 'text',
                            headers: headers,
                        })
                        .pipe(
                            switchMap((translation) => from(xliff.xliff12ToJs(translation))),
                            map((json) => toTranslationFormat(json))
                        );
                })
            );
    }
}

function toTranslationFormat(json) {
    let obj = json.resources;

    //Create an object with the resources
    obj = Object.keys(obj).reduce((acc, key) => {
        return obj[key];
    }, {});

    const translations = Object.keys(obj).reduce((acc, key) => {
        let target = '';
        if (Array.isArray(obj[key].target)) {
            obj[key].target.forEach((targetString) => {
                if (targetString.Standalone) {
                    target += '{{' + targetString.Standalone.id + '}}';
                } else {
                    target += targetString;
                }
            });
        } else {
            target = obj[key].target;
        }

        acc[key] = target;
        return acc;
    }, {});

    loadTranslations(translations);

    return translations;
}

export function HttpLoaderFactory(httpClient: HttpClient): TranslateLoader {
    return new TranslateFallbackHttpLoader(httpClient, environment.apiUrl + '/i18n/mobile/');
}

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        OmniActivationDialogComponent,
        ButtonOverflowVisibleDirective,
        DropdownModalHeightDirective,
        ToolbarOverflowVisibleDirective,
        PxHeightDirective,
        MaintainHeightDirective,
        DisableKeyboardResizeDirective,
        ScrollToBottomDirective,
        SetViewportHeightDirective,
        FormatThousandPipe,
        EuroPricePipe,
        UpperCaseFirstPipe,
        SubscriptionStatusPipe,
        TransactionStatusPipe,
        UserFilterPipe,
        CountryFilterPipe,
        PrivateChatFilter,
        MatchPlayersPipe,
        LiveGamesFilterPipe,
        ProfilePopoverComponent,
        HotkeyKeyboardComponent,
        OnlineSavedGameplayComponent,
        LocalGamesComponent,
        MatchNewGameComponent,
        CricketTacticsNewGameComponent,
        Bobs27NewGameComponent,
        SinglesTrainingNewGameComponent,
        DoublesTrainingNewGameComponent,
        ScoreTrainingNewGameComponent,
        AroundTheClockNewGameComponent,
        ShanghaiNewGameComponent,
        OnlineSetupComponent,
        MatchOnlineSetupComponent,
        CricketTacticsOnlineSetupComponent,
        DashboardComponent,
        SearchUsersComponent,
        StatisticsComponent,
        MatchCardComponent,
        SinglesTrainingCardComponent,
        DoublesTrainingCardComponent,
        ScoreTrainingCardComponent,
        Bobs27CardComponent,
        CricketTacticsCardComponent,
        AroundTheClockCardComponent,
        ShanghaiCardComponent,
        Checkout121CardComponent,
        MatchTableComponent,
        CricketTacticsTableComponent,
        Bobs27TableComponent,
        SinglesTrainingTableComponent,
        doublesTrainingTableComponent,
        ScoreTrainingTableComponent,
        Checkout121TableComponent,
        ShanghaiTableComponent,
        AroundTheClockTableComponent,
        UnfinishedGamesComponent,
        MatchListComponent,
        CricketTacticsListComponent,
        Bobs27ListComponent,
        SinglesTrainingListComponent,
        ShanghaiListComponent,
        AroundTheClockListComponent,
        DoublesTrainingListComponent,
        ScoreTrainingListComponent,
        Checkout121ListComponent,
        MatchDetailsComponent,
        CricketTacticsDetailsComponent,
        StatDetailsListComponent,
        ProfileViewComponent,
        AcceptDenyJoinGameRequestDialogComponent,
        AcceptDenyGameInviteRequestDialogComponent,
        ActiveGameDialogComponent,
        InviteGameRequestDialogComponent,
        GlobalMatchInfoComponent,
        EditPasswordComponent,
        ContactComponent,
        EditEmailComponent,
        BlockedPlayersComponent,
        SinglesTrainingDetailsComponent,
        AroundTheClockDetailsComponent,
        ShanghaiDetailsComponent,
        DoublesTrainingDetailsComponent,
        Bobs27DetailsComponent,
        ScoreTrainingDetailsComponent,
        Checkout121DetailsComponent,
        SearchPlayerDialogComponent,
        SearchFriendDialogComponent,
        CricketTacticsTurnDialogComponent,
        GameLabelsDialogComponent,
        ManageGameLabelDialogComponent,
        ManageGameLabelCategoryDialogComponent,
        PickGameLabelsDialogComponent,
        AssignGameLabelsDialogComponent,
        LinkSocialsDialogComponent,
        SpeechToScoreInfoDialogComponent,
        LogViewerDialogComponent,
        LinkSoundDialogComponent,
        ThrowoutDialogComponent,
        DartboardDialogComponent,
        ReportUserDialogComponent,
        CompleteAccountDialogComponent,

        ChangeLanguageDialogComponent,
        HeadToHeadDialogOneComponent,
        LoginRequiredDialogComponent,
        LoginOrCreateDialogComponent,
        OwnAccountWarningDialogComponent,
        UltimateSubscriptionDialogComponent,
        SocialShareDialogComponent,
        NewOnlineFeatureDialogComponent,
        FeatureSlidesDialogComponent,
        ConnectComponent,
        MaintenanceComponent,
        BannedComponent,
        DevelopmentComponent,
        CTAModuleDialogComponent,
        AllFeaturesDialogComponent,
        CancelSubscriptionDialogComponent,
        SendUpgradeReasonsDialogComponent,
        CameraPreviewDialogComponent,
        LiveCameraPreviewDialogComponent,
        InGameCommsDialogComponent,
        HighlightsDialogComponent,
        VoiceCallDialogComponent,
        YourVirtCamViewDialogComponent,
        TutorialDialogComponent,
        TermsAndNewsletterDialogComponent,
        ThrowForBullDialogComponent,
        VSDartbotDialogComponent,
        CustomScoreDialogComponent,
        GoalAmountDialogComponent,
        RequireTripleDialogComponent,
        OmniSettingsDialogComponent,
        RenameDialogComponent,
        DatePickerDialogComponent,
        SuddenDeathDialogComponent,
        AmountOfTurnsDialogComponent,
        QuitDialogComponent,
        QuitOnlyDialogComponent,
        ProvidePasswordDialogComponent,
        UnfinishedLocalDialogComponent,
        UpgradeSalesFunnelDialogComponent,
        SmartDeviceNetworkPopoverComponent,
        SmartDeviceInvitePopoverComponent,
        PrivateChatsComponent,
        PrivateChatConversationComponent,
        EditOmniScoreDialogComponent,
        HeatmapDialogComponent,
        ShutdownPopoverComponent,
        AddSmartDeviceDialogComponent,
        SwitchSalesFunnelDialogComponent,
        AppAlertsComponent,
        IngameMenuComponent,
        UnfinishedLocalGamesDropdownComponent,
        UnfinishedOnlineGamesDropdownComponent,
        NewGameAddTeamComponent,
        SettingsMenuViewComponent,
        AppSettingsViewComponent,
        FriendLobbyComponent,
        SpectatorsDialogComponent,
        CountriesDialogComponent,
        ProfileStatsDialogComponent,
        ProfileAmountsDialogComponent,
        MyFriendsComponent,
        BirthdateDialogComponent,
        EditUsernameDialogComponent,
        CropProfilePhotoDialogComponent,
        EnterEmailDialogComponent,
        ResetAccountDialogComponent,
        AddBreakDialogComponent,
        CTAModuleDialogComponent,
        HotkeysDialogComponent,
        EditScoresDialogComponent,
        ReinviteDialogComponent,
        OpponentNotRespondingDialogComponent,
        PlayerOptionsModalComponent,
        OnlineGamesComponent,
        GlobalLobbyComponent,
        OpponentQuitDialogComponent,
        LeaveAfterReportDialogComponent,
        SaveOnlineGameDialogComponent,
        CameraOptionsPopoverComponent,
        ActionReplaysComponent,
        ActionReplayViewComponent,
        ClipPopoverComponent,
        RatePlayerDialogComponent,
        RematchInviteDialogComponent,
        PromptDialogComponent,
        SubscriptionsComponent,
        EventOverlayComponent,
        IngameAnimationComponent,
        AppComponent,
        NgxTranslateDataComponent,
    ],
    imports: [
        InfiniteScrollModule,
        ForceChangePasswordDialogComponent,
        GuestTransitionDialogComponent,
        BottomNavigationComponent,
        GamesLocalAddPlayerComponent,
        SwipeDirective,
        SocialLoginModule,
        WebContainerDirective,
        SmallWebContainerDirective,
        LargeWebContainerDirective,
        WebKeyboardContainerDirective,
        FullscreenIconComponent,
        NotificationsIconComponent,
        PageHeaderComponent,
        IconComponent,
        TournamentParticipatingStatusComponent,
        DialogViewComponent,
        ValidationLabelComponent,
        GamesOnlinePageLayoutComponent,
        PrimaryButtonComponent,
        BetaFlagComponent,
        ControlCameraIconComponent,
        OnlinePlayerStatsComponent,
        AccordionComponent,
        ProfilePhotoComponent,
        ActivateSmartDevicesComponent,
        RedeemCouponTypeComponent,
        KeyboardDropdownComponent,
        MatchTeamSwiperStatsComponent,
        MatchBigSwiperStatsComponent,
        MatchInterimScoreComponent,
        ListSwitchComponent,
        SmartDeviceItemComponent,
        OnPageAuthComponent,
        KeyboardDartComponent,
        TournamentMatchTableComponent,
        NewHotkeysKeyboardComponent,
        NewNumbersKeyboardComponent,
        NewSingleDartKeyboardComponent,
        OmniKeyboardComponent,
        OmniThrowsComponent,
        OmniDartBoardComponent,
        OmniHeatmapComponent,
        ZoomedPointComponent,
        CheckboxComponent,
        AppSelectComponent,
        ToggleSwitchComponent,
        OptionButtonComponent,
        OmniNotSupportedComponent,
        DecimalRoundPipe,
        SafeHousePipe,
        ChatDatePipe,
        BrowserAnimationsModule,
        SpeechBarsComponent,
        EntryModePipe,
        AppLoaderComponent,
        FinishModePipe,
        TextFieldModule,
        PickerModule,
        ImageCropperModule,
        FormsModule,
        ReactiveFormsModule,
        RecaptchaV3Module,
        NgxSkeletonLoaderModule,
        AppHeadToHeadComponent,
        DragulaModule.forRoot(),
        TooltipModule.forRoot({
            placement: 'bottom',
            autoPlacement: true,
            trigger: 'click',
            'max-width': '250px',
            hideDelayAfterClick: 3000,
        }),
        SweetAlert2Module.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        BrowserModule,
        IonicModule.forRoot(),
        HttpClientModule,
        AppRoutingModule,
        JoyrideModule.forRoot(),
        ApiServicesModule,
        GameLogicModule,
        CheckboxNewComponent,
        //Firebase
        DartCounterFireStoreModule, // Core Module
        DartCounterCoreServiceModule, // Core Modules
        DartCounterCoreLoggingModule, // Core Modules
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideAuth(() => {
            if (Capacitor.isNativePlatform()) {
                return initializeAuth(getApp(), {
                    persistence: indexedDBLocalPersistence,
                });
            } else {
                return getAuth();
            }
        }),
        provideFunctions(() => getFunctions()),
        provideFirestore(() => initializeFirestore(getApp(), { ignoreUndefinedProperties: true })),
        VoiceBarsComponent,
        TournamentCardComponent,
        GamesTrialBannerComponent,
        GamesOnlineEditJoinRequirementsComponent,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            multi: true,
            useFactory: (appFeaturesService: AppFeaturesService) => () => {
                return appFeaturesService.registerEnabledAppFeatures();
            },
            deps: [AppFeaturesService],
        },
        {
            provide: APP_INITIALIZER,
            multi: true,
            useFactory: (betaFlagsService: BetaFlagsService) => () => {
                return betaFlagsService.registerEnabledBetaFlags();
            },
            deps: [BetaFlagsService],
        },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                lang: 'en',
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(socials.google.clientId, {
                            scopes: ['profile', 'email'],
                        }),
                    },
                ],
                onError: (err) => {
                    console.error(err);
                },
            } as SocialAuthServiceConfig,
        },
        HTTP,
        DartCounterAnalyticsService,
        DartCounterLocalizeService,
        SocialSharing,
        CanDeactivateGuard,
        AuthGuard,
        OnlineRestrictionGuard,
        ProfileEditingRestrictionGuard,
        NoRedirectAuthGuard,
        NoAuthGuard,
        DowntimeGuard,
        NoDowntimeGuard,
        NotBannedGuard,
        DevelopmentGuard,
        FireStoreService,
        DCUpgradeService,
        Instagram,
        ConverterProvider,
        IngameMenuComponent,
        OneSignal,
        CameraPreview,
        AuthService,
        AfterGameService,
        PopoverService,
        LoggingService,
        MediaWatcherService,
        RouterEventsService,
        SmartDeviceService,
        FunctionService,
        Events,
        DartCounterAudioService,
        DartCounterAlertService,
        DartCounterPreferenceService,
        DCLoadingService,
        PurchaseService,
        SocialMediaService,
        ScreenOrientation,
        BackButtonService,
        InAppBrowser,
        UDPService,
        NativeAudioService,
        LocalCameraService,
        ProfilePhotoService,
        VirtServerService,
        EventOverlayService,
        IngameAnimationService,
        CookieConsentService,
        EchoService,
        TournamentService,
        NotificationsService,
        ConfettiService,
        File,
        Media,
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
