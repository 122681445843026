<div class="new-game-card !py-3">
    <div class="title-container">
        <div class="icon">
            <app-icon icon="check_mark" size="small"></app-icon>
        </div>
        <div class="title" [translate]="'JOIN_REQUIREMENTS'" i18n="@@JOIN_REQUIREMENTS">Join requirements</div>
    </div>

    @if (requirements) {
        <div class="space-y-2 !mt-2">
            <app-toggle-switch
                class="block w-full rounded-md px-3 py-2"
                [class]="authService.user.is_ultimate ? 'bg-neutral-50 dark:bg-neutral-900' : 'bg-blue'"
                [model]="requirements.ultimateOnly"
                (itemChange)="toggleUltimateOnly()">
                <div class="flex justify-between items-center space-x-1">
                    <div
                        [ngClass]="{ '!text-white': !authService.user.is_ultimate }"
                        [translate]="'ULTIMATE'"
                        i18n="@@ULTIMATE">
                        Ultimate
                    </div>
                    <div class="flex items-center">
                        <app-icon class="scale-[0.80]" icon="premium" size="small"></app-icon>
                    </div>
                </div>
            </app-toggle-switch>

            <app-toggle-switch
                class="block w-full rounded-md bg-neutral-50 dark:bg-neutral-900 px-3 py-2"
                [model]="requirements.cameraOnly"
                (itemChange)="toggleCameraOnly()">
                <div class="flex justify-between items-center space-x-1">
                    <div [translate]="'CAMERA'" i18n="@@CAMERA">Camera</div>
                    <div class="flex items-center">
                        <app-icon class="px-0.5" icon="videocam" size="small"></app-icon>
                    </div>
                </div>
            </app-toggle-switch>

            <app-toggle-switch
                class="block w-full rounded-md bg-neutral-50 dark:bg-neutral-900 px-3 py-2"
                [model]="requirements.omniScoringOnly"
                (itemChange)="toggleOmniScoringOnly()">
                <div class="flex justify-between items-center space-x-1">
                    <div>Omni Scoring</div>
                    <div class="flex items-center">
                        <img class="w-12 px-0.5" [src]="'assets/icon/ftr/omni_scoring_logo.webp'" />
                    </div>
                </div>
            </app-toggle-switch>

            <app-toggle-switch
                class="block w-full rounded-md bg-neutral-50 dark:bg-neutral-900 px-3 py-2"
                [model]="requirements.cameraOrOmniScoringOnly"
                (itemChange)="toggleCameraOrOmniScoringOnly()">
                <div class="flex justify-between items-center space-x-1">
                    <div [translate]="'CAM_OR_OMNI'" i18n="@@CAM_OR_OMNI">Cam or Omni</div>

                    <div class="flex items-center">
                        <app-icon class="px-0.5" icon="videocam" size="small"></app-icon>
                        <div class="text-xxxs font-bold uppercase leading-none mr-0.5" [translate]="'OR'" i18n="@@OR">
                            Or
                        </div>
                        <img class="w-12 px-0.5" [src]="'assets/icon/ftr/omni_scoring_logo.webp'" />
                    </div>
                </div>
            </app-toggle-switch>
        </div>
    }
</div>
