@if (false) {
    <ng-container i18n="@@501_AVG">501 avg.</ng-container>
    <ng-container i18n="@@TUT_501AVERAGE">The 501 average here, is the average over the last 2 months </ng-container>
}

<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CANCEL'" i18n="@@CANCEL" (click)="dismiss()">Cancel</div>
        </ion-buttons>
        <div class="slide-modal-title" [translate]="'PAGE_PROFILE'" i18n="@@PAGE_PROFILE">Profile</div>
    </div>

    <div class="flex flex-1 flex-col bg-white overflow-y-auto px-4 gap-4 pb-safe-offset-4">
        @if (user) {
            <div class="flex flex-col">
                <div class="flex flex-col bg-neutral-50 rounded-md p-5">
                    <div class="flex justify-between items-center overflow-hidden space-x-2">
                        <div class="flex items-center text-black overflow-hidden">
                            <div class="relative">
                                <app-profile-photo
                                    class="h-fit"
                                    [ultimate]="user.is_ultimate"
                                    [badge]="user.profile?.badge"
                                    [url]="user.profile?.profile_photo_url"
                                    [size]="32">
                                </app-profile-photo>
                            </div>
                            <div class="space-y-0.5 overflow-hidden ml-1">
                                <div class="flex items-center space-x-1">
                                    <div class="ucfirst truncate text-base font-bold leading-none text-black">
                                        {{ user.first_name || ('NO_NAME' | translate) }}
                                    </div>
                                    @if (user.countryFlag) {
                                        <img class="h-3" src="assets/images/flags/rect/{{ user.countryFlag }}" />
                                    }
                                </div>
                                <div class="flex items-center">
                                    <app-online-player-stats
                                        [player]="user"
                                        [reviewCount]="user.times_rated"
                                        size="base"></app-online-player-stats>
                                </div>
                            </div>
                        </div>
                        <div
                            joyrideStep="firstStep"
                            (click)="showTour(0)"
                            title="{{ '501_AVG' | translate }}"
                            text="{{ 'TUT_501AVERAGE' | translate }}"
                            class="font-bebas-neue text-black text-base text-center whitespace-nowrap">
                            @if (doneLoading) {
                                <div>{{ '501_AVG' | translate }}</div>
                                <div>{{ user.two_month_average || 0 | number: '1.2-2' }}</div>
                            } @else {
                                <ngx-skeleton-loader
                                    [theme]="skeletonThemes.infosmall2"
                                    animation="progress-dark"
                                    *ngIf="!doneLoading"></ngx-skeleton-loader>
                            }
                        </div>
                    </div>
                    <div class="space-y-4 mt-3">
                        @if (user.id !== authService.user.id && doneLoading) {
                            <div class="flex gap-4">
                                <div class="basis-1/2">
                                    @if (
                                        !user.is_self &&
                                        !user.is_blocked &&
                                        (user.friend_status === 'no_friends' || user.friend_status === null)
                                    ) {
                                        <app-primary-button
                                            addedClasses="w-full"
                                            size="extrasmall"
                                            color="green"
                                            (click)="addFriend(user.id)"
                                            [translate]="'ADD_FRIEND'"
                                            i18n="@@ADD_FRIEND">
                                            Add friend
                                        </app-primary-button>
                                    } @else if (
                                        !user.is_self && !user.is_blocked && user.friend_status === 'incoming'
                                    ) {
                                        <app-primary-button
                                            addedClasses="w-full"
                                            size="extrasmall"
                                            color="green"
                                            (click)="acceptFriend(user.id)"
                                            [translate]="'ACCEPT_REQUEST'"
                                            i18n="@@ACCEPT_REQUEST">
                                            Accept
                                        </app-primary-button>
                                    } @else if (!user.is_self && !user.is_blocked && user.friend_status === 'pending') {
                                        <app-primary-button
                                            addedClasses="w-full"
                                            size="extrasmall"
                                            color="black"
                                            (click)="showPendingOptions(user.id)"
                                            [translate]="'PENDING'"
                                            i18n="@@PENDING">
                                            Pending
                                        </app-primary-button>
                                    } @else if (!user.is_self && !user.is_blocked && user.friend_status === 'friends') {
                                        <app-primary-button
                                            addedClasses="w-full"
                                            size="extrasmall"
                                            color="white"
                                            (click)="showFriendOptions(user.id)"
                                            [translate]="'FRIENDS'"
                                            i18n="@@FRIENDS">
                                            Friends
                                        </app-primary-button>
                                    }
                                </div>
                                <div class="basis-1/2">
                                    @if (canReport) {
                                        <app-primary-button
                                            addedClasses="w-full"
                                            size="extrasmall"
                                            color="red"
                                            (click)="report()"
                                            [translate]="'REPORT_PLAYER'"
                                            i18n="@@REPORT_PLAYER">
                                            Report player
                                        </app-primary-button>
                                    }
                                </div>
                            </div>

                            @if (canDisqualify) {
                                <app-primary-button
                                    class="block basis-full"
                                    addedClasses="w-full"
                                    size="extrasmall"
                                    color="red"
                                    fill="outline"
                                    (click)="disqualify()"
                                    [translate]="'DISQUALIFY'"
                                    i18n="@@DISQUALIFY">
                                    Disqualify
                                </app-primary-button>
                            }
                        }
                    </div>
                </div>
            </div>
            <div class="flex flex-col bg-neutral-50 rounded-md p-5">
                <div
                    class="font-bebas-neue text-black text-2xl"
                    [translate]="'LAST_FIVE_GAMES'"
                    i18n="@@LAST_FIVE_GAMES">
                    Last results
                </div>
                @if (!doneLoading) {
                    <ngx-skeleton-loader [theme]="skeletonThemes.info3" animation="progress-dark"></ngx-skeleton-loader>
                } @else {
                    @if (lastResults.length > 0) {
                        <div>
                            @for (result of lastResults; track result) {
                                <div
                                    class="w-5 h-5 rounded-full mr-1 inline-block"
                                    [ngClass]="{
                                        'bg-red': result === 'lost',
                                        'bg-green': result === 'won',
                                        'bg-blue': result === 'tie'
                                    }"></div>
                            }
                        </div>
                    } @else {
                        <div [translate]="'NO_ONLINE_MATCHES'" i18n="@@NO_ONLINE_MATCHES">No online matches played</div>
                    }
                }
            </div>
            <div class="flex gap-4">
                <div class="flex flex-col bg-neutral-50 rounded-md p-5 w-full text-black">
                    <div class="font-bebas-neue text-black text-2xl" [translate]="'DARTS_THROWN'" i18n="@@DARTS_THROWN">
                        Darts thrown
                    </div>
                    @if (!doneLoading) {
                        <ngx-skeleton-loader
                            [theme]="skeletonThemes.info2"
                            animation="progress-dark"></ngx-skeleton-loader>
                    } @else {
                        {{ statistics.darts_thrown || 0 }}
                    }
                </div>
                <div class="flex flex-col bg-neutral-50 rounded-md p-5 w-full text-black">
                    <div class="font-bebas-neue text-black text-2xl" [translate]="'MATCHES'" i18n="@@MATCHES">
                        Matches
                    </div>
                    @if (!doneLoading) {
                        <ngx-skeleton-loader
                            [theme]="skeletonThemes.info2"
                            animation="progress-dark"></ngx-skeleton-loader>
                    } @else {
                        {{ statistics.matches_played || 0 }}
                    }
                </div>
            </div>
            <div class="flex flex-col bg-neutral-50 rounded-md p-5 w-full text-black">
                <div
                    class="font-bebas-neue text-black text-2xl"
                    [translate]="'HIGHEST_FINISHES'"
                    i18n="@@HIGHEST_FINISHES">
                    Highest finishes
                </div>
                @if (!doneLoading) {
                    <ngx-skeleton-loader [theme]="skeletonThemes.info2" animation="progress-dark"></ngx-skeleton-loader>
                } @else {
                    {{ statistics.highest_checkouts?.join(',') }}
                }
            </div>
        } @else {
            <div class="flex pt-3">
                <app-icon icon="loading" size="normal" class="mx-auto animate-spin text-black"></app-icon>
            </div>
        }

        @if (showMoreButton) {
            <app-primary-button
                addedClasses="w-full"
                size="medium"
                color="orange"
                (click)="openProfile()"
                [translate]="'MORE_INFO'"
                i18n="@@MORE_INFO">
                More
            </app-primary-button>
        }
    </div>
</div>
