/* eslint-disable max-len */
import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthApiService } from '@dc-api/auth.api.service';
import { UserApiService } from '@dc-api/user.api.service';
import { User } from '@dc-core/dc-backend/dc-classes';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DCLoadingService } from '@providers/DCLoadingService';
import { AuthService } from '@services/auth.service';
import { round } from 'lodash';
import moment from 'moment';
import { ApiResponse } from 'openapi-typescript-fetch';
import { PrivacyPolicyDialogComponent } from 'src/app/modules/app-settings/dialogs/privacy-policy/privacy-policy.dialog';
import { PrimaryButtonComponent } from 'src/app/primary-button/primary-button.component';
import { DatePickerDialogComponent } from 'src/dialogs/date-picker/date-picker.dialog';
import { environment } from 'src/environments/environment';

import { IconComponent } from '../../../../shared/components/icon/icon.component';

@Component({
    selector: 'app-user-birthday-email-dialog',
    standalone: true,
    templateUrl: './user-birthday-email-dialog.component.html',
    imports: [
        CommonModule,
        PrimaryButtonComponent,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        IconComponent,
        IonicModule,
    ],
})
export class UserBirthdayEmailDialogComponent implements OnInit {
    public translateService: TranslateService = inject(TranslateService);

    private modalController: ModalController = inject(ModalController);
    private userApiService: UserApiService = inject(UserApiService);
    private authApiService: AuthApiService = inject(AuthApiService);
    private authService: AuthService = inject(AuthService);
    private loadingService: DCLoadingService = inject(DCLoadingService);
    private alertService: DartCounterAlertService = inject(DartCounterAlertService);

    public form: FormGroup;
    public validationForm: FormGroup = new FormGroup({
        code: new FormControl('', [Validators.required]),
    });
    public emailVerified: boolean = false;
    public existingEmail: string;
    public hasInitialEmail: boolean = false;
    public showVerify: boolean = false;
    public invalidCode: boolean = false;

    private sendedVerifyAt: Date = null;

    constructor(public modal: ModalController) {}

    public ngOnInit(): void {
        const user = this.authService.user;
        this.hasInitialEmail = !!user.email;

        this.initForm(user);
    }

    public changeBirthdate(): void {
        this.modalController
            .create({
                component: DatePickerDialogComponent,
                cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                componentProps: {
                    title: $localize`:@@DATE_OF_BIRTH:Date of birth`,
                    date: this.form.get('birthdate').value,
                    maxDate: moment().format('YYYY-MM-DD'),
                },
                showBackdrop: true,
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.form.get('birthdate').patchValue(dialogRes.data);
                    }
                });
            });
    }

    public save(): void {
        this.loadingService.ShowDefaultLoader();

        const birthdate = this.form.get('birthdate')?.value;
        const email = this.form.get('email')?.value;

        const birthdateReq = birthdate ? this.updateBirthdate(birthdate) : Promise.resolve();
        const emailReq = email ? this.updateEmail(email) : Promise.resolve();

        Promise.all([birthdateReq, emailReq])
            .then((response) => {
                this.loadingService.DismissLoader();
                if (response[0]) {
                    this.authService.user.profile = {
                        ...this.authService.user.profile,
                        birthdate: (response[0] as ApiResponse<User>)?.data.profile?.birthdate,
                    };
                }

                if (response[1]) {
                    this.authService.user = {
                        ...this.authService.user,
                        email: email,
                    };

                    this.showVerify = true;
                } else {
                    this.modalController.dismiss(true);
                }
            })
            .catch((err) => {
                const error = err.getActualType();
                if (error.status === 422) {
                    this.existingEmail = email;
                } else {
                    this.alertService.errorFromApi(err);
                }

                this.loadingService.DismissLoader();
            });
    }

    public tryEmailVerification(): void {
        this.loadingService.ShowDefaultLoader();

        this.verifyEmail(this.validationForm.get('code').value)
            .then(() => {
                this.loadingService.DismissLoader();

                this.authService.user = {
                    ...this.authService.user,
                    email_verified_at: moment().toISOString(),
                };

                this.modalController.dismiss(true);
            })
            .catch((err) => {
                const error = err.getActualType();
                if (error.status === 422) this.invalidCode = true;

                this.loadingService.DismissLoader();
            });
    }

    public sendEmailVerification(): void {
        let secondsWaited: number = null;
        if (this.sendedVerifyAt !== null) {
            secondsWaited = Math.abs((new Date().getTime() - this.sendedVerifyAt.getTime()) / 1000);
        }

        if (secondsWaited === null || secondsWaited >= 60) {
            this.authApiService.sendVerificationEmail({ type: 'code' }).then(() => {
                this.alertService.createAlert({
                    title: $localize`:@@VERIFICATION_EMAIL_HAS_BEEN_SENT:A verification email has been sent, please check your inbox and spam folder to ensure you receive it`,
                    icon: 'info',
                });

                this.sendedVerifyAt = new Date();

                this.showVerify = true;
            });
        } else {
            const waitForSeconds = round(60 - secondsWaited, 0);
            $localize`:@@WAIT_FOR_SECONDS_TO_TRY_AGAIN:Please wait for ${waitForSeconds}:SECONDS: seconds to try again!`;
            this.alertService.createAlert({
                title: this.translateService.instant('WAIT_FOR_SECONDS_TO_TRY_AGAIN', { SECONDS: waitForSeconds }),
                icon: 'error',
            });
        }
    }

    public openPrivacyPolicy(): void {
        this.modalController.create({ component: PrivacyPolicyDialogComponent }).then((elem) => elem.present());
    }

    private updateBirthdate(birthdate: string): Promise<ApiResponse<User>> {
        return this.userApiService.updateUser({
            profile: {
                birthdate: birthdate,
            },
        });
    }

    private updateEmail(email: string): Promise<ApiResponse<{ message?: string }>> {
        return this.authApiService.changeEmail({
            email: email,
            type: 'code',
        });
    }

    private verifyEmail(code: string): Promise<ApiResponse<{ message?: string }>> {
        return this.authApiService.verifyEmailByCode({
            code: parseInt(code),
        });
    }

    private initForm(user: User): void {
        const birthdate = user.profile?.birthdate ? moment(user.profile.birthdate).utc().toISOString() : null;

        this.emailVerified = !!user.email_verified_at;

        this.form = new FormGroup({});

        if (!this.emailVerified) {
            this.form.addControl('email', new FormControl(user.email, [Validators.required, Validators.email]));
        }
        if (!birthdate) {
            this.form.addControl('birthdate', new FormControl('', [Validators.required]));
        }
    }

    dismiss() {
        this.modal.dismiss();
    }
}
