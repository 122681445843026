import { CommonModule } from '@angular/common';
import { Component, input, InputSignal, OnChanges, OnDestroy, OnInit, output, OutputEmitterRef } from '@angular/core';
import { TournamentHelper } from '@dc-core/dc-helpers/tournament.helper';

export type TournamentButtonType =
    | 'view'
    | 'manage'
    | 'join_in'
    | 'join'
    | 'full'
    | 'waiting_for_approval'
    | 'waiting_for_check_in'
    | 'check_in'
    | 'checked_in'
    | 'starts_in'
    | 'go_to_tournament'
    | 'join_tournament'
    | 'tournament_is_full'
    | 'begin_approval'
    | 'check_in_for_tournament'
    | 'check_out_from_tournament'
    | 'withdraw_from_tournament';

@Component({
    selector: 'app-tournament-button',
    templateUrl: 'tournament-button.component.html',
    standalone: true,
    imports: [CommonModule],
})
export class TournamentButtonComponent implements OnInit, OnChanges, OnDestroy {
    public type: InputSignal<TournamentButtonType> = input.required();
    public untilDate: InputSignal<string> = input();
    public disabled: InputSignal<boolean> = input();
    public forceBig: InputSignal<boolean> = input(false);

    public changeButton: OutputEmitterRef<void> = output<void>();

    public text: string;
    public color: 'orange' | 'green' | 'blue' | 'yellow' | 'red';
    public fill: 'full' | 'outline';
    public clickable: boolean;
    public big: boolean = false;
    public hidden: boolean = false;

    public classes: string = null;
    public timeUntil: string = null;
    public hasTimer: boolean;

    public ngOnInit(): void {
        this.setButton();
    }

    public ngOnChanges(): void {
        this.setButton();
    }

    public ngOnDestroy(): void {
        this.hasTimer = false;
    }

    private setButton(): void {
        if (this.untilDate()) {
            this.hasTimer = true;
            this.setTimer();
        } else {
            this.hasTimer = false;
        }

        this.hidden = this.disabled();

        switch (this.type()) {
            case 'view':
            case 'manage':
                this.text = this.type() === 'view' ? $localize`:@@VIEW:View` : $localize`:@@MANAGE:Manage`;
                this.color = 'orange';
                this.fill = 'outline';
                this.clickable = true;
                this.big = false;
                break;
            case 'join_in':
                this.text = $localize`:@@JOIN_IN:Join in`;
                this.color = 'blue';
                this.fill = 'outline';
                this.clickable = false;
                this.big = false;
                break;
            case 'join':
                this.text = $localize`:@@JOIN:Join`;
                this.color = 'blue';
                this.fill = 'full';
                this.clickable = true;
                this.big = false;
                break;
            case 'full':
                this.text = $localize`:@@FULL:Full`;
                this.color = 'blue';
                this.fill = 'full';
                this.clickable = false;
                this.big = false;
                break;
            case 'waiting_for_approval':
                this.text = $localize`:@@WAITING_FOR_APPROVAL:Waiting for approval`;
                this.color = 'green';
                this.fill = 'outline';
                this.clickable = false;
                this.big = false;
                break;
            case 'waiting_for_check_in':
                this.text = $localize`:@@CHECK_IN_IN:Check-in in`;
                this.color = 'yellow';
                this.fill = 'outline';
                this.clickable = false;
                this.big = false;
                break;
            case 'check_in':
                this.text = $localize`:@@CHECK_IN:Check-in`;
                this.color = 'yellow';
                this.fill = 'full';
                this.clickable = true;
                this.big = false;
                break;
            case 'checked_in':
                this.text = $localize`:@@CHECKED_IN:Checked-in`;
                this.color = 'yellow';
                this.fill = 'full';
                this.clickable = false;
                this.hidden = true;
                this.big = false;
                break;
            case 'starts_in':
                this.text = $localize`:@@STARTS_IN:Starts in`;
                this.color = 'orange';
                this.fill = 'outline';
                this.clickable = false;
                this.big = false;
                break;
            case 'go_to_tournament':
                this.text = $localize`:@@GO_TO_TOURNAMENT:Go to tournament`;
                this.color = 'orange';
                this.fill = 'full';
                this.clickable = true;
                this.big = false;
                break;
            case 'join_tournament':
                this.text = $localize`:@@JOIN_TOURNAMENT:Join tournament`;
                this.color = 'blue';
                this.fill = 'full';
                this.clickable = true;
                this.big = true;
                break;
            case 'tournament_is_full':
                this.text = $localize`:@@TOURNAMENT_IS_FULL:Tournament is full`;
                this.color = 'blue';
                this.fill = 'full';
                this.clickable = false;
                this.big = true;
                break;
            case 'begin_approval':
                this.text = $localize`:@@BEGIN_APPROVAL:Begin approval`;
                this.color = 'orange';
                this.fill = 'full';
                this.clickable = true;
                this.big = true;
                break;
            case 'check_in_for_tournament':
                this.text = $localize`:@@CHECK_IN_FOR_TOURNAMENT:Check-in for tournament`;
                this.color = 'yellow';
                this.fill = 'full';
                this.clickable = true;
                this.big = true;
                break;
            case 'check_out_from_tournament':
                this.text = $localize`:@@CHECK_OUT_FROM_TOURNAMENT:Check-out from tournament`;
                this.color = 'orange';
                this.fill = 'outline';
                this.clickable = true;
                this.big = true;
                break;
            case 'withdraw_from_tournament':
                this.text = $localize`:@@WITHDRAW_FROM_TOURNAMENT:Withdraw from tournament`;
                this.color = 'red';
                this.fill = 'outline';
                this.clickable = true;
                this.big = true;
                break;
        }

        if (this.forceBig()) {
            this.big = true;
        }

        let classes = '';
        if (this.fill === 'full') {
            classes += ' bg-' + this.color + ' !text-white border border-solid border-' + this.color;

            if (this.clickable && !this.hidden) {
                classes += ' hover:bg-' + this.color + '-accent';
            }
        } else {
            classes += ' text-black bg-white border-2 border-solid border-' + this.color;

            if (this.clickable && !this.hidden) {
                classes += ' hover:bg-white-accent';
            }
        }

        if (this.hidden) {
            this.clickable = false;
        }

        this.classes = classes;
    }

    private setTimer(): void {
        if (!this.hasTimer) {
            return;
        }

        if (this.timeUntil === '' || this.timeUntil === '0s') {
            switch (this.type()) {
                case 'join_in':
                case 'waiting_for_check_in':
                    this.changeButton.emit();
                    break;
            }
            this.hasTimer = false;
            return;
        }

        this.timeUntil = this.untilDate() ? TournamentHelper.timerFromDate(this.untilDate(), 'until') : null;

        setTimeout(() => {
            this.setTimer();
        }, 1000);
    }
}
