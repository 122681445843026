import { Component } from '@angular/core';
import { LocalStorageKey } from '@dc-core/dc-localstorage';
import { ModalController, NavController } from '@ionic/angular';
import { ModalOptions } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { ThemeService } from '@services/theme.service';
import { DartCounterPreferenceService } from 'dc-core/dc-services/preference/preference.service';
import { DC_LANGUAGES, GA_EVENTACTIONS, GA_EVENTCATEGORIES } from 'src/app/app.globals';
import { ChangeLanguageDialogComponent } from 'src/app/modules/account-settings/components/change-language/change-language.dialog';
import { ThemePreferenceModalComponent } from 'src/app/modules/account-settings/components/theme-preference/theme-preference-modal.component';
import { PrivacyPolicyDialogComponent } from 'src/app/modules/app-settings/dialogs/privacy-policy/privacy-policy.dialog';
import { TermsOfServiceDialogComponent } from 'src/app/modules/app-settings/dialogs/terms-of-service/terms-of-service.dialog';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/services/auth.service';
import * as CookieConsent from 'vanilla-cookieconsent';

/**
 * Generated class for the Settings page.
 *
 * See http://ionicframework.com/docs/components/#navigation for more info
 * on Ionic pages and navigation.
 */

@Component({
    selector: 'app-page-app-settings',
    templateUrl: 'app-settings.html',
})
export class AppSettingsViewComponent {
    public allowCaller: boolean;
    public languages = [];
    public isWeb = environment.isWeb;

    constructor(
        public nav: NavController,
        public auth: AuthService,
        public preferenceService: DartCounterPreferenceService,
        private ga: DartCounterAnalyticsService,
        public translate: TranslateService,
        public modal: ModalController,
        private themeService: ThemeService
    ) {
        this.languages = DC_LANGUAGES;
    }

    showLanguages() {
        this.modal
            .create({
                component: ChangeLanguageDialogComponent,
                componentProps: {
                    firsttime: false,
                },
                cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
            })
            .then((elem) => elem.present());
    }

    public openTermsOfService(): void {
        this.ga.trackEvent(GA_EVENTCATEGORIES.ULTIMATE, GA_EVENTACTIONS.TERMSOFUSE);
        const modal = this.modal.create({
            component: TermsOfServiceDialogComponent,
        } as ModalOptions);
        modal.then((elem) => elem.present());
    }

    public openPrivacyPolicy(): void {
        this.ga.trackEvent(GA_EVENTCATEGORIES.ULTIMATE, GA_EVENTACTIONS.PRIVACYPOLICY);
        const modal = this.modal.create({
            component: PrivacyPolicyDialogComponent,
        } as ModalOptions);
        modal.then((elem) => elem.present());
    }

    changeTheme(): void {
        const currentTheme = localStorage.getItem(LocalStorageKey.theme);

        this.modal
            .create({
                component: ThemePreferenceModalComponent,
                cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then(() => {
                    if (
                        environment.production &&
                        this.themeService.selectedTheme() &&
                        currentTheme !== this.themeService.selectedTheme()
                    ) {
                        this.ga.trackEvent(
                            GA_EVENTCATEGORIES.SETTINGS,
                            GA_EVENTACTIONS.CHANGETHEME,
                            this.themeService.selectedTheme()
                        );
                    }
                });
            });
    }

    public openCookieConsent(): void {
        CookieConsent.show(true);
    }
}
