import { CommonModule } from '@angular/common';
import { Component, inject, input, InputSignal, OnInit } from '@angular/core';
import { Tournament } from '@dc-core/dc-backend/dc-classes';
import { OnlineGameplay } from '@dc-core/dc-backend/dc-interfaces';
import { OnlineFunctions } from '@dc-core/dc-gamelogic/online-game/online.functions';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { IconComponent } from '../../../../shared/components/icon/icon.component';

@Component({
    selector: 'app-tournament-details',
    templateUrl: 'tournament-details.component.html',
    standalone: true,
    imports: [IconComponent, CommonModule, TranslateModule],
})
export class TournamentDetailsComponent implements OnInit {
    public tournament: InputSignal<Tournament> = input.required();
    public withGameplayName: InputSignal<boolean> = input.required();

    public translateService: TranslateService = inject(TranslateService);

    private onlineFunctions: OnlineFunctions = inject(OnlineFunctions);

    public gameplayName: string;

    public ngOnInit(): void {
        if (this.withGameplayName()) {
            this.gameplayName = this.onlineFunctions.onlineGameplayName(
                this.tournament().game_details[0] as OnlineGameplay
            );
        }
    }
}
