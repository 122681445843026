import { CommonModule } from '@angular/common';
import { Component, computed, input, InputSignal, Signal } from '@angular/core';

@Component({
    selector: 'app-loader',
    templateUrl: './app-loader.component.html',
    styleUrls: ['./app-loader.component.scss'],
    standalone: true,
    imports: [CommonModule],
})
export class AppLoaderComponent {
    public color: InputSignal<'theme' | 'white' | 'orange'> = input('theme');
    public size: InputSignal<number> = input(6);

    public rem: Signal<number> = computed(() => this.size() / 16);
}
