<app-page-header mainNavigation="back" defaultBack="local-games">
    <div title [translate]="'MATCH'" i18n="@@MATCH">Match</div>
    <app-fullscreen-icon right></app-fullscreen-icon>
</app-page-header>

<div class="pb-safe-offset relative flex h-full flex-col overflow-hidden bg-white-pattern text-black" id="main-content">
    <div class="flex flex-1 flex-col overflow-auto p-4" [appWebContainer]>
        <div class="space-y-2">
            <!-- Player details -->
            <div class="new-game-card">
                <div class="title-container">
                    <div class="icon">
                        <app-icon icon="people_alt" size="small"></app-icon>
                    </div>
                    <div class="title" [translate]="'PLAYER_DETAILS'" i18n="@@PLAYER_DETAILS">Player details</div>
                </div>

                <app-toggle-switch
                    [disabled]="
                        (teams[1]?.users.length === 1 && vsDartbot && playerType === 'teams') ||
                        (playerType === 'teams' &&
                            teams[0].users.length === 2 &&
                            teams[1].users.length === 2 &&
                            !vsDartbot) ||
                        (playerType === 'players' && users.length === 4 && !vsDartbot)
                    "
                    class="block w-full"
                    [model]="vsDartbot"
                    (itemChange)="vsDartbotChanged($event)">
                    <div class="flex items-center space-x-1">
                        <div [translate]="'VS_DARTBOT'" i18n="@@VS_DARTBOT">vs. Dartbot</div>
                        @if (dartbotAdded && dartbotLevel) {
                            <div
                                class="rounded-md bg-neutral-900 dark:bg-neutral-50 px-2 text-sm font-bold uppercase text-white"
                                (click)="vsDartbotChanged(true)">
                                <span i18n="@@LEVEL">Level</span> {{ dartbotLevel.level }}
                            </div>
                        }
                    </div>
                </app-toggle-switch>

                <app-list-switch
                    class="block"
                    size="medium"
                    [item]="playerType"
                    [items]="playerTypes"
                    (itemChange)="changePlayerType($event)"></app-list-switch>

                @if (playerType === 'players') {
                    <div class="mt-4">
                        <app-games-local-add-player
                            [users]="users"
                            (updatedUsersEvent)="users = $event"
                            (addPlayerEvent)="addPlayer($event)"
                            (removePlayerEvent)="removePlayer($event)"
                            (showPlayerOptionsEvent)="showPlayerOptions($event)"></app-games-local-add-player>
                    </div>
                }

                @if (playerType === 'teams') {
                    <div class="mt-6">
                        <app-new-game-add-team
                            [(teams)]="teams"
                            (addPlayerEvent)="addPlayer($event)"
                            (removePlayerEvent)="removePlayer($event)"
                            (showPlayerOptionsEvent)="showPlayerOptions($event)"></app-new-game-add-team>
                    </div>
                }
            </div>

            <!-- Game settings -->
            <div class="new-game-card">
                <div class="title-container">
                    <div class="icon">
                        <app-icon icon="tune" size="small"></app-icon>
                    </div>
                    <div class="title" [translate]="'GAME_SETTINGS'" i18n="@@GAME_SETTINGS">Game settings</div>
                </div>

                <div class="flex flex-col space-y-2">
                    <div class="flex items-center space-x-1">
                        <app-list-switch
                            class="w-full"
                            [item]="bestOfFirstTo"
                            [items]="bestOfFirstTos"
                            (itemChange)="changeBestOfFirstTo($event)"></app-list-switch>
                        <div
                            class="rounded bg-orange px-3 pb-[0.15rem] pt-[0.25rem] font-bebas-neue text-3xl !text-white cursor-pointer"
                            (click)="showGoalAmountDialog()">
                            {{ goalAmount }}
                        </div>
                        <app-list-switch
                            class="w-full"
                            [item]="setOrLeg"
                            [items]="setsOrLegs"
                            (itemChange)="changeSetOrLeg($event)"></app-list-switch>
                    </div>

                    <div class="list-switch">
                        @for (itemFromList of startWiths; track itemFromList; let itemindex = $index) {
                            <div
                                class="switch-item"
                                [ngClass]="{
                                    '!py-1': itemFromList.type === 'custom',
                                    '!px-0': itemFromList.type === 'custom'
                                }"
                                [class.selected]="startWith === itemFromList.type"
                                (click)="changeStartWith(itemFromList.type)">
                                @if (itemFromList.type !== 'custom') {
                                    <div class="font-bebas-neue text-xl font-normal leading-none cursor-pointer">
                                        {{ itemFromList.label }}
                                    </div>
                                }
                                @if (itemFromList.type === 'custom') {
                                    <div class="flex flex-col items-center cursor-pointer">
                                        <div class="text-xxs text-neutral-400" [translate]="'CUSTOM'" i18n="@@CUSTOM">
                                            Custom
                                        </div>
                                        <div class="font-bebas-neue text-base font-normal leading-none">
                                            {{ customStartWith }}
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>

                    <app-list-switch
                        [item]="entryMode"
                        [items]="entryModes"
                        (itemChange)="changeEntryMode($event)"></app-list-switch>

                    <app-list-switch
                        [item]="finishMode"
                        [items]="finishModes"
                        (itemChange)="changeFinishMode($event)"></app-list-switch>
                </div>

                <div class="flex flex-col space-y-3">
                    <app-toggle-switch
                        class="block w-full"
                        [class.opacity-50]="checkoutPercentageDisabled"
                        [model]="checkoutPercentage"
                        [disabled]="checkoutPercentageDisabled"
                        (itemChange)="toggleCheckoutPercentage()">
                        <button
                            class="flex space-x-1 items-center"
                            (click)="
                                showHelpModal(
                                    'CHECKOUT_RATE',
                                    auth.user.profile.has_checkout_rate
                                        ? 'CHECKOUT_PERCENTAGE_SWITCHED_ON'
                                        : 'CHECKOUT_PERCENTAGE_SWITCHED_OFF'
                                )
                            ">
                            <div class="text-sm text-black" [translate]="'CHECKOUT_RATE'" i18n="@@CHECKOUT_RATE">
                                Checkout rate
                            </div>

                            <div
                                class="relative bg-blue flex items-center justify-center px-[3px] py-[3px] rounded-full">
                                <app-icon
                                    icon="questionmark"
                                    size="custom"
                                    class="!text-white !text-[0.65rem]"></app-icon>
                            </div>
                        </button>
                    </app-toggle-switch>

                    @if (showTwoLegsDifference) {
                        <app-toggle-switch
                            class="block w-full"
                            [model]="twoLegsDifference"
                            (itemChange)="twoLegsDifference = !twoLegsDifference"
                            [translate]="'TWO_LEGS_DIFFERENCE'"
                            i18n="@@TWO_LEGS_DIFFERENCE">
                            Two legs difference
                        </app-toggle-switch>
                    }

                    @if (showTwoLegsDifference && twoLegsDifference === true) {
                        <app-toggle-switch
                            class="block w-full"
                            [model]="suddenDeath"
                            (itemChange)="suddenDeath = !suddenDeath"
                            [translate]="'SUDDEN_DEATH'"
                            i18n="@@SUDDEN_DEATH">
                            Sudden death
                        </app-toggle-switch>
                    }
                </div>
            </div>
        </div>

        <app-primary-button
            class="mt-4"
            size="normal"
            addedClasses="w-full"
            [translate]="'START_GAME'"
            i18n="@@START_GAME"
            (click)="startGame()">
            Start game
        </app-primary-button>
    </div>

    <app-bottom-navigation class="w-full" active="local"></app-bottom-navigation>
</div>
