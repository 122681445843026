import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthApiService } from '@dc-api/auth.api.service';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { DartCounterAlertService } from 'dc-core/dc-services/alert.service';
import { PrimaryButtonComponent } from 'src/app/primary-button/primary-button.component';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';
import { ValidationLabelComponent } from 'src/app/shared/components/validation-label/validation-label.component';
import { matchPasswordsValidator } from 'src/app/shared/validators/match-passwords.validator';
import { AuthService } from 'src/services/auth.service';

import { CheckboxComponent } from '../../../../checkbox/checkbox.component';

@Component({
    selector: 'app-guest-transition-dialog',
    templateUrl: 'guest-transition.dialog.html',
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        PrimaryButtonComponent,
        ValidationLabelComponent,
        IconComponent,
        CheckboxComponent,
        IonicModule,
    ],
})
export class GuestTransitionDialogComponent {
    private alertService: DartCounterAlertService = inject(DartCounterAlertService);
    private authService: AuthService = inject(AuthService);
    private authApiService: AuthApiService = inject(AuthApiService);
    private formBuilder: FormBuilder = inject(FormBuilder);
    private modalController: ModalController = inject(ModalController);

    public guestForm: FormGroup = this.formBuilder.group(
        {
            email: ['', [Validators.required, Validators.email]],
            newsletterOptIn: [false],
            targetNewsletterOptIn: [false],
            password: ['', [Validators.required, Validators.minLength(6)]],
            confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
        },
        { validators: matchPasswordsValidator('password', 'confirmPassword') }
    );
    public passwordVisible: boolean = false;
    public confirmPasswordVisible: boolean = false;

    public togglePasswordVisibility(controlName: 'password' | 'confirmPassword'): void {
        if (controlName === 'password') this.passwordVisible = !this.passwordVisible;
        else if (controlName === 'confirmPassword') this.confirmPasswordVisible = !this.confirmPasswordVisible;
    }

    public submit(): void {
        if (this.guestForm.invalid) {
            return;
        }

        this.authApiService
            .transitionGuestToUser({
                email: this.guestForm.get('email').value,
                password: this.guestForm.get('password').value,
                newsletter_opt_in: this.guestForm.get('newsletterOptIn').value,
                target_newsletter_opt_in: this.guestForm.get('targetNewsletterOptIn').value,
            })
            .then((res) => {
                this.authService.saveAuthentication(res.data.user, res.data.access_token);

                if (res.data.message && res.data.user.newsletter_opt_in) {
                    this.alertService.createAlert({
                        title: res.data.message,
                        icon: 'success',
                    });
                }

                this.dismiss();
            })
            .catch((err) => {
                this.alertService.errorFromApi(err);
            });
    }

    public dismiss(returnValue: any = null): void {
        this.modalController.dismiss(returnValue);
    }
}
