/* eslint-disable max-len */
import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { PaymentApiService } from '@dc-api/payment.api.service';
import { Plan } from '@dc-core/dc-backend/dc-classes';
import { PaymentMethod } from '@dc-core/dc-backend/dc-enums';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { ModalController, Platform } from '@ionic/angular';
import { ModalOptions } from '@ionic/core';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { DCLoadingService } from '@providers/DCLoadingService';
import { UpgradeService } from '@providers/UpgradeService';
import { ThemeService } from '@services/theme.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    GA_EVENTACTIONS,
    GA_EVENTCATEGORIES,
    ULTIMATE_MONTHLY_SUBSCRIPTION,
    ULTIMATE_YEARLY_SUBSCRIPTION,
} from 'src/app/app.globals';
import {
    AppFeaturesService,
    UltimateFeature,
    UltimateFeatureID,
} from 'src/app/core/app-features/services/app-features.service';
import { environment } from 'src/environments/environment';
import { PurchaseService, PurchaseStatus } from 'src/providers/PurchaseService';
import { AuthService } from 'src/services/auth.service';
import { SwiperContainer } from 'swiper/element';
import { SwiperOptions } from 'swiper/types';

import { PrivacyPolicyDialogComponent } from '../../app/modules/app-settings/dialogs/privacy-policy/privacy-policy.dialog';
import { TermsOfServiceDialogComponent } from '../../app/modules/app-settings/dialogs/terms-of-service/terms-of-service.dialog';
import { EnterEmailDialogComponent } from '../enter-email/enter-email';
import { SelectPaymentMethodDialogComponent } from '../select-payment-method/select-payment-method.dialog';

export interface UltimateDialogPayload {
    highlightedFeatureID?: UltimateFeatureID;
}

@Component({
    selector: 'app-ultimate-subscription-dialog',
    templateUrl: 'ultimate-subscription.html',
})
export class UltimateSubscriptionDialogComponent implements OnInit, OnDestroy {
    public recurring = 'yearly';
    public devicePlatform: 'web' | 'ios' | 'android' = null;

    public purchases: PurchaseService = inject(PurchaseService);
    public upgradeService: UpgradeService = inject(UpgradeService);
    public themeService: ThemeService = inject(ThemeService);
    public appFeaturesService: AppFeaturesService = inject(AppFeaturesService);

    public ultimateFeatures: UltimateFeature[] = [];
    @Input() highlightedFeatureID: UltimateFeatureID = null;

    private authService: AuthService = inject(AuthService);
    private platform: Platform = inject(Platform);
    private modalController: ModalController = inject(ModalController);
    private _modalController: ModalController = inject(ModalController);
    private ga: DartCounterAnalyticsService = inject(DartCounterAnalyticsService);
    private paymentApiService: PaymentApiService = inject(PaymentApiService);
    private alertService: DartCounterAlertService = inject(DartCounterAlertService);
    private loadingService: DCLoadingService = inject(DCLoadingService);

    private unsubscribe: Subject<void> = new Subject<void>();

    private backButtonSubscription: Subscription;

    ngOnInit(): void {
        this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(9999, () => {
            this.close();
        });

        this.ga.trackView('Ultimate subscription dialog');

        if (environment.isWeb || !this.platform.is('capacitor')) {
            this.devicePlatform = 'web';
            this.upgradeService.loadPlans(true);
        } else if (this.platform.is('ios')) {
            this.devicePlatform = 'ios';
        } else if (this.platform.is('android')) {
            this.devicePlatform = 'android';
        }

        // Get all Ultimate Features that are defined in the app-features service
        this.ultimateFeatures = this.appFeaturesService.getAvailableUltimateFeatures();

        // If a highlightedFeatureID is passed, move the right slide to the front
        if (this.highlightedFeatureID) {
            const highlightedFeatureIndex = this.ultimateFeatures.findIndex(
                (feature) => feature.id === this.highlightedFeatureID
            );
            if (highlightedFeatureIndex >= 0) {
                // Remove the item and add it to the front
                const [highlightedFeature] = this.ultimateFeatures.splice(highlightedFeatureIndex, 1);
                this.ultimateFeatures.unshift(highlightedFeature);
            }
        }

        this.purchases.status$.pipe(takeUntil(this.unsubscribe)).subscribe((status: PurchaseStatus) => {
            switch (status) {
                case 'close':
                    this.DismissModal();
                    break;
            }
        });

        this.initSwiper();
    }

    public close(returnValue: any = null): void {
        this.modalController.dismiss(returnValue);
    }

    yearPricePerMonth(pricing: any): string {
        return formatCurrency(
            Math.floor(pricing.priceMicros / 10000 / 12) / 100,
            'en',
            getCurrencySymbol(pricing.currency, 'narrow')
        );
    }

    removeSpaces(str: string): string {
        if (!str) {
            return '';
        }
        return str.split(' ').join('');
    }

    public DismissModal(returnValue: any = null): void {
        this.modalController.dismiss(returnValue);
    }

    public setRecurring(value) {
        this.recurring = value;
    }

    public BuyUltimate() {
        if (!this.authService.user.email) {
            this._modalController
                .create({
                    component: EnterEmailDialogComponent,
                    componentProps: {
                        email: this.authService.user.email,
                    },
                    cssClass: 'auto-height',
                    showBackdrop: true,
                })
                .then((elem) => {
                    elem.present();
                    elem.onDidDismiss().then((dialogRes) => {
                        if (dialogRes.data == true) {
                            this.purchase();
                        }
                    });
                });
        } else {
            this.purchase();
        }
    }

    purchase(): void {
        const monthly: boolean = this.recurring === 'monthly';

        if (this.devicePlatform === 'web') {
            const plan: Plan = monthly ? this.upgradeService.monthlyPlan : this.upgradeService.yearlyPlan;
            this._modalController
                .create({ component: SelectPaymentMethodDialogComponent, cssClass: 'auto-height' })
                .then((elem) => {
                    elem.present();
                    elem.onDidDismiss().then((dialogRes) => {
                        if (dialogRes.data) {
                            this.loadingService.ShowDefaultLoader();

                            this.paymentApiService
                                .applySubscription({
                                    plan_id: plan.id,
                                    payment_method: dialogRes.data as PaymentMethod,
                                    success_redirect_url: window.location.origin,
                                    cancel_redirect_url: window.location.href,
                                })
                                .then((res) => {
                                    window.location.href = res.data.redirect_url;
                                })
                                .catch((err) => {
                                    this.alertService.errorFromApi(err);
                                })
                                .finally(() => {
                                    this.loadingService.DismissLoader();
                                });
                        }
                    });
                });
        } else {
            this.purchases.purchase(monthly ? ULTIMATE_MONTHLY_SUBSCRIPTION : ULTIMATE_YEARLY_SUBSCRIPTION);
        }
    }

    public openTermsOfService(): void {
        this.ga.trackEvent(GA_EVENTCATEGORIES.ULTIMATE, GA_EVENTACTIONS.TERMSOFUSE);
        this._modalController
            .create({
                component: TermsOfServiceDialogComponent,
            } as ModalOptions)
            .then((elem) => elem.present());
    }

    public openPrivacyPolicy(): void {
        this.ga.trackEvent(GA_EVENTCATEGORIES.ULTIMATE, GA_EVENTACTIONS.PRIVACYPOLICY);
        this._modalController
            .create({
                component: PrivacyPolicyDialogComponent,
            } as ModalOptions)
            .then((elem) => elem.present());
    }

    private initSwiper(): void {
        const swiperEl: SwiperContainer = document.querySelector('#ultimate-features-slider');
        const swiperParams: SwiperOptions = {
            pagination: false,
            navigation: true,
            autoplay: environment.isWeb
                ? {
                      delay: 3000,
                  }
                : false,
        };
        Object.assign(swiperEl, swiperParams);
        swiperEl.initialize();
    }

    ngOnDestroy(): void {
        if (this.backButtonSubscription) {
            this.backButtonSubscription.unsubscribe();
        }

        this.unsubscribe.next(null);
        this.unsubscribe.complete();
    }
}
