import { inject, Injectable } from '@angular/core';
import { BroadcastEvent } from '@dc-core/dc-backend/dc-enums';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { environment } from 'src/environments/environment';

import { AppFeaturesService } from '../core/app-features/services/app-features.service';

@Injectable()
export class EchoService {
    private appFeaturesService: AppFeaturesService = inject(AppFeaturesService);

    public echo: Echo = null;

    public channels: string[] = [];

    init(accessToken: string): void {
        if (!this.appFeaturesService.enabledAppFeatures().laravel_reverb) {
            return;
        }

        if (!this.echo && accessToken) {
            (window as any).Pusher = Pusher;

            this.echo = new Echo({
                broadcaster: 'reverb',
                key: environment.echo.key,
                wsHost: environment.echo.wsHost,
                wsPort: environment.echo.wsPort,
                wssPort: environment.echo.wssPort,
                forceTLS: environment.echo.scheme === 'https',
                enabledTransports: ['ws', 'wss'],
                authEndpoint: environment.apiUrl + '/broadcasting/auth',
                auth: {
                    headers: {
                        Authorization: 'Bearer ' + accessToken,
                    },
                },
            });
        }
    }

    listen(channel: string, event: BroadcastEvent, callback: any) {
        if (!this.echo) {
            return;
        }

        if (!this.channels.includes(channel)) {
            this.channels.push(channel);
        }

        this.echo.channel(channel).listen(event, (data: any) => {
            callback(data);
        });
    }

    stopListening(channel: string, event: BroadcastEvent, callback: any = null) {
        if (!this.echo) {
            return;
        }

        this.echo.channel(channel).stopListening(event, (data: any) => {
            if (callback) {
                callback(data);
            }
        });
    }

    privateChannel(channel: string, event: BroadcastEvent, callback: any) {
        if (!this.echo) {
            return;
        }

        if (!this.channels.includes(channel)) {
            this.channels.push(channel);
        }

        this.echo.private(channel).listen(`.${event}`, (data: any) => {
            callback(data);
        });
    }

    leave(channel: string) {
        if (!this.echo) {
            return;
        }

        this.echo.leave(channel);
    }

    leaveAll() {
        if (!this.echo) {
            return;
        }

        for (const channel of this.channels) {
            this.leave(channel);
        }
        this.channels = [];
    }

    disconnect() {
        if (!this.echo) {
            return;
        }

        this.echo.disconnect();
        this.echo = null;
    }
}
