<div class="flex flex-col items-center overflow-hidden">
    <div class="new-game-player-container relative">
        @if (users()) {
            <div
                class="flex justify-center items-end space-x-2 h-[4.125rem] relative"
                [ngStyle]="{ 'width.rem': users().length > 1 ? 4.125 + 0.75 * users().length : 4.125 }">
                @for (user of users(); track $index; let count = $count) {
                    <app-profile-photo
                        class="h-fit block absolute"
                        [ngStyle]="{ 'left.rem': $index * 0.75, 'z-index': count - $index }"
                        [size]="profilePhotoSize()"
                        [big]="true"
                        [badge]="user?.profile?.badge"
                        [ultimate]="user?.is_ultimate"
                        [url]="user?.profile?.profile_photo_url">
                    </app-profile-photo>
                }
            </div>
        } @else {
            <app-profile-photo
                class="h-fit block"
                [size]="profilePhotoSize()"
                [big]="true"
                [badge]="user()?.profile?.badge"
                [ultimate]="user()?.is_ultimate"
                [url]="user()?.profile?.profile_photo_url">
            </app-profile-photo>
        }
    </div>
    <div class="font-bold truncate w-full text-black">
        {{ name() || ('NO_NAME' | translate) }}
    </div>
</div>
