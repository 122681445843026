import { Component, inject, Input, OnInit, output, OutputEmitterRef } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SetSingleDartPayload } from '@dc-core/dc-gamelogic/in-game/ingame.globals';
import { OmniIngameService, OmniSingleDart } from '@dc-core/dc-services/omni/omni-ingame.service';
import { ModalController } from '@ionic/angular';

export type EditOmniScorePayload = {
    point: OmniSingleDart;
    svgContent: string;
    dartIndex: number;
};

@Component({
    selector: 'app-edit-omni-score-dialog',
    templateUrl: 'edit-omni-score.dialog.html',
})
export class EditOmniScoreDialogComponent implements OnInit {
    @Input() point: OmniSingleDart;
    @Input() svgContent: string;
    @Input() dartIndex: number;

    public sanitizedSvgContent: SafeHtml; // Safe HTML for rendering
    public editablePoint: SetSingleDartPayload = null;
    public setSingleDart: OutputEmitterRef<SetSingleDartPayload> = output<SetSingleDartPayload>();

    private _modalController: ModalController = inject(ModalController);
    private _omniIngameService: OmniIngameService = inject(OmniIngameService);
    private _sanitizer: DomSanitizer = inject(DomSanitizer);

    public ngOnInit(): void {
        if (this.svgContent) {
            this.sanitizedSvgContent = this._sanitizer.bypassSecurityTrustHtml(this.svgContent);
        }

        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        //Add 'implements OnInit' to the class.
        if (!this.point) {
            this.point = {
                coordinates: null,
                singleDart: {
                    amount: 0,
                    multiplier: 0,
                    needConfirm: true,
                    isOmni: true,
                },
                isValidThrow: null,
            };
        }
        this.editablePoint = { ...this.point.singleDart };
    }

    public saveEditedScore(): void {
        // User selected a different amount and/or multiplier.
        if (
            this.point.singleDart.amount != this.editablePoint.amount ||
            this.point.singleDart.multiplier != this.editablePoint.multiplier
        ) {
            this.point.singleDart = this.editablePoint;
            this._omniIngameService.addDartHit(null, this.editablePoint, true, this.dartIndex);

            this.editablePoint.needConfirm = true;
            this.editablePoint.isOmni = true;

            this._omniIngameService.editedSingleDart$.next({
                dartIndex: this.dartIndex,
                singleDart: this.editablePoint,
            });
        }
        this._modalController.dismiss();
    }

    public dismiss(returnValue: any = null): void {
        this._modalController.dismiss(returnValue);
    }

    public setNewSingleDartValue(payload: SetSingleDartPayload) {
        this.editablePoint = payload;
    }

    public getScoreEvent(point: SetSingleDartPayload): string {
        if (!point) {
            return '';
        }

        if (point.amount === 0 || point.multiplier === null) {
            return $localize`:@@MISS:Miss`;
        }

        if (point.amount === 25) {
            if (point.multiplier === 1) {
                return 'S-BULL';
            }
            return 'BULL';
        } else {
            if (point.multiplier === 1) {
                return 'S' + point.amount;
            } else if (point.multiplier === 2) {
                return 'D' + point.amount;
            } else if (point.multiplier === 3) {
                return 'T' + point.amount;
            }
        }
    }
}
