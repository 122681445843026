import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, Input, OnDestroy, OnInit, signal, WritableSignal } from '@angular/core';
import { PageHeaderComponent } from '@components/page-header/page-header.component';
import { TournamentStatisticsApiService } from '@dc-api/tournament-statistics.api.service';
import { Tournament } from '@dc-core/dc-backend/dc-classes';
import { GameStatisticsCollection, GroupedGameStatistics } from '@dc-core/dc-backend/dc-interfaces';
import { ExtendedTournamentMatchUser, TournamentHelper } from '@dc-core/dc-helpers/tournament.helper';
import { IonicModule, ModalController, Platform } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import { ConfettiService } from '@services/confetti.service';
import { Subscription } from 'rxjs';
import { OptionButtonComponent } from 'src/app/option-button/option-button.component';
import { PrimaryButtonComponent } from 'src/app/primary-button/primary-button.component';
import { SwipeDirective } from 'src/directives/swipe.directive';
import { environment } from 'src/environments/environment';

import { AppLoaderComponent } from '../../../../../components/loader/app-loader.component';
import { GamesEndScreenPlayerComponent } from '../../../../modules/games/components/games-end-screen-player/games-end-screen-player.component';
import { GamesEndScreenStatsSlideComponent } from '../../../../modules/games/components/games-end-screen-stats-slide/games-end-screen-stats-slide.component';
import { IconComponent } from '../../icon/icon.component';

@Component({
    selector: 'app-tournament-winner-dialog',
    standalone: true,
    templateUrl: './tournament-winner.dialog.html',
    imports: [
        IonicModule,
        TranslateModule,
        IconComponent,
        PrimaryButtonComponent,
        SwipeDirective,
        CommonModule,
        PageHeaderComponent,
        OptionButtonComponent,
        GamesEndScreenPlayerComponent,
        GamesEndScreenStatsSlideComponent,
        AppLoaderComponent,
    ],
})
export class TournamentWinnerDialogComponent implements OnInit, OnDestroy {
    @Input() tournament: Tournament;

    public authService: AuthService = inject(AuthService);

    private modalController: ModalController = inject(ModalController);
    private platform: Platform = inject(Platform);
    private translateService: TranslateService = inject(TranslateService);
    private tournamentStatisticsApiService: TournamentStatisticsApiService = inject(TournamentStatisticsApiService);
    private confettiService: ConfettiService = inject(ConfettiService);

    public matchUser: ExtendedTournamentMatchUser = null;
    public statistics: GameStatisticsCollection;
    public subtext: string;
    public currentSlide: WritableSignal<number> = signal(1);
    public amountOfSlides = 1;
    public slides: any[] = [];

    public isWeb = environment.isWeb;

    private backButtonSubscription: Subscription;

    protected destroyRef: DestroyRef = inject(DestroyRef);

    public ngOnInit(): void {
        this.confettiService.showStarAndBigBang();

        $localize`:@@YOU_ARE_THE_WINNER_OF_THE_TOURNAMENT:You are the winner of tournament '${this.tournament.name}:name:'`;
        this.subtext = this.translateService.instant('YOU_ARE_THE_WINNER_OF_THE_TOURNAMENT', {
            name: this.tournament.name,
        });

        for (let i = 1; i <= this.amountOfSlides; i++) {
            this.slides.push(i);
        }

        this.tournamentStatisticsApiService
            .getSpecificTournamentMatchStatistics({
                tournament_id: this.tournament.id,
                user_id: this.authService.user.id,
            })
            .then((res) => {
                this.matchUser = TournamentHelper.calculateTournamentMatchUser(res.data);
                this.setStatisticsCollection();
            });

        this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(9999, () => {
            this.close();
        });
    }

    public ngOnDestroy(): void {
        if (this.backButtonSubscription) {
            this.backButtonSubscription.unsubscribe();
        }
    }

    public prevSlide(): void {
        if (this.currentSlide() > 1) {
            this.currentSlide.update((slide) => --slide);
        }
    }

    public nextSlide(): void {
        if (this.currentSlide() < this.amountOfSlides) {
            this.currentSlide.update((slide) => ++slide);
        }
    }

    public close(returnValue = null): void {
        this.modalController.dismiss(returnValue);
    }

    private setStatisticsCollection(): void {
        const threeDartAverages: GroupedGameStatistics = {
            title: $localize`:@@THREE_DART_AVERAGE:3-dart average`,
            format: 'decimal',
            values: [this.matchUser?.three_dart_average ?? null],
        };
        const dartsThrown: GroupedGameStatistics = {
            title: $localize`:@@DARTS_THROWN:Darts thrown`,
            format: 'number',
            values: [this.matchUser?.darts_thrown ?? null],
        };
        const checkoutRates: GroupedGameStatistics = {
            title: $localize`:@@CHECKOUT_RATE:Checkout rate`,
            format: 'percentage',
            values: [this.matchUser?.checkout_rate ?? null],
        };
        const checkouts: GroupedGameStatistics = {
            title: $localize`:@@CHECKOUTS:Checkouts`,
            format: 'number',
            values: [this.matchUser?.checkouts ?? null],
        };
        const highestFinishes: GroupedGameStatistics = {
            title: $localize`:@@HIGHEST_FINISH:Highest finish`,
            format: 'number',
            values: [this.matchUser?.highest_finish ?? null],
        };
        const highestScores: GroupedGameStatistics = {
            title: $localize`:@@HIGHEST_SCORE:Highest score`,
            format: 'number',
            values: [this.matchUser?.highest_score ?? null],
        };
        const totalLegsWon: GroupedGameStatistics = {
            title: $localize`:@@TOTAL_LEGS_WON:Total legs won`,
            format: 'number',
            values: [this.matchUser?.total_legs_won ?? null],
        };

        this.statistics = [
            [threeDartAverages, dartsThrown, checkoutRates, checkouts, highestFinishes, highestScores, totalLegsWon],
        ];
    }
}
