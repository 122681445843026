<app-page-header mainNavigation="menu">
    <div title [translate]="'CONTACT'" i18n="@@CONTACT">Contact</div>
</app-page-header>

<div class="p-4 flex flex-col h-full bg-white-pattern text-black overflow-y-auto" id="main-content">
    <div class="flex-1" [appWebContainer]>
        <p class="mb-4" [translate]="'ANY_QUESTIONS_CONTACT_US'" i18n="@@ANY_QUESTIONS_CONTACT_US">
            Any questions, new ideas or other feedback? Contact us!
        </p>

        <form class="space-y-4" [formGroup]="contactForm" (ngSubmit)="send()" #form="ngForm">
            <div class="my-2">
                <div [translate]="'CATEGORY'" i18n="@@CATEGORY" class="dialog-input-label">Category</div>
                <app-select
                    class="block mt-1"
                    [initialValue]="contactForm.get('category').value"
                    [options]="categories"
                    (selectionChange)="updateCategory($event)"
                    [error]="form.submitted && contactForm.get('category').errors"
                    mode="dialog-input"
                    size="full"></app-select>
            </div>
            @if (contactForm.get('category').value === 'other') {
                <div class="mt-2">
                    <div class="dialog-input-label" [translate]="'SUBJECT'" i18n="@@SUBJECT">Subject</div>
                    <input
                        type="text"
                        [ngClass]="{
                            '!border-red': form.submitted && contactForm.get('subject').errors,
                            '!py-2': isWeb
                        }"
                        class="dialog-input"
                        placeholder="{{ 'SUBJECT' | translate }}"
                        formControlName="subject"
                        required />
                </div>
            }
            @if (frequentlyAskedQuestions.length) {
                <p
                    class="dialog-input-label"
                    [translate]="'FREQUENTLY_ASKED_QUESTIONS'"
                    i18n="@@FREQUENTLY_ASKED_QUESTIONS">
                    Frequently asked questions
                </p>
                <div class="divide-y divide-1 divide-neutral-100 dark:divide-neutral-800">
                    @for (question of frequentlyAskedQuestions; track question) {
                        <div
                            class="flex items-center justify-between w-full py-3 cursor-pointer"
                            (click)="showQuestion(question)">
                            <div class="font-normal">{{ question.question }}</div>
                            <app-icon icon="keyboard_arrow_right" class="text-neutral-400"></app-icon>
                        </div>
                    }
                    <div
                        class="flex items-center justify-between w-full py-3 cursor-pointer"
                        (click)="questionNotPresent()">
                        <div class="font-normal" [translate]="'QUESTION_NOT_PRESENT'" i18n="@@QUESTION_NOT_PRESENT">
                            My question is not on the list
                        </div>
                        <app-icon icon="keyboard_arrow_right" class="text-neutral-400"></app-icon>
                    </div>
                </div>
            }
            @if (showForm) {
                <div class="grid grid-cols-2 gap-x-3 mt-2">
                    <div>
                        <div class="dialog-input-label" [translate]="'FIRST_NAME'" i18n="@@FIRST_NAME">First name</div>
                        @if (!isGuest) {
                            <span>{{ user.first_name }}</span>
                        }
                        @if (isGuest) {
                            <input
                                type="text"
                                [ngClass]="{
                                    '!border-red': form.submitted && contactForm.get('firstName').errors,
                                    '!py-2': isWeb
                                }"
                                class="dialog-input"
                                placeholder="{{ 'FIRST_NAME' | translate }}"
                                formControlName="firstName"
                                required />
                        }
                    </div>
                    <div>
                        <div class="dialog-input-label" [translate]="'LAST_NAME'" i18n="@@LAST_NAME">Last name</div>
                        @if (!isGuest) {
                            <span>{{ user.last_name }}</span>
                        }
                        @if (isGuest) {
                            <input
                                type="text"
                                [ngClass]="{
                                    '!border-red': form.submitted && contactForm.get('lastName').errors,
                                    '!py-2': isWeb
                                }"
                                class="dialog-input"
                                placeholder="{{ 'LAST_NAME' | translate }}"
                                formControlName="lastName"
                                required />
                        }
                    </div>
                </div>
                <div class="mt-2">
                    <div class="dialog-input-label" [translate]="'EMAIL'" i18n="@@EMAIL">Email</div>
                    @if (user?.email) {
                        <span>{{ user.email }}</span>
                    }
                    @if (!user?.email) {
                        <input
                            type="text"
                            [ngClass]="{
                                '!border-red': form.submitted && contactForm.get('email').errors,
                                '!py-2': isWeb
                            }"
                            class="dialog-input"
                            placeholder="{{ 'EMAIL' | translate }}"
                            formControlName="email"
                            [readonly]="user && user.email" />
                    }
                </div>
                @if (contactForm.get('category').value === 'mastercaller') {
                    <div class="mt-2">
                        <div class="dialog-input-label" [translate]="'NOTE'" i18n="@@NOTE">Note</div>
                        <div
                            [translate]="'REQUEST_YOUR_NAME_TO_OUR_MASTERCALLER'"
                            i18n="@@REQUEST_YOUR_NAME_TO_OUR_MASTERCALLER">
                            Requested your name to our master caller. It should be added within a week from now. Please
                            keep an eye on the list.
                        </div>
                    </div>
                }
                <div class="mt-2">
                    <div class="dialog-input-label" [translate]="'MESSAGE'" i18n="@@MESSAGE">Message</div>
                    <textarea
                        class="dialog-input w-full mt-1"
                        style="height: 8rem"
                        type="text"
                        name="message"
                        [ngClass]="{
                            '!border-red': form.submitted && contactForm.get('message').errors,
                            '!py-2': isWeb
                        }"
                        formControlName="message"
                        placeholder="{{
                            (contactForm.get('category').value === 'mastercaller'
                                ? 'ENTER_YOUR_NAME_HERE'
                                : 'SEND_A_MESSAGE_DOTS'
                            ) | translate
                        }}"
                        required></textarea>
                </div>
                @if (contactForm.get('category').value === 'bug_issue') {
                    <div class="mt-2">
                        <div class="dialog-input-label" [translate]="'FILE'" i18n="@@FILE">File</div>
                        <input type="file" style="display: none" (change)="onFileChange($event)" #thumbnailFile />
                        <div class="flex space-x-2 items-center">
                            <input
                                (click)="thumbnailFile.click()"
                                type="text"
                                [ngClass]="{
                                    '!py-2': isWeb
                                }"
                                class="dialog-input"
                                placeholder="{{ 'FILE' | translate }}"
                                formControlName="file_name" />
                            <app-primary-button
                                class="block whitespace-nowrap"
                                size="small"
                                (click)="thumbnailFile.click()"
                                [translate]="'UPLOAD_FILE'"
                                i18n="@@UPLOAD_FILE">
                                Upload file
                            </app-primary-button>
                        </div>
                    </div>
                }

                <app-primary-button
                    class="block w-full mt-4"
                    size="large"
                    [translate]="'SEND'"
                    i18n="@@SEND"
                    color="blue"
                    type="submit">
                    Send
                </app-primary-button>

                <div class="text-neutral-400 text-xs mt-4">
                    This site is protected by reCAPTCHA and the Google
                    <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                    <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                </div>
            }
        </form>
    </div>
</div>
