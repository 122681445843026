import { Component, inject, input, InputSignal, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BetaFlagsService } from 'src/app/core/beta-flags/services/beta-flags.service';

type BetaFlag = 'tournaments';

@Component({
    selector: 'app-beta-flag',
    templateUrl: 'beta-flag.component.html',
    standalone: true,
    imports: [TranslateModule],
})
export class BetaFlagComponent implements OnInit {
    public flag: InputSignal<BetaFlag> = input(null);
    public active: InputSignal<boolean> = input(true);

    private betaFlagsService: BetaFlagsService = inject(BetaFlagsService);

    public showFlag: boolean;

    public ngOnInit(): void {
        switch (this.flag()) {
            case 'tournaments':
                this.showFlag = this.betaFlagsService.enabledBetaFlags().tournaments;
                break;
            default:
                this.showFlag = true;
        }
    }
}
