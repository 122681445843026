import { AbstractControl, ValidatorFn } from '@angular/forms';

export function matchPasswordsValidator(passwordKey: string, confirmPasswordKey: string): ValidatorFn {
    return (formGroup: AbstractControl) => {
        const password = formGroup.get(passwordKey)?.value;
        const confirmPassword = formGroup.get(confirmPasswordKey)?.value;

        if (password !== confirmPassword) {
            return { passwordsDoNotMatch: true };
        }
        return null;
    };
}
