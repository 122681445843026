import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HelpModalComponent } from '@components/help-modal/help-modal.component';
import { OnlineGameplay } from '@dc-core/dc-backend/dc-interfaces';
import { LobbySettingsCollectionService } from '@dc-core/dc-firestore/collection-helpers/lobby_settings.collection.service';
import { DartCounterOnlineCricketTacticsSettings } from '@dc-core/dc-gamelogic/settings/online/cricket-tactics/cricket-tactics-settings.default';
import { JanusVideoRoomService } from '@dc-core/dc-services/dc-janus/janus-video-room.service';
import { OnlineGamesService } from '@dc-core/dc-services/online-games.service';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DartCounterAlertService } from 'dc-core/dc-services/alert.service';
import { Observable, Subscription } from 'rxjs';
import { GoalAmountDialogComponent } from 'src/dialogs/goalamount/goalamount.dialog';
import { SuddenDeathDialogComponent } from 'src/dialogs/suddendeath/suddendeath.dialog';
import { TimerDurationDialogComponent } from 'src/dialogs/timerduration/timerduration.dialog';
import { TutorialDialogComponent } from 'src/dialogs/tutorial/tutorial';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/services/auth.service';

import { FireStoreService } from '../../../providers/FirebaseService';

@Component({
    selector: 'app-cricket-tactics-online-setup',
    templateUrl: 'cricket-tactics.online-setup.component.html',
})
export class CricketTacticsOnlineSetupComponent
    extends DartCounterOnlineCricketTacticsSettings
    implements OnInit, OnDestroy
{
    @Input() saveEvents: Observable<void>;
    @Output() saveEvent = new EventEmitter<OnlineGameplay>();
    private eventsSubscription: Subscription;

    constructor(
        public auth: AuthService,
        private modal: ModalController,
        private modal2: ModalController,
        public firestore: FireStoreService,
        public route: ActivatedRoute,
        private translate: TranslateService,
        private _online: OnlineGamesService,
        private _lobbySettingsService: LobbySettingsCollectionService,
        public videoRoomService: JanusVideoRoomService,
        private _alertService: DartCounterAlertService
    ) {
        super();
    }

    ngOnInit(): void {
        this.setUser(this.auth.user);
        this.setOnlineGameMode('cricket_tactics');

        this.eventsSubscription = this.saveEvents.subscribe(() => this.confirm());
    }

    ngOnDestroy(): void {
        this.eventsSubscription.unsubscribe();
    }

    dismiss() {
        this.modal.dismiss();
    }

    public confirm(): void {
        const errors = this.checkForErrors();
        if (errors.length) {
            this._alertService.createAlert({
                title: errors.join('\n'),
                icon: 'error',
                timer: 3000,
            });
        } else {
            this.saveSettings();

            this._alertService.createAlert({
                title: $localize`:@@GAMEPLAY_HAS_BEEN_CREATED:Gameplay settings have been saved`,
                icon: 'success',
                timer: 'default',
            });

            this.saveEvent.next(this.mapToOnlineGameplayObject());
        }
    }

    toggleFastMode() {
        this.fastScoring = !this.fastScoring;
    }

    toggleTwoLegsDiff() {
        this.twoLegsDifference = !this.twoLegsDifference;

        if (this.twoLegsDifference) {
            let dialogRef;
            this.modal
                .create({
                    component: SuddenDeathDialogComponent,
                    backdropDismiss: true,
                    componentProps: {
                        suddenDeath: JSON.parse(JSON.stringify(this.suddenDeath)),
                    },
                    cssClass: 'auto-height',
                })
                .then((elem) => {
                    elem.present();
                    dialogRef = elem;
                    dialogRef.onDidDismiss().then((dialogRes) => {
                        if (dialogRes.data) {
                            this.suddenDeath = dialogRes.data;
                        }
                    });
                });
        } else {
            this.suddenDeath = false;
        }
    }

    showGoalAmountDialog() {
        this.modal
            .create({
                component: GoalAmountDialogComponent,
                backdropDismiss: true,
                componentProps: {
                    goalAmount: JSON.parse(JSON.stringify(this.goalAmount)),
                },
                cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.goalAmount = dialogRes.data;
                        const errors = this.checkForErrors();
                        if (errors.length) {
                            this._alertService.createAlert({
                                title: errors.join('\n'),
                                icon: 'error',
                                timer: 3000,
                            });
                        }
                    }
                });
            });
    }

    showTimerDurationDialog() {
        let dialogRef;
        this.modal2
            .create({
                component: TimerDurationDialogComponent,
                backdropDismiss: true,
                componentProps: {
                    timerDuration: JSON.parse(JSON.stringify(this.inGameTimer)),
                },
                cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
            })
            .then((elem) => {
                elem.present();
                dialogRef = elem;

                dialogRef.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.inGameTimer = dialogRes.data;
                    }
                });
            });
    }

    showFastScoringInfo(): void {
        this.modal2
            .create({
                component: TutorialDialogComponent,
                backdropDismiss: true,
                componentProps: {
                    slides: [
                        {
                            title: this.translate.instant('SEND_SCORES_IMMEDIATELY'),
                            description: this.translate.instant('FAST_OR_SLOW'),
                        },
                    ],
                },
                cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
            })
            .then((elem) => {
                elem.present();
            });
    }

    showHelpModal(title, contents) {
        this.modal
            .create({
                component: HelpModalComponent,
                componentProps: {
                    title: this.translate.instant(title),
                    contents: this.translate.instant(contents),
                },
                cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                backdropDismiss: true,
            })
            .then((elem) => {
                elem.present();
            });
    }
}
