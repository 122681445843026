<div class="bg-white-pattern text-black overflow-y-auto">
    <app-page-header mainNavigation="custom" color="transparent" [safePadding]="false">
        <div title class="text-black" [translate]="'PRIVACY_POLICY'" i18n="@@PRIVACY_POLICY">Privacy Policy</div>
        <app-icon right class="text-black" icon="close" (click)="dismiss()"></app-icon>
    </app-page-header>

    <div class="copy p-4 text-md text-black">
        @if (lang === 'nl') {
            <p><strong>Laatste revisie:</strong>&nbsp;29 mei 2024</p>
            <p>
                <strong>Verantwoordelijke voor de gegevensverwerking</strong><br />
                DartCounter<br />
                Pollaan 48A<br />
                7202 BX Zutphen
            </p>
            <p>
                DartCounter ("Bedrijf," "wij," of "ons") is toegewijd aan het beschermen van uw privacy. Dit
                Privacybeleid ("Beleid") beschrijft onze praktijken met betrekking tot de persoonlijke informatie die
                wij verzamelen van gebruikers van onze website (https://dartcounter.net) ("Site"), onze mobiele
                applicatie ("App"), en online diensten als geheel ("Diensten"), in gevallen zoals wanneer u
            </p>
            <ul>
                <li>Onze website bezoekt, of een van onze websites die naar deze privacyverklaring linken.</li>
                <li>Wanneer u onze App, DartCounter, downloadt.</li>
                <li>
                    Met ons om andere gerelateerde manieren in contact komt, inclusief verkoop, marketing of
                    evenementen.
                </li>
            </ul>
            <p>
                <strong>Vragen of zorgen?</strong> Door deze privacyverklaring te lezen, krijgt u een beter inzicht in
                uw privacyrechten en -keuzes. Als u niet akkoord gaat met ons beleid en onze aanpak, gebruik onze
                Diensten dan niet. Als u nog vragen of zorgen heeft, neem dan contact met ons op via
                <a href="mailto:support@dartcounter.net">support&#64;dartcounter.net</a>.
            </p>
            <h2>1. Soorten gegevens die we verzamelen</h2>
            <h3>1.1 Informatie die u verstrekt</h3>
            <p>
                We verzamelen op verschillende manieren informatie over u wanneer u onze Diensten gebruikt. Door akkoord
                te gaan met ons Privacybeleid, stemt u in met onze verzameling van deze informatie en staat u ons toe
                deze te gebruiken zoals beschreven. De informatie die wij verzamelen, hangt af van de context van uw
                interacties met ons en onze Diensten, de keuzes die u maakt en de functies die u gebruikt. De informatie
                die wij verzamelen kan het volgende omvatten:
            </p>
            <ul>
                <li>
                    <strong>Persoonlijke informatie:</strong> Wanneer u zich registreert voor een persoonlijk account,
                    verzamelen wij uw e-mailadres. Optioneel kunt u uw naam, geboortedatum en een profielfoto
                    verstrekken. Als u feedback geeft of contact met ons opneemt, verzamelen wij de gegevens die in uw
                    communicatie zijn opgenomen. Bij deelname aan promoties verzamelen wij de gegevens die u verstrekt
                    met betrekking tot de promotie.
                </li>
                <li>
                    <strong>Gevoelige informatie:</strong> Hoewel u het altijd optioneel kunt delen, vereisen wij dat u
                    uw geboortedatum opgeeft wanneer u camera- of chatfuncties in onze Diensten gebruikt om te voldoen
                    aan de wetgeving en minderjarigen te beschermen die onze Diensten gebruiken.
                </li>
                <li>
                    <strong>Gegevens voor inloggen via sociale media:</strong> Onze Diensten bieden u de mogelijkheid om
                    u te registreren en in te loggen met behulp van een social media ccount van een derde partij
                    (Facebook en Google logins). Als u ervoor kiest om dit te doen, ontvangen wij bepaalde
                    profielinformatie over u van uw sociale media provider. De profielinformatie die wij ontvangen kan
                    variëren afhankelijk van de betreffende sociale media provider. Wij verzamelen enkel uw naam en
                    e-mailadres.<br />Wij zullen de informatie die wij ontvangen alleen gebruiken voor de doeleinden die
                    in deze privacyverklaring worden beschreven of die anderszins aan u duidelijk worden gemaakt op de
                    relevante Diensten. Houd er rekening mee dat wij geen controle hebben over, en niet verantwoordelijk
                    zijn voor, ander gebruik van uw persoonlijke informatie door uw derde partij sociale media provider.
                    Wij raden u aan hun privacyverklaring te bekijken waarin ze beschrijven hoe ze uw persoonlijke
                    informatie verzamelen, gebruiken en delen, en hoe u uw privacyvoorkeuren kunt instellen op hun sites
                    en apps.
                </li>
                <li>
                    <strong>Applicatiegegevens:</strong> als u onze Diensten gebruikt, kunnen wij de volgende informatie
                    verzamelen als u ons toegang of toestemming geeft:
                    <ul>
                        <li>
                            <em>Geolocatie-informatie:</em> Wij kunnen geolocatie-informatie opvragen wanneer u externe
                            apparaten op onze Diensten aansluit. Deze informatie is alleen bedoeld om de
                            apparaatverbinding te maken. Wij slaan de informatie niet op.
                        </li>
                        <li>
                            <em>Toegang tot mobiele apparaten:</em> Wij kunnen toegang of toestemming vragen voor
                            bepaalde functies van uw mobiele apparaat, inclusief de camera, microfoon en
                            afbeeldingsbibliotheek van uw mobiele apparaat. Als u onze toegang of permissies wilt
                            wijzigen, kunt u dit doen in de instellingen van uw apparaat.
                        </li>
                        <li>
                            <em>Pushmeldingen:</em> Wij kunnen verzoeken om u pushmeldingen te sturen met betrekking tot
                            uw account of bepaalde functies van de applicatie(s). Als u deze communicatie niet wilt
                            ontvangen, kunt u deze uitschakelen in de instellingen van uw apparaat. Deze informatie is
                            voornamelijk nodig om de veiligheid en werking van onze Diensten te waarborgen, voor het
                            oplossen van problemen en voor onze interne analyses en rapportagedoeleinden.
                        </li>
                    </ul>
                </li>
            </ul>
            Deze informatie is voornamelijk nodig om de veiligheid en werking van onze Diensten te waarborgen, voor het
            oplossen van problemen en voor onze interne analyses en rapportagedoeleinden.
            <h3>1.2 Informatie automatisch verzameld via technologie</h3>
            <p>
                Wanneer u onze Site, App en dus Diensten gebruikt, wordt bepaalde informatie automatisch verzameld. Deze
                informatie is niet herleidbaar tot uw specifieke identiteit (zoals uw naam of contactgegevens), maar kan
                apparaat- en gebruiksinformatie bevatten.
            </p>
            <ul>
                <li>
                    <strong>Gebruiksinformatie:</strong> This may include your device’s operating system, IP address,
                    browser type and language preferences, device name, country, location and unique device identifier
                    (UDID).<br /><br />This information is primarily needed to maintain the security and operation of
                    our Services, and for our internal analytics and reporting purposes.
                    <ul>
                        <li>
                            <em>Mobiele apparaatgegevens:</em> Wij verzamelen automatisch apparaatgegevens (zoals uw
                            mobiele apparaat-ID, model en fabrikant), besturingssysteem, versie-informatie en
                            systeemconfiguratie-informatie, apparaat- en applicatie-identificatienummers, browsertype en
                            versie, hardwaremodel, internetprovider en/of mobiele provider, en internetprotocol
                            (IP)-adres (of proxyserver). Als u onze Diensten gebruikt, kunnen wij ook informatie
                            verzamelen over het telefoonnetwerk dat aan uw mobiele apparaat is gekoppeld, het
                            besturingssysteem of platform van uw mobiele apparaat, het type mobiel apparaat dat u
                            gebruikt, de unieke apparaat-ID van uw mobiele apparaat, en informatie over de functies van
                            onze Diensten die u hebt geopend.
                        </li>
                        <li>
                            <em>Logboek- en gebruiksgegevens:</em> Log- en gebruiksgegevens zijn servicegerelateerde,
                            diagnostische, gebruiks- en prestatie-informatie die onze servers automatisch verzamelen
                            wanneer u toegang krijgt tot of gebruik maakt van onze Diensten en die wij in logbestanden
                            registreren. Afhankelijk van hoe u met ons omgaat, kunnen deze logboekgegevens uw IP-adres,
                            apparaatinformatie, browsertype en -instellingen en informatie over uw activiteiten in de
                            Diensten omvatten (zoals de datum/tijdstempels die verband houden met uw gebruik, pagina's
                            en bestanden die zijn bekeken, zoekopdrachten en andere acties die u onderneemt, zoals welke
                            functies u gebruikt), apparaatgebeurtenisinformatie (zoals systeemactiviteit, foutmeldingen
                            (soms "crash dumps" genoemd) en hardware-instellingen). Deze logboek- en gebruiksgegevens
                            worden anoniem verzameld en kunnen niet worden herleid tot individuele gebruikers.
                        </li>
                        <li>
                            <em>Apparaatgegevens:</em> Wij verzamelen apparaatgegevens zoals informatie over uw
                            computer, telefoon, tablet of ander apparaat dat u gebruikt om toegang te krijgen tot de
                            Diensten. Afhankelijk van het gebruikte apparaat kunnen deze apparaatgegevens informatie
                            omvatten zoals uw IP-adres (of proxyserver), apparaat- en applicatie-identificatienummers,
                            locatie, browsertype, hardwaremodel, internetprovider en/of mobiele provider,
                            besturingssysteem en systeemconfiguratie-informatie.
                        </li>
                    </ul>
                </li>
                <li>
                    <strong>Cookies:</strong> Wij kunnen cookies en soortgelijke trackingtechnologieën gebruiken om
                    gebruikerssessiegegevens op te slaan en op te halen om onze Diensten te verbeteren. Meer informatie
                    vindt u in ons cookiebeleid.
                </li>
            </ul>
            <h2>2. Gebruik van uw gegevens</h2>
            <p>
                Wij gebruiken de persoonlijke informatie die wordt verzameld via onze Diensten voor doeleinden die in
                dit beleid worden beschreven of aan u worden meegedeeld in verband met onze Diensten. Wij gebruiken de
                gegevens om te beheren, te communiceren, om veiligheidsredenen, voor fraudepreventie en om te voldoen
                aan de wet. Wij kunnen uw informatie ook voor andere doeleinden verwerken mits met uw toestemming. Wij
                verwerken uw persoonlijke informatie om verschillende redenen, afhankelijk van hoe u met onze diensten
                omgaat, waaronder:
            </p>
            <ul>
                <li>
                    <strong
                        >Om het aanmaken van een account en authenticatie te vergemakkelijken en anderszins
                        gebruikersaccounts te beheren.
                    </strong>
                    Wij kunnen uw informatie verwerken zodat u een account kunt aanmaken en inloggen, en uw account in
                    werking te kunnen houden.
                </li>
                <li>
                    <strong>Om diensten aan de gebruiker te leveren en te faciliteren.</strong> Wij kunnen uw informatie
                    verwerken om u de gevraagde dienst te leveren.
                </li>
                <li>
                    <strong>Om te reageren op gebruikersvragen en ondersteuning te bieden aan gebruikers. </strong> Wij
                    kunnen uw informatie verwerken om te reageren op uw vragen en eventuele problemen die u heeft met de
                    gevraagde dienst op te lossen.
                </li>
                <li>
                    <strong>Om administratieve informatie naar u te verzenden.</strong> Wij kunnen uw informatie
                    verwerken om u details te sturen over onze producten en diensten, wijzigingen in onze voorwaarden en
                    beleid te melden of te benaderen met soortgelijke informatie.
                </li>
                <li>
                    <strong>Om uw abonnementen te vervullen en te beheren.</strong> Wij kunnen uw informatie verwerken
                    om uw betalingen via de Diensten te vervullen en te beheren.
                </li>
                <li>
                    <strong>Om communicatie tussen gebruikers mogelijk te maken.</strong>Wij kunnen uw informatie
                    verwerken als u ervoor kiest om een van onze functionaliteiten te gebruiken die communicatie met een
                    andere gebruiker mogelijk maakt.
                </li>
                <li>
                    <strong>Om feedback te vragen.</strong> Wij kunnen uw informatie verwerken wanneer dat nodig is om
                    feedback te vragen en contact met u op te nemen over uw gebruik van onze Diensten.
                </li>
                <li>
                    <strong>Om u marketing- en promotionele communicatie te sturen.</strong> Wij kunnen de persoonlijke
                    informatie die u ons stuurt verwerken voor onze marketingdoeleinden, indien dit in overeenstemming
                    is met uw marketingvoorkeuren. U kunt zich op elk moment afmelden voor onze marketing-e-mails.
                </li>
                <li>
                    <strong>Om onze Diensten te beschermen. </strong> Wij kunnen uw informatie verwerken als onderdeel
                    van onze inspanningen om onze Diensten veilig en beveiligd te houden, inclusief fraudemonitoring en
                    -preventie.
                </li>
                <li>
                    <strong>Om gebruikstrends te identificeren. </strong> Wij kunnen informatie verwerken over hoe u
                    onze Diensten gebruikt om beter te begrijpen hoe ze worden gebruikt zodat we ze kunnen verbeteren.
                </li>
                <li>
                    <strong>Om de effectiviteit van onze marketing- en promotiecampagnes te bepalen. </strong> Wij
                    kunnen uw informatie verwerken om beter te begrijpen hoe we marketing- en promotiecampagnes kunnen
                    aanbieden die het meest relevant voor u zijn.
                </li>
                <li>
                    <strong>Om te kunnen acteren op en ter bescherming van individuen. </strong> We mogen uw informatie
                    verwerken wanneer dit nodig is om een invididu te kunnen beschermen, bijvoorbeeld om leed te
                    voorkomen.
                </li>
            </ul>
            <p>
                We verwerken je persoonlijke gegevens alleen wanneer we van mening zijn dat dit noodzakelijk is en we
                een geldige juridische reden (d.w.z. wettelijke basis) hebben om dit te doen volgens de toepasselijke
                wetgeving, zoals met jouw toestemming, om te voldoen aan wetten, om je van diensten te voorzien om onze
                contractuele verplichtingen na te komen, om je rechten te beschermen of om onze legitieme zakelijke
                belangen te vervullen.
            </p>
            <p>
                De Algemene Verordening Gegevensbescherming (AVG) en de Britse AVG vereisen dat we de geldige juridische
                grondslagen uitleggen waarop we ons baseren om je persoonlijke gegevens te verwerken. Daarom kunnen we
                ons baseren op de volgende juridische grondslagen om je persoonlijke gegevens te verwerken:
            </p>
            <ul>
                <li>
                    <strong>Toestemming.</strong> Wij kunnen uw gegevens verwerken als u ons toestemming hebt gegeven
                    (d.w.z. toestemming) om uw persoonlijke gegevens voor een specifiek doel te gebruiken. U kunt uw
                    toestemming op elk moment intrekken. Zie de sectie gegevensbeoordeling en verwijdering voor
                    informatie over hoe u uw toestemming kunt intrekken.
                </li>
                <li>
                    <strong>Uitvoering van een overeenkomst.</strong> Wij kunnen uw persoonlijke gegevens verwerken
                    wanneer wij van mening zijn dat dit noodzakelijk is om onze contractuele verplichtingen jegens u na
                    te komen, inclusief het leveren van onze diensten of op uw verzoek voorafgaand aan het aangaan van
                    een contract met u.
                </li>
                <li>
                    <strong>Gerechtvaardigd belang.</strong>Wij kunnen uw gegevens verwerken wanneer wij van mening zijn
                    dat dit redelijkerwijs noodzakelijk is om onze legitieme zakelijke belangen te verwezenlijken en
                    deze belangen niet zwaarder wegen dan uw belangen en fundamentele rechten en vrijheden. Wij kunnen
                    uw persoonlijke gegevens verwerken voor bijvoorbeeld de volgende doeleinden:
                    <ul>
                        <li>
                            Gebruikers informatie sturen over speciale aanbiedingen en kortingen op onze producten en
                            diensten
                        </li>
                        <li>
                            Gepersonaliseerde en relevante advertentie-inhoud voor onze gebruikers ontwikkelen en
                            weergeven
                        </li>
                        <li>
                            Analyseren hoe onze diensten worden gebruikt zodat wij deze kunnen verbeteren om gebruikers
                            te betrekken en te behouden
                        </li>
                        <li>Onze marketingactiviteiten ondersteunen</li>
                        <li>Problemen diagnosticeren en/of frauduleuze activiteiten voorkomen</li>
                        <li>
                            Begrijpen hoe onze gebruikers onze producten en diensten gebruiken zodat wij de
                            gebruikerservaring kunnen verbeteren
                        </li>
                    </ul>
                </li>
                <li>
                    <strong>Wettelijke verplichtingen.</strong> Wij kunnen uw gegevens verwerken wanneer wij van mening
                    zijn dat dit noodzakelijk is om te voldoen aan onze wettelijke verplichtingen, zoals samenwerken met
                    een wetshandhavingsinstantie of regelgevende instantie, het uitoefenen of verdedigen van onze
                    wettelijke rechten, of het bekendmaken van uw gegevens als bewijs in een rechtszaak waarin wij
                    betrokken zijn.
                </li>
                <li>
                    <strong>Vitale belangen.</strong> Wij kunnen uw gegevens verwerken wanneer wij van mening zijn dat
                    dit noodzakelijk is om uw vitale belangen of die van een derde te beschermen, zoals in situaties
                    waarin er sprake is van potentiële bedreigingen voor de veiligheid van een persoon.
                </li>
            </ul>
            <p>
                In juridische termen zijn wij over het algemeen de “gegevensbeheerder” onder de Europese
                gegevensbeschermingswetten van de persoonlijke informatie die in deze privacyverklaring wordt
                beschreven, omdat wij de middelen en/of doeleinden van de gegevensverwerking die wij uitvoeren bepalen.
            </p>
            <h2>3. Betalingsgegevens</h2>
            <p>
                Als u zich aanmeldt voor onze premium diensten of aankopen doet, kunnen uw betalingsgegevens worden
                verzameld en opgeslagen door onze betalingsverwerkers. Het verzamelen van deze gegevens is noodzakelijk
                om het betalingsproces te voltooien. Dit kan krediet- of debetkaartinformatie, postcode, mobiel nummer
                en transactiegeschiedenis omvatten. Betalingsverwerkers kunnen ons beperkte informatie verstrekken,
                zoals een unieke token voor aanvullende aankopen, kaarttype, vervaldatum en bepaalde cijfers van uw
                kaartnummer.
            </p>
            <h2>4. Delen van persoonlijke informatie</h2>
            <ul>
                <li>
                    <strong>Leveranciers, consultants en andere derde partij dienstverleners</strong> Wij kunnen uw
                    gegevens delen met externe leveranciers, dienstverleners, onderaannemers of partijen ("derden") die
                    diensten voor ons of namens ons uitvoeren en toegang tot dergelijke informatie nodig hebben om dat
                    werk te doen. Wij hebben contracten met deze partijen, die zijn onderworpen om uw persoonlijke
                    gegevens te beschermen. Dit betekent dat zij niets met uw persoonlijke gegevens kunnen doen tenzij
                    wij hen hebben geïnstrueerd om dat te doen. Zij zullen uw persoonlijke gegevens ook niet delen met
                    enige andere organisatie dan ons. Zij verplichten zich ook om de gegevens die zij namens ons bewaren
                    te beschermen en deze te bewaren voor de periode die wij hen instrueren. De categorieën van derden
                    waarmee wij persoonlijke informatie kunnen delen zijn de volgende:
                    <ul>
                        <li>Advertentienetwerken</li>
                        <li>Affiliate marketing programma's</li>
                        <li>Cloud computing diensten</li>
                        <li>Communicatie- en samenwerkingstools</li>
                        <li>Data-analyse diensten</li>
                        <li>Dataopslag dienstverleners</li>
                        <li>Financiële en boekhoudkundige tools</li>
                        <li>Overheidsinstanties</li>
                        <li>Orderverwerkingsdienstverleners</li>
                        <li>Betalingsverwerkers</li>
                        <li>Prestatiemonitoring tools</li>
                        <li>Product engineering &amp; ontwerptools</li>
                        <li>Retargeting platforms</li>
                        <li>Verkoop- en marketingtools</li>
                        <li>Sociale netwerken</li>
                        <li>Testtools</li>
                        <li>Gebruikersaccount registratie- en authenticatiediensten</li>
                        <li>Website hosting dienstverleners</li>
                    </ul>
                    Het is ook mogelijk dat we uw persoonlijke informatie moeten delen in de volgende gevallen:
                </li>
                <li>
                    <strong>Bedrijfsoverdrachten.</strong>Wij kunnen uw gegevens delen of overdragen in verband met, of
                    tijdens onderhandelingen over, een fusie, verkoop van bedrijfsmiddelen, financiering of overname van
                    ons gehele bedrijf of een deel daarvan door een ander bedrijf.
                </li>
                <li>
                    <strong>Wanneer wij Google Analytics gebruiken.</strong> Wij kunnen uw gegevens delen met Google
                    Analytics om het gebruik van de diensten te volgen en te analyseren. Om u af te melden voor tracking
                    door Google Analytics via de diensten, bezoek
                    <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>. U
                    kunt zich afmelden voor Google Analytics-advertentiefuncties via de advertentie-instellingen en
                    advertentie-instellingen voor mobiele apps. Voor meer informatie over het privacybeleid van Google,
                    bezoek de Google Privacy & Voorwaarden-pagina.
                </li>
                <li>
                    <strong>Zakelijke partners.</strong> Wij kunnen uw gegevens delen met onze zakelijke partners om u
                    bepaalde producten, diensten of promoties aan te bieden.
                </li>
                <li>
                    <strong>Andere gebruikers.</strong> Wanneer u persoonlijke informatie deelt (bijvoorbeeld door
                    opmerkingen, bijdragen of andere inhoud te plaatsen op de diensten) of anderszins interactie hebt
                    met openbare gebieden van de diensten, kan deze persoonlijke informatie door alle gebruikers worden
                    bekeken en mogelijk permanent openbaar beschikbaar worden gemaakt buiten de diensten. Als u
                    interacteert met andere gebruikers van onze diensten en zich registreert voor onze diensten via een
                    sociaal netwerk (zoals Facebook), zullen uw contacten op het sociale netwerk uw naam, profielfoto en
                    beschrijvingen van uw activiteit zien. Evenzo zullen andere gebruikers beschrijvingen van uw
                    activiteit kunnen bekijken, met u kunnen communiceren en uw profiel kunnen bekijken.
                </li>
            </ul>
            <h2>5. Ons standpunt over websites van derden</h2>
            <p>
                De diensten kunnen linken naar websites van derden, online diensten of mobiele applicaties en/of
                advertenties van derden bevatten. Dienovereenkomstig geven wij geen enkele garantie met betrekking tot
                dergelijke derden, en wij zijn niet aansprakelijk voor enig verlies of schade veroorzaakt door het
                gebruik van dergelijke websites, diensten of applicaties van derden. Wij kunnen de veiligheid en privacy
                van gegevens die u aan derden verstrekt niet garanderen. Gegevens die door derden worden verzameld,
                vallen niet onder deze privacyverklaring. Wij zijn niet verantwoordelijk voor de inhoud of privacy en
                beveiligingsvormen en -beleid van derden, inclusief andere websites, diensten of applicaties die
                mogelijk gelinkt zijn aan of van de diensten. U dient het beleid van dergelijke derden te beoordelen en
                hen rechtstreeks te contacteren om op uw vragen te reageren.
            </p>
            <h2>6. Internationale gegevensoverdracht</h2>
            <p>
                Onze servers bevinden zich in Nederland en Duitsland. Als u onze diensten vanuit een ander land dan
                Nederland of Duitsland benadert, wees u er dan van bewust dat uw informatie mogelijk wordt overgedragen
                aan, opgeslagen en verwerkt door ons in onze faciliteiten en door die derde partijen met wie wij uw
                persoonlijke gegevens kunnen delen.<br /><br />Wij hebben maatregelen geïmplementeerd om uw persoonlijke
                gegevens te beschermen, waaronder het gebruik van de standaardcontractbepalingen van de Europese
                Commissie voor overdrachten van persoonlijke gegevens tussen onze groepsmaatschappijen en tussen ons en
                onze externe dienstverleners. Deze clausules vereisen dat alle ontvangers alle persoonlijke gegevens die
                zij verwerken en afkomstig zijn uit de EER of het VK beschermen in overeenstemming met de Europese
                gegevensbeschermingswetten en -voorschriften. Wij hebben soortgelijke passende waarborgen
                geïmplementeerd met onze externe dienstverleners en partners.
            </p>
            <h2>7. Uw keuzes met betrekking tot uw persoonlijke gegevens</h2>
            <p>
                U kunt kiezen welke communicatie u van ons ontvangt, uw openbare informatie beheren en uw voorkeuren
                instellen. U kunt zich afmelden voor promotionele e-mails door de instructies in die e-mails te volgen.
                Niet-promotionele e-mails met betrekking tot uw account of lopende zakelijke relaties zullen nog steeds
                worden verzonden.<br /><br />De meeste webbrowsers en sommige mobiele besturingssystemen en mobiele
                applicaties bevatten een Do-Not-Track ("DNT")-functie of -instelling die u kunt activeren om uw
                privacyvoorkeur aan te geven om niet te worden gevolgd en gegevens over uw online browse-activiteiten te
                laten verzamelen. Op dit moment is er geen uniforme technologiestandaard voor het herkennen en
                implementeren van DNT-signalen vastgesteld. Daarom reageren wij momenteel niet op DNT-browsersignalen of
                enige andere mechanismen die automatisch uw keuze communiceren om niet online te worden gevolgd. Als een
                standaard voor online tracking wordt aangenomen die wij in de toekomst moeten volgen, zullen wij u over
                die praktijk informeren in een herziene versie van deze privacyverklaring.
            </p>
            <h2>8. Beveiliging van uw persoonlijke gegevens</h2>
            <p>
                Wij gebruiken industriestandaard beveiligingstechnologieën om uw persoonlijke gegevens te beschermen
                tegen ongeautoriseerde toegang, gebruik of openbaarmaking. Geen enkele beveiligingsmaatregel is echter
                volledig veilig, en wij kunnen de absolute veiligheid van uw gegevens niet garanderen.
            </p>
            <h2>9. Opslag en veiligheid van uw gegevens</h2>
            <p>
                Wij zullen uw persoonlijke gegevens alleen bewaren zolang het nodig is voor de doeleinden die in deze
                privacyverklaring worden uiteengezet, tenzij een langere bewaartermijn vereist of toegestaan is door de
                wet (zoals voor belasting-, boekhoudkundige of andere wettelijke vereisten). Geen enkel doel in deze
                kennisgeving zal ons verplichten uw persoonlijke gegevens langer te bewaren dan de periode waarvoor u
                een DartCounter-account hebt, tenzij de bewaartermijn volgens de wet langer is.
            </p>
            <p>
                Wanneer wij geen voortdurende legitieme zakelijke noodzaak hebben om uw persoonlijke gegevens te
                verwerken, zullen wij dergelijke gegevens verwijderen of anonimiseren.
            </p>
            <p>
                Wij hebben passende en redelijke technische en organisatorische beveiligingsmaatregelen geïmplementeerd
                die zijn ontworpen om de beveiliging van alle persoonlijke gegevens die wij verwerken te beschermen.
                Ondanks onze waarborgen en inspanningen om uw gegevens te beveiligen, kan geen enkele elektronische
                overdracht via internet of informatieopslagtechnologie 100% veilig worden gegarandeerd, dus wij kunnen
                niet beloven of garanderen dat hackers, cybercriminelen of andere ongeautoriseerde derden niet in staat
                zullen zijn onze beveiliging te omzeilen en uw gegevens onrechtmatig te verzamelen, te openen, te stelen
                of te wijzigen. Hoewel wij ons best zullen doen om uw persoonlijke gegevens te beschermen, is de
                overdracht van persoonlijke gegevens van en naar onze diensten op eigen risico. U dient de diensten
                alleen binnen een veilige omgeving te openen.
            </p>
            <h2>10. Gegevensverzameling van minderjarigen</h2>
            <p>
                Om minderjarigen te beschermen, worden actieve maatregelen genomen om het gebruik van camera- en
                chatfunctionaliteiten voor gebruikers onder de 18 jaar uit te schakelen. Het verzamelen en gebruiken van
                gegevens van minderjarigen is verder niet anders dan andere gegevensverzameling die in dit privacybeleid
                wordt beschreven, met uitzondering van gegevens van minderjarigen die te allen tijde worden
                geanonimiseerd. Als wij ontdekken dat een gebruiker jonger dan 18 jaar onze diensten onrechtmatig
                gebruikt zoals beschreven, zal het account worden gedeactiveerd en zullen redelijke maatregelen worden
                genomen om dergelijke gegevens snel uit onze administratie te verwijderen. Als u enig onrechtmatig
                gebruik van de diensten door minderjarigen onder de 18 jaar opmerkt, neem dan contact met ons op via
                <a href="support@dartcounter.net">support&#64;dartcounter.net</a>.
            </p>
            <h2>11. Gegevensbeoordeling en verwijdering</h2>
            <p>
                In sommige regio's (zoals de EER, het VK, Zwitserland en Canada) heeft u bepaalde rechten onder de
                toepasselijke gegevensbeschermingswetten. Deze kunnen het recht omvatten (i) om toegang te vragen tot en
                een kopie te verkrijgen van uw persoonlijke gegevens, (ii) om rectificatie of verwijdering te verzoeken;
                (iii) om de verwerking van uw persoonlijke gegevens te beperken; (iv) indien van toepassing, op
                gegevensoverdraagbaarheid; en (v) om niet te worden onderworpen aan geautomatiseerde besluitvorming. In
                bepaalde omstandigheden kunt u ook het recht hebben om bezwaar te maken tegen de verwerking van uw
                persoonlijke gegevens. U kunt een dergelijk verzoek indienen door contact met ons op te nemen via
                <a href="support@dartcounter.net">support&#64;dartcounter.net</a>. Wij zullen binnen twee werkdagen
                reageren.
            </p>
            <p>
                Op uw verzoek om uw account te beëindigen, zullen wij uw account en informatie in onze actieve databases
                deactiveren of verwijderen. Wij kunnen echter bepaalde informatie in onze bestanden bewaren om fraude te
                voorkomen, problemen op te lossen, te helpen bij onderzoeken, onze juridische voorwaarden af te dwingen
                en/of te voldoen aan toepasselijke wettelijke vereisten.
            </p>
            <p>U hebt het recht om te weten:</p>
            <ul>
                <li>of wij uw persoonlijke gegevens verzamelen en gebruiken;</li>
                <li>de categorieën van persoonlijke gegevens die wij verzamelen;</li>
                <li>de doeleinden waarvoor de verzamelde persoonlijke gegevens worden gebruikt;</li>
                <li>of wij uw persoonlijke gegevens aan derden verkopen of delen;</li>
                <li>
                    de categorieën van persoonlijke gegevens die wij voor zakelijke doeleinden hebben verkocht, gedeeld
                    of openbaar gemaakt;
                </li>
                <li>
                    de categorieën van derden aan wie de persoonlijke gegevens zijn verkocht, gedeeld of openbaar
                    gemaakt voor een zakelijke doel;
                </li>
                <li>
                    het zakelijke of commerciële doel voor het verzamelen, delen of verkopen van persoonlijke gegevens;
                    en
                </li>
                <li>de specifieke stukken persoonlijke gegevens die wij over u hebben verzameld.</li>
            </ul>
            <p>
                Bij het ontvangen van uw verzoek zullen wij uw identiteit moeten verifiëren om te bepalen of u dezelfde
                persoon bent over wie wij de informatie in ons systeem hebben. Deze verificatie-inspanningen kunnen
                vereisen dat wij u vragen om informatie te verstrekken zodat wij deze kunnen vergelijken met de
                informatie die u ons eerder heeft verstrekt. Afhankelijk van het type verzoek dat u indient, kunnen wij
                u bijvoorbeeld vragen om bepaalde informatie te verstrekken zodat wij de informatie die u verstrekt
                kunnen vergelijken met de informatie die wij al in ons bestand hebben, of wij kunnen contact met u
                opnemen via een communicatiemethode (e-mail) die u ons eerder hebt verstrekt. Wij kunnen ook andere
                verificatiemethoden gebruiken, afhankelijk van de omstandigheden.
            </p>
            <p>
                Wij zullen persoonlijke gegevens die in uw verzoek zijn verstrekt alleen gebruiken om uw identiteit of
                bevoegdheid om het verzoek in te dienen te verifiëren. Voor zover mogelijk zullen wij vermijden
                aanvullende informatie van u te vragen voor verificatiedoeleinden. Als wij uw identiteit echter niet
                kunnen verifiëren aan de hand van de informatie die wij al hebben, kunnen wij vragen om aanvullende
                informatie voor verificatiedoeleinden en voor veiligheids- of fraudepreventiedoeleinden. Wij zullen
                dergelijke aanvullende informatie verwijderen zodra wij klaar zijn met de verificatie.
            </p>
            <p>
                U kunt zich op elk moment afmelden voor onze marketing- en promotionele communicatie door te klikken op
                de afmeldlink in de e-mails die wij verzenden of door uw voorkeuren bij te werken in uw
                gebruikersprofiel. U wordt dan verwijderd van de marketinglijsten — echter, wij kunnen nog steeds met u
                communiceren, bijvoorbeeld om u servicegerelateerde berichten te sturen die noodzakelijk zijn voor het
                beheer en gebruik van uw account, om te reageren op serviceverzoeken, of voor andere
                niet-marketingdoeleinden.
            </p>
            <h2>12. Contactinformatie</h2>
            <p>
                Voor vragen, opmerkingen of klachten over ons privacybeleid of gegevensverwerking, of om privacy of
                beveiligingsschendingen te melden, neem contact met ons op via
                <a href="support@dartcounter.net">support&#64;dartcounter.net</a>
            </p>
            <h2>13. Wijzigingen in dit privacybeleid</h2>
            <p>
                Wij kunnen dit privacybeleid van tijd tot tijd herzien. De bijgewerkte versie wordt aangegeven door een
                bijgewerkte revisiedatum. De bijgewerkte versie wordt 30 dagen na e-mailmelding of bekendmaking aan
                bestaande gebruikers van kracht en per direct voor nieuwe gebruikers. Zodra wij wijzigingen aanbrengen,
                zullen wij u via e-mail en/of een prominente kennisgeving op onze platforms informeren. Het is uw
                verantwoordelijkheid om ervoor te zorgen dat wij uw huidige e-mailadres hebben. Voortgezet gebruik van
                onze diensten na kennisgeving van wijzigingen geeft aan dat u instemt met het gewijzigde beleid. Wij
                raden u aan dit privacybeleid regelmatig te bekijken om te weten te komen hoe wij uw informatie
                beschermen.
            </p>
        } @else {
            <p><strong>Last Revised:</strong>&nbsp;May 29, 2024</p>
            <p>
                <strong>Data controller</strong><br />
                DartCounter<br />
                Pollaan 48A<br />
                7202 BX Zutphen
            </p>
            <p>
                DartCounter ("Company," "we," or "us") is committed to protecting your privacy. This Privacy Policy
                ("Policy") describes our practices regarding the personal information we collect from users of our
                website (https://dartcounter.net) ( "Site"), our mobile application ("App"), and online services as a
                whole ("Services") in instances such as when
            </p>
            <ul>
                <li>You visit our website, or any website of ours that links to this privacy notice</li>
                <li>When you download our App, DartCounter</li>
                <li>Engage with us in other related ways, including any sales marketing or events</li>
            </ul>
            <p>
                <strong>Questions or concerns?</strong> Reading this privacy notice will help you understand your
                privacy rights and choices. If you do not agree with our policies and practices, please do not use our
                Services. If you still have any questions or concerns, please contact us at
                <a href="mailto:support@dartcounter.net">support&#64;dartcounter.net</a>.
            </p>
            <h2>1. Types Of Data We Collect</h2>
            <h3>1.1 Information You Provide</h3>
            <p>
                We collect information about you in various ways when you use our Services. By agreeing to our Privacy
                Policy, you consent to our collection of this information and permit us to use it as described.The
                information that we collect depends on the context of your interactions with us and our Services, the
                choices you make, and the features you use. The information we collect may include the following:
            </p>
            <ul>
                <li>
                    <strong>Personal Information:</strong> When you register for a personal account, we collect your
                    email address. Optionally you can provide your name, date of birth and a profile picture. If you
                    provide feedback or contact us, we collect the data included in your communication. When
                    participating in promotions, we collect the data you provide related to the promotion.
                </li>
                <li>
                    <strong>Sensitive Information:</strong> Though optional at any time, we require you to provide your
                    date of birth when using any camera or chat related features in our Services to adhere to law and
                    protect minors using our Services.
                </li>
                <li>
                    <strong>Social Media Login Data:</strong> Our Services offer you the ability to register and log in
                    using your third-party social media account details (Facebook and Google logins). Where you choose
                    to do this, we will receive certain profile information about you from your social media provider.
                    The profile information we receive may vary depending on the social media provider concerned. We
                    will collect your name and email address.<br />We will use the information we receive only for the
                    purposes that are described in this privacy notice or that are otherwise made clear to you on the
                    relevant Services. Please note that we do not control, and are not responsible for other uses of
                    your personal information by your third-party social media provider. We recommend that you review
                    their privacy notice to understand how they collect, use and share your personal information, and
                    how you can set your privacy preferences on their sites and apps.
                </li>
                <li>
                    <strong>Application Data:</strong> if your use our Services, we may collect the following
                    information if you choose to provide us with access or permission:
                    <ul>
                        <li>
                            <em>Geolocation Information:</em> We may request geolocation information when connecting
                            external devices to our Services. This information is only to create the device connection.
                            We do not store the information.
                        </li>
                        <li>
                            <em>Mobile Device access:</em> We may request access or permission to certain features from
                            your mobile device, including your mobile device’s camera, microphone and image library. If
                            you wish to change our access or permissions, you may do so in your device’s settings.
                        </li>
                        <li>
                            <em>Push Notifications:</em> We may request to send you push notifications regarding your
                            account or certain features of the application(s). If you wish to opt out from receiving
                            these types of communications, you may turn them off in your device's settings.
                        </li>
                    </ul>
                </li>
            </ul>
            This information is primarily needed to maintain the security and operation of our Services, for
            troubleshooting and for our internal analytics and reporting purposes.
            <h3>1.2 Information Automatically Collected via Technology</h3>
            <p>
                When you use our Site, App and thus Services, some information is automatically collected. This
                information does not reveal your specific identity (like your name or contact information), but may
                include device and usage information.
            </p>
            <ul>
                <li>
                    <strong>Usage Information:</strong> This may include your device’s operating system, IP address,
                    browser type and language preferences, device name, country, location and unique device identifier
                    (UDID).<br /><br />This information is primarily needed to maintain the security and operation of
                    our Services, and for our internal analytics and reporting purposes.
                    <ul>
                        <li>
                            <em>Mobile Device Data:</em> We automatically collect device information (such as your
                            mobile device ID, model, and manufacturer), operating system, version information and system
                            configuration information, device and application identification numbers, browser type and
                            version, hardware model, Internet service provider and/or mobile carrier, and Internet
                            Protocol (IP) address (or proxy server). If you are using our Services, we may also collect
                            information about the phone network associated with your mobile device, your mobile device’s
                            operating system or platform, the type of mobile device you use, your mobile device’s unique
                            device ID, and information about the features of our Services you accessed.
                        </li>
                        <li>
                            <em>Log and Usage Data:</em> Log and usage data is service-related, diagnostic, usage, and
                            performance information our servers automatically collect when you access or use our
                            Services and which we record in log files. Depending on how you interact with us, this log
                            data may include your IP address, device information, browser type, and settings and
                            information about your activity in the Services (such as the date/time stamps associated
                            with your usage, pages and files viewed, searches, and other actions you take such as which
                            features you use), device event information (such as system activity, error reports
                            (sometimes called "crash dumps"), and hardware settings). This log and usage data is
                            collected anonymously and cannot be traced to individual users.
                        </li>
                        <li>
                            <em>Device Data:</em> We collect device data such as information about your computer, phone,
                            tablet, or other device you use to access the Services. Depending on the device used, this
                            device data may include information such as your IP address (or proxy server), device and
                            application identification numbers, location, browser type, hardware model, Internet service
                            provider and/or mobile carrier, operating system, and system configuration information.
                        </li>
                    </ul>
                </li>
                <li>
                    <strong>Cookies:</strong> We may use cookies and similar tracking technologies to store and retrieve
                    user session data to improve our Services. More information can be found in our cookie notice.
                </li>
            </ul>
            <h2>2. Use of Your Data</h2>
            <p>
                We use the personal information collected through our Services for purposes described in this policy or
                disclosed to you in connection with our Services. We use the data to administer, communicate, for
                security reasons, for fraud prevention as well as to comply with law. We may also process your
                information for other purposes with your consent. We process your personal information for a variety of
                reasons, depending on how you interact with our services, including:
            </p>
            <ul>
                <li>
                    <strong
                        >To facilitate account creation and authentication and otherwise manage user accounts.</strong
                    >
                    We may process your information so you can create and log in to your account, as well as keep your
                    account in working order.
                </li>
                <li>
                    <strong>To deliver and facilitate delivery of services to the user</strong> We may process your
                    information to provide you with the requested service.
                </li>
                <li>
                    <strong>To respond to user inquiries/offer support to users.</strong> We may process your
                    information to respond to your inquiries and solve any potential issues you might have with the
                    requested service.
                </li>
                <li>
                    <strong>To send administrative information to you.</strong> We may process your information to send
                    you details about our products and services, changes to our terms and policies, and other similar
                    information.
                </li>
                <li>
                    <strong>To fulfill and manage your subscriptions.</strong> We may process your information to
                    fulfill and manage your payments made through the Services.
                </li>
                <li>
                    <strong>To enable user-to-user communications.</strong> We may process your information if you
                    choose to use any of our offerings that allow for communication with another user. To request
                    feedback. We may process your information when necessary to request feedback and to contact you
                    about your use of our Services.
                </li>
                <li>
                    <strong>To request feedback.</strong> We may process your information when necessary to request
                    feedback and to contact you about your use of our Services.
                </li>
                <li>
                    <strong>To send you marketing and promotional communications.</strong> We may process the personal
                    information you send to us for our marketing purposes, if this is in accordance with your marketing
                    preferences. You can opt out of our marketing emails at any time.
                </li>
                <li>
                    <strong>To protect our Services.</strong> We may process your information as part of our efforts to
                    keep our Services safe and secure, including fraud monitoring and prevention. To identify usage
                    trends. We may process information about how you use our Services to better understand how they are
                    being used so we can improve them.
                </li>
                <li>
                    <strong>To identify usage trends.</strong> We may process information about how you use our Services
                    to better understand how they are being used so we can improve them.
                </li>
                <li>
                    <strong>To determine the effectiveness of our marketing and promotional campaigns.</strong> We may
                    process your information to better understand how to provide marketing and promotional campaigns
                    that are most relevant to you.
                </li>
                <li>
                    <strong>To save or protect an individual's vital interest.</strong> We may process your information
                    when necessary to save or protect an individual’s vital interest, such as to prevent harm.
                </li>
            </ul>
            <p>
                We only process your personal information when we believe it is necessary and we have a valid legal
                reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply with laws,
                to provide you with services to enter into or fulfill our contractual obligations, to protect your
                rights, or to fulfill our legitimate business interests.
            </p>
            <p>
                The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we
                rely on in order to process your personal information. As such, we may rely on the following legal bases
                to process your personal information:
            </p>
            <ul>
                <li>
                    <strong>Consent.</strong> We may process your information if you have given us permission (i.e.,
                    consent) to use your personal information for a specific purpose. You can withdraw your consent at
                    any time. Please see the data review and deletion section on how consent can be withdrawn.
                </li>
                <li>
                    <strong>Performance of a contract.</strong> We may process your personal information when we believe
                    it is necessary to fulfill our contractual obligations to you, including providing our Services or
                    at your request prior to entering into a contract with you.
                </li>
                <li>
                    <strong>Legitimate interests.</strong> We may process your information when we believe it is
                    reasonably necessary to achieve our legitimate business interests and those interests do not
                    outweigh your interests and fundamental rights and freedoms. For example, we may process your
                    personal information for some of the purposes described in order to:
                    <ul>
                        <li>Send users information about special offers and discounts on our products and services</li>
                        <li>Develop and display personalised and relevant advertising content for our users</li>
                        <li>Analyse how our Services are used so we can improve them to engage and retain users</li>
                        <li>Support our marketing activities</li>
                        <li>Diagnose problems and/or prevent fraudulent activities</li>
                        <li>
                            Understand how our users use our products and services so we can improve user experience
                        </li>
                    </ul>
                </li>
                <li>
                    <strong>Legal obligations.</strong> We may process your information where we believe it is necessary
                    for compliance with our legal obligations, such as to cooperate with a law enforcement body or
                    regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in
                    litigation in which we are involved.
                </li>
                <li>
                    <strong>Vital interests.</strong> We may process your information where we believe it is necessary
                    to protect your vital interests or the vital interests of a third party, such as situations
                    involving potential threats to the safety of any person.
                </li>
            </ul>
            <p>
                In legal terms, we are generally the “data controller” under European data protection laws of the
                personal information described in this privacy notice, since we determine the means and/or purposes of
                the data processing we perform.
            </p>
            <h2>3. Payment Data</h2>
            <p>
                If you sign up for our Premium Services or make purchases, your payment information may be collected and
                stored by our payment processors. The collection of this data is necessary to fulfill the payment
                process. This may include credit or debit card information, postal code, mobile number, and transaction
                history. Payment processors may provide us with limited information such as a unique token for
                additional purchases, card type, expiration date, and certain digits of your card number.
            </p>
            <h2>4. Sharing Of Personal Information</h2>
            <ul>
                <li>
                    <strong>Vendors, Consultants, and Other Third-Party Service Providers.</strong> We may share your
                    data with third-party vendors, service providers, contractors, or agents (“third parties”) who
                    perform services for us or on our behalf and require access to such information to do that work. We
                    have contracts in place with our third parties, which are designed to help safeguard your personal
                    information. This means that they cannot do anything with your personal information unless we have
                    instructed them to do it. They will also not share your personal information with any organisation
                    apart from us. They also commit to protect the data they hold on our behalf and to retain it for the
                    period we instruct. The categories of third parties we may share personal information with are as
                    follows:
                    <ul>
                        <li>Ad Networks</li>
                        <li>Affiliate Marketing Programs</li>
                        <li>Cloud Computing Services</li>
                        <li>Communication &amp; Collaboration Tools</li>
                        <li>Data Analytics Services</li>
                        <li>Data Storage Service Providers</li>
                        <li>Finance &amp; Accounting Tools</li>
                        <li>Government Entities</li>
                        <li>Order Fulfillment Service Providers</li>
                        <li>Payment Processors</li>
                        <li>Performance Monitoring Tools</li>
                        <li>Product Engineering &amp; Design Tools</li>
                        <li>Retargeting Platforms</li>
                        <li>Sales &amp; Marketing Tools</li>
                        <li>Social Networks</li>
                        <li>Testing Tools</li>
                        <li>User Account Registration &amp; Authentication Services</li>
                        <li>Website Hosting Service Providers</li>
                    </ul>
                    We also may need to share your personal information in the following situations:
                </li>
                <li>
                    <strong>Business Transfers.</strong> We may share or transfer your information in connection with,
                    or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a
                    portion of our business to another company.
                </li>
                <li>
                    <strong>When we use Google Analytics.</strong> We may share your information with Google Analytics
                    to track and analyze the use of the Services. To opt out of being tracked by Google Analytics across
                    the Services, visit
                    <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>. You
                    can opt out of Google Analytics Advertising Features through Ads Settings and Ad Settings for mobile
                    apps. For more information on the privacy practices of Google, please visit the Google Privacy &
                    Terms page.
                </li>
                <li>
                    <strong>Business Partners.</strong> We may share your information with our business partners to
                    offer you certain products, services, or promotions.
                </li>
                <li>
                    <strong>Other Users.</strong> When you share personal information (for example, by posting comments,
                    contributions, or other content to the Services) or otherwise interact with public areas of the
                    Services, such personal information may be viewed by all users and may be publicly made available
                    outside the Services in perpetuity. If you interact with other users of our Services and register
                    for our Services through a social network (such as Facebook), your contacts on the social network
                    will see your name, profile photo, and descriptions of your activity. Similarly, other users will be
                    able to view descriptions of your activity, communicate with you within our Services, and view your
                    profile.
                </li>
            </ul>
            <h2>5. Our Stance on Third Party Websites</h2>
            <p>
                The Services may link to third-party websites, online services, or mobile applications and/or contain
                advertisements from third parties. Accordingly, we do not make any guarantee regarding any such third
                parties, and we will not be liable for any loss or damage caused by the use of such third-party
                websites, services, or applications. We cannot guarantee the safety and privacy of data you provide to
                any third parties. Any data collected by third parties is not covered by this privacy notice. We are not
                responsible for the content or privacy and security practices and policies of any third parties,
                including other websites, services, or applications that may be linked to or from the Services. You
                should review the policies of such third parties and contact them directly to respond to your questions.
            </p>
            <h2>6. International Data Transfer</h2>
            <p>
                Our servers are located in the Netherlands and Germany. If you are accessing our Services from outside
                the Netherlands and Germany, please be aware that your information may be transferred to, stored, and
                processed by us in our facilities and by those third parties with whom we may share your personal
                information.<br /><br />We have implemented measures to protect your personal information, including by
                using the European Commission's Standard Contractual Clauses for transfers of personal information
                between our group companies and between us and our third-party providers. These clauses require all
                recipients to protect all personal information that they process originating from the EEA or UK in
                accordance with European data protection laws and regulations. We have implemented similar appropriate
                safeguards with our third-party service providers and partners.
            </p>
            <h2>7. Your Choices Regarding Your Personal Information</h2>
            <p>
                You can choose which communications you receive from us, manage your public information, and set your
                sharing preferences. You may opt out of promotional emails by following the instructions in those
                emails. Non-promotional emails related to your account or ongoing business relations will still be
                sent.<br /><br />Most web browsers and some mobile operating systems and mobile applications include a
                Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have
                data about your online browsing activities monitored and collected. At this stage no uniform technology
                standard for recognising and implementing DNT signals has been finalised. As such, we do not currently
                respond to DNT browser signals or any other mechanism that automatically communicates your choice not to
                be tracked online. If a standard for online tracking is adopted that we must follow in the future, we
                will inform you about that practice in a revised version of this privacy notice.
            </p>
            <h2>8. Security of Your Personal Information</h2>
            <p>
                We use industry-standard security technologies to protect your personal information from unauthorised
                access, use, or disclosure. However, no security measure is completely secure, and we cannot guarantee
                the absolute security of your information.
            </p>
            <h2>9. Storage and Safety of Your Data</h2>
            <p>
                We will only keep your personal information for as long as it is necessary for the purposes set out in
                this privacy notice, unless a longer retention period is required or permitted by law (such as tax,
                accounting, or other legal requirements). No purpose in this notice will require us keeping your
                personal information for longer than the period for which you have a DartCounter account, unless the
                retention period by law exceeds that period.
            </p>
            <p>
                When we have no ongoing legitimate business need to process your personal information, we will either
                delete or anonymise such information.
            </p>
            <p>
                We have implemented appropriate and reasonable technical and organisational security measures designed
                to protect the security of any personal information we process. However, despite our safeguards and
                efforts to secure your information, no electronic transmission over the Internet or information storage
                technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers,
                cybercriminals, or other unauthorised third parties will not be able to defeat our security and
                improperly collect, access, steal, or modify your information. Although we will do our best to protect
                your personal information, transmission of personal information to and from our Services is at your own
                risk. You should only access the Services within a secure environment.
            </p>
            <h2>10. Data Collection From Minors</h2>
            <p>
                To protect minors, active measures are taken to disable the use of camera and chat functionalities for
                users under the age of 18. Collection and use of data from minors is otherwise not different from other
                data collection described in this Privacy Policy, with the exception of data from minors being
                anonymised at all times. If we learn that a user less than the age of 18 years old uses our Services
                unlawfully as described, the account will be deactivated and reasonable measures will be taken to
                promptly delete such data from our records. If you become aware of any unlawful Service usage from
                minors under the age of 18, please contact us at
                <a href="support@dartcounter.net">support&#64;dartcounter.net</a>.
            </p>
            <h2>11. Data Review and Deletion</h2>
            <p>
                In some regions (like the EEA, UK, Switzerland, and Canada), you have certain rights under applicable
                data protection laws. These may include the right (i) to request access and obtain a copy of your
                personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your
                personal information; (iv) if applicable, to data portability; and (v) not to be subject to automated
                decision-making. In certain circumstances, you may also have the right to object to the processing of
                your personal information. You can make such a request by contacting us at
                <a href="support@dartcounter.net">support&#64;dartcounter.net</a>. We will respond within two working
                days.
            </p>
            <p>
                Upon your request to terminate your account, we will deactivate or delete your account and information
                from our active databases. However, we may retain some information in our files to prevent fraud,
                troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with
                applicable legal requirements.
            </p>
            <p>You have the right to know:</p>
            <ul>
                <li>whether we collect and use your personal information;</li>
                <li>the categories of personal information that we collect;</li>
                <li>the purposes for which the collected personal information is used;</li>
                <li>whether we sell or share our personal information to third parties;</li>
                <li>
                    the categories of personal information that we sold, shared or disclosed for a business purpose;
                </li>
                <li>
                    the categories of third parties to whom the personal information was sold, shared, or disclosed for
                    a business purpose;
                </li>
                <li>
                    the business or commercial purpose for collecting, sharing, or selling personal information; and
                </li>
                <li>the specific pieces of personal information we collected about you.</li>
            </ul>
            <p>
                Upon receiving your request, we will need to verify your identity to determine you are the same person
                about whom we have the information in our system. These verification efforts require us to ask you to
                provide information so that we can match it with information you have previously provided us. For
                instance, depending on the type of request you submit, we may ask you to provide certain information so
                that we can match the information you provide with the information we already have on file, or we may
                contact you through a communication method (email) that you have previously provided to us. We may also
                use other verification methods as the circumstances dictate.
            </p>
            <p>
                We will only use personal information provided in your request to verify your identity or authority to
                make the request. To the extent possible, we will avoid requesting additional information from you for
                the purposes of verification. However, if we cannot verify your identity from the information already
                maintained by us, we may request that you provide additional information for the purposes of verifying
                your identity and for security or fraud-prevention purposes. We will delete such additionally provided
                information as soon as we finish verifying you.
            </p>
            <p>
                You can unsubscribe from our marketing and promotional communications at any time by clicking on the
                unsubscribe link in the emails that we send or by updating your preferences in your user profile. You
                will then be removed from the marketing lists — however, we may still communicate with you, for example
                to send you service-related messages that are necessary for the administration and use of your account,
                to respond to service requests, or for other non-marketing purposes.
            </p>
            <h2>12. Contact Information</h2>
            <p>
                For questions, concerns, or complaints about our Privacy Policy or data practices, or to report privacy
                or security violations, contact us at <a href="support@dartcounter.net">support&#64;dartcounter.net</a>
            </p>
            <h2>13. Changes To This Privacy Policy</h2>
            <p>
                We may revise this Privacy Policy from time to time. The updated version will be indicated by an update
                “Revised” date and the updated version will be effective 30 days after email notification or posting for
                existing users, and instantaneously for new users. As soon as we make changes, we will notify you via
                email and/or a prominent notice on our Platforms. It is your responsibility to ensure we have your
                current email address. Continued use of our Services after notice of changes indicates your agreement to
                be bound by the updated Policy. We encourage you to review this privacy notice frequently to be informed
                of how we are protecting your information.
            </p>
        }
    </div>
</div>
