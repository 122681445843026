<app-page-header mainNavigation="back" defaultBack="local-games">
    <div title [translate]="'SHANGHAI'" i18n="@@SHANGHAI">Shanghai</div>
    <app-fullscreen-icon right></app-fullscreen-icon>
</app-page-header>

<div class="pb-safe-offset relative flex h-full flex-col overflow-hidden bg-white-pattern text-black" id="main-content">
    <div class="flex flex-1 flex-col overflow-auto p-4" [appWebContainer]>
        <div class="space-y-2">
            <!-- Player details -->
            <div class="new-game-card">
                <div class="title-container">
                    <div class="icon">
                        <app-icon icon="people_alt" size="small"></app-icon>
                    </div>
                    <div class="title" [translate]="'PLAYER_DETAILS'" i18n="@@PLAYER_DETAILS">Player details</div>
                </div>

                <div class="mt-4">
                    <app-games-local-add-player
                        [users]="users"
                        (updatedUsersEvent)="users = $event"
                        (addPlayerEvent)="addPlayer($event)"
                        (removePlayerEvent)="removePlayer($event)"
                        (showPlayerOptionsEvent)="showPlayerOptions($event)"></app-games-local-add-player>
                </div>
            </div>

            <!-- Game settings -->
            <div class="new-game-card">
                <div class="title-container">
                    <div class="icon">
                        <app-icon icon="tune" size="small"></app-icon>
                    </div>
                    <div class="title" [translate]="'GAME_SETTINGS'" i18n="@@GAME_SETTINGS">Game settings</div>
                </div>

                <div class="flex flex-col space-y-2">
                    <div
                        class="text-left text-xs text-neutral-600"
                        [translate]="'SHANGHAI_DESCRIPTION'"
                        i18n="@@SHANGHAI_DESCRIPTION">
                        In Shanghai you play from 1 to 7 or 1 to 20 in ascending order. You will earn points based on
                        the fields you hit. The points you earn have the same value as they have in a regular match. The
                        player with the most points at the end of the game wins. When you manage to hit a Shanghai
                        (single, double, treble) in a single turn, the game ends immediately and you win.
                    </div>
                    <app-list-switch
                        *ngIf="users.length > 1"
                        [item]="trainingMode"
                        [items]="trainingModes"
                        (itemChange)="changeTrainingMode($event)"></app-list-switch>
                    <div
                        class="text-left text-xs text-neutral-600"
                        *ngIf="trainingMode === 'training' && users.length > 1"
                        [translate]="'TRAINING_MODE_TRAINING_INFO'"
                        i18n="@@TRAINING_MODE_TRAINING_INFO">
                        In training mode, only your results will be shown in your statistics.
                    </div>
                    <div
                        class="text-left text-xs text-neutral-600"
                        *ngIf="trainingMode === 'versus' && users.length > 1"
                        [translate]="'TRAINING_MODE_VERSUS_INFO'"
                        i18n="@@TRAINING_MODE_VERSUS_INFO">
                        In versus mode, your results and your opponents results will be shown in your statistics.
                    </div>

                    <app-list-switch
                        [item]="range"
                        [items]="ranges"
                        (itemChange)="changeRange($event)"></app-list-switch>

                    <app-toggle-switch
                        class="block w-full pt-1"
                        [model]="requireNumber"
                        (itemChange)="requireNumber = !requireNumber">
                        <span [translate]="'REQUIRE_NUMBER'" i18n="@@REQUIRE_NUMBER">Require number</span>
                    </app-toggle-switch>
                    <div
                        class="text-left text-xs text-neutral-600"
                        [translate]="'REQUIRE_NUMBER_EXPLANATION'"
                        i18n="@@REQUIRE_NUMBER_EXPLANATION">
                        Require the current number to be thrown. Your score will be halved otherwise.
                    </div>

                    <app-toggle-switch
                        class="block w-full pt-1"
                        [model]="requireTriple"
                        (itemChange)="requireTriple = !requireTriple">
                        <span [translate]="'REQUIRE_TRIPLE'" i18n="@@REQUIRE_TRIPLE">Require triple</span>
                    </app-toggle-switch>
                    <div class="flex flex-row items-start justify-between space-x-1">
                        <div
                            class="text-left text-xs text-neutral-600"
                            [translate]="'REQUIRE_TRIPLE_EXPLANATION'"
                            i18n="@@REQUIRE_TRIPLE_EXPLANATION">
                            Require a specific triple to be thrown, your score will be reset to 0 otherwise.
                        </div>
                        <div
                            class="rounded bg-orange px-3 pb-1 pt-1.5 font-bebas-neue text-3xl leading-none !text-white"
                            (click)="showTripleDialog()"
                            *ngIf="requireTriple">
                            {{ goalTriple }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-primary-button
            class="mt-4"
            size="normal"
            addedClasses="w-full"
            [translate]="'START_GAME'"
            i18n="@@START_GAME"
            (click)="startGame()">
            Start game
        </app-primary-button>
    </div>

    <app-bottom-navigation class="w-full" active="local"></app-bottom-navigation>
</div>
