import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { Network } from '@capacitor/network';
import { BetaFlagsRequest } from '@dc-core/dc-backend/dc-requests';
import { BetaFlagsResponse } from '@dc-core/dc-backend/dc-responses';
import { LocalStorageKey } from '@dc-core/dc-localstorage';
import { firstValueFrom, take } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class BetaFlagsService {
    private http: HttpClient = inject(HttpClient);

    public enabledBetaFlags: WritableSignal<BetaFlagsResponse> = signal({
        tournaments: false,
    });
    public flagsStatusUpdated: boolean = false;

    public registerEnabledBetaFlags(): Promise<void> {
        return Network.getStatus()
            .then(async (status) => {
                if (!status.connected) {
                    return Promise.resolve();
                }

                let bearerToken = '';
                const localToken = JSON.parse(localStorage.getItem(LocalStorageKey.accessToken));
                if (localToken) {
                    bearerToken = `Bearer ${localToken}`;
                }

                const enabledBetaFlags$ = this.http
                    .get<BetaFlagsResponse>(`${environment.apiUrl}/beta-flags`, {
                        headers: {
                            Authorization: bearerToken,
                        },
                        params: {
                            version: environment.version,
                            build: environment.build,
                        } as BetaFlagsRequest,
                    })
                    .pipe(take(1));

                return firstValueFrom(enabledBetaFlags$)
                    .then((appFeatures) => {
                        this.enabledBetaFlags.update(() => appFeatures);
                        this.flagsStatusUpdated = true;
                    })
                    .catch(() => Promise.resolve());
            })
            .catch(() => Promise.resolve());
    }
}
