import { Component, inject, OnInit } from '@angular/core';
import { InAppBrowser, InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { AuthApiService } from '@dc-api/auth.api.service';
import { NewsletterApiService } from '@dc-api/newsletter.api.service';
import { UserApiService } from '@dc-api/user.api.service';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PrivacyPolicyDialogComponent } from 'src/app/modules/app-settings/dialogs/privacy-policy/privacy-policy.dialog';
import { TermsOfServiceDialogComponent } from 'src/app/modules/app-settings/dialogs/terms-of-service/terms-of-service.dialog';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-terms-and-newsletter-dialog',
    templateUrl: 'terms-and-newsletter.dialog.html',
})
export class TermsAndNewsletterDialogComponent implements OnInit {
    public authService: AuthService = inject(AuthService);

    private modalController: ModalController = inject(ModalController);
    private translateService: TranslateService = inject(TranslateService);
    private alertService: DartCounterAlertService = inject(DartCounterAlertService);
    private authApiService: AuthApiService = inject(AuthApiService);
    private newsletterApiService: NewsletterApiService = inject(NewsletterApiService);
    private userApiService: UserApiService = inject(UserApiService);
    private inAppBrowser: InAppBrowser = inject(InAppBrowser);

    public title: string;

    public agreements = false;
    public newsletterOptIn = false;
    public targetNewsletterOptIn = false;

    public ngOnInit(): void {
        if (!this.authService.user.accepted_terms_at) {
            $localize`:@@HELLO_USER:Hello, ${this.authService.user.first_name}:user:!`;
            this.title = this.translateService.instant('HELLO_USER', {
                user: this.authService.user.first_name || $localize`:@@NO_NAME:No name`,
            });
        } else {
            this.title = $localize`:@@EMAIL_PREFERENCES:Email preferences`;
        }

        this.newsletterOptIn = this.authService.user.newsletter_opt_in === true;
        this.targetNewsletterOptIn = this.authService.user.target_newsletter_opt_in === true;
    }

    public openTermsOfService(event: MouseEvent): void {
        event.stopPropagation();
        this.modalController.create({ component: TermsOfServiceDialogComponent }).then((elem) => elem.present());
    }

    public openPrivacyPolicy(event: MouseEvent): void {
        event.stopPropagation();
        this.modalController.create({ component: PrivacyPolicyDialogComponent }).then((elem) => elem.present());
    }

    public openCodeOfConduct(event: MouseEvent): void {
        event.stopPropagation();
        this.inAppBrowser.create('https://dartcounter.net/code-of-conduct', '_system', {
            location: 'yes',
        } as InAppBrowserOptions);
    }

    public async continue(): Promise<void> {
        if (!this.agreements && !this.authService.user.accepted_terms_at) {
            this.alertService.createAlert({
                title: $localize`:@@PLEASE_AGREE_TO_THE_TERMS:Please agree to the Terms of Service!`,
                icon: 'warning',
                timer: null,
                showCloseButton: true,
            });
            return;
        }

        if (!this.authService.user.accepted_terms_at) {
            await this.authApiService.acceptTerms({}).catch(console.error);
        }

        if (this.authService.user.email) {
            await this.newsletterApiService
                .updateNewsletterOptIn({
                    newsletter_opt_in: this.newsletterOptIn,
                    target_newsletter_opt_in: this.targetNewsletterOptIn,
                })
                .then((res) => {
                    if (!this.newsletterOptIn) {
                        this.authService.user.newsletter_opt_in = this.newsletterOptIn;
                    }
                    this.authService.user.target_newsletter_opt_in = this.targetNewsletterOptIn;

                    if (res.data.message && this.newsletterOptIn) {
                        this.alertService.createAlert({
                            title: res.data.message,
                            icon: 'success',
                        });
                    }
                })
                .catch(console.error);
        }

        await this.userApiService.getCurrentUser({}).then((res) => this.authService.saveAuthentication(res.data));

        this.modalController.dismiss();
    }
}
