import { Injectable, WritableSignal, effect, signal, untracked } from '@angular/core';

import { DartCounterAlertService } from '../alert.service';
import { JanusVideoRoomService } from '../dc-janus/janus-video-room.service';
import { ActionsLogicService } from '@dc-core/dc-firestore/actions.service';
import { UserMediaService } from '../dc-janus/usermedia.service';
import { UserMedia } from '../dc-janus/DartCounterUserMedia';
import { DCFireStoreUser, FSVoiceCallAction } from '@dc-core/dc-firestore/globals/firestore.tables';
import moment from 'moment';
import { DartCounterAudioService } from '../audio/audio.service';
import { SoundPath } from '@dc-core/dc-backend/dc-enums';

export enum VoiceCallStatus {
    None = 'none',
    Calling = 'calling',
    Incoming = 'incoming',
    InCall = 'in_call',
    Hangup = 'hangup',
    Declined = 'declined',
    NoResponse = 'no_response',
}

@Injectable()
export class VoiceCallService {
    public authUserId: number;
    public showingDialog = false;
    public callingUser: DCFireStoreUser = null;
    public currentCall: WritableSignal<FSVoiceCallAction> = signal(null);
    public currentStatus: WritableSignal<VoiceCallStatus> = signal(VoiceCallStatus.None);

    public incomingTimer: number = null;
    public incomingTimerInterval: any = null;
    public missedCalls: WritableSignal<number> = signal(0);

    public inCallTimer = 0;
    public inCallTimerInterval: any = null;

    public userMedia = new UserMedia();

    public playingRingtone = false;

    constructor(
        private _videoRoomService: JanusVideoRoomService,
        private _actionsLogicService: ActionsLogicService,
        private _alertService: DartCounterAlertService,
        private _userMediaService: UserMediaService,
        private _audioService: DartCounterAudioService
    ) {
        effect(
            () => {
                const currentCall = this.currentCall();
                if (currentCall) {
                    if (currentCall.status === VoiceCallStatus.Calling) {
                        if (currentCall.janusRoom) {
                            this.startIncomingTimer();
                        }
                        this.startRingtone('outgoing');
                    } else if (currentCall.status === VoiceCallStatus.Incoming) {
                        this.startIncomingTimer();
                        this.startRingtone('incoming');
                    } else {
                        this.stopIncomingTimer();
                        this.stopRingtone();

                        if (currentCall.status !== VoiceCallStatus.InCall) {
                            this._userMediaService.enableCaller();
                        }
                    }

                    if (currentCall.status === VoiceCallStatus.InCall) {
                        this.startInCallTimer();
                    } else {
                        this.stopInCallTimer();
                    }

                    if (
                        currentCall.status === VoiceCallStatus.Hangup ||
                        currentCall.status === VoiceCallStatus.Declined ||
                        currentCall.status === VoiceCallStatus.NoResponse
                    ) {
                        this.hangup(false, currentCall.status);
                    } else {
                        this.currentStatus.set(currentCall.status);
                    }
                } else {
                    this._userMediaService.enableCaller();
                    this.currentStatus.set(VoiceCallStatus.None);
                }
            },
            { allowSignalWrites: true }
        );
    }

    async call(): Promise<void> {
        await this._audioService.changeAudioCategory('voiceCall');

        this.currentCall.update((currentCall) => ({ ...currentCall, status: VoiceCallStatus.Calling }));

        this._userMediaService
            .activateMicrophone(this._videoRoomService.ownAudioUserMedia)
            .then((media) => {
                try {
                    this._videoRoomService
                        .startPublishing('audio', media, this._videoRoomService.ownAudioUserMedia)
                        .then(() => {
                            const action = this._actionsLogicService.sendVoiceCallAction(
                                {
                                    sender: this.authUserId,
                                    janusRoom: this._videoRoomService.ownVoiceCall,
                                },
                                VoiceCallStatus.Incoming
                            );
                            action.status = VoiceCallStatus.Calling;
                            this.currentCall.set(action);

                            this.joinJanusRoom();
                        });
                } catch (_) {
                    this._alertService.createAlert({
                        title: $localize`:@@CANNOT_ACTIVATE_MICROPHONE:Cannot activate microphone`,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                this._alertService.createAlert({
                    text: err,
                    icon: 'error',
                    timer: null,
                    showCloseButton: true,
                    allowOutsideClick: true,
                    confirmButtonText: $localize`:@@CLOSE:Close`,
                });
            });
    }

    async hangup(sendAction = true, status: VoiceCallStatus = VoiceCallStatus.None): Promise<void> {
        this._userMediaService.stopMicrophone();

        if (sendAction) {
            const action = this._actionsLogicService.sendVoiceCallAction(
                {
                    sender: this.authUserId,
                    janusRoom: null,
                },
                VoiceCallStatus.Hangup
            );
            action.status = VoiceCallStatus.None;
            this.currentCall.set(action);
        } else {
            this.currentCall.set(null);
            this.currentStatus.set(status);
        }

        this.leaveJanusRoom();

        await this._audioService.changeAudioCategory('default');
    }

    decline(): void {
        const action = this._actionsLogicService.sendVoiceCallAction(
            {
                sender: this.authUserId,
                janusRoom: null,
            },
            VoiceCallStatus.Declined
        );
        action.status = VoiceCallStatus.None;
        this.currentCall.set(action);
    }

    async accept(): Promise<void> {
        this.stopIncomingTimer();

        await this._audioService.changeAudioCategory('voiceCall');

        this._userMediaService
            .activateMicrophone(this._videoRoomService.ownAudioUserMedia)
            .then(async (media) => {
                try {
                    this._videoRoomService.ownVoiceCall = this.currentCall().janusRoom;
                    this._videoRoomService.audioMedia = media;

                    await this._videoRoomService.publishOwnFeed('audio', this._videoRoomService.ownAudioUserMedia);

                    this.joinJanusRoom().finally(() => {
                        const action = this._actionsLogicService.sendVoiceCallAction(
                            {
                                sender: this.authUserId,
                                janusRoom: this._videoRoomService.ownVoiceCall,
                            },
                            VoiceCallStatus.InCall
                        );
                        this.currentCall.set(action);
                    });
                } catch (_) {
                    this._alertService.createAlert({
                        title: $localize`:@@CANNOT_ACTIVATE_MICROPHONE:Cannot activate microphone`,
                        icon: 'error',
                    });
                }
            })
            .catch((err) => {
                this._alertService.createAlert({
                    text: err,
                    icon: 'error',
                    timer: null,
                    showCloseButton: true,
                    allowOutsideClick: true,
                    confirmButtonText: $localize`:@@CLOSE:Close`,
                });
            });
    }

    async joinJanusRoom(): Promise<void> {
        await this._videoRoomService
            .spectateRoom(this._videoRoomService.ownVoiceCall, 'audio', null, this.userMedia, false, false)
            .then((janusRoom) => {
                this.userMedia.janusRoom = janusRoom;
            })
            .catch(console.error);
    }

    leaveJanusRoom(): void {
        this._videoRoomService.ownAudioUserMedia.cleanupUserMedia(
            { video: false, audio: true },
            { video: false, audio: true }
        );
        this._videoRoomService.ownAudioUserMedia.unmuteAudio();

        this.userMedia.cleanupUserMedia(true, true);

        if (!this.userMedia.janusRoom?.roomID) {
            return;
        }

        this._videoRoomService
            .leaveRoomAndDetachAllHandles(this.userMedia.janusRoom.roomID, true)
            .catch(console.error)
            .finally(() => {
                this._videoRoomService.destroyUnused();
            });
    }

    startIncomingTimer(): void {
        this.stopIncomingTimer();

        this.incomingTimerInterval = setInterval(() => {
            if (this.incomingTimer === 0) {
                if (this.currentCall()?.status === VoiceCallStatus.Calling) {
                    this.currentCall.update((currentCall) => ({ ...currentCall, status: VoiceCallStatus.NoResponse }));
                } else if (this.currentCall()?.status === VoiceCallStatus.Incoming) {
                    this.currentCall.update((currentCall) => ({ ...currentCall, status: VoiceCallStatus.None }));
                    this.missedCalls.update((missedCalls) => ++missedCalls);
                }
                this.stopIncomingTimer();
            } else {
                this.incomingTimer--;
            }
        }, 1000);
    }

    stopIncomingTimer(): void {
        if (this.incomingTimerInterval) {
            clearInterval(this.incomingTimerInterval);
            this.incomingTimerInterval = null;
        }

        this.incomingTimer = 20;
    }

    startInCallTimer(): void {
        this.stopInCallTimer();

        this.inCallTimer = 0;

        this.inCallTimerInterval = setInterval(() => {
            this.inCallTimer++;
        }, 1000);
    }

    stopInCallTimer(): void {
        if (this.inCallTimerInterval) {
            clearInterval(this.inCallTimerInterval);
            this.inCallTimerInterval = null;
        }
    }

    showTimer(): string {
        return moment('2015-01-01').startOf('day').seconds(this.inCallTimer).format('mm:ss');
    }

    startRingtone(type: 'incoming' | 'outgoing'): void {
        this._userMediaService.disableCaller();

        if (!this.playingRingtone) {
            this.playingRingtone = true;
            this.playRingtone(type);
        }
    }

    playRingtone(type: 'incoming' | 'outgoing'): void {
        if (this.playingRingtone) {
            this._audioService
                .playAudioFromDevice(SoundPath.RINGTONE, type + '_call.mp3')
                .then(() => {
                    this.playRingtone(type);
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }

    stopRingtone(): void {
        if (this.playingRingtone) {
            this.playingRingtone = false;
            this._audioService.stopAudio();
        }
    }
}
