@if (false) {
    <ng-container i18n="@@AROUND_THE_CLOCK_SEQUENCE_LOW_TO_HIGH">Play from 1 to 20</ng-container>
    <ng-container i18n="@@AROUND_THE_CLOCK_SEQUENCE_HIGH_TO_LOW">Play from 20 to 1</ng-container>
    <ng-container i18n="@@AROUND_THE_CLOCK_SEQUENCE_INFO_RANDOM">Play in random order</ng-container>
    <ng-container i18n="@@INCREASE_BY_SEGMENT">Increase by segment</ng-container>
    <ng-container i18n="@@AROUND_THE_CLOCK_GOAL_INCREASE_INFO">
        When hitting the target, do you prefer the next goal to be an increase of 1, or an increase by the segment
        thrown? When switching this on, i.e. throwing a triple increases the goal by 3.
    </ng-container>
}

<app-page-header mainNavigation="back" defaultBack="local-games">
    <div title [translate]="'AROUND_THE_CLOCK'" i18n="@@AROUND_THE_CLOCK">Around The Clock</div>
    <app-fullscreen-icon right></app-fullscreen-icon>
</app-page-header>

<div class="pb-safe-offset relative flex h-full flex-col overflow-hidden bg-white-pattern text-black" id="main-content">
    <div class="flex flex-1 flex-col overflow-auto p-4" [appWebContainer]>
        <div class="space-y-2">
            <!-- Player details -->
            <div class="new-game-card">
                <div class="title-container">
                    <div class="icon">
                        <app-icon icon="people_alt" size="small"></app-icon>
                    </div>
                    <div class="title" [translate]="'PLAYER_DETAILS'" i18n="@@PLAYER_DETAILS">Player details</div>
                </div>

                <div class="mt-4">
                    <app-games-local-add-player
                        [users]="users"
                        (updatedUsersEvent)="users = $event"
                        (addPlayerEvent)="addPlayer($event)"
                        (removePlayerEvent)="removePlayer($event)"
                        (showPlayerOptionsEvent)="showPlayerOptions($event)"></app-games-local-add-player>
                </div>
            </div>

            <!-- Game settings -->
            <div class="new-game-card">
                <div class="title-container">
                    <div class="icon">
                        <app-icon icon="tune" size="small"></app-icon>
                    </div>
                    <div class="title" [translate]="'GAME_SETTINGS'" i18n="@@GAME_SETTINGS">Game settings</div>
                </div>

                <div class="flex flex-col space-y-2">
                    <div
                        class="text-left text-xs text-neutral-600"
                        [translate]="'AROUND_THE_CLOCK_DESCRIPTION'"
                        i18n="@@AROUND_THE_CLOCK_DESCRIPTION">
                        The goal of Around The Clock is to hit every number on the dartboard from 1 to 20, in ascending
                        order, followed by the bullseye. You can adjust the settings to suit your game preferences.
                    </div>
                    <app-list-switch
                        *ngIf="users.length > 1"
                        [item]="trainingMode"
                        [items]="trainingModes"
                        (itemChange)="changeTrainingMode($event)"></app-list-switch>
                    <div
                        class="text-center text-xs text-neutral-600"
                        *ngIf="trainingMode === 'training' && users.length > 1"
                        [translate]="'TRAINING_MODE_TRAINING_INFO'"
                        i18n="@@TRAINING_MODE_TRAINING_INFO">
                        In training mode, only your results will be shown in your statistics.
                    </div>
                    <div
                        class="text-center text-xs text-neutral-600"
                        *ngIf="trainingMode === 'versus' && users.length > 1"
                        [translate]="'TRAINING_MODE_VERSUS_INFO'"
                        i18n="@@TRAINING_MODE_VERSUS_INFO">
                        In versus mode, your results and your opponents results will be shown in your statistics.
                    </div>
                    <div class="flex items-center space-x-1">
                        <app-list-switch
                            class="w-full"
                            [item]="bullOrBullseye"
                            [items]="bullOrBullseyes"
                            (itemChange)="changeBullOrBullseye($event)"></app-list-switch>
                    </div>
                    <div
                        class="text-center text-xs text-neutral-600"
                        [translate]="'AROUND_THE_CLOCK_BULL_BULLSEYE_INFO'"
                        i18n="@@AROUND_THE_CLOCK_BULL_BULLSEYE_INFO">
                        Finish by hitting the bullseye or single bull
                    </div>
                    <div class="flex items-center space-x-1">
                        <app-list-switch
                            class="w-full"
                            [item]="numberSegment"
                            [items]="numberSegments"
                            (itemChange)="changSegmentsMode($event)"></app-list-switch>
                    </div>
                    <div
                        class="text-center text-xs text-neutral-600"
                        [translate]="'AROUND_THE_CLOCK_SEGMENT_INFO'"
                        i18n="@@AROUND_THE_CLOCK_SEGMENT_INFO">
                        Hit any field or create a challenge yourself by only going for a singles, doubles or triples
                    </div>
                    <div class="flex items-center space-x-1">
                        <app-list-switch
                            class="w-full"
                            [item]="hit"
                            [items]="hits"
                            (itemChange)="changeHits($event)"></app-list-switch>
                    </div>
                    <div
                        class="text-center text-xs text-neutral-600"
                        [translate]="'AROUND_THE_CLOCK_HITS_INFO'"
                        i18n="@@AROUND_THE_CLOCK_HITS_INFO">
                        Amount of times to hit each target
                    </div>

                    <app-list-switch
                        [item]="throwOrder"
                        [items]="throwOrders"
                        (itemChange)="changeThrowOrder($event)"></app-list-switch>
                    <div class="text-center text-xs text-neutral-600">
                        {{ getSequenceInfo(throwOrder) | translate }}
                    </div>

                    <app-toggle-switch
                        class="block w-full"
                        [model]="increaseGoalByMultiplier"
                        (itemChange)="toggleIncreaseGoalByMultiplier()">
                        <button
                            class="flex space-x-1 items-center"
                            (click)="showHelpModal('INCREASE_BY_SEGMENT', 'AROUND_THE_CLOCK_GOAL_INCREASE_INFO')">
                            <div
                                class="text-sm text-black"
                                [translate]="'INCREASE_BY_SEGMENT'"
                                i18n="@@INCREASE_BY_SEGMENT">
                                Increase by segment
                            </div>

                            <div
                                class="relative bg-blue flex items-center justify-center px-[3px] py-[3px] rounded-full">
                                <app-icon
                                    icon="questionmark"
                                    size="custom"
                                    class="!text-white !text-[0.65rem]"></app-icon>
                            </div>
                        </button>
                    </app-toggle-switch>
                </div>
            </div>
        </div>

        <app-primary-button
            class="mt-4"
            size="normal"
            addedClasses="w-full"
            [translate]="'START_GAME'"
            i18n="@@START_GAME"
            (click)="startGame()">
            Start game
        </app-primary-button>
    </div>

    <app-bottom-navigation class="w-full" active="local"></app-bottom-navigation>
</div>
