<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CLOSE'" i18n="@@CLOSE" (click)="dismiss()">Close</div>
        </ion-buttons>
        <div class="slide-modal-title" i18n="@@TRANSITION_TO_USER" [translate]="'TRANSITION_TO_USER'">
            Transition to user
        </div>
    </div>
    <form class="slide-modal-content" #form="ngForm" [formGroup]="guestForm" (ngSubmit)="guestForm.valid && submit()">
        <div i18n="@@TRANSITION_TO_COMPLETE_YOUR_ACCOUNT" [translate]="'TRANSITION_TO_COMPLETE_YOUR_ACCOUNT'">
            Transition your guest account to complete your account
        </div>
        <div class="mt-5 space-y-4 mb-3">
            <div class="w-full space-y-2">
                <div class="dialog-input-label" [translate]="'EMAIL'" i18n="@@EMAIL">Email</div>
                <input type="text" class="dialog-input" formControlName="email" />
            </div>

            <app-checkbox
                class="block"
                [model]="guestForm.get('newsletterOptIn').value"
                color="white"
                (itemChange)="guestForm.get('newsletterOptIn').patchValue($event)">
                <div class="flex text-black" [translate]="'NEWSLETTER_OPT_IN_INFO'" i18n="@@NEWSLETTER_OPT_IN_INFO">
                    Yes, I want to hear about exclusive offers, announcements and the newest products from Target
                    DartCounter, as well as those tailored to my account and activity.
                </div>
            </app-checkbox>

            <app-checkbox
                class="block"
                [model]="guestForm.get('targetNewsletterOptIn').value"
                color="white"
                (itemChange)="guestForm.get('targetNewsletterOptIn').patchValue($event)">
                <div
                    class="flex text-black"
                    [translate]="'TARGET_NEWSLETTER_OPT_IN_INFO'"
                    i18n="@@TARGET_NEWSLETTER_OPT_IN_INFO">
                    Yes, I wish to be subscribe to Target Darts newsletter and hear about the latest Target Darts
                    products, offers and darts information.
                </div>
            </app-checkbox>

            <div>
                <div class="dialog-input-label" [translate]="'NEW_PASSWORD'" i18n="@@NEW_PASSWORD">New password</div>
                <div class="relative">
                    <input
                        class="dialog-input"
                        [type]="passwordVisible ? 'text' : 'password'"
                        [ngClass]="{
                            '!border-red': form.submitted && guestForm.get('confirmPassword').errors
                        }"
                        placeholder="{{ 'NEW_PASSWORD' | translate }}"
                        formControlName="password" />
                    <app-icon
                        class="flex absolute -translate-y-1/2 top-1/2 opacity-60 right right-4 cursor-pointer text-black"
                        [icon]="passwordVisible ? 'visibility_off' : 'visibility'"
                        (click)="togglePasswordVisibility('password')"></app-icon>
                </div>

                @if (form.submitted && guestForm.get('password').errors) {
                    @if (guestForm.get('password').errors.required) {
                        <app-validation-label [noAbsolute]="true">
                            <span [translate]="'PASSWORD_REQUIRED'" i18n="@@PASSWORD_REQUIRED">
                                Password is required
                            </span>
                        </app-validation-label>
                    } @else if (guestForm.get('password').errors.minlength) {
                        <app-validation-label [noAbsolute]="true">
                            <span
                                [translate]="'PASSWORD_MUST_BE_ATLEAST_6_CHARACTERS_LONG'"
                                i18n="@@PASSWORD_MUST_BE_ATLEAST_6_CHARACTERS_LONG">
                                Password must be atleast 6 characters long
                            </span>
                        </app-validation-label>
                    }
                }
            </div>

            <div>
                <div class="dialog-input-label" [translate]="'REPEAT_PASS'" i18n="@@REPEAT_PASS">Repeat password</div>
                <div class="relative">
                    <input
                        class="dialog-input"
                        [type]="confirmPasswordVisible ? 'text' : 'password'"
                        [ngClass]="{
                            '!border-red':
                                form.submitted &&
                                (guestForm.get('confirmPassword').errors || guestForm.hasError('passwordsDoNotMatch'))
                        }"
                        formControlName="confirmPassword"
                        placeholder="{{ 'REPEAT_PASS' | translate }}" />
                    <app-icon
                        class="flex absolute -translate-y-1/2 top-1/2 opacity-60 right right-4 cursor-pointer text-black"
                        [icon]="confirmPasswordVisible ? 'visibility_off' : 'visibility'"
                        (click)="togglePasswordVisibility('confirmPassword')"></app-icon>
                </div>
                @if (form.submitted) {
                    @if (guestForm.get('confirmPassword').errors) {
                        @if (guestForm.get('confirmPassword').errors.required) {
                            <app-validation-label [noAbsolute]="true">
                                <span [translate]="'PASSWORD_REQUIRED'" i18n="@@PASSWORD_REQUIRED">
                                    Password is required
                                </span>
                            </app-validation-label>
                        } @else if (guestForm.get('confirmPassword').errors.minlength) {
                            <app-validation-label [noAbsolute]="true">
                                <span
                                    [translate]="'PASSWORD_MUST_BE_ATLEAST_6_CHARACTERS_LONG'"
                                    i18n="@@PASSWORD_MUST_BE_ATLEAST_6_CHARACTERS_LONG">
                                    Password must be atleast 6 characters long
                                </span>
                            </app-validation-label>
                        }
                    } @else if (guestForm.hasError('passwordsDoNotMatch')) {
                        <app-validation-label [noAbsolute]="true">
                            <span [translate]="'REPEATPASS_ERROR'" i18n="@@REPEATPASS_ERROR">
                                The two passwords don't match
                            </span>
                        </app-validation-label>
                    }
                }
            </div>
        </div>
        <app-primary-button
            type="submit"
            size="normal"
            addedClasses="w-full"
            color="orange"
            [translate]="'TRANSITION'"
            i18n="@@TRANSITION">
            Transition
        </app-primary-button>
    </form>
</div>
