<div class="relative rounded-lg bg-white px-6 py-20" style="z-index: 30001; pointer-events: all" [@fade]>
    <app-icon
        icon="close"
        class="text-black absolute right-4 top-4 w-[1.5rem] cursor-pointer"
        (click)="dismiss()"></app-icon>
    <div class="flex flex-col items-center space-y-4">
        <div class="text-center text-5xl text-black font-bebas-neue">{{ title }}</div>

        @if (subtext) {
            <div class="text-center text-sm text-neutral-400 font-bold px-8">{{ subtext }}</div>
        }

        @if (reason) {
            <div class="space-y-1">
                <div
                    class="text-center text-sm text-neutral-400 font-bold px-8"
                    [translate]="'REASON_COLON'"
                    i18n="@@REASON_COLON">
                    Reason:
                </div>
                <div class="text-center text-sm text-neutral-400 font-bold px-8">
                    {{ reason }}
                </div>
            </div>
        }

        @if (timeUntilText && timeUntil) {
            <div class="text-center text-sm text-neutral-400 font-bold px-8">{{ timeUntilText }}:</div>
            <app-tournament-timer [timerDate]="timeUntil" [hideTimerWhenExpired]="false"></app-tournament-timer>
        }
    </div>
    @if (buttonText) {
        <div class="mt-8 flex justify-between">
            <app-primary-button size="normal" class="w-full" addedClasses="w-full" (click)="dismiss(true)">
                {{ buttonText }}
            </app-primary-button>
        </div>
    }
</div>
