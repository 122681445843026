<div class="flex h-full flex-col overflow-y-auto overscroll-none bg-white-pattern pb-safe-offset-4">
    <app-page-header mainNavigation="custom" color="white">
        <div
            left
            class="text-center font-bold text-black text-sm cursor-pointer"
            (click)="close()"
            [translate]="'CLOSE'"
            i18n="@@CLOSE">
            Close
        </div>
        <div title [translate]="'TOURNAMENT_RESULTS'" i18n="@@TOURNAMENT_RESULTS">Tournament results</div>
        @if (!isWeb) {
            <!-- <app-option-button right icon="share" color="text-black"></app-option-button> -->
        }
    </app-page-header>

    <div class="flex flex-1 flex-col portrait:overflow-hidden landscape:overflow-y-auto">
        <div
            class="bg-orange-pattern !text-white text-center pt-4 relative pb-11"
            style="border-radius: 100% 100% 100% 100% / 0% 0% 40% 40%">
            <div class="font-bebas-neue text-5xl" [translate]="'YOU_HAVE_WON'" i18n="@@YOU_HAVE_WON">You have won!</div>
            <div class="font-bebas-neue text-3xl mt-2 px-8">{{ subtext }}</div>

            <div class="mt-4">
                <div class="local-end-screen-player-container has-absolute">
                    <div class="col-span-1"></div>
                    <div class="player">
                        <app-games-end-screen-player
                            class="block overflow-hidden w-full"
                            [name]="authService.user.full_name"
                            [user]="authService.user"
                            [profilePhotoSize]="72"></app-games-end-screen-player>
                    </div>
                    <div class="col-span-1"></div>
                </div>
            </div>
        </div>

        <div class="mt-18 flex flex-col h-full portrait:overflow-hidden">
            <div
                class="flex-1 flex flex-col mt-4 relative portrait:overflow-y-auto"
                swipe
                (previous)="prevSlide()"
                (next)="nextSlide()">
                <div class="flex-1 portrait:overflow-y-auto flex flex-col overflow-x-hidden">
                    @if (statistics) {
                        <div class="flex-1">
                            @switch (currentSlide()) {
                                @case (1) {
                                    <app-games-end-screen-stats-slide
                                        [statistics]="statistics[0]"></app-games-end-screen-stats-slide>
                                }
                            }
                        </div>
                    } @else {
                        <app-loader class="block py-3.5"></app-loader>
                    }
                </div>

                @if (amountOfSlides > 1) {
                    <div class="grid max-w-36 mx-auto grid-cols-3 mt-auto py-4 flex-none">
                        <div class="col-span-1 flex justify-center" (click)="prevSlide()">
                            @if (currentSlide() > 1) {
                                <app-icon
                                    icon="keyboard_arrow_left"
                                    class="text-neutral-600 dark:text-neutral-400 cursor-pointer"></app-icon>
                            }
                        </div>
                        <div class="col-span-1 flex items-center space-x-2 justify-center">
                            @for (slide of slides; track $index) {
                                @if (slide === currentSlide()) {
                                    <div class="rounded-full bg-black w-2 h-2"></div>
                                } @else {
                                    <div class="rounded-full border-2 border-neutral-800 w-1.5 h-1.5"></div>
                                }
                            }
                        </div>
                        <div class="col-span-1 flex justify-center" (click)="nextSlide()">
                            @if (currentSlide() < amountOfSlides) {
                                <app-icon
                                    icon="keyboard_arrow_right"
                                    class="text-neutral-600 dark:text-neutral-400 cursor-pointer"></app-icon>
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>

    <div class="flex items-center px-4 flex-none">
        <app-primary-button
            class="w-full"
            color="orange"
            addedClasses="w-full"
            size="medium"
            [translate]="'VIEW_DETAILS'"
            i18n="@@VIEW_DETAILS"
            (click)="close(true)">
            View details
        </app-primary-button>
    </div>
</div>
