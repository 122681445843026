import { CommonModule } from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    effect,
    ElementRef,
    EventEmitter,
    HostListener,
    inject,
    Input,
    input,
    InputSignal,
    OnInit,
    Output,
} from '@angular/core';
import { DropdownSelectItem } from '@dc-core/dc-statistics/statistics.globals';
import { IonicModule, ModalController } from '@ionic/angular';
import { UpgradeService } from '@providers/UpgradeService';
import { AuthService } from '@services/auth.service';
import _ from 'lodash';
import { GA_EVENTCATEGORIES } from 'src/app/app.globals';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-select',
    standalone: true,
    templateUrl: './app-select.component.html',
    styleUrls: ['./app-select.component.scss'],
    imports: [CommonModule, IonicModule, IconComponent],
})
export class AppSelectComponent implements OnInit {
    public relativePositioning: InputSignal<boolean> = input(true);
    @Input() title: string;
    @Input() titleAlign: 'left' | 'center' = 'center';
    @Input() i18n: string;
    @Input() options: DropdownSelectItem[] = [];
    @Input() mode: 'white' | 'gray' | 'dialog-input' = 'white';
    @Input() size: string;
    @Input() titleColor: string;
    public initialValue: InputSignal<string> = input();
    @Input() error = false;
    @Output() selectionChange = new EventEmitter<any>();

    public activeOption: any;
    public showOptions = false;
    public isUltimate: boolean;

    private auth: AuthService = inject(AuthService);
    public modal: ModalController = inject(ModalController);
    private upgrade: UpgradeService = inject(UpgradeService);
    private _elementRef: ElementRef = inject(ElementRef);
    private _changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);

    constructor() {
        effect(() => {
            if (this.initialValue()) {
                this.activeOption = _.find(this.options, (option) => option.value === this.initialValue());
            } else {
                this.activeOption = this.options[0];
            }
        });
    }

    @HostListener('document:click', ['$event.target'])
    onClick(targetElement: HTMLElement): void {
        if (!targetElement) {
            return;
        }

        const clickedInside = this._elementRef.nativeElement.contains(targetElement);
        if (!clickedInside && this.showOptions) {
            this.showOptions = false;
            this._changeDetectorRef.detectChanges();
        }
    }

    ngOnInit(): void {
        if (this.auth.user.is_ultimate) {
            this.isUltimate = true;
        }
    }

    emitValue(selectedValue: any) {
        const option = _.find(this.options, { value: selectedValue });
        if (option.ultimateOnly && !this.auth.user.is_ultimate) {
            this.checkUltimate();
            return false;
        }
        this.selectionChange.emit(selectedValue);
        this.activeOption = option;
        this.toggleOptions();
    }

    async checkUltimate() {
        const dialogComponent = await this.upgrade.GetUpgradeDialog(GA_EVENTCATEGORIES.STATISTICS);
        this.modal
            .create({
                component: dialogComponent,
                cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
            })
            .then((elem) => {
                elem.present();
            });
    }

    toggleOptions() {
        this.showOptions = !this.showOptions;
    }
}
