import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AppLoaderComponent } from '@components/loader/app-loader.component';
import { ConnectionStatus, OmniCommunicationService } from '@dc-core/dc-services/omni/omni-communication.service';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SmartDeviceService } from '@services/smart-device.service';
import { UDPService } from '@services/udp.service';
import { PrimaryButtonComponent } from 'src/app/primary-button/primary-button.component';

type Slide = {
    index: number;
    name: string;
};

@Component({
    selector: 'app-omni-connection-lost-dialog',
    standalone: true,
    templateUrl: 'omni-connection-lost.dialog.html',
    imports: [CommonModule, IonicModule, TranslateModule, PrimaryButtonComponent, AppLoaderComponent],
})
export class OmniConnectionLostDialogComponent implements OnInit {
    @Input() isInitialCalibration: any;

    public slides: Slide[] = [
        {
            index: 0,
            name: $localize`:@@OMNI_TRYING_TO_AUTO_RECONNECT:We're trying to reconnect to the Omni Scoring`,
        },
        {
            index: 1,
            name: $localize`:@@DEVICE_GREEN_LIGHT_CHECK:Check the Omni light`,
        },
        {
            index: 2,
            name: $localize`:@@CHECKING_WIFI_STATUS:WiFi connection`,
        },
        {
            index: 3,
            name: $localize`:@@RETRY_CONNECTING:Retrying to connect`,
        },
        {
            index: 4,
            name: $localize`:@@FIND_OMNI_IN_NETWORK:Searching for Omni`,
        },
    ];
    public currentSlide: Slide = this.slides[0];
    public animationState = 'right';

    public omniCommunicationService: OmniCommunicationService = inject(OmniCommunicationService);
    public udpService: UDPService = inject(UDPService);
    private smartDeviceService: SmartDeviceService = inject(SmartDeviceService);
    private modalController: ModalController = inject(ModalController);
    private destroyRef: DestroyRef = inject(DestroyRef);

    ngOnInit(): void {
        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        //Add 'implements OnInit' to the class.
        this.omniCommunicationService.connectionStatus$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((status) => {
                if (status === ConnectionStatus.CONNECTED) {
                    this.dismiss();
                }
            });

        console.log(this.omniCommunicationService.smartDevice);
    }

    public confirmAndReconnect() {
        this.retryReconnect();
        this.onNextButtonTouched();
    }

    public onNextButtonTouched() {
        this.currentSlide = this.slides[this.currentSlide.index + 1];
    }

    public retryReconnect() {
        this.omniCommunicationService.disconnect();
        this.omniCommunicationService.connect(true);
    }

    public searchForDevice() {
        this.smartDeviceService.checkForIPChange(this.omniCommunicationService.smartDevice, 10000);
        this.onNextButtonTouched();
    }

    public dismiss(returnValue: any = null): void {
        this.modalController.dismiss(returnValue);
    }
}
