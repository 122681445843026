<div class="end-screen-slide">
    @for (stat of statistics(); track $index) {
        @if (stat.values.length === 1) {
            <div class="stat-container">
                <div class="stat-name-container">
                    <div class="stat-name">{{ stat.title }}</div>
                </div>
                <ng-container
                    *ngTemplateOutlet="
                        statScore;
                        context: { value: stat.values[0], format: stat.format, isSmall: false }
                    "></ng-container>
            </div>
        } @else if (stat.values.length === 2) {
            <div class="stat-container">
                <ng-container
                    *ngTemplateOutlet="
                        statScore;
                        context: { value: stat.values[0], format: stat.format, isSmall: false }
                    "></ng-container>
                <div class="stat-name-container">
                    <div class="stat-name">{{ stat.title }}</div>
                </div>
                <ng-container
                    *ngTemplateOutlet="
                        statScore;
                        context: { value: stat.values[1], format: stat.format, isSmall: false }
                    "></ng-container>
            </div>
        } @else {
            <div
                class="stat-container"
                [class.three]="stat.values.length === 3"
                [class.four]="stat.values.length === 4">
                <div class="stat-name-container">
                    <div class="stat-name small">{{ stat.title }}</div>
                </div>
                @for (value of stat.values; track $index) {
                    <ng-container
                        *ngTemplateOutlet="
                            statScore;
                            context: { value, format: stat.format, isSmall: true }
                        "></ng-container>
                }
            </div>
        }
    }
</div>

<ng-template #statScore let-value="value" let-format="format" let-isSmall="isSmall">
    @if (value !== null) {
        @if (!format || format === 'number') {
            <div class="stat-score" [class.small]="isSmall">{{ value }}</div>
        } @else if (format === 'decimal') {
            <div class="stat-score" [class.small]="isSmall">
                {{ value || 0 | number: '1.2-2' }}
            </div>
        } @else if (format === 'percentage') {
            <div class="stat-score" [class.small]="isSmall">{{ value || 0 | number: '1.2-2' }}%</div>
        }
    } @else {
        <div class="stat-score" [class.small]="isSmall">-</div>
    }
</ng-template>
