/* eslint-disable max-len */
import { inject, Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class RouterEventsService {
    private previousUrl: string;
    private currentUrl: string;

    private router: Router = inject(Router);

    constructor() {
        this.currentUrl = this.router.url;
        this.previousUrl = null;

        this.router.events
            .pipe(
                filter((evt: any) => evt instanceof RoutesRecognized),
                pairwise()
            )
            .subscribe((events: RoutesRecognized[]) => {
                this.previousUrl = events[0].urlAfterRedirects;
                this.currentUrl = events[1].urlAfterRedirects;
            });

        // this._router.events.pipe(filter((evt: any) => evt instanceof NavigationEnd)).subscribe(() => {
        //     if (
        //         this._platform.is('capacitor') &&
        //         this._platform.is('ios') &&
        //         this.janusVideoRoomService.isPublishingVideo
        //     ) {
        //         if (
        //             this.janusVideoRoomService.media &&
        //             this.janusVideoRoomService.ownCamera.roomID &&
        //             this.janusVideoRoomService.ownUserMedia
        //         ) {
        //             this._ngZone.run(() => {
        //                 this.janusVideoRoomService.ownUserMedia.cleanupUserMedia(true, true);
        //                 this._userMediaService.getUserMedia(this.janusVideoRoomService.media).then((stream) => {
        //                     this._userMediaService.setStreamAsOwnUserMedia(
        //                         this.janusVideoRoomService.ownUserMedia,
        //                         stream,
        //                         true
        //                     );
        //                     this.janusVideoRoomService.republishToOwnRoom();
        //                 });
        //             });
        //         }
        //     }
        // });
    }

    public getCurrentUrl() {
        return this.currentUrl;
    }

    public getPreviousUrl() {
        return this.previousUrl;
    }
}
