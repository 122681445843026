import { CommonModule } from '@angular/common';
import { Component, inject, input, InputSignal } from '@angular/core';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { ProfileApiService } from '@dc-api/profile.api.service';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { ModalController, Platform } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { DCLoadingService } from '@providers/DCLoadingService';
import { AuthService } from '@services/auth.service';
import { CropProfilePhotoDialogComponent } from 'src/dialogs/crop-profile-photo/crop-profile-photo.dialog';
import { environment } from 'src/environments/environment';

import { PrimaryButtonComponent } from '../../../../primary-button/primary-button.component';
import { IconComponent } from '../../../../shared/components/icon/icon.component';

@Component({
    selector: 'app-update-online-setup',
    templateUrl: 'update-online-setup.component.html',
    standalone: true,
    imports: [CommonModule, PrimaryButtonComponent, TranslateModule, IconComponent],
})
export class UpdateOnlineSetupComponent {
    public showTitle: InputSignal<boolean> = input(true);

    public authService: AuthService = inject(AuthService);

    private platform: Platform = inject(Platform);
    private alertService: DartCounterAlertService = inject(DartCounterAlertService);
    private modalController: ModalController = inject(ModalController);
    private loadingService: DCLoadingService = inject(DCLoadingService);
    private profileApiService: ProfileApiService = inject(ProfileApiService);

    public async uploadOnlineSetup(): Promise<void> {
        if (this.platform.is('capacitor')) {
            try {
                const checkCamPermissions = await Camera.checkPermissions();
                if (checkCamPermissions.camera !== 'granted' || checkCamPermissions.photos !== 'granted') {
                    const requestCamResult = await Camera.requestPermissions();
                    if (requestCamResult.camera === 'granted' && requestCamResult.photos === 'granted') {
                        setTimeout(() => {
                            this.uploadOnlineSetup();
                        }, 500);
                        return;
                    } else {
                        throw 'No permission';
                    }
                }
            } catch (_) {
                this.alertService.createAlert({
                    title: $localize`:@@ENABLE_CAM_APP_PERMS:Please enable camera permissions in your device settings.`,
                    icon: 'error',
                    timer: 2000,
                    timerProgressBar: true,
                    showCloseButton: true,
                });
                return;
            }
        }

        await Camera.getPhoto({
            height: 500,
            width: 500,
            allowEditing: false,
            correctOrientation: true,
            source: CameraSource.Camera,
            quality: 90,
            resultType: CameraResultType.Base64,
            webUseInput: environment.isWeb,
        }).then((imageData: Photo) => {
            this.modalController
                .create({
                    component: CropProfilePhotoDialogComponent,
                    componentProps: {
                        image: imageData.base64String,
                        format: imageData.format,
                    },
                    cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
                })
                .then((elem) => {
                    elem.present();
                    elem.onDidDismiss().then(async (dialogRes) => {
                        if (dialogRes.data) {
                            await this.loadingService.ShowDefaultLoader();

                            this.profileApiService
                                .uploadOnlineSetup({ encoded_online_setup: dialogRes.data })
                                .then((res) => {
                                    this.authService.user.profile.online_setup_path = res.data.online_setup_path;
                                    this.authService.user.profile.online_setup_url = dialogRes.data;
                                })
                                .catch((err) => {
                                    this.alertService.errorFromApi(err);
                                })
                                .finally(() => this.loadingService.DismissLoader());
                        }
                    });
                });
        });
    }
}
