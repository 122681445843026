<ion-content scrollY="true" fullscreen class="bg-white-pattern text-black">
    <app-page-header mainNavigation="custom" color="transparent">
        <div title class="text-black" [translate]="'HEAD_TO_HEAD'" i18n="@@HEAD_TO_HEAD">Head to head</div>
    </app-page-header>

    <ion-row *ngIf="users.length" #focus>
        <ion-col *ngFor="let user of users" size="6">
            <div *ngIf="user" class="animate__animated animate__backInDown m-0">
                <ion-col style="justify-content: center; display: flex" [class]="playerAnimation">
                    <app-profile-photo
                        [class]="user.good_form ? profilePicAnimation : ''"
                        [ultimate]="user.is_ultimate"
                        [size]="52"
                        [url]="user.profile?.profile_photo_url"
                        [badge]="user.profile?.badge">
                    </app-profile-photo>
                </ion-col>

                <ion-col class="text-center -mt-2">
                    <h2 class="onelineEllipsis capitalize text-black m-0">{{ user.first_name }}</h2>
                    <p class="m-0 text-xs text-neutral-800">
                        {{ user.profile?.nickname }}
                    </p>
                </ion-col>
            </div>
        </ion-col>
    </ion-row>

    @if (headToHeadInfo && section === 'general_stats' && effect2) {
        <div class="mx-4">
            <app-head-to-head
                [headToHeadData]="headToHeadInfo"
                [position]="'top_center'"
                class="w-full mx-4"
                [animated]="true"></app-head-to-head>
        </div>
    }

    <ion-row
        *ngIf="users.length && section === 'general_stats'"
        [ngClass]="{ animate__fadeOut: fadeout }"
        class="animate__animated">
        <ion-col *ngFor="let user of users" size="6" class="borderedRows">
            <div class="m-0" *ngIf="user">
                <ion-row
                    *ngIf="slideIn[0]"
                    style="padding: 5px 0"
                    class="animate__animated animate__fadeInLeft border-b-1 border-white">
                    <ion-col class="text-center text-black">
                        <p class="text-xs font-semibold mb-1">
                            {{ '501_AVG' | translate }}
                        </p>
                        <p>{{ user.statistics?.all_time_average || 0 | number: '1.2-2' }}</p>
                    </ion-col>
                </ion-row>
                <ion-row
                    *ngIf="slideIn[1]"
                    style="padding: 5px 0"
                    class="animate__animated animate__fadeInLeft border-b-1 border-white">
                    <ion-col class="text-center text-black">
                        <p class="text-xs font-semibold mb-1">180s</p>
                        <p>{{ user.statistics?.one_hundred_eighties || 0 }}</p>
                    </ion-col>
                </ion-row>
                <ion-row
                    *ngIf="slideIn[2]"
                    style="padding: 5px 0"
                    class="animate__animated animate__fadeInLeft border-b-1 border-white">
                    <ion-col class="text-center text-black">
                        <p class="text-xs font-semibold mb-1">
                            {{ 'BEST_LEG' | translate }}
                        </p>
                        <p>{{ user.statistics?.best_leg || 0 }} darts</p>
                    </ion-col>
                </ion-row>
                <ion-row *ngIf="slideIn[3]" style="padding: 5px 0" class="animate__animated animate__fadeInLeft">
                    <ion-col class="text-center text-black">
                        <p class="text-xs font-semibold mb-1">
                            {{ 'HIGHEST_FINISH' | translate }}
                        </p>
                        <p>{{ user.statistics?.highest_checkouts[0] || 0 }}</p>
                    </ion-col>
                </ion-row>
            </div>
        </ion-col>
    </ion-row>

    <ion-row
        *ngIf="headToHeadInfo?.last_match?.users && section === 'headtohead'"
        class="lastmatch"
        [ngClass]="{ animate__fadeOut: fadeout2 }">
        <ion-col
            size="12"
            *ngIf="slideIn[0]"
            class="animate__animated animate__fadeIn fullWidth border-t-1 border-b-1 border-white bg-white bg-opacity-20 dark:bg-black dark:bg-opacity-20">
            <h2 class="text-center text-black" [translate]="'LAST_RESULT'" i18n="@@LAST_RESULT">Last result</h2>
            <p class="text-center text-black border-b-1 border-white">{{ headToHeadInfo?.last_match?.name }}</p>
        </ion-col>
        <ion-col *ngFor="let matchUser of headToHeadInfo?.last_match?.users" size="6" class="borderedRows">
            <div style="margin: 0" *ngIf="matchUser">
                <ion-row
                    *ngIf="slideIn[1] && headToHeadInfo?.last_match?.has_sets"
                    style="padding: 5px 0"
                    class="animate__animated animate__fadeInLeft border-b-1 border-white">
                    <ion-col class="text-center text-black">
                        <p class="text-xs font-semibold mb-1">
                            {{ 'SETS' | translate }}
                        </p>
                        <p>{{ matchUser.total_score_sets }}</p>
                    </ion-col>
                </ion-row>
                <ion-row
                    *ngIf="slideIn[1] && !headToHeadInfo?.last_match?.has_sets"
                    style="padding: 5px 0"
                    class="animate__animated animate__fadeInLeft border-b-1 border-white">
                    <ion-col class="text-center text-black">
                        <p class="text-xs font-semibold mb-1">
                            {{ 'LEGS' | translate }}
                        </p>
                        <p>{{ matchUser.total_score_legs }}</p>
                    </ion-col>
                </ion-row>
                <ion-row
                    *ngIf="slideIn[2]"
                    style="padding: 5px 0"
                    class="animate__animated animate__fadeInLeft border-b-1 border-white">
                    <ion-col class="text-center text-black">
                        <p class="text-xs font-semibold mb-1">
                            {{ 'AVERAGE' | translate }}
                        </p>
                        <p>{{ matchUser.three_dart_average || 0 | number: '1.2-2' }}</p>
                    </ion-col>
                </ion-row>
                <ion-row
                    *ngIf="slideIn[3]"
                    style="padding: 5px 0"
                    class="animate__animated animate__fadeInLeft border-b-1 border-white">
                    <ion-col class="text-center text-black">
                        <p class="text-xs font-semibold mb-1">
                            {{ 'HIGHEST_SCORE' | translate }}
                        </p>
                        <p>{{ matchUser.highest_score || '-' }}</p>
                    </ion-col>
                </ion-row>
                <ion-row *ngIf="slideIn[4]" style="padding: 5px 0" class="animate__animated animate__fadeInLeft">
                    <ion-col class="text-center text-black">
                        <p class="text-xs font-semibold mb-1">
                            {{ 'HIGHEST_FINISH' | translate }}
                        </p>
                        <p>{{ matchUser.highest_finish || '-' }}</p>
                    </ion-col>
                </ion-row>
            </div>
        </ion-col>
    </ion-row>

    <ion-row
        *ngIf="
            headToHeadInfo?.first_user_last_matches &&
            headToHeadInfo?.second_user_last_matches &&
            section === 'lastfive'
        "
        class="lastmatch">
        <ion-col size="12" *ngIf="slideIn[0]" class="animate__animated animate__fadeIn fullWidth">
            <h2 class="text-center text-black" [translate]="'LAST_RESULTS'" i18n="@@LAST_RESULTS">Last results</h2>
        </ion-col>
        <ion-col size="6" class="borderedRows">
            <div style="margin: 0" *ngFor="let match of headToHeadInfo?.first_user_last_matches; let i = index">
                <ion-row
                    style="padding: 5px 0"
                    *ngIf="slideIn[i + 1]"
                    class="opponent animate__animated animate__fadeInLeft">
                    <ion-col
                        class="centerColumn"
                        size="5"
                        style="align-items: center; display: flex; flex-direction: column">
                        <app-profile-photo
                            [size]="38"
                            [ultimate]="match.users[1]?.user?.is_ultimate"
                            [url]="match.users[1]?.user?.profile?.profile_photo_url"
                            [badge]="match.users[1]?.user.profile?.badge"></app-profile-photo>
                        <span class="opponentName text-black">{{
                            match.users[1]?.user?.first_name || match.users[1]?.name
                        }}</span>
                    </ion-col>
                    <ion-col class="centerColumn">
                        <div class="matchresult text-black">
                            <p *ngIf="match.has_sets">
                                {{ match.users[0]?.total_score_sets ?? 0 }} -
                                {{ match.users[1]?.total_score_sets ?? 0 }}
                            </p>
                            <p *ngIf="!match.has_sets">
                                {{ match.users[0]?.total_score_legs ?? 0 }} -
                                {{ match.users[1]?.total_score_legs ?? 0 }}
                            </p>
                            <p *ngIf="match.has_sets">{{ 'SETS' | translate }}</p>
                            <p *ngIf="!match.has_sets">{{ 'LEGS' | translate }}</p>
                        </div>
                    </ion-col>
                    <ion-col class="centerColumn text-black">
                        <span
                            class="dot"
                            [ngClass]="{
                                lose: match.users[0]?.result === 'lost',
                                win: match.users[0]?.result === 'won',
                                draw: match.users[0]?.result === 'tie'
                            }"></span>
                    </ion-col>
                </ion-row>
            </div>
        </ion-col>
        <ion-col size="6" class="borderedRows">
            <div style="margin: 0" *ngFor="let match of headToHeadInfo?.second_user_last_matches; let i = index">
                <ion-row
                    style="padding: 5px 0"
                    *ngIf="slideIn[i + 1]"
                    class="opponent animate__animated animate__fadeInRight">
                    <ion-col class="centerColumn text-black">
                        <span
                            class="dot"
                            [ngClass]="{
                                lose: match.users[0]?.result === 'lost',
                                win: match.users[0]?.result === 'won',
                                draw: match.users[0]?.result === 'tie'
                            }"></span>
                    </ion-col>
                    <ion-col class="centerColumn text-black">
                        <div class="matchresult">
                            <p *ngIf="match.has_sets">
                                {{ match.users[0]?.total_score_sets ?? 0 }} -
                                {{ match.users[1]?.total_score_sets ?? 0 }}
                            </p>
                            <p *ngIf="!match.has_sets">
                                {{ match.users[0]?.total_score_legs ?? 0 }} -
                                {{ match.users[1]?.total_score_legs ?? 0 }}
                            </p>
                            <p *ngIf="match.has_sets">{{ 'SETS' | translate }}</p>
                            <p *ngIf="!match.has_sets">{{ 'LEGS' | translate }}</p>
                        </div>
                    </ion-col>
                    <ion-col class="centerColumn text-black items-center flex flex-col" size="5">
                        <app-profile-photo
                            [size]="38"
                            [ultimate]="match.users[1]?.user?.is_ultimate"
                            [url]="match.users[1]?.user?.profile?.profile_photo_url"
                            [badge]="match.users[1]?.user?.profile?.badge"></app-profile-photo>
                        <span class="opponentName text-black">{{
                            match.users[1]?.user?.first_name || match.users[1]?.name
                        }}</span>
                    </ion-col>
                </ion-row>
            </div>
        </ion-col>
    </ion-row>

    <ion-footer *ngIf="!isOnline && showSkipButton" class="skip ion-no-border">
        <p class="text-right mr-3 text-neutral-800" (click)="skip()" [translate]="'SKIP'" i18n="@@SKIP">Skip</p>
    </ion-footer>
</ion-content>
