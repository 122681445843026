/* eslint-disable max-len */
import { Component, computed, inject, Signal, signal, WritableSignal } from '@angular/core';
import { FirebaseApiService } from '@dc-api/firebase.api.service';
import { GameApiService } from '@dc-api/game.api.service';
import { User } from '@dc-core/dc-backend/dc-classes';
import { ReportOrigin } from '@dc-core/dc-backend/dc-enums';
import { ONLINEGAMESTATUS } from '@dc-core/dc-backend/dc-interfaces';
import { ActiveGamesCollectionService } from '@dc-core/dc-firestore/collection-helpers/active_games.collection.service';
import { InviteCollectionService } from '@dc-core/dc-firestore/collection-helpers/invite.collection.service';
import { LobbySettingsCollectionService } from '@dc-core/dc-firestore/collection-helpers/lobby_settings.collection.service';
import { UsersCollectionService } from '@dc-core/dc-firestore/collection-helpers/users.collection.service';
import { FireStoreAuthService } from '@dc-core/dc-firestore/firestore-auth.service';
import { DCFireStoreInvite, INVITATIONSTATUS, INVITATIONTYPE } from '@dc-core/dc-firestore/globals/firestore.tables';
import { LobbyUser } from '@dc-core/dc-gamelogic/game-interfaces';
import { OnlineFunctions } from '@dc-core/dc-gamelogic/online-game/online.functions';
import { OnlineGameHelper } from '@dc-core/dc-helpers/online-game.helper';
import { RXJSSubscriptionManager } from '@dc-core/dc-logging/rxjs-subscription.manager';
import { JanusVideoRoomService } from '@dc-core/dc-services/dc-janus/janus-video-room.service';
import { FreeMatchesService } from '@dc-core/dc-services/free-matches.service';
import { FriendsLobbyTab, OnlineGamesService } from '@dc-core/dc-services/online-games.service';
import { ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { LocalCameraService } from '@services/local-camera.service';
import { AlertPayload, DartCounterAlertService } from 'dc-core/dc-services/alert.service';
import { Timestamp } from 'firebase/firestore';
import { GA_EVENTACTIONS, GA_EVENTCATEGORIES } from 'src/app/app.globals';
import { TournamentService } from 'src/app/modules/tournaments/services/tournament.service';
import { PromptDialogComponent } from 'src/dialogs/prompt/prompt.dialog';
import { ReportUserDialogComponent } from 'src/dialogs/report-user/report-user.dialog';
import { environment } from 'src/environments/environment';
import { UpgradeService } from 'src/providers/UpgradeService';
import { AuthService } from 'src/services/auth.service';

import { ProfileDialogComponent } from '../../../app/modules/profile/dialogs/profile-dialog/profile.dialog';
import { InviteGameRequestDialogComponent } from '../invite-game-request/invite-game-request';

@Component({
    selector: 'app-page-friend-lobby',
    templateUrl: 'friendLobby.html',
})
export class FriendLobbyComponent {
    public isWeb = environment.isWeb;

    private _subscriptionsToDestroy: string[] = [];

    public selectedTab: WritableSignal<FriendsLobbyTab> = signal(undefined);
    public activeTab: Signal<FriendsLobbyTab> = computed(() => {
        const openFriends = this.online.openFriends();
        if (openFriends !== undefined) {
            const selectedTab = this.selectedTab();
            if (selectedTab === undefined) {
                return openFriends;
            } else {
                return selectedTab;
            }
        }
        return null;
    });

    private tournamentService: TournamentService = inject(TournamentService);
    private activeGamesCollectionService: ActiveGamesCollectionService = inject(ActiveGamesCollectionService);

    private isInviting = false;

    constructor(
        public auth: AuthService,
        private nav: NavController,
        private ga: DartCounterAnalyticsService,
        private modal: ModalController,
        public onlineFunctions: OnlineFunctions,
        public freeMatchesService: FreeMatchesService,
        private upgrade: UpgradeService,
        public gameApiService: GameApiService,
        private _translate: TranslateService,
        private _alertService: DartCounterAlertService,
        private _lobbySettingsService: LobbySettingsCollectionService,
        private _dcFireAuth: FireStoreAuthService,
        private _usersCollectionService: UsersCollectionService,
        private _rxjsSubscriptionManager: RXJSSubscriptionManager,
        public dcInvites: InviteCollectionService,
        private _firebaseApiService: FirebaseApiService,
        public online: OnlineGamesService,
        public videoRoomService: JanusVideoRoomService,
        private _localCameraService: LocalCameraService
    ) {
        this.ga.trackView('Online Friends Lobby');
    }

    ionViewWillEnter(): void {
        this.online.start(this.auth.user);
        this._localCameraService.checkPublishingVideo();
    }

    invite(user: LobbyUser): void {
        if (this.isInviting) {
            return;
        }

        if (this.tournamentService.isParticipatingRestricting()) {
            this._alertService.createAlert({
                title: $localize`:@@CANNOT_PLAY_ONLINE_GAME_BECAUSE_TOURNAMENT:You cannot play an online game because you are participating in an online tournament`,
                icon: 'error',
            });
            return;
        }

        if (!this.freeMatchesService.canPlay) {
            this._alertService.createAlert({
                icon: 'warning',
                title: $localize`:@@NO_MORE_FREE_GAMES_THIS_WEEK:Your free online games for this week have been used`,
            });
            return;
        }

        if (!this.online.onlineSavedGameplay) {
            this._alertService.createAlert({
                title: $localize`:@@NO_GAME_CREATED:Please create a game first!`,
                icon: 'error',
            });

            return;
        }

        this.isInviting = true;

        this.modal
            .create({
                component: InviteGameRequestDialogComponent,
                componentProps: {
                    user,
                },
                cssClass: 'auto-height',
                backdropDismiss: false,
                showBackdrop: true,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data === true) {
                        this.setLobbyGame(user);
                    } else {
                        this.isInviting = false;
                    }
                });
            });
    }

    setLobbyGame(receiver: LobbyUser) {
        this.online.prepareLobbyGame(ONLINEGAMESTATUS.INVITE_ONLY, [receiver]);

        this._lobbySettingsService.addItem(this.online.onlineSavedGameplay).then((docRef) => {
            this.dcInvites.currentFriendInviteGame.set({
                ...this.online.onlineSavedGameplay,
            });
            this.dcInvites.currentFriendInviteGameRef = docRef;

            this.invitePlayer(receiver);
        });
    }

    invitePlayer(receiver: LobbyUser): void {
        this.dcInvites.currentFriendInviteGame.update((currentFriendInviteGame) => ({
            ...currentFriendInviteGame,
            name: this.onlineFunctions.onlineGameplayName(this.dcInvites.currentFriendInviteGame()),
        }));

        const fsUser = this._usersCollectionService.getDCFireStoreUser(this.online.user);

        const invite = {
            sender_uid: this._dcFireAuth.getCurrentUID(),
            receiver_uid: receiver.uid,
            gameplay_doc_id: this.dcInvites.currentFriendInviteGameRef.id,
            invitation_type: INVITATIONTYPE.INVITE,
            sent_at: Timestamp.now(),
            status: INVITATIONSTATUS.PENDING,
            user: fsUser,
        } as DCFireStoreInvite;

        this.dcInvites.sendFirestoreInvite(invite);

        this._firebaseApiService.firebaseGameInvite({ user_ids: [receiver.id] });

        this._alertService.createAlert({
            title: $localize`:@@INVITE_IS_SENT:The invite has been sent`,
            icon: 'success',
            timerProgressBar: true,
            showCloseButton: true,
        });

        this.isInviting = false;
    }

    public removeOutgoingInvite() {
        this.dcInvites.removeOutgoingInvites();
    }

    cancelInvite() {
        this.modal
            .create({
                component: PromptDialogComponent,
                componentProps: {
                    title: $localize`:@@CANCEL_INVITE:Cancel invite`,
                    text: $localize`:@@CANCEL_GAMEINVITE_QUESTION:Do you want to cancel this invite?`,
                    cancelText: $localize`:@@CANCEL:Cancel`,
                    confirmText: $localize`:@@CONFIRM:Confirm`,
                    confirmColor: 'orange',
                } as AlertPayload,
                cssClass: 'auto-height',
                showBackdrop: true,
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data == true) {
                        this._alertService.createAlert({
                            title: $localize`:@@INVITE_IS_CANCELLED:The invite has been cancelled`,
                            icon: 'success',
                            showCloseButton: true,
                        });

                        this.dcInvites.removeOutgoingInvites();
                    }
                });
            });
    }

    openFriendSearch() {
        this.nav.navigateForward('search-users');
    }

    showPlayerInfo(user: User) {
        this.ga.trackEvent(GA_EVENTCATEGORIES.GLOBALLOBBY, GA_EVENTACTIONS.SHOWPROFILEPOPUP);

        this.modal
            .create({
                component: ProfileDialogComponent,
                componentProps: {
                    user,
                    canReport: this.auth.user.id != user.id,
                },
                cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                backdropDismiss: true,
                showBackdrop: true,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data === 'report') {
                        this.modal
                            .create({
                                component: ReportUserDialogComponent,
                                cssClass: 'auto-height',
                                componentProps: {
                                    user,
                                    origin: 'friends_lobby' as ReportOrigin,
                                },
                            })
                            .then((elem) => {
                                elem.present();
                            });
                    }
                });
            });
    }

    async openUpgradeDialog() {
        const dialogComponent = await this.upgrade.GetUpgradeDialog(GA_EVENTCATEGORIES.FRIENDSLOBBY);
        this.modal
            .create({
                component: dialogComponent,
                cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
            })
            .then((elem) => {
                elem.present();
            });
    }

    ionViewWillLeave(): void {
        this.auth.setInApp();

        // Unsubscribe from all subscriptions
        this._subscriptionsToDestroy.forEach((key) => {
            this._rxjsSubscriptionManager.cleanSubscription(key);
        });

        this.freeMatchesService.clear();
    }

    toggleTab(tab: FriendsLobbyTab, number: number) {
        if (number === 0) {
            return;
        }
        if (this.activeTab() === tab) {
            this.selectedTab.set(null);
        } else {
            this.selectedTab.set(tab);
        }
    }

    watch(user: LobbyUser): void {
        if (!user.game) {
            return;
        }

        this.activeGamesCollectionService.redirectBack = '/friends-lobby';
        this.nav.navigateForward(OnlineGameHelper.getGameRoute(user.game.game_mode, user.game.doc_id, true));
    }
}
