import { NgModule } from '@angular/core';
import { FireStoreAuthService } from './firestore-auth.service';
import { FireStoreCollectionsService } from './firestore-collections.service';
import { LobbySettingsCollectionService } from './collection-helpers/lobby_settings.collection.service';
import { UsersCollectionService } from './collection-helpers/users.collection.service';
import { ActiveGamesCollectionService } from './collection-helpers/active_games.collection.service';
import { InviteCollectionService } from './collection-helpers/invite.collection.service';
import { PausesCollectionService } from './collection-helpers/pauses.collection.service';
import { ActionsCollectionService } from './collection-helpers/actions.collection.service';
import { ActionsLogicService } from './actions.service';
import { ChatsCollectionService } from './collection-helpers/chats.collection.service';
import { PublicGamesCollectionService } from './collection-helpers/public_games.collection.service';
import { GameEventsCollectionService } from './collection-helpers/game_events.collection.service';
import { SpectatorsCollectionService } from './collection-helpers/spectators.collection.service';
import { UnfinishedGamesService } from './unfinished-games.service';
import { DowntimesCollectionService } from './collection-helpers/downtimes.collection.service';
import { CountsCollectionService } from './collection-helpers/counts.collection.service';
import { RatingsCollectionService } from './collection-helpers/ratings.collection.service';
import { BackendEventsCollectionService } from './collection-helpers/backend-events.collection.service';
import { OmniCollectionService } from './collection-helpers/omni.collection';

@NgModule({
    providers: [
        // Base Logic
        ActionsLogicService,
        FireStoreAuthService,
        FireStoreCollectionsService,
        UnfinishedGamesService,

        // Fire Store Collections
        ActionsCollectionService,
        GameEventsCollectionService,
        BackendEventsCollectionService,
        ActiveGamesCollectionService,
        ChatsCollectionService,
        CountsCollectionService,
        DowntimesCollectionService,
        InviteCollectionService,
        LobbySettingsCollectionService,
        PausesCollectionService,
        PublicGamesCollectionService,
        UsersCollectionService,
        SpectatorsCollectionService,
        RatingsCollectionService,
        OmniCollectionService,
    ],
    imports: [],
})
export class DartCounterFireStoreModule {}
