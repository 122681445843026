import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { PlayersHeadToHeadResponse } from '@dc-core/dc-backend/dc-responses';

@Component({
    selector: 'app-head-to-head',
    templateUrl: 'app-head-to-head.component.html',
    styleUrls: ['app-head-to-head.component.scss'],
    standalone: true,
    imports: [CommonModule],
})
export class AppHeadToHeadComponent implements OnInit {
    @Input() versusPlayer: string = null;
    @Input() headToHeadData: PlayersHeadToHeadResponse;
    @Input() position: 'top_center' | 'bottom_left' = 'bottom_left';
    @Input() animated: boolean = false;
    constructor() {}

    ngOnInit(): void {}

    getBarWidth(value: number, total: number): number {
        if (total === 0) {
            return 33.3;
        }
        const minPercentage = 7;
        const maxPercentage = 100 - 2 * minPercentage;
        const percentage = (value / total) * 100;

        return Math.min(Math.max(percentage, minPercentage), maxPercentage);
    }
}
