<div
    class="flex relative bottom-0 w-full flex-col overflow-y-hidden overscroll-none rounded-t-lg bg-white-pattern text-black">
    <div class="relative flex items-center rounded-t-lg py-4 px-safe-offset-4">
        <ion-buttons slot="start">
            <div
                class="ml-1 text-center text-sm font-bold text-black cursor-pointer"
                [translate]="'CLOSE'"
                i18n="@@CLOSE"
                (click)="dismiss()">
                Close
            </div>
        </ion-buttons>
        <div
            class="absolute left-0 right-0 my-auto w-full text-center font-bebas-neue text-2xl text-black"
            [translate]="'OMNI_CONNECTION_LOST'"
            i18n="@@OMNI_CONNECTION_LOST">
            OMNI Connection lost
        </div>
    </div>
    <div class="mt-2 flex-1 rounded-t-4xl bg-white pt-6 text-black px-safe" *ngIf="currentSlide">
        <div class="space-y-3 bg-white px-5" *ngIf="currentSlide.name">
            <div class="dialog-input-label">{{ currentSlide.name }}</div>
            <div class="flex items-center justify-between space-x-2">
                <div
                    class="h-1.5 w-full rounded-full"
                    [class.bg-orange-50]="slide.index > currentSlide.index"
                    [class.!bg-orange]="slide.index <= currentSlide.index"
                    *ngFor="let slide of slides"></div>
            </div>
        </div>

        <div class="my-3 flex flex-col w-full text-center justify-center overflow-x-scroll bg-white scrollbar-hide">
            @switch (currentSlide.index) {
                @case (0) {
                    <div class="flex-col space-y-2 px-5">
                        <div class="relative flex mx-auto w-[60%] !text-white">
                            <div
                                class="flex flex-col absolute justify-center items-center space-y-2 w-full h-full bg-[#303332d6] z-10">
                                @if (
                                    omniCommunicationService.reconnectionAttempts !==
                                    omniCommunicationService.maxReconnectAttempts
                                ) {
                                    <app-loader color="white" class="block mt-2"></app-loader>

                                    <div
                                        class="font-semibold text-black"
                                        [translate]="'RECONNECT_ATTEMPTS'"
                                        i18n="@@RECONNECT_ATTEMPTS">
                                        Reconnect attempts
                                    </div>
                                    <div>
                                        <span class="animate-pulse">{{
                                            omniCommunicationService.reconnectionAttempts
                                        }}</span>
                                        /
                                        {{ omniCommunicationService.maxReconnectAttempts }}
                                    </div>
                                } @else {
                                    <div
                                        class="font-semibold text-black"
                                        [translate]="'RECONNECTING_FAILED'"
                                        i18n="@@RECONNECTING_FAILED">
                                        Reconnecting failed
                                    </div>
                                }
                            </div>
                            <img class="rounded-md" src="assets/images/ftr/omni.jpg" />
                        </div>
                    </div>
                    @if (
                        omniCommunicationService.reconnectionAttempts === omniCommunicationService.maxReconnectAttempts
                    ) {
                        <div class="flex flex-col space-y-2 bg-white px-5 pt-5 pb-safe-offset-5">
                            <app-primary-button
                                size="normal"
                                addedClasses="w-full"
                                (click)="onNextButtonTouched()"
                                [translate]="'NEXT'"
                                i18n="@@NEXT">
                                Next
                            </app-primary-button>
                        </div>
                    }
                }
                @case (1) {
                    <div class="flex-col space-y-2 px-5">
                        <div
                            class="font-semibold text-black"
                            [translate]="'OMNI_LIGHT_IS_GREEN'"
                            i18n="@@OMNI_LIGHT_IS_GREEN">
                            Please confirm that the Omni Scoring light is solid green
                        </div>
                        <img class="rounded-md" src="assets/images/ftr/omni-scoring-setup/Omni-setup-green.jpg" />
                    </div>
                    <div class="flex flex-col space-y-2 bg-white px-5 pt-5 pb-safe-offset-5">
                        <app-primary-button
                            size="normal"
                            addedClasses="w-full"
                            (click)="onNextButtonTouched()"
                            [translate]="'CONFIRM'"
                            i18n="@@CONFIRM">
                            Confirm
                        </app-primary-button>
                    </div>
                }
                @case (2) {
                    <div class="flex-col space-y-2 px-5">
                        <div
                            class="font-semibold text-black"
                            [translate]="'CONNECTED_TO_CORRECT_NETWORK'"
                            i18n="@@CONNECTED_TO_CORRECT_NETWORK">
                            Please check if you're connected to the right network
                        </div>
                        <div class="relative flex mx-auto w-[60%] !text-white">
                            <div
                                class="flex flex-col absolute justify-center items-center space-y-1 w-full h-full bg-[#303332d6] z-10">
                                @if (omniCommunicationService.smartDevice?.networkName) {
                                    <div
                                        [translate]="'YOU_SHOULD_BE_CONNECTED_TO_NETWORK'"
                                        i18n="@@YOU_SHOULD_BE_CONNECTED_TO_NETWORK">
                                        You should be connected to network
                                    </div>
                                    <div class="font-semibold">
                                        {{ omniCommunicationService.smartDevice?.networkName }}
                                    </div>
                                }
                            </div>
                            <img class="rounded-md" src="assets/images/ftr/omni.jpg" />
                        </div>
                    </div>
                    <div class="flex flex-col space-y-2 bg-white px-5 pt-5 pb-safe-offset-5">
                        <app-primary-button
                            size="normal"
                            addedClasses="w-full"
                            (click)="confirmAndReconnect()"
                            [translate]="'CONFIRM'"
                            i18n="@@CONFIRM">
                            Confirm
                        </app-primary-button>
                    </div>
                }
                @case (3) {
                    <div class="flex-col space-y-2 px-5">
                        <div class="relative flex mx-auto w-[60%] !text-white">
                            <div
                                class="flex flex-col absolute justify-center items-center space-y-2 w-full h-full bg-[#303332d6] z-10">
                                @if (
                                    omniCommunicationService.reconnectionAttempts !==
                                    omniCommunicationService.maxReconnectAttempts
                                ) {
                                    <app-loader color="white" class="block mt-2"></app-loader>

                                    <div
                                        class="font-semibold text-black"
                                        [translate]="'RECONNECT_ATTEMPTS'"
                                        i18n="@@RECONNECT_ATTEMPTS">
                                        Reconnect attempts
                                    </div>
                                    <div>
                                        <span class="animate-pulse">{{
                                            omniCommunicationService.reconnectionAttempts
                                        }}</span>
                                        /
                                        {{ omniCommunicationService.maxReconnectAttempts }}
                                    </div>
                                } @else {
                                    <div
                                        class="font-semibold text-black"
                                        [translate]="'RECONNECTING_FAILED'"
                                        i18n="@@RECONNECTING_FAILED">
                                        Reconnecting failed
                                    </div>
                                }
                            </div>
                            <img class="rounded-md" src="assets/images/ftr/omni.jpg" />
                        </div>
                    </div>
                    @if (
                        omniCommunicationService.reconnectionAttempts === omniCommunicationService.maxReconnectAttempts
                    ) {
                        <div class="flex flex-col space-y-2 bg-white px-5 pt-5 pb-safe-offset-5">
                            <app-primary-button
                                size="normal"
                                addedClasses="w-full"
                                (click)="searchForDevice()"
                                [translate]="'NEXT'"
                                i18n="@@NEXT">
                                Next
                            </app-primary-button>
                        </div>
                    }
                }
                @case (4) {
                    <div class="flex-col space-y-2 px-5">
                        <div class="relative flex mx-auto w-[80%] !text-white">
                            @if (udpService.status === 'searching') {
                                <div
                                    class="flex flex-col absolute justify-center items-center space-y-2 w-full h-full bg-[#303332d6] z-10">
                                    <div
                                        class="font-semibold text-black"
                                        [translate]="'SEARCHING_OMNI_IN_NETWORK'"
                                        i18n="@@SEARCHING_OMNI_IN_NETWORK">
                                        Searching the Omni in your network
                                    </div>
                                    <app-loader color="white" class="block mt-2"></app-loader>
                                </div>
                            } @else {
                                <div
                                    class="flex flex-col absolute justify-center items-center space-y-2 w-full h-full bg-[#303332d6] z-10">
                                    <div
                                        class="font-semibold text-black"
                                        [translate]="'DEVICE_NOT_FOUND_IN_NETWORK'"
                                        i18n="@@DEVICE_NOT_FOUND_IN_NETWORK">
                                        We could not find the Omni Scoring in your network
                                    </div>
                                </div>
                            }
                            <img class="rounded-md" src="assets/images/ftr/omni.jpg" />
                        </div>
                    </div>
                    <div class="flex flex-col space-y-2 bg-white px-5 pt-5 pb-safe-offset-5">
                        <app-primary-button
                            size="normal"
                            addedClasses="w-full"
                            (click)="dismiss()"
                            [translate]="'CLOSE'"
                            i18n="@@CLOSE">
                            Close
                        </app-primary-button>
                    </div>
                }
            }
        </div>
    </div>
</div>
