/* eslint-disable max-len */
import { AfterViewInit, Component, EnvironmentInjector, inject, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { PluginListenerHandle } from '@capacitor/core';
import { ConnectionStatus, Network } from '@capacitor/network';
import { SplashScreen } from '@capacitor/splash-screen';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { KeepAwake } from '@capacitor-community/keep-awake';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { CameraStreamApiService } from '@dc-api/camera-stream.api.service';
import { FriendApiService } from '@dc-api/friend.api.service';
import { GameApiService } from '@dc-api/game.api.service';
import { PrivateChatApiService } from '@dc-api/private-chat.api.service';
import { SmartDeviceApiService } from '@dc-api/smart-device.api.service';
import { UserApiService } from '@dc-api/user.api.service';
import { BanLog, Discount, SmartDevice } from '@dc-core/dc-backend/dc-classes';
import { SoundPath } from '@dc-core/dc-backend/dc-enums';
import { FirestoreUserBackendEvent } from '@dc-core/dc-backend/dc-interfaces';
import { ActionsLogicService } from '@dc-core/dc-firestore/actions.service';
import { ActiveGamesCollectionService } from '@dc-core/dc-firestore/collection-helpers/active_games.collection.service';
import { BackendEventsCollectionService } from '@dc-core/dc-firestore/collection-helpers/backend-events.collection.service';
import { DowntimesCollectionService } from '@dc-core/dc-firestore/collection-helpers/downtimes.collection.service';
import { InviteCollectionService } from '@dc-core/dc-firestore/collection-helpers/invite.collection.service';
import { LobbySettingsCollectionService } from '@dc-core/dc-firestore/collection-helpers/lobby_settings.collection.service';
import { PublicGamesCollectionService } from '@dc-core/dc-firestore/collection-helpers/public_games.collection.service';
import { RatingsCollectionService } from '@dc-core/dc-firestore/collection-helpers/ratings.collection.service';
import { UsersCollectionService } from '@dc-core/dc-firestore/collection-helpers/users.collection.service';
import {
    DCFireStoreInvite,
    DCFireStoreRating,
    DCFireStoreUser,
    INVITATIONSTATUS,
    INVITATIONTYPE,
} from '@dc-core/dc-firestore/globals/firestore.tables';
import { DCOnlineCore } from '@dc-core/dc-gamelogic/online-core.functions';
import { IntervalManager } from '@dc-core/dc-logging/interval.manager';
import { RXJSSubscriptionManager } from '@dc-core/dc-logging/rxjs-subscription.manager';
import { SUBSCRIPTION_KEY } from '@dc-core/dc-logging/subscription_enums';
import { AlertPayload, DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { DartCounterAudioService } from '@dc-core/dc-services/audio/audio.service';
import { CAMERA_TYPE, JanusRoom } from '@dc-core/dc-services/camera/camera.models';
import { InGameCameraService } from '@dc-core/dc-services/camera/ingame-camera.service';
import { CookieConsentService } from '@dc-core/dc-services/cookie-consent.service';
import { JanusEventService, JanusEventType } from '@dc-core/dc-services/dc-janus/janus-events.service';
import { JanusVideoRoomService } from '@dc-core/dc-services/dc-janus/janus-video-room.service';
import { UserMediaService } from '@dc-core/dc-services/dc-janus/usermedia.service';
import { OnlineGamesService } from '@dc-core/dc-services/online-games.service';
import { PreferenceLocalStorageKey } from '@dc-core/dc-services/preference/preference.models';
import { MenuController, ModalController, NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { UpgradeService } from '@providers/UpgradeService';
import { AuthService } from '@services/auth.service';
import { IngameAnimationService } from '@services/ingame-animation.service';
import { LoggingService } from '@services/logging.service';
import { NativeAudioService } from '@services/native-audio.service';
import { RouterEventsService } from '@services/router-events.service';
import { SmartDeviceService } from '@services/smart-device.service';
import { ThemeService } from '@services/theme.service';
import { UDPService } from '@services/udp.service';
import { LocalStorageKey } from 'dc-core/dc-localstorage';
import { DartCounterPreferenceService } from 'dc-core/dc-services/preference/preference.service';
import { deleteDoc, getDoc, QueryDocumentSnapshot } from 'firebase/firestore';
import _ from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import OneSignal from 'onesignal-cordova-plugin';
import { OpenedEvent } from 'onesignal-cordova-plugin/dist/models/NotificationOpened';
import { ActivateCameraDialogComponent } from 'src/dialogs/activate-camera/activate-camera.dialog';
import { PromptDialogComponent } from 'src/dialogs/prompt/prompt.dialog';
import { SwitchSalesFunnelDialogComponent } from 'src/dialogs/switch-sales-funnel/switch-sales-funnel.dialog';
import { environment, socials } from 'src/environments/environment';
import {
    AcceptDenyGameInviteRequestDialogComponent,
    AcceptDenyGameInviteRequestDialogResponse,
} from 'src/pages/online/accept-deny-game-invite-request/accept-deny-game-invite-request';
import {
    AcceptDenyJoinGameRequestDialogComponent,
    AcceptDenyJoinGameRequestDialogResponse,
} from 'src/pages/online/accept-deny-join-game-request/accept-deny-join-game-request';
import { ActiveGameDialogComponent, ActiveGameDialogPayload } from 'src/pages/online/active-game/active-game';
import { register as registerSwiper } from 'swiper/element/bundle';

import { GA_EVENTCATEGORIES } from './app.globals';
import { LocaleService } from './core/locale/locale.service';
import { GamesLeaveBusterService } from './modules/games/services/games-leave-buster.service';
import { TournamentService } from './modules/tournaments/services/tournament.service';

//Init SwiperJS
registerSwiper();

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
    public isWeb = environment.isWeb;

    private reconnectCameraDialog: HTMLIonModalElement = null;
    private networkDialog: HTMLIonModalElement = null;

    public themeService: ThemeService = inject(ThemeService);

    private _subscriptionsToDestroy: SUBSCRIPTION_KEY[] = [];
    private _currentActiveGameDocId = null;
    private _camDisconnected = false;
    private deviceDetectorService: DeviceDetectorService = inject(DeviceDetectorService);
    private ingameAnimationService: IngameAnimationService = inject(IngameAnimationService);
    private tournamentService: TournamentService = inject(TournamentService);
    private cookieConsentService: CookieConsentService = inject(CookieConsentService);

    private _currentAppState: boolean;
    private _latestNetworkStatus: ConnectionStatus;
    private _networkListener: PluginListenerHandle;
    private splashScreenIsHidden = false;

    constructor(
        public environmentInjector: EnvironmentInjector,
        public modal: ModalController,
        public preferences: DartCounterPreferenceService,
        public janusVideoRoomService: JanusVideoRoomService,
        public authService: AuthService,
        public gameApiService: GameApiService,
        public friendApiService: FriendApiService,
        private platform: Platform,
        private menu: MenuController,
        private ga: DartCounterAnalyticsService,
        private _file: File,
        private _nativeAudioService: NativeAudioService,
        private translate: TranslateService,
        private nav: NavController,
        private upgrade: UpgradeService,
        private _router: Router,
        private _ngZone: NgZone,
        private _userApiService: UserApiService,
        private _alertService: DartCounterAlertService,
        private _audioService: DartCounterAudioService,
        private _lobbySettingsCollectionService: LobbySettingsCollectionService,
        private _activeGamesCollectionService: ActiveGamesCollectionService,
        private _usersCollectionService: UsersCollectionService,
        private _gamesLeaveBusterService: GamesLeaveBusterService,
        private _dcInvites: InviteCollectionService,
        private _ratingsCollectionService: RatingsCollectionService,
        private _backendEventsCollectionService: BackendEventsCollectionService,
        private _rxjsSubscriptionManager: RXJSSubscriptionManager,
        private _intervalmanager: IntervalManager,
        private _publicGamesCollectionService: PublicGamesCollectionService,
        private _downtimesCollectionService: DowntimesCollectionService,
        private _loggingService: LoggingService,
        private _routerEventsService: RouterEventsService, // Do not remove
        private _janusEventService: JanusEventService,
        private _online: OnlineGamesService,
        private _userMediaService: UserMediaService,
        private _actionsLogicService: ActionsLogicService,
        private _ingameCameraService: InGameCameraService,
        private _onlineCore: DCOnlineCore,
        private _smartDeviceService: SmartDeviceService,
        private _smartDeviceApiService: SmartDeviceApiService,
        private _cameraStreamApiService: CameraStreamApiService,
        private _udpService: UDPService,
        private localeService: LocaleService,
        private privateChatApiService: PrivateChatApiService
    ) {}

    async ngOnInit(): Promise<void> {
        this.cookieConsentService.load();

        this.menu.enable(true, 'mainmenu');
        this.menu.enable(false, 'ingamemenu');

        this.localeService.registerLocales();

        if (environment.isWeb) {
            document.documentElement.classList.add('web-font-size');
            document.documentElement.classList.remove('base-font-size');

            //Try to add the 'keep screen on' feature if it's supported by the browser
            try {
                if ('wakeLock' in navigator) {
                    await navigator.wakeLock.request('screen');
                }
            } catch (__) {}
        }

        this.upgrade.checkDiscount('year').then((discount: Discount) => {
            this.upgrade.currentActiveDiscount.set(discount);
        });

        this._downtimesCollectionService.enableDowntimes();
        this._downtimesCollectionService.downtimes$.subscribe((downtime) => {
            if (downtime) {
                if (downtime.active) {
                    this.nav.navigateRoot('/maintenance');
                } else {
                    $localize`:@@DOWNTIME_STARTS_IN_MINUTES:Downtime starting in ${downtime.minutesToGo}:minutes: minute(s)!`;
                    this._alertService.createAlert({
                        title: this.translate.instant('DOWNTIME_STARTS_IN_MINUTES', { minutes: downtime.minutesToGo }),
                        icon: 'info',
                        timer: 3000,
                    });
                }
            }
        });

        this._janusEventService.events$.subscribe(async (event) => {
            this.janusVideoRoomService.log('********** Janus event', event.type);

            switch (event.type) {
                case JanusEventType.ExternalConnectionLost: // Handle external connection lost
                case JanusEventType.OwnDeviceDisconnected:
                case JanusEventType.ResetExternalDevice:
                    // Handle own device disconnected
                    if (this._camDisconnected) {
                        break;
                    }

                    if (event.type === JanusEventType.OwnDeviceDisconnected && this.janusVideoRoomService.isExternal) {
                        break;
                    }

                    this._camDisconnected = true;

                    this.modal
                        .create({
                            component: ActivateCameraDialogComponent,
                            backdropDismiss: false,
                            showBackdrop: true,
                            cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
                        })
                        .then((elem) => {
                            this.reconnectCameraDialog = elem;
                            this.reconnectCameraDialog.present().finally(() => {
                                this._alertService.showPrompt(
                                    $localize`:@@YOUR_CAM_DISCONNECTED:Your camera is disconnected!`,
                                    'warning'
                                );
                            });
                            this.reconnectCameraDialog.onDidDismiss().then((dialogRes) => {
                                this.reconnectCameraDialog = null;

                                const janusRoom: JanusRoom = dialogRes.data;
                                if (janusRoom) {
                                    this._camDisconnected = false;
                                    this._alertService.createAlert({
                                        title: $localize`:@@CAMERA_IS_ACTIVATED:Your camera is activated for online games!`,
                                    });

                                    this._janusEventService.emitEvent({
                                        type: JanusEventType.NewRoom,
                                        previousType: event.type,
                                        janusRoom,
                                    });
                                }
                            });
                        });
                    break;
                case JanusEventType.NewRoom:
                    if (this._activeGamesCollectionService.inGame) {
                        this._actionsLogicService.sendCameraAction({
                            sender: this.authService.user.id,
                            janusRoom: event.janusRoom,
                        });
                    } else if (this._lobbySettingsCollectionService.currentGameInLobby()) {
                        this._online.updateLobbyGame();
                    } else if (this.janusVideoRoomService.isExternal) {
                        if (this.janusVideoRoomService.externalCode && this.janusVideoRoomService.externalPassword) {
                            this._cameraStreamApiService.updateCameraStreamByCodeAndPassword({
                                room: event.janusRoom.roomID,
                                code: this.janusVideoRoomService.externalCode,
                                password: this.janusVideoRoomService.externalPassword,
                                janus_server_hostname: event.janusRoom.janusServerHost,
                            });
                        }
                    }

                    if (
                        event.previousType === JanusEventType.ResetCurrentDevice ||
                        event.previousType === JanusEventType.ResetExternalDevice ||
                        event.previousType === JanusEventType.ResetSmartDevice
                    ) {
                    }
                    break;
                case JanusEventType.ResetCurrentDevice:
                    this._userMediaService.getUserMedia(this.janusVideoRoomService.videoMedia).then((stream) => {
                        this._userMediaService.setStreamAsOwnUserMedia(
                            this.janusVideoRoomService.ownUserMedia,
                            stream,
                            true
                        );
                        this.janusVideoRoomService
                            .startPublishing('video', event.media, this.janusVideoRoomService.ownUserMedia)
                            .then((janusRoom) => {
                                this._janusEventService.emitEvent({
                                    type: JanusEventType.NewRoom,
                                    previousType: event.type,
                                    janusRoom,
                                });
                            })
                            .catch((err) => {
                                this.janusVideoRoomService.error(err);
                            });
                    });
                    break;
                case JanusEventType.ResetSmartDevice:
                    this._smartDeviceService
                        .stopStreaming(event.smartDevice, true, false)
                        .then(() => {
                            this._smartDeviceService.startStreaming(event.smartDevice, true).then((janusRoom) => {
                                this._ngZone.run(() => {
                                    this.janusVideoRoomService
                                        .useExternalRoom(janusRoom, CAMERA_TYPE.SMART_DEVICE)
                                        .finally(() => {
                                            this._janusEventService.emitEvent({
                                                type: JanusEventType.NewRoom,
                                                previousType: event.type,
                                                janusRoom: this.janusVideoRoomService.ownCamera,
                                            });
                                        });
                                });
                            });
                        })
                        .catch(console.error);
                    break;
                case JanusEventType.PlayerCamNotEnabled:
                    $localize`:@@PLAYER_HAS_NOT_ENABLED_PLAYER_CAM:${event.user.first_name}:player: has not enabled the player camera`;
                    this._alertService.createAlert({
                        title: this.translate.instant('PLAYER_HAS_NOT_ENABLED_PLAYER_CAM', {
                            player: event.user.first_name,
                        }),
                        icon: 'info',
                        timer: null,
                        showCloseButton: true,
                    });
                    break;
                case JanusEventType.OtherUserStreamDisconnect:
                    // Handle other user device disconnected
                    break;
                case JanusEventType.OwnDeviceConnected:
                case JanusEventType.OwnExternalDeviceConnected:
                    if (this._camDisconnected) {
                        this._camDisconnected = false;
                    }

                    if (this.reconnectCameraDialog) {
                        this._alertService.clearAlerts();
                        this.reconnectCameraDialog.dismiss();
                        this.reconnectCameraDialog = null;
                    }
                    break;
                case JanusEventType.ServerReconnected:
                case JanusEventType.ServerDestroyed:
                    if (environment.debug) {
                        console.log(
                            '********** Refreshing all streams',
                            event.janusServerHost,
                            this.janusVideoRoomService.ownCamera.janusServerHost
                        );
                    }

                    if (event.janusServerHost === this.janusVideoRoomService.ownCamera.janusServerHost) {
                        this.janusVideoRoomService.ownUserMedia?.cleanupUserMedia(true, true);

                        if (this.janusVideoRoomService.ownUserMedia) {
                            if (this.janusVideoRoomService.usingExternal) {
                                this.janusVideoRoomService.leaveRoomAndDetachAllOwnHandles().finally(() => {
                                    this.janusVideoRoomService.useExternalRoom(
                                        this.janusVideoRoomService.ownCamera,
                                        null
                                    );
                                });
                            }

                            this._ngZone.run(() => {
                                if (
                                    this.janusVideoRoomService.isPublishingVideo &&
                                    this.janusVideoRoomService.videoMedia
                                ) {
                                    this._userMediaService
                                        .getUserMedia(this.janusVideoRoomService.videoMedia)
                                        .then((stream) => {
                                            this._userMediaService.setStreamAsOwnUserMedia(
                                                this.janusVideoRoomService.ownUserMedia,
                                                stream,
                                                true
                                            );
                                            this.janusVideoRoomService.attachToOwnCameraRoomAndPublish();
                                        });
                                }
                            });
                        }
                    }

                    // if (event.janusServerHost === this.janusVideoRoomService.ownVoiceCall.janusServerHost) {
                    //     this.janusVideoRoomService.ownAudioUserMedia?.cleanupUserMedia(true, true);

                    //     if (this.janusVideoRoomService.ownAudioUserMedia) {
                    //         this._ngZone.run(() => {
                    //             if (
                    //                 this.janusVideoRoomService.isPublishingAudio &&
                    //                 this.janusVideoRoomService.audioMedia
                    //             ) {
                    //                 this.janusVideoRoomService.ownAudioUserMedia?.cleanupUserMedia(true, true);

                    //                 this._userMediaService
                    //                     .getUserMedia(this.janusVideoRoomService.audioMedia)
                    //                     .then((stream) => {
                    //                         this._userMediaService.setStreamAsOwnUserMedia(
                    //                             this.janusVideoRoomService.ownAudioUserMedia,
                    //                             stream,
                    //                             true
                    //                         );
                    //                         this.janusVideoRoomService.attachToOwnVoiceCallRoomAndPublish();
                    //                     });
                    //             }
                    //         });
                    //     }
                    // }

                    const smartDeviceRoomId = this._smartDeviceService.userMedia.janusRoom.roomID;
                    const smartDeviceHostname = this._smartDeviceService.userMedia.janusRoom.janusServerHost;
                    if (smartDeviceRoomId && event.janusServerHost === smartDeviceHostname) {
                        this._smartDeviceService.userMedia.cleanupUserMedia(true, true);

                        await this.janusVideoRoomService
                            .leaveRoomAndDetachAllHandles(smartDeviceRoomId, true)
                            .catch(console.error);

                        this.janusVideoRoomService
                            .spectateRoom(
                                {
                                    janusServerHost: smartDeviceHostname,
                                    roomID: smartDeviceRoomId,
                                    camType: CAMERA_TYPE.SMART_DEVICE,
                                },
                                'video',
                                null,
                                this._smartDeviceService.userMedia,
                                true,
                                false
                            )
                            .catch(console.error);
                    }

                    const roomUserMap = this.janusVideoRoomService.serverRoomUserMap.get(event.janusServerHost);
                    if (roomUserMap) {
                        roomUserMap.forEach((user, roomId) => {
                            this._ingameCameraService.joinJanusRoom(
                                { janusServerHost: event.janusServerHost, roomID: roomId, camType: null },
                                user
                            );
                        });
                    }
                    break;
                default:
                    // Handle unknown event
                    break;
            }
        });

        const languageFromStorage = localStorage.getItem(LocalStorageKey.language);
        this.translate.use(languageFromStorage || this.preferences.lang);
        if (languageFromStorage && languageFromStorage != this.preferences.lang) {
            this.preferences.changePreference(PreferenceLocalStorageKey.lang, languageFromStorage);
        }

        this.platform.ready().then(async () => {
            setTimeout(() => {
                SplashScreen.hide().then(() => {
                    this.splashScreenIsHidden = true;
                });
            }, 1500);

            this._audioService.initialize(
                !this.platform.is('capacitor') ? 'web' : this.platform.is('android') ? 'android' : 'ios',
                this._file,
                this._nativeAudioService
            );

            this.initOneSignal();

            GoogleAuth.initialize();

            if (this.platform.is('capacitor')) {
                try {
                    const { version } = await App.getInfo();
                    await FirebaseAnalytics.logEvent({ name: 'app_started', params: { version } });
                } catch (_) {
                    await FirebaseAnalytics.logEvent({ name: 'app_started' });
                }
            } else {
                await FirebaseAnalytics.logEvent({ name: 'app_started' });
            }
            await FacebookLogin.initialize({ appId: socials.facebook.clientId });

            //Capacitor insomnia variation
            KeepAwake.keepAwake().catch((err) => {
                console.log('No keepawake available', err);
            });

            this._latestNetworkStatus = await Network.getStatus();
            this.addNetworkListener();
        });

        this._usersCollectionService.isLoggedIn$.subscribe((isLoggedIn) => {
            if (isLoggedIn) {
                // Start watching invites if user is logged in
                this.watchInvites();
                this.watchTournamentInvites();
                this.watchOwnActiveGame();
                this.watchIncomingRatings();
                this.watchIncomingBackendEvents();
                this.initSmartDevices();
            } else {
                // If the user logs out
                this._subscriptionsToDestroy.forEach((key) => {
                    this._rxjsSubscriptionManager.cleanSubscription(key);
                });
            }
        });

        if (!environment.isWeb || this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet()) {
            App.addListener('appStateChange', ({ isActive }) => {
                // console.log('*** appStateChange', isActive);
                if (this._currentAppState === false && isActive === true) {
                    this.janusVideoRoomService.reconnect(null);

                    if (this._onlineCore.onlineGameplay) {
                        this._ngZone.run(() => {
                            if (this._onlineCore.pause) {
                                this._onlineCore.initPauseTimer();
                            } else {
                                this._onlineCore.setTimeLeft(true);
                            }
                        });
                    }

                    if (!this.janusVideoRoomService.isExternal) {
                        this._usersCollectionService.updateLastActive();
                    }

                    if (this._smartDeviceService.isSettingUpSmartDevice) {
                        this._smartDeviceService.refreshSetup$.next();
                    }

                    this.privateChatApiService.refreshChat$.next();

                    this.addNetworkListener();
                } else if (!isActive) {
                    if (this._networkListener) {
                        this._networkListener.remove();
                        this._networkListener = null;
                    }

                    this.janusVideoRoomService.ownUserMedia?.cleanupUserMedia(true, true);
                    // this.janusVideoRoomService.ownAudioUserMedia?.cleanupUserMedia(true, true);

                    if (this._smartDeviceService.userMedia.janusRoom.roomID) {
                        this._smartDeviceService.userMedia.cleanupUserMedia(true, true);
                    }

                    if (this.janusVideoRoomService.isPublishingVideo) {
                        this.janusVideoRoomService
                            .unpublishAndLeaveRoomAndDetachHandle(
                                this.janusVideoRoomService.ownVideoPublisherRoomHandle
                            )
                            .catch(console.error);
                    }

                    // if (this.janusVideoRoomService.isPublishingAudio) {
                    //     this.janusVideoRoomService
                    //         .unpublishAndLeaveRoomAndDetachHandle(
                    //             this.janusVideoRoomService.ownAudioPublisherRoomHandle
                    //         )
                    //         .catch(console.error);
                    // }

                    if (this.janusVideoRoomService.usingExternal) {
                        this.janusVideoRoomService.leaveRoomAndDetachAllOwnHandles().catch(console.error);
                    }

                    if (!this.janusVideoRoomService.isExternal) {
                        this._usersCollectionService.setOffline();
                    }
                }
                this._currentAppState = isActive;
            });
        }

        if (!environment.isWeb && this.platform.is('capacitor')) {
            App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
                this.platform.ready().then(() => {
                    // Example url: https://dartcounter.app/tabs/tab1
                    // slug = /tabs/tab1
                    const slug = event.url.split('.app').pop();
                    if (slug) {
                        if (slug.startsWith('/upgrade')) {
                            setTimeout(
                                async () => {
                                    const dialogComponent = await this.upgrade.GetUpgradeDialog(
                                        GA_EVENTCATEGORIES.DEEPLINK
                                    );
                                    this.modal
                                        .create({
                                            component: dialogComponent,
                                            cssClass: environment.isWeb
                                                ? ['ion-fullscreen-modal', 'web']
                                                : 'ion-fullscreen-modal',
                                        })
                                        .then((elem) => {
                                            elem.present();
                                        });
                                },
                                this.splashScreenIsHidden ? 0 : 1500
                            );
                        } else if (slug.startsWith('/switch-to-yearly')) {
                            setTimeout(
                                async () => {
                                    if (this.authService.user.is_ultimate) {
                                        this.modal
                                            .create({
                                                component: SwitchSalesFunnelDialogComponent,
                                                componentProps: {
                                                    recurring: 'yearly',
                                                },
                                                cssClass: environment.isWeb
                                                    ? ['ion-fullscreen-modal', 'web']
                                                    : 'ion-fullscreen-modal',
                                            })
                                            .then((elem) => {
                                                elem.present();
                                            });
                                    }
                                },
                                this.splashScreenIsHidden ? 0 : 1500
                            );
                        } else {
                            this.nav.navigateForward(slug);
                        }
                    }
                });
            });
        }

        // if (environment.debug) {
        //     this._router.events.subscribe((event) => {
        //         if (event instanceof NavigationEnd) {
        //             this.logActiveWatchers();
        //         }
        //     });
        // }

        this.themeService.initTheme();
    }

    public ngAfterViewInit(): void {
        this.cookieConsentService.init();
    }

    async addNetworkListener(): Promise<void> {
        this._networkListener = await Network.addListener('networkStatusChange', (status) => {
            // console.log('*** networkStatusChange', status);
            if (this._latestNetworkStatus.connected !== status.connected) {
                this._latestNetworkStatus = status;

                if (status.connected) {
                    this.janusVideoRoomService.reconnect(null);

                    if (this.networkDialog) {
                        this.networkDialog.dismiss();
                        this.networkDialog = null;
                    }
                } else {
                    this.janusVideoRoomService.destroy(null, null, false);

                    if (this._alertService.showNetworkAlert && !this.networkDialog) {
                        this.modal
                            .create({
                                component: PromptDialogComponent,
                                componentProps: {
                                    title: $localize`:@@NO_CONNECTION:No connection`,
                                    text: $localize`:@@PLEASE_CHECK_YOUR_INTERNET:Please check your internet connection`,
                                    icon: 'error',
                                    confirmText: $localize`:@@OK:OK`,
                                } as AlertPayload,
                                cssClass: 'auto-height',
                                showBackdrop: true,
                                backdropDismiss: false,
                            })
                            .then((elem) => {
                                this.networkDialog = elem;
                                this.networkDialog.present();
                                this.networkDialog.onDidDismiss().then(() => {
                                    this.networkDialog = null;
                                });
                            });
                    }
                }
            }
        });
    }

    watchInvites() {
        const incomingInvites = this._dcInvites
            .watchIncomingInvites()
            .subscribe((queryInvite: QueryDocumentSnapshot<DCFireStoreInvite>) => {
                if (!this._gamesLeaveBusterService.inGame()) {
                    const invite = queryInvite.data();
                    if (invite && invite.invitation_type === INVITATIONTYPE.JOIN) {
                        this.showJoinGameDialog(queryInvite);
                    } else if (invite && invite.invitation_type === INVITATIONTYPE.INVITE) {
                        if (!this.tournamentService.participatingTournament()) {
                            this.showGameInviteDialog(queryInvite);
                        }
                    } else if (invite && invite.invitation_type === INVITATIONTYPE.REINVITE) {
                        if (!this.tournamentService.participatingTournament()) {
                            this.showGameReInviteDialog(queryInvite);
                        }
                    }
                }
            });
        this._rxjsSubscriptionManager.addSubscription(
            'appComponent',
            SUBSCRIPTION_KEY.INCOMING_INVITES,
            incomingInvites
        );
        this._subscriptionsToDestroy.push(SUBSCRIPTION_KEY.INCOMING_INVITES);

        const outgoingInvitesSub = this._dcInvites.checkOwnOutgoingInvites().subscribe();

        this._dcInvites.inviteUpdate$.subscribe((invite: DCFireStoreInvite) => {
            if (invite) {
                // Update your UI here
                switch (invite.status) {
                    case INVITATIONSTATUS.ACCEPTED:
                        this._audioService.playAudioFromDevice(SoundPath.EFFECTS, 'accepted_invite.mp3');

                        this._alertService.createAlert({
                            title: $localize`:@@INVITE_ACCEPTED_JOINING_NOW:Your invite has been accepted!`,
                        });
                        this._activeGamesCollectionService.isJoining = true;
                        this._dcInvites.removeOutgoingInvites();
                        break;
                    case INVITATIONSTATUS.DECLINED:
                        this._audioService.playAudioFromDevice(SoundPath.EFFECTS, 'declined_invite.mp3');

                        this._alertService.createAlert({
                            icon: 'warning',
                            title: $localize`:@@INVITE_DECLINED:Your invite has been declined!`,
                        });
                        this._dcInvites.removeOutgoingInvites();
                        break;
                }
            }
        });

        // Manage the subscription in the core
        this._rxjsSubscriptionManager.addSubscription(
            'appComponent',
            SUBSCRIPTION_KEY.OUTGOING_INVITES,
            outgoingInvitesSub
        );

        // Auto unwatch onDestroy
        this._subscriptionsToDestroy.push(SUBSCRIPTION_KEY.OUTGOING_INVITES);
    }

    public watchTournamentInvites(): void {
        const incomingInvites = this._dcInvites
            .watchTournamentInvites()
            .subscribe(async (queryInvite: QueryDocumentSnapshot<DCFireStoreInvite>) => {
                if (queryInvite) {
                    const invite = queryInvite.data();
                    if (invite) {
                        let participatingTournament = this.tournamentService.participatingTournament();
                        if (!participatingTournament) {
                            participatingTournament = await this.tournamentService.getCurrentTournaments();
                        }

                        if (
                            participatingTournament &&
                            participatingTournament.id === invite.tournament_game.tournament_id &&
                            !participatingTournament.participation.result
                        ) {
                            invite.doc_ref = queryInvite.ref;
                            invite.doc_id = queryInvite.id;

                            this.tournamentService.currentGameInvite.set(invite);

                            if (invite.status === INVITATIONSTATUS.PENDING) {
                                this._activeGamesCollectionService.setActiveGameRef(
                                    invite.tournament_game.active_game_doc_id,
                                    true
                                );
                                this.tournamentService.showPreGameInvite(invite);
                            }
                        } else {
                            this.tournamentService.currentGameInvite.set(null);
                            deleteDoc(queryInvite.ref);
                        }
                    }
                } else {
                    this.tournamentService.currentGameInvite.set(null);
                }
            });
        this._rxjsSubscriptionManager.addSubscription(
            'appComponent',
            SUBSCRIPTION_KEY.TOURNAMENT_INVITES,
            incomingInvites
        );
        this._subscriptionsToDestroy.push(SUBSCRIPTION_KEY.TOURNAMENT_INVITES);
    }

    public watchOwnActiveGame() {
        const watchOwnActiveGame = this._usersCollectionService
            .watchOwnUserDocRef()
            .subscribe((user: DCFireStoreUser) => {
                if (!user.activeGameRef) {
                    this._currentActiveGameDocId = null;
                } else if (
                    this._currentActiveGameDocId != user.activeGameRef.id &&
                    !this._activeGamesCollectionService.disableJoin
                ) {
                    this._currentActiveGameDocId = user.activeGameRef.id;

                    if (this._dcInvites.currentOutgoingInvite() || this._activeGamesCollectionService.isJoining) {
                        this._lobbySettingsCollectionService.removeOwnItems();
                        this.goToPreGame();
                    } else if (!this._activeGamesCollectionService.inGame) {
                        const publicGameRef = this._publicGamesCollectionService.getDocByID(user.activeGameRef.id);
                        getDoc(publicGameRef).then((docSnapshot) => {
                            const gameplay = docSnapshot.data();
                            if (gameplay) {
                                gameplay.doc_id = docSnapshot.id;
                                gameplay.doc_ref = docSnapshot.ref;

                                this.modal
                                    .create({
                                        component: ActiveGameDialogComponent,
                                        componentProps: {
                                            gameplay,
                                        } as ActiveGameDialogPayload,
                                        cssClass: 'auto-height',
                                        backdropDismiss: environment.isWeb,
                                        showBackdrop: true,
                                    })
                                    .then((elem) => {
                                        elem.present();
                                    });
                            }
                        });
                    }
                }
            });

        this._rxjsSubscriptionManager.addSubscription(
            'appComponent',
            SUBSCRIPTION_KEY.WATCH_OWN_ACTIVE_GAME,
            watchOwnActiveGame
        );

        this._subscriptionsToDestroy.push(SUBSCRIPTION_KEY.WATCH_OWN_ACTIVE_GAME);
    }

    public watchIncomingRatings(): void {
        const incomingRatings = this._ratingsCollectionService
            .watchIncomingRatings()
            .subscribe((rating: DCFireStoreRating) => {
                if (!this._activeGamesCollectionService.inGame) {
                    $localize`:@@GAVE_YOU_A_RATING:${rating.user.first_name}:user: gave you a rating of ${rating.rating}:rating: stars`;
                    this._alertService.createAlert({
                        title: this.translate.instant('GAVE_YOU_A_RATING', {
                            user: rating.user.first_name,
                            rating: rating.rating,
                        }),
                        icon: 'info',
                        timer: 3000,
                        showCloseButton: true,
                    });
                }
                this._ratingsCollectionService.removeIncomingRatings();
            });
        this._rxjsSubscriptionManager.addSubscription(
            'appComponent',
            SUBSCRIPTION_KEY.INCOMING_RATINGS,
            incomingRatings
        );
        this._subscriptionsToDestroy.push(SUBSCRIPTION_KEY.INCOMING_RATINGS);
    }

    public watchIncomingBackendEvents(): void {
        const backendEventsSub = this._backendEventsCollectionService
            .watchIncomingEvents()
            .subscribe((event: FirestoreUserBackendEvent) => {
                switch (event.type) {
                    case 'ban':
                        this._backendEventsCollectionService.removeBackendEvent(event.doc_ref);

                        const banData = event.data as BanLog;

                        this.authService.forceDeactivateRoute = true;

                        this.authService.user.is_banned = true;
                        this.authService.user.banned_at = banData.banned_at;
                        this.authService.user.banned_until = banData.banned_until;
                        this.authService.user.ban_reason = banData.ban_reason;

                        this.nav.navigateRoot('/banned');
                        break;
                    case 'incoming_friend_request':
                        this._backendEventsCollectionService.removeBackendEvent(event.doc_ref);
                        break;
                }
            });
        this._rxjsSubscriptionManager.addSubscription(
            'appComponent',
            SUBSCRIPTION_KEY.BACKEND_EVENTS,
            backendEventsSub
        );
        this._subscriptionsToDestroy.push(SUBSCRIPTION_KEY.BACKEND_EVENTS);
    }

    public initSmartDevices(): void {
        this._smartDeviceApiService.getSmartDeviceNetworks({}).then((res) => {
            this._smartDeviceApiService.setSmartDevices(res.data);

            if (!this.platform.is('capacitor')) {
                return;
            }

            const devices: SmartDevice[] = [];
            this._smartDeviceApiService.smartDeviceNetworks.forEach((network) => {
                network.devices.forEach((device) => {
                    devices.push(device);
                });
            });

            if (devices.length) {
                const udpSubscription = this._udpService.watchUDPSocket(30000, 10000).subscribe((response) => {
                    if (!response && udpSubscription) {
                        udpSubscription.unsubscribe();
                    } else if (response.data) {
                        const parsedResponse = this._udpService.parseUDPResponse(response.data);
                        if (!parsedResponse) return;

                        const { guid, type, mac } = parsedResponse;
                        const remoteAddress = response.remoteAddress;
                        const smartDeviceType = this._udpService.getSmartDeviceType(type);

                        if (!guid || !smartDeviceType || !this._udpService.isValidRemoteAddress(remoteAddress)) return;

                        const existingDevice = _.find(
                            devices,
                            (device) => device.guid === guid && device.type === smartDeviceType
                        );

                        if (existingDevice && existingDevice.ip_address !== remoteAddress) {
                            this._smartDeviceApiService
                                .updateSmartDeviceById({
                                    smartDeviceId: existingDevice.id,
                                    mac_address: mac,
                                    ip_address: remoteAddress,
                                })
                                .catch(console.error);
                            _.remove(devices, (device) => device.id == existingDevice.id);
                        }
                    }
                });
            }
        });
    }

    goToPreGame(): void {
        if (this._currentActiveGameDocId) {
            this._activeGamesCollectionService.redirectBack = this._router.url.includes('online-games')
                ? '/online-games/global-lobby'
                : '/friends-lobby';

            this._online.stop();

            this.nav.navigateForward('pre-game/' + this._currentActiveGameDocId);
        }
    }

    showJoinGameDialog(queryInvite: QueryDocumentSnapshot<DCFireStoreInvite>) {
        this._audioService.playAudioFromDevice(SoundPath.EFFECTS, 'incoming_invite.mp3');

        this.modal
            .create({
                component: AcceptDenyJoinGameRequestDialogComponent,
                componentProps: {
                    queryInvite,
                },
                cssClass: 'auto-height',
                backdropDismiss: false,
                showBackdrop: true,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    const result = dialogRes.data as AcceptDenyJoinGameRequestDialogResponse;
                    if (result) {
                        if (result?.acceptedInvite) {
                            this._activeGamesCollectionService.isJoining = true;
                            this._lobbySettingsCollectionService.addOwner(queryInvite.data().user);

                            // It's accepted -> Give feedback and add the user.uid to the owners of my game
                            this._dcInvites.updateInviteStatus(queryInvite.ref, INVITATIONSTATUS.ACCEPTED);
                        } else {
                            // It's declined -> Give feedback and remove my receiver_uid
                            this._dcInvites.updateInviteStatus(queryInvite.ref, INVITATIONSTATUS.DECLINED);
                        }
                    }
                });
            });
    }

    showGameInviteDialog(queryInvite: QueryDocumentSnapshot<DCFireStoreInvite>) {
        this._audioService.playAudioFromDevice(SoundPath.EFFECTS, 'incoming_invite.mp3');

        this.modal
            .create({
                component: AcceptDenyGameInviteRequestDialogComponent,
                componentProps: {
                    queryInvite,
                },
                cssClass: 'auto-height',
                backdropDismiss: false,
                showBackdrop: true,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    const result = dialogRes.data as AcceptDenyGameInviteRequestDialogResponse;
                    if (result) {
                        if (result?.acceptedInvite) {
                            const user = this.authService.user;
                            const newPlayer = this._usersCollectionService.getDCFireStoreUser(user);
                            this._activeGamesCollectionService.isJoining = true;

                            this._lobbySettingsCollectionService.addPlayerToStartGame(
                                queryInvite.data().gameplay_doc_id,
                                newPlayer
                            );

                            //Remove own lobby game (if any)
                            this._lobbySettingsCollectionService.removeLobbyGame();

                            // It's accepted -> Give feedback and add the user.uid to the owners of my game
                            this._dcInvites.updateInviteStatus(queryInvite.ref, INVITATIONSTATUS.ACCEPTED);
                        } else {
                            // It's declined -> Give feedback and remove my receiver_uid
                            this._dcInvites.updateInviteStatus(queryInvite.ref, INVITATIONSTATUS.DECLINED);
                        }
                    }
                });
            });
    }

    showGameReInviteDialog(queryInvite: QueryDocumentSnapshot<DCFireStoreInvite>) {
        this._audioService.playAudioFromDevice(SoundPath.EFFECTS, 'incoming_invite.mp3');

        this.modal
            .create({
                component: AcceptDenyGameInviteRequestDialogComponent,
                componentProps: {
                    queryInvite,
                },
                cssClass: 'auto-height',
                backdropDismiss: false,
                showBackdrop: true,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    const result = dialogRes.data as AcceptDenyGameInviteRequestDialogResponse;
                    if (result) {
                        if (result?.acceptedInvite) {
                            this._activeGamesCollectionService.isJoining = true;
                            this._activeGamesCollectionService.resumeOnlineGame(queryInvite.data().gameplay_doc_id);

                            //Remove own lobby game (if any)
                            this._lobbySettingsCollectionService.removeLobbyGame();

                            // It's accepted -> Give feedback and add the user.uid to the owners of my game
                            this._dcInvites.updateInviteStatus(queryInvite.ref, INVITATIONSTATUS.ACCEPTED);
                        } else {
                            // It's declined -> Give feedback and remove my receiver_uid
                            this._dcInvites.updateInviteStatus(queryInvite.ref, INVITATIONSTATUS.DECLINED);
                        }
                    }
                });
            });
    }

    initOneSignal(): void {
        if (this.platform.is('capacitor') && !this.platform.is('desktop')) {
            // Uncomment to set OneSignal device logging to VERBOSE
            // OneSignal.setLogLevel(6, 0);

            // NOTE: Update the setAppId value below with your OneSignal AppId.
            OneSignal.setAppId('0e0c348a-2874-4db2-b0a3-86bafc1db732');
            OneSignal.setNotificationOpenedHandler((openedEvent: OpenedEvent) => {
                const additionalData = openedEvent.notification.additionalData;
                if (additionalData) {
                    if ('location' in additionalData) {
                        this._ngZone.run(() => {
                            const location = additionalData.location as string;
                            this.nav.navigateForward(location);
                        });
                    }
                }
            });

            // Prompts the user for notification permissions.
            OneSignal.promptForPushNotificationsWithUserResponse(() => {
                // console.log('User accepted notifications: ' + accepted);
            });
        }
    }

    private logActiveWatchers(): void {
        setTimeout(() => {
            console.groupCollapsed('--- Subscriptions Log --- ', this._router.url);

            const subs = this._rxjsSubscriptionManager.getActiveSubscriptions();
            if (subs.length) console.log('DC RXJS Subscriptions', subs);
            else console.info('No active subscriptions found');

            const intervals = this._intervalmanager.getActiveIntervals();
            if (intervals.length) console.log('DC Intervals', intervals);
            else console.info('No active intervals found');

            console.groupEnd();
        }, 1000);
    }
}
