import { Component, inject, Input } from '@angular/core';
import { ReportOrigin } from '@dc-core/dc-backend/dc-enums';
import { DCFireStoreUser } from '@dc-core/dc-firestore/globals/firestore.tables';
import { OnlineFunctions } from '@dc-core/dc-gamelogic/online-game/online.functions';
import { ModalController } from '@ionic/angular';
import { AuthService } from '@services/auth.service';
import { GamesLeaveBusterService } from 'src/app/modules/games/services/games-leave-buster.service';
import { ReportUserDialogComponent } from 'src/dialogs/report-user/report-user.dialog';
import { environment } from 'src/environments/environment';

import { ProfileDialogComponent } from '../../../app/modules/profile/dialogs/profile-dialog/profile.dialog';

@Component({
    selector: 'app-opponent-quit-dialog',
    templateUrl: 'opponent-quit.dialog.html',
})
export class OpponentQuitDialogComponent {
    @Input() opponent: DCFireStoreUser;

    public modalController: ModalController = inject(ModalController);
    public leaveBusterService: GamesLeaveBusterService = inject(GamesLeaveBusterService);

    private authService: AuthService = inject(AuthService);
    private onlineFunctions: OnlineFunctions = inject(OnlineFunctions);

    public DismissModal(returnValue: any = null): void {
        this.modalController.dismiss(returnValue);
    }

    public showPlayerInfo() {
        const user = this.onlineFunctions.fsUserToOnlineUser(this.opponent);

        this.modalController
            .create({
                component: ProfileDialogComponent,
                componentProps: {
                    user,
                    canReport: this.authService.user.id != user.id,
                },
                cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                backdropDismiss: true,
                showBackdrop: true,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data === 'report') {
                        this.modalController
                            .create({
                                component: ReportUserDialogComponent,
                                cssClass: 'auto-height',
                                componentProps: {
                                    user,
                                    origin: 'quit_dialog' as ReportOrigin,
                                },
                            })
                            .then((elem) => {
                                elem.present();
                            });
                    }
                });
            });
    }
}
