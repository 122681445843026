<div class="grid grid-cols-2 gap-4 text-sm">
    @if (withGameplayName()) {
        <div class="col-span-2 flex items-center space-x-1.5">
            <app-icon icon="dartboard" size="normal" class="!text-neutral-300"></app-icon>
            <div class="font-semibold whitespace-nowrap">
                {{ gameplayName }}
            </div>
        </div>
    }
    <div class="col-span-1 flex items-center space-x-1.5">
        <app-icon icon="calendar" size="normal" class="!text-neutral-300"></app-icon>
        <div class="font-semibold whitespace-nowrap">
            {{ tournament().starting_at | date: 'd MMM yyyy' : '' : translateService.currentLang }}
        </div>
    </div>
    <div class="col-span-1 flex items-center space-x-1.5">
        <app-icon icon="timer" size="normal" class="!text-neutral-300"></app-icon>
        @if (!tournament().finished_at) {
            <div class="font-semibold whitespace-nowrap">
                {{ tournament().starting_at | date: 'HH:mm' }}
            </div>
        } @else {
            <div class="font-semibold whitespace-nowrap">
                {{ tournament().starting_at | date: 'HH:mm' }} - {{ tournament().finished_at | date: 'HH:mm' }}
            </div>
        }
    </div>
    <div class="col-span-1 flex items-center space-x-1.5">
        <app-icon icon="supervisor_account" size="normal" class="!text-neutral-300"></app-icon>
        <div class="font-semibold whitespace-nowrap flex items-center">
            <div>{{ tournament().current_participants }}/{{ tournament().amount_of_participants }}</div>
            <div class="ml-1 lowercase" [translate]="'PLAYERS'" i18n="@@PLAYERS">Players</div>
        </div>
    </div>
    @if (tournament().status !== 'started' && tournament().status !== 'finished') {
        <div class="col-span-1 flex items-center space-x-1.5">
            <app-icon icon="check_mark" size="normal" class="!text-neutral-300"></app-icon>
            <div class="font-semibold whitespace-nowrap">
                <span [translate]="'FROM'" i18n="@@FROM">From</span>
                {{ tournament().check_in_from | date: 'HH:mm' }}
            </div>
        </div>
    }
    <div class="col-span-1 flex items-center space-x-1.5">
        <app-icon
            [icon]="tournament().is_private ? 'closed_lock' : tournament().needs_approval ? 'videocam' : 'open_lock'"
            size="normal"
            class="!text-neutral-300"></app-icon>
        <div class="font-semibold whitespace-nowrap">
            @if (
                tournament().needs_approval && tournament().status !== 'started' && tournament().status !== 'finished'
            ) {
                <span [translate]="'NEEDS_APPROVAL'" i18n="@@NEEDS_APPROVAL">Needs approval</span>
            } @else if (tournament().is_private) {
                <span [translate]="'PRIVATE'" i18n="@@PRIVATE">Private</span>
            } @else {
                <span [translate]="'OPEN'" i18n="@@OPEN">Open</span>
            }
        </div>
    </div>
    @if (tournament().min_three_dart_avg || tournament().max_three_dart_avg) {
        <div class="col-span-1 flex items-center space-x-1.5">
            <app-icon icon="three_dots" size="normal" class="!text-neutral-300"></app-icon>
            <div class="font-semibold whitespace-nowrap">
                @if (tournament().min_three_dart_avg) {
                    {{ tournament().min_three_dart_avg }}
                } @else {
                    <span [translate]="'NO_MINIMUM'" i18n="@@NO_MINIMUM">No minimum</span>
                }
                <span> - </span>
                @if (tournament().max_three_dart_avg) {
                    {{ tournament().max_three_dart_avg }}
                } @else {
                    <span [translate]="'NO_MAXIMUM'" i18n="@@NO_MAXIMUM">No maximum</span>
                }
            </div>
        </div>
    }
</div>
