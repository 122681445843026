<div class="slide-modal-outer slide-modal-full bg-white-pattern">
    <div class="slide-modal-header">
        <div class="slide-modal-close" (click)="close()" [translate]="'CLOSE'" i18n="@@CLOSE">Close</div>
        <div class="slide-modal-title" [translate]="'ULTIMATE_FEATURES'" i18n="@@ULTIMATE_FEATURES">
            Ultimate features
        </div>
    </div>
    <div class="mt-4 flex flex-1 flex-col text-white overflow-y-auto">
        <div
            class="flex rounded-lg bg-gray-50 p-4 m-4 gap-4 text-black"
            [translate]="'YOU_ARE_ULTIMATE_INTRO'"
            i18n="@@YOU_ARE_ULTIMATE_INTRO">
            As an Ultimate player, you have access to exclusive features that will take your dart game to the next
            level. Discover the features tools below and get ready to enhance your performance today!
        </div>
        <div class="bg-gray-pattern rounded-lg relative pt-5 mx-4">
            <div class="absolute top-0 left-0 w-full h-2/5 bg-blue rounded-t-lg"></div>
            <swiper-container
                id="ultimate-features-slider"
                init="false"
                [style.--swiper-navigation-size]="'20px'"
                [style.--swiper-navigation-color]="themeService.theme() === 'dark' ? '#000000' : '#FFFFFF'">
                @for (feature of ultimateFeatures; track feature) {
                    <swiper-slide>
                        <div class="flex flex-col justify-center">
                            <div class="flex justify-center mb-2">
                                <svg
                                    width="32"
                                    height="32"
                                    viewBox="0 0 32 32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#ultimate_ribbon_ultimate_subscription)">
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M30.5079 15.9178L27.2712 12.2168L27.7222 7.32191L22.9334 6.23416L20.4263 1.98926L15.9161 3.92599L11.4059 1.98926L8.89871 6.22089L4.10993 7.29538L4.56095 12.2035L1.32422 15.9178L4.56095 19.6188L4.10993 24.527L8.89871 25.6148L11.4059 29.8464L15.9161 27.8964L20.4263 29.8331L22.9334 25.6015L27.7222 24.5137L27.2712 19.6188L30.5079 15.9178ZM15.9161 15.9176C17.3813 15.9176 18.5691 14.7298 18.5691 13.2645C18.5691 11.7993 17.3813 10.6114 15.9161 10.6114C14.4508 10.6114 13.263 11.7993 13.263 13.2645C13.263 14.7298 14.4508 15.9176 15.9161 15.9176ZM10.611 19.8977C10.611 18.1334 14.1462 17.2446 15.9171 17.2446C17.688 17.2446 21.2232 18.1334 21.2232 19.8977V20.5609C21.2232 20.9257 20.9247 21.2242 20.5599 21.2242H11.2742C10.9094 21.2242 10.611 20.9257 10.611 20.5609V19.8977Z"
                                            fill="url(#ultimate_ribbon_ultimate_subscription_linear_gradient)" />
                                    </g>
                                    <defs>
                                        <linearGradient
                                            id="ultimate_ribbon_ultimate_subscription_linear_gradient"
                                            x1="15.9161"
                                            y1="1.98926"
                                            x2="15.9161"
                                            y2="29.8464"
                                            gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#ECD676" />
                                            <stop offset="0.24" stop-color="#D39E3A" />
                                            <stop offset="0.655" stop-color="#EDB948" />
                                            <stop offset="1" stop-color="#BB8B32" />
                                        </linearGradient>
                                        <clipPath id="ultimate_ribbon_ultimate_subscription">
                                            <rect width="31.8367" height="31.8367" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <h5 class="text-lg font-bold text-center !text-white">{{ feature.title }}</h5>
                            <div class="mt-5 px-5 max-w-xs mx-auto">
                                <img src="{{ feature.image }}" />
                            </div>
                        </div>
                    </swiper-slide>
                }
            </swiper-container>
        </div>
        <div class="flex flex-col rounded-lg bg-gray-50 p-4 m-4 duration-200 hover:scale-[1.02]">
            <div class="text-black font-bebas-neue text-lg" [translate]="'INCLUDED'" i18n="@@INCLUDED">Included:</div>
            <div class="ml-3 text-sm text-black font-semibold">
                @for (feature of ultimateFeatures; track feature) {
                    <div class="flex flex-col">
                        <div class="flex items-center mb-1">
                            <div class="text-blue mr-3">&#9679;</div>
                            <div>{{ feature.title }}</div>
                        </div>
                    </div>
                }
            </div>
        </div>
        @if (subscription) {
            <div class="flex flex-col rounded-lg bg-gray-50 p-4 m-4 duration-200 hover:scale-[1.02]">
                <div class="ml-3 text-sm text-black font-semibold">
                    @if (subscription.ends_at && !subscription.trial_ends_at) {
                        <span [translate]="'ULTIMATE_UNTIL'" i18n="@@ULTIMATE_UNTIL">Ultimate until</span>
                        {{ subscription.ends_at | date: 'dd-MMM-yyyy' : '' : translateService.currentLang }}
                    }
                    @if (subscription.cycle_ends_at) {
                        <span [translate]="'AUTOMATICALLY_RENEWED_ON'" i18n="@@AUTOMATICALLY_RENEWED_ON"
                            >Automatically renewed on</span
                        >
                        {{ subscription.cycle_ends_at | date: 'dd-MMM-yyyy' : '' : translateService.currentLang }}
                    }
                    @if (subscription.trial_ends_at) {
                        <span [translate]="'TRIAL_UNTIL'" i18n="@@TRIAL_UNTIL">Trial until</span>
                        {{ subscription.trial_ends_at | date: 'dd-MMM-yyyy' : '' : translateService.currentLang }}
                    }
                </div>
            </div>
        }
        <div class="flex rounded-lg bg-gray-50 p-4 m-4 gap-4 text-xs text-black">
            <button (click)="openTermsOfService()" [translate]="'TERMS_OF_SERVICE'" i18n="@@TERMS_OF_SERVICE">
                Terms of Service
            </button>
            <button class="ml-2" (click)="openPrivacyPolicy()" [translate]="'PRIVACY_POLICY'" i18n="@@PRIVACY_POLICY">
                Privacy Policy
            </button>
            @if (devicePlatform !== 'web') {
                <button
                    class="ml-2"
                    (click)="purchases.restore(true)"
                    [translate]="'RESTORE_PURCHASE'"
                    i18n="@@RESTORE_PURCHASE">
                    Restore purchase
                </button>
            }
        </div>
    </div>
</div>
