import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { MenuController, NavController } from '@ionic/angular';
import { AuthService } from '@services/auth.service';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-page-header',
    standalone: true,
    imports: [IconComponent, CommonModule],
    templateUrl: './page-header.component.html',
})
export class PageHeaderComponent implements OnInit {
    @Input() color: 'orange' | 'black' | 'white' | 'transparent' | 'orange-pattern' = 'orange';
    @Input() mainNavigation: 'menu' | 'back' | 'custom' = 'menu';
    @Input() defaultBack: string;
    @Input() safePadding = true;
    @Input() popOnBack = false;

    public authService: AuthService = inject(AuthService);

    private navController: NavController = inject(NavController);
    private menuController: MenuController = inject(MenuController);

    public classes = '';

    public isWeb = environment.isWeb;

    ngOnInit(): void {
        switch (this.color) {
            case 'orange':
                this.classes += 'bg-orange !text-white';
                break;
            case 'white':
                this.classes += 'bg-white text-black';
                break;
            case 'black':
                this.classes += 'bg-black text-white';
                break;
            case 'orange-pattern':
                this.classes += '!text-white';
        }
    }

    toggleMenu() {
        this.menuController.toggle();
    }

    goBack() {
        if (this.popOnBack) {
            this.navController.pop().catch(() => {
                this.navController.navigateRoot(this.defaultBack);
            });
        } else if (this.defaultBack) {
            this.navController.navigateRoot(this.defaultBack);
        } else {
            this.navController.pop();
        }
    }
}
