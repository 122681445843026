import { Injectable, WritableSignal, signal } from '@angular/core';

import { ApiService } from './api.service';
import { SmartDevice, SmartDeviceNetwork } from '@dc-core/dc-backend/dc-classes';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SmartDeviceApiService extends ApiService {
    public hasOmni$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public hasVirt$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public smartDeviceNetworks: SmartDeviceNetwork[] = [];
    public omniScorings: WritableSignal<SmartDevice[]> = signal([]);
    public virtCameras: WritableSignal<SmartDevice[]> = signal([]);

    // Device
    public storeSmartDevice = super.getFetcher().path('/smart-devices').method('post').create();
    public updateSmartDeviceById = super.getFetcher().path('/smart-devices/{smartDeviceId}').method('put').create();
    public deleteSmartDeviceById = super.getFetcher().path('/smart-devices/{smartDeviceId}').method('delete').create();
    public logSmartDeviceUsage = super.getFetcher().path('/smart-devices/log-usage').method('post').create();

    // Network
    public getSmartDeviceNetworks = super.getFetcher().path('/smart-device-networks').method('get').create();
    public storeSmartDeviceNetwork = super.getFetcher().path('/smart-device-networks').method('post').create();
    public showSmartDeviceNetworkById = super
        .getFetcher()
        .path('/smart-device-networks/{smartDeviceNetworkId}')
        .method('get')
        .create();
    public updateSmartDeviceNetworkById = super
        .getFetcher()
        .path('/smart-device-networks/{smartDeviceNetworkId}')
        .method('put')
        .create();
    public deleteSmartDeviceNetworkById = super
        .getFetcher()
        .path('/smart-device-networks/{smartDeviceNetworkId}')
        .method('delete')
        .create();
    public getSmartDeviceNetworkInvites = super
        .getFetcher()
        .path('/smart-device-networks/invites')
        .method('get')
        .create();
    public inviteUserToSmartDeviceNetwork = super
        .getFetcher()
        .path('/smart-device-networks/{smartDeviceNetworkId}/invite')
        .method('post')
        .create();
    public acceptSmartDeviceNetworkInvite = super
        .getFetcher()
        .path('/smart-device-networks/invite/{smartDeviceNetworkUserId}/accept')
        .method('post')
        .create();
    public denySmartDeviceNetworkInvite = super
        .getFetcher()
        .path('/smart-device-networks/invite/{smartDeviceNetworkUserId}/deny')
        .method('post')
        .create();

    setSmartDevices(networks: SmartDeviceNetwork[]) {
        this.smartDeviceNetworks = networks;
        this.virtCameras.set([]);
        this.omniScorings.set([]);
        this.smartDeviceNetworks.forEach((network) => {
            network.devices.forEach((device) => {
                if (device.type === 'virt_cam') {
                    this.virtCameras.update((virtCameras) => [...virtCameras, device]);
                }
                if (device.type === 'omni_scoring') {
                    this.omniScorings.update((omniScorings) => [...omniScorings, device]);
                }
            });
        });

        this.hasVirt$.next(this.virtCameras().length > 0);
        this.hasOmni$.next(this.omniScorings().length > 0);
    }
}
