<div class="flex items-center justify-between">
    <div
        class="flex-1"
        [ngClass]="{
            'font-semibold text-black': mode === 'normal',
            'text-base text-black': mode === 'game_menu',
            'text-sm': mode === 'normal' || mode === 'settings'
        }"
        [class.opacity-60]="disabled">
        <ng-content></ng-content>
    </div>
    <div class="ml-2 flex-none inline-flex items-center">
        <ion-toggle
            mode="ios"
            class="!pr-2"
            style="zoom: 0.75"
            color="secondary"
            [checked]="model"
            [disabled]="disabled"
            (click)="changeItem($event)"></ion-toggle>
    </div>
</div>
