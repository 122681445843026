<div class="ml-3 mr-3 flex items-end">
    <app-select
        title="{{ 'PERIOD' | translate }}"
        [options]="periodOptions"
        [initialValue]="statisticsRange"
        (selectionChange)="changeFilter('statisticsRange', $event)"
        class="mx-1 w-full"></app-select>
    <app-select
        title="{{ 'GAME_TYPE' | translate }}"
        [options]="gameSettingOptions"
        [initialValue]="gameSetting"
        (selectionChange)="changeFilter('gameSetting', $event)"
        class="mx-1 w-full"></app-select>
</div>

<div>
    @if (statisticsRange === 'custom' && auth.user.is_ultimate) {
        <div class="flex gap-4 m-2 text-black">
            <div>
                <ion-select
                    style="overflow: auto; padding: 0px 12px; font-size: 13px; margin-top: 0"
                    interface="popover"
                    [(ngModel)]="year"
                    (ionChange)="changeStatisticsRange('custom')">
                    <ion-select-option *ngFor="let yearOption of years" [value]="yearOption">
                        {{ yearOption }}
                    </ion-select-option>
                </ion-select>
            </div>
            <div>
                <ion-select
                    style="overflow: auto; padding: 0px 12px; font-size: 13px; margin-top: 0"
                    interface="popover"
                    [(ngModel)]="month"
                    (ionChange)="changeStatisticsRange('custom')">
                    <ion-select-option *ngFor="let monthOption of months" [value]="monthOption">
                        {{ monthOption }}
                    </ion-select-option>
                </ion-select>
            </div>
        </div>
    }

    <div class="m-3 text-black">
        <div class="grid grid-cols-9 gap-1 whitespace-nowrap text-sm">
            <div class="col-span-3"></div>
            <div class="col-span-2 text-center font-normal uppercase" [translate]="'AVG'" i18n="@@AVG">Avg.</div>
            <div class="col-span-2 text-center font-normal uppercase" [translate]="'BEST'" i18n="@@BEST">Best</div>
            <div class="col-span-2 text-center font-normal uppercase" [translate]="'WORST'" i18n="@@WORST">Worst</div>
        </div>
        <div class="game-stat-main-table-container">
            <!-- Total score -->
            <div class="col-span-3" [translate]="'TOTAL_SCORE'" i18n="@@TOTAL_SCORE">Total score</div>
            <div class="col-span-2 py-1 text-center text-sm">
                @if (!loadedOverallStats) {
                    <app-loader></app-loader>
                } @else {
                    {{ overallStats.totalScore.average || 0 | number: '1.2-2' }}
                }
            </div>
            <div class="game-specific-detail-stat" (click)="goToDetails('totalScore', 'best')">
                @if (!loadedOverallStats) {
                    <app-loader color="white"></app-loader>
                } @else {
                    {{ overallStats.totalScore.best || 0 }}
                }
            </div>
            <div class="game-specific-detail-stat" (click)="goToDetails('totalScore', 'worst')">
                @if (!loadedOverallStats) {
                    <app-loader color="white"></app-loader>
                } @else {
                    {{ overallStats.totalScore.worst || 0 }}
                }
            </div>
        </div>
        <div class="relative">
            <div class="game-stat-table-container">
                <!-- Avg hits per turn taken -->
                <div class="col-span-3" [translate]="'AVG_HITS_PER_TURN'" i18n="@@AVG_HITS_PER_TURN">
                    Avg. hits per turn
                </div>
                <div class="col-span-2 py-1 text-center text-sm">
                    @if (!loadedOverallStats) {
                        <app-loader></app-loader>
                    } @else {
                        {{ overallStats.avgHitsPerTurn.average || 0 | number: '1.2-2' }}
                    }
                </div>
                <div class="game-specific-detail-stat" (click)="goToDetails('avgHitsPerTurn', 'best')">
                    @if (!loadedOverallStats) {
                        <app-loader color="white"></app-loader>
                    } @else {
                        {{ overallStats.avgHitsPerTurn.best || 0 | number: '1.2-2' }}
                    }
                </div>
                <div class="game-specific-detail-stat" (click)="goToDetails('avgHitsPerTurn', 'worst')">
                    @if (!loadedOverallStats) {
                        <app-loader color="white"></app-loader>
                    } @else {
                        {{ overallStats.avgHitsPerTurn.worst || 0 | number: '1.2-2' }}
                    }
                </div>
                <!-- Avg turns taken -->
                <div class="col-span-3" [translate]="'AVG_TURNS_TAKEN'" i18n="@@AVG_TURNS_TAKEN">Avg. turns taken</div>
                <div class="col-span-2 py-1 text-center text-sm">
                    @if (!loadedOverallStats) {
                        <app-loader></app-loader>
                    } @else {
                        {{ overallStats.avgTurnsTaken.average || 0 | number: '1.2-2' }}
                    }
                </div>
                <div class="game-specific-detail-stat" (click)="goToDetails('avgTurnsTaken', 'best')">
                    @if (!loadedOverallStats) {
                        <app-loader color="white"></app-loader>
                    } @else {
                        {{ overallStats.avgTurnsTaken.best || 0 | number: '1.2-2' }}
                    }
                </div>
                <div class="game-specific-detail-stat" (click)="goToDetails('avgTurnsTaken', 'worst')">
                    @if (!loadedOverallStats) {
                        <app-loader color="white"></app-loader>
                    } @else {
                        {{ overallStats.avgTurnsTaken.worst || 0 | number: '1.2-2' }}
                    }
                </div>
                <!-- Double hit rate taken -->
                <div class="col-span-3" [translate]="'DOUBLE_HIT_RATE'" i18n="@@DOUBLE_HIT_RATE">Double hit rate</div>
                <div class="col-span-2 py-1 text-center text-sm">
                    @if (!loadedOverallStats) {
                        <app-loader></app-loader>
                    } @else {
                        {{ overallStats.doubleHitRate.average || 0 | number: '1.2-2' }}
                    }
                </div>
                <div class="game-specific-detail-stat" (click)="goToDetails('doubleHitRate', 'best')">
                    @if (!loadedOverallStats) {
                        <app-loader color="white"></app-loader>
                    } @else {
                        {{ overallStats.doubleHitRate.best || 0 | number: '1.2-2' }}
                    }
                </div>
                <div class="game-specific-detail-stat" (click)="goToDetails('doubleHitRate', 'worst')">
                    @if (!loadedOverallStats) {
                        <app-loader color="white"></app-loader>
                    } @else {
                        {{ overallStats.doubleHitRate.worst || 0 | number: '1.2-2' }}
                    }
                </div>

                <!-- Upgrade -->
                @if (auth.user.is_ultimate !== true) {
                    <div class="absolute bottom-0 left-0 right-0 top-0 grid w-full grid-cols-9">
                        <div class="top-0 col-span-6 col-start-4 rounded-md backdrop-blur-[5px] bg-[#ffffff2e]">
                            <div
                                (click)="OpenUltimateDialog()"
                                class="flex h-full flex-col items-center justify-center py-0.5">
                                <app-icon icon="closed_lock" size="medium" class="text-black mb-1"></app-icon>
                                <app-primary-button
                                    size="small"
                                    color="blue"
                                    [translate]="'UPGRADE_BTN'"
                                    i18n="@@UPGRADE_BTN"
                                    >Upgrade</app-primary-button
                                >
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
</div>
