/* eslint-disable max-len */
import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Device } from '@capacitor/device';
import { HelpModalComponent } from '@components/help-modal/help-modal.component';
import { User } from '@dc-core/dc-backend/dc-classes';
import { ContactCategory } from '@dc-core/dc-backend/dc-enums';
import { ContactDropdownSelectItem, FrequentlyAskedQuestion } from '@dc-core/dc-statistics/statistics.globals';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { DartCounterAlertService } from 'dc-core/dc-services/alert.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { DCLoadingService } from 'src/providers/DCLoadingService';
import { SocialMediaService } from 'src/providers/SocialMediaService';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-contact',
    templateUrl: 'contact.component.html',
})
export class ContactComponent {
    user: User;
    isGuest = false;
    showForm: boolean = false;

    frequentlyAskedQuestions: FrequentlyAskedQuestion[] = [];

    file: File;

    public contactForm: FormGroup;
    public isWeb: boolean = environment.isWeb;

    categories: ContactDropdownSelectItem[] = [
        {
            label: $localize`:@@CHOOSE_A_CATEGORY:Choose a category`,
            value: null,
        },
        {
            label: $localize`:@@REQUEST_NAME_FOR_MASTERCALLER:Request name for MasterCaller`,
            value: 'mastercaller' as ContactCategory,
            questions: [] as FrequentlyAskedQuestion[],
        },
        {
            label: $localize`:@@Q_ABOUT_ACCOUNT_SUBSCRIPTION:Question about account/subscription`,
            value: 'accounts' as ContactCategory,
            questions: [
                {
                    title: $localize`:@@NO_UPGRADE_TITLE:No upgrade`,
                    question: $localize`:@@NO_UPGRADE_QUESTION:I upgraded through the App Store or Play Store, but why is my upgrade not showing?`,
                    answer: $localize`:@@NO_UPGRADE_ANSWER:1. Open the DartCounter app<br />
2. Go to ’Settings’ in the main menu<br />
3. Go to ’Subscriptions'<br />
4. Click on ‘Restore purchase'<br />
The subscription should be applied on your account now, maybe reboot the DartCounter app and see if your account is upgraded.<br />
If this doesn’t work, follow the next steps:<br />
1. Delete the DartCounter app from your device<br />
2. Install the DartCounter app from the App Store or Google Play<br />
3. Login to your account<br />
4. Go to ’Settings’ in the main menu<br />
5. Go to ’Subscriptions’<br />
6. Click on ‘Restore purchase’<br />`,
                },
                {
                    title: $localize`:@@CANCEL_SUBSCRIPTION_TITLE:Cancel subscription`,
                    question: $localize`:@@CANCEL_SUBSCRIPTION_TITLE:How do I cancel my subscription?`,
                    answer: $localize`:@@CANCEL_SUBSCRIPTION_ANSWER:You can manage your subscription yourself. To do this, log in with your account in the DartCounter app or at https://dartcounter.net. Go to 'Settings' in the main menu and then to Subscriptions. If you use the app, please make sure you have got the latest version installed.`,
                },
            ] as FrequentlyAskedQuestion[],
        },
        {
            label: $localize`:@@REQUEST_A_NEW_FEATURE:Request a new feature`,
            value: 'new_feature' as ContactCategory,
            questions: [
                {
                    title: $localize`:@@REQUEST_NEW_FEATURE_TITLE:Request a feature`,
                    question: $localize`:@@REQUEST_NEW_FEATURE_QUESTION:How do I request a feature?`,
                    answer: $localize`:@@REQUEST_NEW_FEATURE_ANSWER:We have launched a requests platform for passing on ideas for our software. Please check carefully if your idea is already listed to avoid duplicate entries.
If it is, upvote the existing request, otherwise add your own. You can then follow the progress of your request.`,
                    callToAction: $localize`:@@REQUEST_A_FEATURE:Request a feature`,
                    callToActionUrl: 'https://requests.dartcounter.net',
                },
                {
                    title: $localize`:@@WHATS_ON_YOUR_SCHEDULE_TITLE:On our schedule`,
                    question: $localize`:@@WHATS_ON_YOUR_SCHEDULE_QUESTION:What is on your schedule?`,
                    answer: $localize`:@@WHATS_ON_YOUR_SCHEDULE_ANSWER:Curious to know which new features are on our schedule? Take a look at our requests platform, where you can see the status of requests that have been made.`,
                    callToAction: $localize`:@@VIEW_THE_FEEDBACK_PLATFORM:View the requests`,
                    callToActionUrl: 'https://requests.dartcounter.net',
                },
            ] as FrequentlyAskedQuestion[],
        },
        {
            label: $localize`:@@REPORT_A_BUG_ISSUE:Report a bug/issue`,
            value: 'bug_issue' as ContactCategory,
            questions: [] as FrequentlyAskedQuestion[],
        },
        {
            label: $localize`:@@TOURNAMENTS:Tournaments`,
            value: 'tournaments' as ContactCategory,
            questions: [] as FrequentlyAskedQuestion[],
        },
        {
            label: 'Target Omni Scoring',
            value: 'omni_scoring' as ContactCategory,
            questions: [] as FrequentlyAskedQuestion[],
        },
        {
            label: 'Target Virt Camera',
            value: 'virt_cam' as ContactCategory,
            questions: [] as FrequentlyAskedQuestion[],
        },
        {
            label: $localize`:@@OTHER:Other`,
            value: 'other' as ContactCategory,
            questions: [] as FrequentlyAskedQuestion[],
        },
    ];

    private isSending = false;

    private recaptchaV3Service: ReCaptchaV3Service = inject(ReCaptchaV3Service);
    private destroyRef: DestroyRef = inject(DestroyRef);

    constructor(
        public nav: NavController,
        public auth: AuthService,
        public platform: Platform,
        public navCtrl: NavController,
        public loading: DCLoadingService,
        public social: SocialMediaService,
        public modal: ModalController,
        public route: ActivatedRoute,
        private _alertService: DartCounterAlertService,
        private formBuilder: FormBuilder
    ) {
        this.user = this.auth.user;

        this.contactForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: [''],
            email: ['', Validators.required],
            category: [this.categories[0].value, Validators.required],
            subject: [''],
            message: ['', Validators.required],
            file_name: [''],
        });
        if (!this.user || this.user.first_name?.startsWith('guest_')) {
            this.isGuest = true;
        } else {
            this.contactForm.patchValue({
                firstName: this.user.first_name,
                lastName: this.user.last_name,
            });
        }

        this.contactForm.patchValue({
            email: this.user?.email,
        });

        $localize`:@@SUBJECT:Subject`;
        $localize`:@@MESSAGE:Message`;
        $localize`:@@ENTER_YOUR_NAME_HERE:Enter your name here`;
    }

    public updateCategory(category: ContactCategory): void {
        this.showForm = false;
        this.frequentlyAskedQuestions = [];
        this.file = null;
        if (category !== 'other') {
            const selectedCategory = this.categories.find((cat) => cat.value === category);
            if (selectedCategory.questions.length) {
                this.frequentlyAskedQuestions = selectedCategory.questions || [];
            } else {
                this.showForm = true;
            }
        } else {
            this.showForm = true;
        }

        this.contactForm.patchValue({
            category: category,
        });
    }

    onFileChange(event: any) {
        if (event.target.files.length > 0) {
            this.file = event.target.files[0];
            this.contactForm.controls['file_name'].setValue(this.file.name);
        }
    }

    async send() {
        if (this.contactForm.invalid) {
            this._alertService.createAlert({
                title: $localize`:@@FILL_IN_REQUIRED_FIELDS:Please fill in the required fields`,
                icon: 'error',
            });
            return;
        }

        if (this.isSending) {
            return;
        }

        this.isSending = true;

        this.loading.ShowDefaultLoader();

        let deviceInfo = null;
        try {
            deviceInfo = await Device.getInfo();
        } catch (_) {}

        this.recaptchaV3Service
            .execute('contact')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(async (token) => {
                const formData = new FormData();
                formData.append('first_name', this.contactForm.get('firstName').value);
                formData.append('last_name', this.contactForm.get('lastName').value);
                formData.append('email', this.contactForm.get('email').value);
                formData.append('category', this.contactForm.get('category').value);
                formData.append('subject', this.contactForm.get('subject').value);
                formData.append('message', this.contactForm.get('message').value);
                formData.append('platform', environment.isWeb ? 'web' : this.platform.is('ios') ? 'ios' : 'android');
                formData.append(
                    'device',
                    environment.isWeb ? 'website' : this.platform.is('tablet') ? 'tablet' : 'mobile'
                );
                formData.append('version', environment.version);
                formData.append('build', environment.build.toString());
                formData.append('device_model', deviceInfo?.model);
                formData.append('os_version', deviceInfo?.osVersion);
                formData.append('width', this.platform.width().toString());
                formData.append('height', this.platform.height().toString());
                formData.append('recaptcha', token);
                if (this.file) {
                    formData.append('file', this.file);
                }

                try {
                    const response = await fetch(environment.apiUrl + '/contact-us', {
                        body: formData,
                        headers: {
                            Authorization: `Bearer ${this.auth.accessToken}`,
                        },
                        method: 'POST',
                    });
                    if (response.ok) {
                        if (this.contactForm.get('category').value === 'mastercaller') {
                            this._alertService.createAlert({
                                title: $localize`:@@YOUR_NAME_IS_REQUESTED_TO_OUR_MASTERCALLER:Your name is requested to our master caller. It will be added within a week from now. Please keep an eye on the list.`,
                                timer: null,
                                confirmButtonText: $localize`:@@CLOSE:Close`,
                            });
                        } else {
                            this._alertService.createAlert({
                                title: $localize`:@@CONTACTED_US_ASAP_RESPONSE:We have received your message, we will get back to you as soon as possible!`,
                                timer: null,
                                confirmButtonText: $localize`:@@CLOSE:Close`,
                            });
                        }

                        this.contactForm.patchValue({
                            category: null,
                            subject: null,
                            message: null,
                        });

                        setTimeout(() => {
                            this.nav.back();
                        }, 1500);
                    }
                } catch (err) {
                    this._alertService.errorFromApi(err);
                } finally {
                    this.isSending = false;
                    this.loading.DismissLoader();
                }
            });
    }

    showQuestion(question: FrequentlyAskedQuestion) {
        this.modal
            .create({
                component: HelpModalComponent,
                componentProps: {
                    title: question.title,
                    contents: question.answer,
                    button: {
                        text: question.callToAction,
                        url: question.callToActionUrl,
                    },
                },
                cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                backdropDismiss: true,
            })
            .then((elem) => {
                elem.present();
            });
    }

    questionNotPresent() {
        this.frequentlyAskedQuestions = [];
        this.showForm = true;
    }
}
