/* eslint-disable max-len */
import { CommonModule } from '@angular/common';
import { Component, computed, DestroyRef, inject, Input, OnInit, Signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SmartDeviceApiService } from '@dc-api/smart-device.api.service';
import { Tournament } from '@dc-core/dc-backend/dc-classes';
import { DartCounterAlertService } from '@dc-core/dc-services/alert.service';
import { JanusVideoRoomService } from '@dc-core/dc-services/dc-janus/janus-video-room.service';
import { ConnectionStatus, OmniCommunicationService } from '@dc-core/dc-services/omni/omni-communication.service';
import { OmniIngameService } from '@dc-core/dc-services/omni/omni-ingame.service';
import { IonicModule, ModalController, NavController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { LocalCameraService } from '@services/local-camera.service';
import { SmartDeviceService } from '@services/smart-device.service';
import { OmniConnectionLostDialogComponent } from 'src/app/modules/smart-devices/dialogs/omni-connection-lost.dialog/omni-connection-lost.dialog';
import { OmniActivationDialogComponent } from 'src/dialogs/omni-activation-dialog/omni-activation.dialog';
import { environment } from 'src/environments/environment';
import { SmartDeviceStatus } from 'src/pages/online/online-games/online-games.component';

import { PrimaryButtonComponent } from '../../../../primary-button/primary-button.component';
import { DialogViewComponent } from '../../../../shared/components/dialog/dialog-view/dialog-view.component';
import { IconComponent } from '../../../../shared/components/icon/icon.component';

export type OnlineGameJoinRequirementsCheckDialogPayload = {
    cameraOnly: boolean;
    omniScoringOnly: boolean;
    cameraOrOmniScoringOnly: boolean;
    tournament?: Tournament;
};

@Component({
    selector: 'app-online-game-join-requirements-check-dialog',
    templateUrl: 'online-game-join-requirements-check.dialog.html',
    standalone: true,
    imports: [CommonModule, IconComponent, PrimaryButtonComponent, IonicModule, TranslateModule, DialogViewComponent],
})
export class OnlineGameJoinRequirementsCheckDialogComponent implements OnInit {
    @Input() title = $localize`:@@JOIN_REQUIREMENTS:Join requirements`;
    @Input() cameraOnly: boolean;
    @Input() omniScoringOnly: boolean;
    @Input() cameraOrOmniScoringOnly: boolean;
    @Input() tournament: Tournament;

    public videoRoomService: JanusVideoRoomService = inject(JanusVideoRoomService);
    public omniIngameService: OmniIngameService = inject(OmniIngameService);

    private modalController: ModalController = inject(ModalController);
    private alertService: DartCounterAlertService = inject(DartCounterAlertService);
    private smartDeviceService: SmartDeviceService = inject(SmartDeviceService);
    private smartDeviceApiService: SmartDeviceApiService = inject(SmartDeviceApiService);
    private omniCommunicationService: OmniCommunicationService = inject(OmniCommunicationService);
    private localCameraService: LocalCameraService = inject(LocalCameraService);
    private navController: NavController = inject(NavController);

    readonly SmartDeviceStatus = SmartDeviceStatus;

    public omniStatus: Signal<SmartDeviceStatus> = computed(() => this.smartDeviceService.omniStatus());

    protected destroyRef: DestroyRef = inject(DestroyRef);

    public ngOnInit(): void {
        this.smartDeviceApiService.hasOmni$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((hasOmni) => {
            if (hasOmni) {
                if (!this.omniIngameService.omniConnected()) {
                    this.smartDeviceService.omniStatus.set(SmartDeviceStatus.OWNED);
                } else {
                    this.smartDeviceService.omniStatus.set(SmartDeviceStatus.ACTIVATED);
                }
            }
        });
    }

    public linkCamera(): void {
        this.localCameraService.linkCamera(false);
    }

    public unlinkCamera(): void {
        this.localCameraService.unlinkCamera(false);
    }

    public activateOmni(): void {
        const networks = this.smartDeviceService.getNetworksByType(['omni_scoring']);
        if (!networks.totalDevices) {
            return;
        }

        if (environment.isWeb) {
            this.alertService.createAlert({
                title: $localize`:@@FEATURE_NOT_SUPPORTED_ON_WEB_YET:This feature not supported on the web version yet.`,
                icon: 'error',
            });
            return;
        }

        if (!this.omniIngameService.omniConnected()) {
            if (networks.totalDevices === 1) {
                const connectionSub = this.omniCommunicationService.connectionStatus$
                    .pipe(takeUntilDestroyed(this.destroyRef))
                    .subscribe((connectionStatus) => {
                        if (connectionStatus === ConnectionStatus.CONNECTED) {
                            this.alertService.clearAlerts();
                            connectionSub.unsubscribe();

                            this.checkOmniConnectionStatus(connectionStatus);
                        } else if (connectionStatus === ConnectionStatus.DISCONNECTED) {
                            this.alertService.clearAlerts();
                            connectionSub.unsubscribe();

                            this.checkOmniConnectionStatus(connectionStatus);
                        }
                    });

                this.omniCommunicationService.loadAndConnect(networks.networks[0].devices[0]);
                return;
            }

            this.modalController
                .create({
                    component: OmniActivationDialogComponent,
                    componentProps: {
                        startGameAfterConnection: false, //Dont start watching darts, as you're still in the lobby
                    },
                    cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                    backdropDismiss: true,
                })
                .then((elem) => {
                    elem.present();
                    elem.onDidDismiss().then((dialogRes) => {
                        const connectionStatus = dialogRes.data as ConnectionStatus;
                        if (connectionStatus) {
                            this.checkOmniConnectionStatus(connectionStatus);
                        }
                    });
                });
        }
    }

    public setupSmartDevice(): void {
        this.dismiss();
        this.navController.navigateForward('smart-devices');
    }

    public confirm(): void {
        if (this.tournament && environment.local) {
            this.dismiss(true);
            return;
        }

        const cameraEnabled =
            this.videoRoomService.ownCamera.roomID &&
            this.videoRoomService.ownUserMedia?.videoStreams?.hasStreams &&
            this.videoRoomService.ownUserMedia?.videoStreams?.activeStreams === true;
        const omniEnabled = this.omniIngameService.omniConnected();

        if (this.cameraOnly && !cameraEnabled) {
            this.alertService.createAlert({
                title: $localize`:@@NEED_TO_HAVE_AN_CAMERA_ACTIVATED_TO_JOIN:You need to have a camera activated to join`,
                icon: 'error',
            });
            return;
        }

        if (this.omniScoringOnly && !omniEnabled) {
            this.alertService.createAlert({
                title: $localize`:@@NEED_TO_HAVE_AN_OMNI_ACTIVATED_TO_JOIN:You need to have an OMNI activated to join`,
                icon: 'error',
            });
            return;
        }

        if (this.cameraOrOmniScoringOnly && !cameraEnabled && !omniEnabled) {
            this.alertService.createAlert({
                title: $localize`:@@NEED_TO_HAVE_A_CAMERA_OR_OMNI_ACTIVATED_TO_JOIN:You need to have a camera or an OMNI activated to join`,
                icon: 'error',
            });
            return;
        }

        this.dismiss(true);
    }

    public dismiss(returnValue: any = null): void {
        this.modalController.dismiss(returnValue);
    }

    private checkOmniConnectionStatus(connectionStatus: ConnectionStatus): void {
        if (connectionStatus === ConnectionStatus.CONNECTED) {
            this.smartDeviceService.omniStatus.set(SmartDeviceStatus.ACTIVATED);
        } else {
            this.modalController
                .create({
                    component: OmniConnectionLostDialogComponent,
                    cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                    showBackdrop: true,
                    backdropDismiss: true,
                })
                .then((elem) => {
                    elem.present();
                });
        }
    }
}
