<div class="select-container">
    @if (title) {
        <div
            class="mb-1 text-sm uppercase text-black"
            [class.text-left]="titleAlign === 'left'"
            [class.text-center]="titleAlign === 'center'">
            {{ title }}
        </div>
    }

    @if (activeOption) {
        <div
            class="select rounded-t-md rounded-b-md"
            [class.rounded-b-none]="showOptions && relativePositioning()"
            [ngClass]="{
                'bg-white': mode === 'white',
                'bg-neutral-50': mode === 'gray',
                'text-black dark:bg-neutral-800': mode === 'white' || mode === 'gray',
                'dialog-input': mode === 'dialog-input',
                'w-full p-4': size === 'full',
                'py-1 pl-3 pr-2 text-sm': size !== 'full',
                'border border-red': error,
                relative: relativePositioning()
            }">
            <div
                (click)="toggleOptions()"
                class="flex flex-row items-center justify-between whitespace-nowrap cursor-pointer">
                <div class="whitespace-normal">{{ activeOption.label }}</div>
                <div class="flex items-center space-x-2">
                    @if (activeOption.unverifiedGames > 0) {
                        <ion-badge mode="md" class="ion-bg-yellow ion-text-black">
                            {{ activeOption.unverifiedGames }}
                        </ion-badge>
                    }

                    <app-icon
                        [icon]="showOptions ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
                        class="text-neutral-800 !p-0"></app-icon>
                </div>
            </div>

            @if (showOptions) {
                <ul
                    class="text-md z-10 !w-full rounded-b-md py-2 pt-1.5 overflow-y-auto"
                    [ngClass]="{
                        'bg-white': mode === 'white',
                        'dark:bg-neutral-800': mode === 'white' || mode === 'gray',
                        'bg-neutral-50 ': mode === 'gray' || mode === 'dialog-input',
                        'w-full': size === 'full',
                        'top-full': relativePositioning(),
                        'max-h-full': !relativePositioning(),
                        'top-[4.315rem]': !relativePositioning()
                    }">
                    @for (option of options; track $index) {
                        @if (option.alwaysShow || (option.value && !option.hidden)) {
                            <li
                                (click)="emitValue(option.value)"
                                class="flex flex-row items-center justify-between px-4 py-2 cursor-pointer hover:bg-neutral-100"
                                [ngClass]="{
                                    'dark:hover:bg-neutral-950': mode === 'white' || mode === 'gray',
                                    'bg-neutral-50 dark:bg-neutral-900':
                                        (mode === 'white' || mode === 'gray') && option.value === activeOption.value,
                                    'bg-neutral-100': mode === 'dialog-input' && option.value === activeOption.value
                                }">
                                {{ option.label }}

                                <div class="flex items-center space-x-2">
                                    @if (option.unverifiedGames > 0) {
                                        <ion-badge mode="md" class="ion-bg-yellow ion-text-black">
                                            {{ option.unverifiedGames }}
                                        </ion-badge>
                                    }

                                    @if (option.ultimateOnly && !isUltimate) {
                                        <div class="h-4 w-4">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="ionicon"
                                                viewBox="0 0 512 512">
                                                <path
                                                    d="M336 208v-95a80 80 0 00-160 0v95"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="32" />
                                                <rect
                                                    x="96"
                                                    y="208"
                                                    width="320"
                                                    height="272"
                                                    rx="48"
                                                    ry="48"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="32" />
                                            </svg>
                                        </div>
                                    }
                                </div>
                            </li>
                        }
                    }
                </ul>
            }
        </div>
    }
</div>
