<modal-content scrollY="false" fullscreen>
    <div class="auto-height-dialog">
        <div class="title-container">
            <div class="title" *ngIf="title">{{ title }}</div>
        </div>
        <div class="mt-8 space-y-2">
            @if (!authService.user.accepted_terms_at) {
                <app-checkbox class="block" [model]="agreements" color="white" (itemChange)="agreements = !agreements">
                    <div class="flex flex-wrap text-black">
                        <span [translate]="'I_AGREE_TO_THE'" i18n="@@I_AGREE_TO_THE">I agree to the</span>
                        <a
                            class="text-orange hover:!underline ml-1"
                            (click)="openTermsOfService($event)"
                            [translate]="'TERMS_OF_SERVICE'"
                            i18n="@@TERMS_OF_SERVICE">
                            Terms of Service
                        </a>
                        <span>,</span>
                        <a
                            class="text-orange hover:!underline mx-1"
                            [translate]="'PRIVACY_POLICY'"
                            i18n="@@PRIVACY_POLICY"
                            (click)="openPrivacyPolicy($event)">
                            Privacy Policy
                        </a>
                        <span [translate]="'AND'" i18n="@@AND">and</span>
                        <a
                            class="text-orange hover:!underline mx-1"
                            [translate]="'CODE_OF_CONDUCT'"
                            i18n="@@CODE_OF_CONDUCT"
                            (click)="openCodeOfConduct($event)">
                            Code of Conduct
                        </a>
                    </div>
                </app-checkbox>
            }

            @if (authService.user.email) {
                <app-checkbox
                    class="block"
                    [model]="newsletterOptIn"
                    color="white"
                    (itemChange)="newsletterOptIn = !newsletterOptIn">
                    <div class="flex text-black" [translate]="'NEWSLETTER_OPT_IN_INFO'" i18n="@@NEWSLETTER_OPT_IN_INFO">
                        Yes, I want to hear about exclusive offers, announcements and the newest products from Target
                        DartCounter, as well as those tailored to my account and activity.
                    </div>
                </app-checkbox>

                <app-checkbox
                    class="block"
                    [model]="targetNewsletterOptIn"
                    color="white"
                    (itemChange)="targetNewsletterOptIn = !targetNewsletterOptIn">
                    <div
                        class="flex text-black"
                        [translate]="'TARGET_NEWSLETTER_OPT_IN_INFO'"
                        i18n="@@TARGET_NEWSLETTER_OPT_IN_INFO">
                        Yes, I wish to be subscribe to Target Darts newsletter and hear about the latest Target Darts
                        products, offers and darts information.
                    </div>
                </app-checkbox>
            }
        </div>
        <div class="button-container">
            <app-primary-button
                size="normal"
                addedClasses="w-full"
                color="orange"
                (click)="continue()"
                [translate]="'CONTINUE'"
                i18n="@@CONTINUE">
                Continue
            </app-primary-button>
        </div>
    </div>
</modal-content>
