import { User } from 'dc-core/dc-backend/dc-classes';
import { GameStatistics } from '../../game-statistics.classes';
import { StartScoreOption } from '../../statistics.globals';
import { TournamentStatisticsApiService } from '@dc-api/tournament-statistics.api.service';

export interface TournamentMatchOverallStatsData {
    tournament: { played?: number; won?: number };
    game: { wins?: number; losses?: number };
    threeDartAvg: { average?: number; best?: number; worst?: number };
    firstNineAvg: { average?: number; best?: number; worst?: number };
    checkoutRate: { average?: number; best?: number; worst?: number };
    checkouts: { average?: number; best?: number; worst?: number };
}

export type MatchStatDetailType = 'threeDartAvg' | 'firstNineAvg' | 'checkoutRate' | 'checkouts';

export interface TournamentMatchStatDetails {
    startScore: number;
}

export class TournamentMatchOverallStats extends GameStatistics {
    public startScoreOption: StartScoreOption = '501';
    public startScore: number = 170;

    public overallStats: TournamentMatchOverallStatsData = {
        tournament: {},
        game: {},
        threeDartAvg: {},
        firstNineAvg: {},
        checkoutRate: {},
        checkouts: {},
    } as any;

    public loadedOverallStats: boolean = false;
    public tournamentStatisticsApiService: TournamentStatisticsApiService;

    private _localStorageKey = 'tournamentMatchOverallStats';

    constructor(user: User, tournamentStatisticsApiService: TournamentStatisticsApiService) {
        super(user);
        this.tournamentStatisticsApiService = tournamentStatisticsApiService;
    }

    setFilters(): void {
        let savedFilters = JSON.parse(localStorage.getItem(this._localStorageKey));
        if (savedFilters) {
            this.startScoreOption = savedFilters.startScoreOption ?? this.startScoreOption;
            this.startScore = savedFilters.startScore ?? this.startScore;
        }
        super.setFilters();
    }

    saveFilters() {
        localStorage.setItem(
            this._localStorageKey,
            JSON.stringify({
                startScoreOption: this.startScoreOption,
                startScore: this.startScore,
            })
        );
        super.saveFilters();
    }

    async loadOverallStats(): Promise<void> {
        this.loadedOverallStats = false;
        await this.initGeneral();
        await this.initThreeDartAverage();
        if (this.user.is_ultimate) {
            await this.initFirstNineAverage();
            await this.initCheckoutRate();
            await this.initCheckouts();
        }
        this.loadedOverallStats = true;
        this.saveFilters();
    }

    async initGeneral(): Promise<void> {
        await this.tournamentStatisticsApiService
            .getTournamentStatistics({
                game_mode: 'match',
                from_date: this.fromDate,
                until_date: this.untilDate,
            })
            .then((res) => {
                this.overallStats.tournament.played = res.data.played;
                this.overallStats.tournament.won = res.data.won;
            })
            .catch((e) => {
                console.error(e);
            });
        await this.tournamentStatisticsApiService
            .getTournamentMatchStatistics({
                start_score: this.getStartScore(),
                from_date: this.fromDate,
                until_date: this.untilDate,
            })
            .then((res) => {
                this.overallStats.game.wins = res.data.wins;
                this.overallStats.game.losses = res.data.losses;
            })
            .catch((e) => {
                console.error(e);
            });
    }

    async initThreeDartAverage(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.allStatTypes.forEach(async (type, index) => {
                await this.tournamentStatisticsApiService
                    .getTournamentMatchThreeDartAvgStatistics({
                        type,
                        start_score: this.getStartScore(),
                        limit: 1,
                        from_date: this.fromDate,
                        until_date: this.untilDate,
                    })
                    .then((res) => {
                        this.overallStats.threeDartAvg[type] = res.data[0] ? res.data[0].value : 0;
                    })
                    .catch((e) => {
                        console.error(e);
                    });

                if (index === this.allStatTypes.length - 1) {
                    resolve();
                }
            });
        });
    }

    async initFirstNineAverage(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.allStatTypes.forEach(async (type, index) => {
                await this.tournamentStatisticsApiService
                    .getTournamentMatchFirstNineAvgStatistics({
                        type,
                        start_score: this.getStartScore(),
                        limit: 1,
                        from_date: this.fromDate,
                        until_date: this.untilDate,
                    })
                    .then((res) => {
                        this.overallStats.firstNineAvg[type] = res.data[0] ? res.data[0].value : 0;
                    })
                    .catch((e) => {
                        console.error(e);
                    });

                if (index === this.allStatTypes.length - 1) {
                    resolve();
                }
            });
        });
    }

    async initCheckoutRate(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.allStatTypes.forEach(async (type, index) => {
                await this.tournamentStatisticsApiService
                    .getTournamentMatchCheckoutRateStatistics({
                        type,
                        start_score: this.getStartScore(),
                        limit: 1,
                        from_date: this.fromDate,
                        until_date: this.untilDate,
                    })
                    .then((res) => {
                        this.overallStats.checkoutRate[type] = res.data[0] ? res.data[0].value : 0;
                    })
                    .catch((e) => {
                        console.error(e);
                    });

                if (index === this.allStatTypes.length - 1) {
                    resolve();
                }
            });
        });
    }

    async initCheckouts(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.allStatTypes.forEach(async (type, index) => {
                await this.tournamentStatisticsApiService
                    .getTournamentMatchCheckoutsStatistics({
                        type,
                        start_score: this.getStartScore(),
                        limit: 1,
                        from_date: this.fromDate,
                        until_date: this.untilDate,
                    })
                    .then((res) => {
                        this.overallStats.checkouts[type] = res.data[0] ? res.data[0].value : 0;
                    })
                    .catch((e) => {
                        console.error(e);
                    });

                if (index === this.allStatTypes.length - 1) {
                    resolve();
                }
            });
        });
    }

    updateStartScoreOption(startScoreOption: StartScoreOption): void {
        this.startScoreOption = startScoreOption;
    }

    updateStartScore(startScore: number = null): void {
        this.startScore = startScore;
    }

    getStartScore(): number | null {
        switch (this.startScoreOption) {
            case 'all':
                return null;
            case '501':
                return 501;
            case '701':
                return 701;
            case 'custom':
                return this.startScore;
        }
    }
}
