<div class="flex min-h-4 items-center justify-center space-x-1">
    <span class="sr-only">Loading...</span>
    <div
        style="height: {{ rem() }}rem; width: {{ rem() }}rem"
        class="animate-bounce rounded-full [animation-delay:-0.3s]"
        [ngClass]="{
            'bg-black': color() === 'theme',
            '!bg-white': color() === 'white',
            '!bg-orange': color() === 'orange'
        }"></div>
    <div
        style="height: {{ rem() }}rem; width: {{ rem() }}rem"
        class="animate-bounce rounded-full [animation-delay:-0.15s]"
        [ngClass]="{
            'bg-black': color() === 'theme',
            '!bg-white': color() === 'white',
            '!bg-orange': color() === 'orange'
        }"></div>
    <div
        style="height: {{ rem() }}rem; width: {{ rem() }}rem"
        class="animate-bounce rounded-full"
        [ngClass]="{
            'bg-black': color() === 'theme',
            '!bg-white': color() === 'white',
            '!bg-orange': color() === 'orange'
        }"></div>
</div>
