import { Directive, effect, ElementRef, input, InputSignal } from '@angular/core';
import { environment } from 'src/environments/environment';

@Directive({
    selector: '[appLargeWebContainer]',
    standalone: true,
})
export class LargeWebContainerDirective {
    public appLargeWebContainer: InputSignal<boolean> = input(true);

    constructor(private el: ElementRef) {
        effect(() => {
            if (environment.isWeb) {
                const isActive = this.appLargeWebContainer();
                if (isActive) {
                    this.el.nativeElement.classList.add('w-full', 'max-w-3xl', 'mx-auto');
                } else {
                    this.el.nativeElement.classList.remove('w-full', 'max-w-3xl', 'mx-auto');
                }
            }
        });
    }
}
